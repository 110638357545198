const GET_CATEGORIES_START = 'GET_CATEGORIES_START';
const GET_CATEGORIES_SUCCESS = 'GET_CATEGORIES_SUCCESS';
const GET_CATEGORIES_FAILURE = 'GET_CATEGORIES_FAILURE';

const GET_BRAINSTORM_START = 'GET_BRAINSTORM_START';
const GET_BRAINSTORM_SUCCESS = 'GET_BRAINSTORM_SUCCESS';
const GET_BRAINSTORM_FAILURE = 'GET_BRAINSTORM_FAILURE';

const GET_BRAINSTORM_BY_PARICIPANT_START = 'GET_BRAINSTORM_BY_PARICIPANT_START';
const GET_BRAINSTORM_BY_PARICIPANT_SUCCESS =
  'GET_BRAINSTORM_BY_PARICIPANT_SUCCESS';
const GET_BRAINSTORM_BY_PARICIPANT_FAILURE =
  'GET_BRAINSTORM_BY_PARICIPANT_FAILURE';

const GET_BRAINSTORMS_START = 'GET_BRAINSTORMS_START';
const GET_BRAINSTORMS_SUCCESS = 'GET_BRAINSTORMS_SUCCESS';
const GET_BRAINSTORMS_FAILURE = 'GET_BRAINSTORMS_FAILURE';

const GET_BRAINSTORMS_NEXT_START = 'GET_BRAINSTORMS_NEXT_START';
const GET_BRAINSTORMS_NEXT_SUCCESS = 'GET_BRAINSTORMS_NEXT_SUCCESS';
const GET_BRAINSTORMS_NEXT_FAILURE = 'GET_BRAINSTORMS_NEXT_FAILURE';

const RESET_BRAINSTORMS = 'RESET_BRAINSTORMS';

const GET_BRAINSTORM_IMAGE_PRESET_START = 'GET_BRAINSTORM_IMAGE_PRESET_START';
const GET_BRAINSTORM_IMAGE_PRESET_SUCCESS =
  'GET_BRAINSTORM_IMAGE_PRESET_SUCCESS';
const GET_BRAINSTORM_IMAGE_PRESET_FAILURE =
  'GET_BRAINSTORM_IMAGE_PRESET_FAILURE';

const POST_BRAINSTORM_START = 'POST_BRAINSTORM_START';
const POST_BRAINSTORM_SUCCESS = 'POST_BRAINSTORM_SUCCESS';
const POST_BRAINSTORM_FAILURE = 'POST_BRAINSTORM_FAILURE';

const PUT_BRAINSTORM_START = 'PUT_BRAINSTORM_START';
const PUT_BRAINSTORM_SUCCESS = 'PUT_BRAINSTORM_SUCCESS';
const PUT_BRAINSTORM_FAILURE = 'PUT_BRAINSTORM_FAILURE';

const DELETE_BRAINSTORM_START = 'DELETE_BRAINSTORM_START';
const DELETE_BRAINSTORM_SUCCESS = 'DELETE_BRAINSTORM_SUCCESS';
const DELETE_BRAINSTORM_FAILURE = 'DELETE_BRAINSTORM_FAILURE';

const VOID_BRAINSTORM = 'VOID_BRAINSTORM';

const POST_BRAINSTORM_MODULES_START = 'POST_BRAINSTORM_MODULES_START';
const POST_BRAINSTORM_MODULES_SUCCESS = 'POST_BRAINSTORM_MODULES_SUCCESS';
const POST_BRAINSTORM_MODULES_FAILURE = 'POST_BRAINSTORM_MODULES_FAILURE';

const PUT_BRAINSTORM_MODULES_START = 'PUT_BRAINSTORM_MODULES_START';
const PUT_BRAINSTORM_MODULES_SUCCESS = 'PUT_BRAINSTORM_MODULES_SUCCESS';
const PUT_BRAINSTORM_MODULES_FAILURE = 'PUT_BRAINSTORM_MODULES_FAILURE';

const DELETE_BRAINSTORM_MODULES_START = 'DELETE_BRAINSTORM_MODULES_START';
const DELETE_BRAINSTORM_MODULES_SUCCESS = 'DELETE_BRAINSTORM_MODULES_SUCCESS';
const DELETE_BRAINSTORM_MODULES_FAILURE = 'DELETE_BRAINSTORM_MODULES_FAILURE';

const POST_BRAINSTORM_MODULE_SORT = 'POST_BRAINSTORM_MODULE_SORT';

const POST_BRAINSTORM_IMAGE_START = 'POST_BRAINSTORM_IMAGE_START';
const POST_BRAINSTORM_IMAGE_SUCCESS = 'POST_BRAINSTORM_IMAGE_SUCCESS';
const POST_BRAINSTORM_IMAGE_FAILURE = 'POST_BRAINSTORM_IMAGE_FAILURE';

const PUT_BRAINSTORM_IMAGE_START = 'PUT_BRAINSTORM_IMAGE_START';
const PUT_BRAINSTORM_IMAGE_SUCCESS = 'PUT_BRAINSTORM_IMAGE_SUCCESS';
const PUT_BRAINSTORM_IMAGE_FAILURE = 'PUT_BRAINSTORM_IMAGE_FAILURE';

const POST_BRAINSTORM_IMAGE_PRESET_START = 'POST_BRAINSTORM_IMAGE_PRESET_START';
const POST_BRAINSTORM_IMAGE_PRESET_SUCCESS =
  'POST_BRAINSTORM_IMAGE_PRESET_SUCCESS';
const POST_BRAINSTORM_IMAGE_PRESET_FAILURE =
  'POST_BRAINSTORM_IMAGE_PRESET_FAILURE';

const PUT_BRAINSTORM_IMAGE_PRESET_START = 'PUT_BRAINSTORM_IMAGE_PRESET_START';
const PUT_BRAINSTORM_IMAGE_PRESET_SUCCESS =
  'PUT_BRAINSTORM_IMAGE_PRESET_SUCCESS';
const PUT_BRAINSTORM_IMAGE_PRESET_FAILURE =
  'PUT_BRAINSTORM_IMAGE_PRESET_FAILURE';

const POST_BRAINSTORM_DOCUMENTS_START = 'POST_BRAINSTORM_DOCUMENTS_START';
const POST_BRAINSTORM_DOCUMENTS_SUCCESS = 'POST_BRAINSTORM_DOCUMENTS_SUCCESS';
const POST_BRAINSTORM_DOCUMENTS_FAILURE = 'POST_BRAINSTORM_DOCUMENTS_FAILURE';

const DELETE_BRAINSTORM_DOCUMENTS_START = 'DELETE_BRAINSTORM_DOCUMENTS_START';
const DELETE_BRAINSTORM_DOCUMENTS_SUCCESS =
  'DELETE_BRAINSTORM_DOCUMENTS_SUCCESS';
const DELETE_BRAINSTORM_DOCUMENTS_FAILURE =
  'DELETE_BRAINSTORM_DOCUMENTS_FAILURE';

const POST_BRAINSTORM_INVITATION_START = 'POST_BRAINSTORM_INVITATION_START';
const POST_BRAINSTORM_INVITATION_SUCCESS = 'POST_BRAINSTORM_INVITATION_SUCCESS';
const POST_BRAINSTORM_INVITATION_FAILURE = 'POST_BRAINSTORM_INVITATION_FAILURE';

export {
  GET_CATEGORIES_START,
  GET_CATEGORIES_SUCCESS,
  GET_CATEGORIES_FAILURE,
  GET_BRAINSTORM_START,
  GET_BRAINSTORM_SUCCESS,
  GET_BRAINSTORM_FAILURE,
  GET_BRAINSTORM_BY_PARICIPANT_START,
  GET_BRAINSTORM_BY_PARICIPANT_SUCCESS,
  GET_BRAINSTORM_BY_PARICIPANT_FAILURE,
  GET_BRAINSTORMS_START,
  GET_BRAINSTORMS_SUCCESS,
  GET_BRAINSTORMS_FAILURE,
  GET_BRAINSTORMS_NEXT_START,
  GET_BRAINSTORMS_NEXT_SUCCESS,
  GET_BRAINSTORMS_NEXT_FAILURE,
  RESET_BRAINSTORMS,
  GET_BRAINSTORM_IMAGE_PRESET_START,
  GET_BRAINSTORM_IMAGE_PRESET_SUCCESS,
  GET_BRAINSTORM_IMAGE_PRESET_FAILURE,
  VOID_BRAINSTORM,
  POST_BRAINSTORM_START,
  POST_BRAINSTORM_SUCCESS,
  POST_BRAINSTORM_FAILURE,
  DELETE_BRAINSTORM_START,
  DELETE_BRAINSTORM_SUCCESS,
  DELETE_BRAINSTORM_FAILURE,
  PUT_BRAINSTORM_START,
  PUT_BRAINSTORM_SUCCESS,
  PUT_BRAINSTORM_FAILURE,
  POST_BRAINSTORM_MODULES_START,
  POST_BRAINSTORM_MODULES_SUCCESS,
  POST_BRAINSTORM_MODULES_FAILURE,
  PUT_BRAINSTORM_MODULES_START,
  PUT_BRAINSTORM_MODULES_SUCCESS,
  PUT_BRAINSTORM_MODULES_FAILURE,
  POST_BRAINSTORM_MODULE_SORT,
  DELETE_BRAINSTORM_MODULES_START,
  DELETE_BRAINSTORM_MODULES_SUCCESS,
  DELETE_BRAINSTORM_MODULES_FAILURE,
  POST_BRAINSTORM_IMAGE_START,
  POST_BRAINSTORM_IMAGE_SUCCESS,
  POST_BRAINSTORM_IMAGE_FAILURE,
  PUT_BRAINSTORM_IMAGE_START,
  PUT_BRAINSTORM_IMAGE_SUCCESS,
  PUT_BRAINSTORM_IMAGE_FAILURE,
  POST_BRAINSTORM_IMAGE_PRESET_START,
  POST_BRAINSTORM_IMAGE_PRESET_SUCCESS,
  POST_BRAINSTORM_IMAGE_PRESET_FAILURE,
  PUT_BRAINSTORM_IMAGE_PRESET_START,
  PUT_BRAINSTORM_IMAGE_PRESET_SUCCESS,
  PUT_BRAINSTORM_IMAGE_PRESET_FAILURE,
  POST_BRAINSTORM_DOCUMENTS_START,
  POST_BRAINSTORM_DOCUMENTS_SUCCESS,
  POST_BRAINSTORM_DOCUMENTS_FAILURE,
  DELETE_BRAINSTORM_DOCUMENTS_START,
  DELETE_BRAINSTORM_DOCUMENTS_SUCCESS,
  DELETE_BRAINSTORM_DOCUMENTS_FAILURE,
  POST_BRAINSTORM_INVITATION_START,
  POST_BRAINSTORM_INVITATION_SUCCESS,
  POST_BRAINSTORM_INVITATION_FAILURE,
};
