const card = {
  status: 'initial',
  error: null,
  data: null,
};

const invoice = {
  status: 'initial',
  error: null,
  data: null,
};

const customer = {
  status: 'initial',
  error: null,
  data: null,
};

export default {
  card,
  invoice,
  customer,
};
