import React, { useState, useEffect, useMemo } from 'react';

import { isValidElementType } from 'react-is';

import {
  Transition as SpringTransition,
  animated,
} from 'react-spring/renderprops';

const Mount = ({
  component = 'div',
  children,
  animationOption = {},
  style = {},
  ...rest
}) => {
  const [show, set] = useState(false);

  const Wrapper = useMemo(() => {
    const idValidHTMLTag =
      typeof component === 'string' && isValidElementType(component);
    const isReactComponent = isValidElementType(component);

    return idValidHTMLTag
      ? animated[component]
      : isReactComponent
      ? animated(component)
      : animated.div;
  }, [component]);

  useEffect(() => {
    set(true);
  }, []);

  const animateOnMount = useMemo(
    () => !!animationOption && !animationOption.disable,
    []
  );

  if (!animateOnMount) {
    return (
      <Wrapper style={style} {...rest}>
        {children}
      </Wrapper>
    );
  }

  return (
    <SpringTransition items={show} {...animationOption}>
      {(item) =>
        item &&
        ((props) => (
          <Wrapper style={{ ...style, ...props }} {...rest}>
            {children}
          </Wrapper>
        ))
      }
    </SpringTransition>
  );
};

export default Mount;
