const getPattern = (provider) => {
  switch (provider) {
    case 'youtube':
      return /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
    case 'vimeo':
      return /^(?:https?:\/\/)?(?:m\.|www\.)?vimeo\.com\/(.*)$/;
    case 'soundcloud':
      return /^(?:https?:\/\/)?(?:m\.|www\.)?soundcloud\.com\/(.*)$/;
    case 'spotify':
      return /^(?:https?:\/\/)?(?:embed\.|open\.)(?:spotify\.com\/)(?:track\/|\?uri=spotify:track:)((\w|-){22})/;
    default:
      return null;
  }
};

const isProvider = (option) => (string) => {
  let value = string.trim();

  if (Array.isArray(option)) {
    return option.some((provider) => {
      const pattern = getPattern(provider);

      if (!pattern) return true;

      return pattern.test(value);
    });
  }
  if (typeof option === 'string') {
    const pattern = getPattern(option);

    if (!pattern) return true;

    return pattern.test(value);
  }

  return false;
};

export default isProvider;
