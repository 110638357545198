import React from 'react';

const withContext = (Component, Provider, providerProps) => {
  const WrappedComponent = (props) => (
    <Provider {...providerProps}>
      <Component {...props} />
    </Provider>
  );

  WrappedComponent.displayName = `ComponentWithContext`;

  return WrappedComponent;
};

export default withContext;
