const isURL = (string) => {
  if (typeof string !== 'string') return false;

  let value = string.trim();

  const urlPattern = /(http(s)?:\/\/.)(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;
  if (!urlPattern.test(value)) return false;

  return true;
};

export default isURL;
