import React, { useContext, Suspense, useEffect, useState } from 'react';
import {
  Switch,
  Route,
  withRouter,
  Redirect,
  useLocation,
  useHistory,
} from 'react-router-dom';

import routes from 'Abstracts/routes';
import { ThemeContext, DataContext } from 'Contexts';
import { useSelector } from 'react-redux';

import PrivateRoute from 'Components/PrivateRoute';
import Dialog from 'Components/Elements/Dialog';

import Interlude from 'Components/Compounds/Interlude';

import { useResponsive } from 'Helpers/hooks';

import Background from 'Components/Frames/_common/Background';
import {
  PageWrapper,
  PageContent,
} from 'Components/Frames/_common/Frames.styles';

import manageBrainstormTips from 'Abstracts/tips/manageBrainstorm';

import { DefaultHeader, DefaultFooter } from 'Components/Frames/Default';
import {
  SessionHeader,
  SessionHeaderAlt,
  SessionFooter,
} from 'Components/Frames/Session';
import { SignInHeader, TransparentHeader } from 'Components/Frames/Auth';

import { langPath } from '@/i18n';
import dayjs from 'dayjs';

const Frames = () => {
  const { theme } = useContext(ThemeContext);
  const { scopedData, resetScopedData } = useContext(DataContext);
  const [expired, setExpired] = useState(false);
  const [expireDays, setExpireDays] = useState(null);

  const location = useLocation();

  const screen = useResponsive();
  const isDesktop = screen.size('sm');

  const userStatus =
    useSelector((reduxState) => reduxState.user.status, []) || null;
  const userData =
    useSelector((reduxState) => reduxState.user.data, []) || null;
  const sessionData =
    useSelector((reduxState) => reduxState.session.data, []) || null;

  let Header;
  let Footer;
  let data = {};

  switch (theme.frame) {
    case 'session':
      Header = SessionHeader;
      Footer = SessionFooter;
      data = {
        brainstormId: scopedData.brainstormId,
        participants:
          scopedData.participants && scopedData.participants.length > 0
            ? scopedData.participants
            : [],
        isFacilitator: scopedData.isFacilitator,
        permission: {
          plan: scopedData.permission ? scopedData.permission.plan : '',
          valid: scopedData.permission ? scopedData.permission.valid : false,
        },
        status: scopedData.status,
        chiefStream: scopedData.chiefStream,
      };
      break;
    case 'session-alt':
      Header = SessionHeaderAlt;
      Footer = SessionFooter;
      data = {
        brainstormId: scopedData.brainstormId,
        participants:
          scopedData.participants && scopedData.participants.length > 0
            ? scopedData.participants
            : [],
        isFacilitator: scopedData.isFacilitator,
      };
      break;
    case 'signup':
    case 'error':
    case 'unsubscribe':
    case 'none':
      break;
    case 'signin':
    case 'success':
      Header = SignInHeader;
      break;
    case 'verified':
      Header = TransparentHeader;
      break;
    case 'manage-brainstorm':
      Header = DefaultHeader;
      Footer = isDesktop ? DefaultFooter : null;
      data = {
        hideOnMobile: true,
        tips: manageBrainstormTips,
        hideExpiry: true,
      };
      break;
    default:
      Header = DefaultHeader;
      Footer = DefaultFooter;
  }

  useEffect(() => {
    if (userStatus !== 'loaded' || !userData) return;

    if (!userData && !sessionData) return;

    const planCancelsAt =
      userData.plan === 'spark'
        ? dayjs(userData.freePlanStartDate).add(1, 'month')
        : dayjs(userData.payment.cancelsAt);

    const days = planCancelsAt.diff(dayjs(), 'days');
    const expired =
      userStatus === 'loaded' &&
      !userData.isPlanActive &&
      theme.frame !== 'manage-brainstorm' &&
      theme.frame !== 'session';

    setExpired(expired);
    setExpireDays(days);
  }, [userStatus, theme.frame]);

  useEffect(() => {
    window.scrollTo(0, 0);
    resetScopedData();
  }, [location.pathname]);

  return (
    <>
      <Background options={theme.background} />
      <Dialog {...theme.dialog} />
      <PageWrapper id="page-wrapper">
        {Header && (
          <Header {...data} expired={expired} expireDays={expireDays} />
        )}
        <PageContent>
          <Suspense fallback={<Interlude outside={false} />}>
            <Switch>
              {routes.map(({ isPrivate = false, path, ...rest }, index) => {
                const pathWithLang = langPath + path;

                return !isPrivate ? (
                  <Route key={index} path={pathWithLang} {...rest} />
                ) : (
                  <PrivateRoute key={index} path={pathWithLang} {...rest} />
                );
              })}
              <Redirect to="/404" />
            </Switch>
          </Suspense>
        </PageContent>
        {Footer && <Footer {...data} />}
      </PageWrapper>
      <div id="overlay-container" />
      <div id="interlude" />
    </>
  );
};

export default withRouter(Frames);
