import React, { useMemo } from 'react';

import dayjs from 'dayjs';

import i18n, { getLangPrefix } from '@/i18n';

import { Text } from 'Components/Elements/TypeSet';
import { Row } from 'Components/Elements/Grid';

import Ripple, { useRipple } from 'Components/Elements/Ripple';

import { rgba } from 'polished';
import { colors } from 'Abstracts/variables';

import { StatusBullet } from 'Components/Elements/SmallBullet';

import { withRouter } from 'react-router-dom';

import { ReactComponent as CrossIcon } from 'Assets/images/icons/cross-slim.svg';

import { useConfirm } from 'Helpers/hooks';
import { EXPIRED_FACILITATOR_CTA } from './constant';

import {
  StyledView,
  StyledEdit,
  StyledCard,
  StyledCardInner,
  StyledIcon,
  CardTitle,
  RemoveButton,
} from './Project.styles';

const ProjectCard = (props) => {
  const {
    history,
    to,
    data = {},
    isPlanActive = true,
    clickable = false,
    removing = false,
    removeHandler,
    onMouseDown = () => {},
  } = props;

  const [ripples, addRipple, cleanRipples] = useRipple();

  const confirm = useConfirm();

  const getStatus = (status) => {
    switch (status) {
      case 'ended':
        return i18n.t('Completed');
      case 'holding':
      case 'playing':
        return i18n.t('Ongoing');
      case 'draft':
        return i18n.t('Draft');
      case 'pending':
        return i18n.t('Scheduled');
      default:
        return status;
    }
  };

  const handleBrainstormClick = () => {
    if (data.status !== 'draft') {
      history.push(to);
    }
  };

  const langPrefix = useMemo(() => getLangPrefix(i18n), [i18n.language]);

  const handleExpiry = () => {
    confirm(i18n.t('Locked'), EXPIRED_FACILITATOR_CTA).then((res) => {
      if (res) {
        history.push(`${langPrefix}/user`);
      } else {
        history.push(`${langPrefix}/`);
      }
    });
  };

  const handleEditClick = (e) => {
    if (isPlanActive) {
      e.stopPropagation();
      history.push(`${langPrefix}/manage/update/${data.ID}`);
    }

    handleExpiry();
  };

  return (
    <StyledCard
      style={{
        backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.15) 49%, rgba(0, 0, 0, 0.3)), url("${data.image}")`,
      }}
      m="14px 10px"
      clickable={clickable}
      removing={removing}
    >
      <StyledCardInner
        removing={removing}
        onClick={handleBrainstormClick}
        onMouseDown={(event) => {
          onMouseDown(event);
          addRipple(event);
        }}
      >
        {clickable && (
          <Ripple
            color={rgba(colors.dark, 0.4)}
            ripples={ripples}
            cleanRipples={cleanRipples}
          />
        )}
        <Row mx="0" px="0" justifyContent="space-between" mb="20px">
          <StatusBullet variant={data.status}>
            {getStatus(data.status)}
          </StatusBullet>

          {!removing && (
            <>
              {data.status === 'ended' && (
                <StyledIcon
                  p="10px"
                  hoverColor={rgba(colors.light, 0.2)}
                  ripple={{ color: rgba(colors.light, 0.4), duration: 500 }}
                  onClick={handleBrainstormClick}
                >
                  <StyledView width="25px" height="18px" />
                </StyledIcon>
              )}
              {(data.status === 'draft' || data.status === 'pending') && (
                <StyledIcon
                  p="10px"
                  hoverColor={rgba(colors.light, 0.2)}
                  ripple={{ color: rgba(colors.light, 0.4), duration: 500 }}
                  onClick={handleEditClick}
                >
                  <StyledEdit width="25px" height="22px" />
                </StyledIcon>
              )}
            </>
          )}
        </Row>
        <Row mx="0" px="0" flexGrow="1">
          <CardTitle variant="paragraph" fontWeight="bold" color="#fff">
            {data.title}
          </CardTitle>
        </Row>
        <Row mx="0" px="0">
          <Text variant="tipsNotes" color="#fff">
            {`Last edit: ${dayjs(data.updatedAt).format('D-MMMM-YYYY')}`}
          </Text>
        </Row>
      </StyledCardInner>
      {!!removeHandler && removing && (
        <RemoveButton onClick={removeHandler}>
          <CrossIcon width="16px" height="16px" />
        </RemoveButton>
      )}
    </StyledCard>
  );
};

export default withRouter(ProjectCard);
