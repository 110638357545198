import React from 'react';

import styled from 'styled-components';

import Modal from 'Components/Elements/Modal';
import Card from 'Components/Elements/Card';

import { Paragraph } from 'Components/Elements/TypeSet';
import { Col, Row } from 'Components/Elements/Grid';

import Button from 'Components/Elements/Button';

import { ReactComponent as ExclamationIcon } from 'Assets/images/icons/exclamation.svg';

const defaultOption = {
  layout: {
    cta: 'horizontal',
  },
  copy: {
    body: null,
    button: {
      confirm: 'Yes',
      reject: 'No',
    },
  },
};

const Dialog = (props) => {
  const {
    show = false,
    prompt = '',
    reply = () => {},
    options = defaultOption,
  } = props;

  const { copy, layout } = options;

  const handleConfirm = () => reply(true);
  const handleReject = () => reply(false);

  const isVerticalCTA = !!layout && !!layout.cta && layout.cta === 'vertical';

  return (
    show && (
      <Modal
        show
        fullHeight
        closable={false}
        maxWidth={{ _: 'calc(100% - 30px)', sm: '500px' }}
      >
        <Card
          width="100%"
          padding={{ _: '35px 15px', md: '45px 75px' }}
          borderRadius="20px"
        >
          <Row mx="0">
            <Col p="0" v-align="center" h-align="center">
              <IconWrapper>
                <ExclamationIcon />
              </IconWrapper>

              <Paragraph
                variant="h2"
                mt={{ _: '20px', md: '25px' }}
                mb={{ _: '15px', md: '20px' }}
                color="#333333"
              >
                {prompt}
              </Paragraph>

              {!!copy && !!copy.body && (
                <Paragraph
                  variant="font-12"
                  tone="grey1"
                  mt="10px"
                  px={{ _: '10px', md: 0 }}
                  mb={{ _: '15px', md: '20px' }}
                >
                  {copy.body}
                </Paragraph>
              )}

              {!isVerticalCTA ? (
                <Row mx="0" px="0" mt="20px" justifyContent="space-evenly">
                  {!!copy && !!copy.button && !!copy.button.reject && (
                    <Col
                      span="auto"
                      py="0"
                      pl={{ _: '10px', md: 0 }}
                      pr={{ _: '10px', md: '25px' }}
                      pb="0"
                    >
                      <Button
                        variant="tertiary"
                        onClick={handleReject}
                        my={{ _: '5px', md: '0' }}
                      >
                        {copy.button.reject}
                      </Button>
                    </Col>
                  )}

                  {!!copy?.button?.confirm && (
                    <Col span="auto" py="0" px={{ _: '10px', md: '0' }}>
                      <Button
                        variant="primary"
                        onClick={handleConfirm}
                        my={{ _: '5px', md: '0' }}
                      >
                        {copy.button.confirm}
                      </Button>
                    </Col>
                  )}
                </Row>
              ) : (
                <Row mx="0">
                  <Col p="0" h-align="center">
                    <div>
                      <Row mx="0" px="0" mt="10px">
                        <Col span={{ _: 12, sm: 'auto' }} p="0">
                          {!!copy && !!copy.button && !!copy.button.reject && (
                            <Button
                              variant="tertiary"
                              onClick={handleReject}
                              mb="10px"
                            >
                              {copy.button.reject}
                            </Button>
                          )}

                          {!!copy?.button?.confirm && (
                            <Button variant="primary" onClick={handleConfirm}>
                              {copy.button.confirm}
                            </Button>
                          )}
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              )}
            </Col>
          </Row>
        </Card>
      </Modal>
    )
  );
};

export default Dialog;

const IconWrapper = styled.div`
  & svg {
    height: 50px;
    width: 50px;
  }
`;
