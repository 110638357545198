import React from 'react';

import Image from 'Components/Elements/Image';
import { colors } from 'Abstracts/variables';
import { getInitials } from './Avatar.func';
import { AvatarIcon, AvatarText } from './Avatar.styles';

const Avatar = (props) => {
  const {
    name = '',
    picture,
    size = 'normal',
    disabled = false,
    color = colors.blue,
    ...rest
  } = props;

  const initials = /.+@.+\.[A-Za-z]+$/.test(name)
    ? name.charAt(0).toUpperCase()
    : getInitials(name);

  return (
    <AvatarIcon
      tooltip={name}
      size={size}
      color={color}
      disabled={disabled}
      borderWidth={picture && 0}
      {...rest}
    >
      {!picture && (
        <AvatarText
          variant={`avatar-${size}`}
          color={color}
          disabled={disabled}
        >
          {initials}
        </AvatarText>
      )}
      {!!picture && <Image src={picture} style={{ borderRadius: '100%' }} />}
    </AvatarIcon>
  );
};

export default Avatar;
