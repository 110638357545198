import { useDispatch } from 'react-redux';

import i18n from '@/i18n';
import { logError } from '@/sentry';

import { ReactComponent as UploadVideoIcon } from 'Assets/images/modules/upload-video.svg';

import {
  postUploadVideo,
  putUploadVideos,
  deleteUploadVideo,
} from 'Stores/actions/module';

import { objectToForm } from 'Helpers/data-transform';

import { basicFields } from './_common';

const parseData = ({ data, participants }) =>
  data.moduleUploadVideo.data
    .filter((item) => !!item.submitted)
    .reduce(
      (accumulator, currentUser) => [
        ...(accumulator || []),
        {
          ...currentUser,
          user: participants.find(
            (participant) => participant.id === currentUser.user
          ),
        },
      ],
      []
    );

const getHandler = ({ brainstormId, user, participants, data }) => {
  const dispatch = useDispatch();

  const handler = ({ type, ...payload }) => {
    let formData;

    switch (type) {
      case 'upload':
        let node = 'video';

        if (payload.dataType === 'youtube') {
          node = 'youtube';
        }

        if (payload.dataType === 'vimeo') {
          node = 'vimeo';
        }

        formData = objectToForm({
          [node]: payload.file,
        });

        dispatch(
          postUploadVideo(
            brainstormId,
            data.id,
            formData,
            payload.onProgress,
            payload.cancelToken
          )
        )
          .then((response) => {
            payload.onDone(response.data.payload.id);
            payload.onProgress(100);
          })
          .catch((error) => {
            logError(error);
            payload.onError(error);
          });
        break;
      case 'remove':
        dispatch(deleteUploadVideo(brainstormId, data.id, payload.id)).catch(
          logError
        );
        break;
      case 'submit':
        dispatch(
          putUploadVideos(
            brainstormId,
            data.id,
            Object.keys(payload.files)
              .filter((key) => !!payload.files[key].id)
              .reduce(
                (acc, key) => ({
                  ...acc,
                  [key]: payload.files[key],
                }),
                {}
              )
          )
        ).then((res) => {
          res.forEach((item) => {
            if (item instanceof Error) {
              logError(item);
            }
          });
        });
        break;
    }
  };

  return handler;
};

const getAnswer = (datum) => datum.video;

const uploadVideo = {
  name: 'uploadVideo',
  obj: i18n.t('Collect video inspirations'),
  info: i18n.t('Participants share videos inspired by your question.'),
  icon: UploadVideoIcon,
  label: i18n.t('Video Collection'),
  unit: i18n.t('video'),
  endpoint: 'upload-video',
  color: '#622DE3',
  getHandler,
  parseData,
  getAnswer,
  fields: [...basicFields],
  methodologies: [
    {
      label: i18n.t('Personification'),
      value: 'personification',
      description: i18n.t(`Share a video of a person that embodies _____`),
    },
    {
      label: i18n.t('Idea Generator'),
      value: 'ideaGenerator',
      description: i18n.t(`When you think of _____ what comes to mind?`),
    },
    {
      label: i18n.t('Comparison'),
      value: 'comparison',
      description: i18n.t(`What would be a good video to compare _____ to?`),
    },
    {
      label: i18n.t('Oxymoron'),
      value: 'oxymoron',
      description: i18n.t(`What would be the exact opposite of _____ be?`),
    },
    {
      label: i18n.t('Hyperbole'),
      value: 'hyperbole',
      description: i18n.t(
        `What would be a good exaggerated reference for _____?`
      ),
    },
    {
      label: i18n.t('SWOT'),
      value: 'swot',
      description: i18n.t(
        `What would be a good depiction of a [Strength/Weakness/Opportunity/Threat] for _____?`
      ),
    },
    {
      label: i18n.t('Methaphor'),
      value: 'methaphor',
      description: i18n.t(`What would be a good video to represent _____?`),
    },
    {
      label: i18n.t('N/A'),
      value: 'none',
      description: i18n.t(``),
    },
  ],
  compliment: [
    i18n.t('Check out our videos!'),
    i18n.t(`Now that's cool!`),
    i18n.t('Wow, how inspiring!'),
    i18n.t('This is fantastic!'),
    i18n.t(`Now that's a different angle.`),
  ],
  listed: true,
};

export default uploadVideo;
