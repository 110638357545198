import * as types from 'Stores/types';

import { saveToken } from 'Helpers/api/auth';

import {
  validateParticipantToken,
  fetchParticipantToken,
  setParticipantProfle,
  fetchSessionTicket,
  fetchSessionAudioTicket,
  playSession,
  pauseSession,
  addTimeSession,
  nextSession,
  submitChatMessage,
  fetchChatMessages,
  muteParicipant,
  muteAllParicipants,
  muteSelf,
} from 'Helpers/api/session';

import { updateBrainstorm } from 'Helpers/api/brainstorm';

export function setParticipantToken(data) {
  return (dispatch) =>
    new Promise((resolve) => {
      const token = saveToken(data);

      dispatch({
        type: types.SET_PARTICIPANT_TOKEN,
        payload: {
          status: 'loaded',
          data: token,
        },
      });

      resolve(true);
    });
}

export function postParticipantValidate(brainstormId, key) {
  return (dispatch) => {
    dispatch({
      type: types.POST_PARTICIPANT_VALIDATE,
    });

    return validateParticipantToken(brainstormId, key);
  };
}

export function postParticipantAccept(brainstormId, key) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch({
        type: types.POST_PARTICIPANT_ACCEPT_START,
        payload: {
          status: 'loading',
        },
      });
      fetchParticipantToken(brainstormId, key)
        .then((response) => {
          dispatch({
            type: types.POST_PARTICIPANT_ACCEPT_SUCCESS,
            payload: {
              status: 'loaded',
              data: response.data.payload.JwtToken,
            },
          });
          return resolve(response.data.payload);
        })
        .catch((error) => {
          dispatch({
            type: types.POST_PARTICIPANT_ACCEPT_FAILURE,
            payload: {
              status: 'loaded',
              error,
            },
          });
          return reject(error);
        });
    });
}

export function postParticipantProfile(brainstormId, user) {
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      const state = getState();

      const { token } = state.session;

      dispatch({
        type: types.POST_PARTICIPANT_PROFILE_START,
        payload: {
          data: null,
        },
      });
      setParticipantProfle(brainstormId, user, token.data)
        .then((response) => {
          dispatch({
            type: types.POST_PARTICIPANT_PROFILE_SUCCESS,
            payload: {
              data: response.data.payload,
            },
          });
          return resolve(response.data.payload);
        })
        .catch((error) => {
          dispatch({
            type: types.POST_PARTICIPANT_PROFILE_FAILURE,
            payload: {
              data: null,
            },
          });
          return reject(error);
        });
    });
}

export function postSessionTicketRequest(brainstormId, token) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch({
        type: types.POST_PARTICIPANT_TICKET_REQUEST_START,
        payload: {
          status: 'loading',
        },
      });
      fetchSessionTicket(brainstormId, token)
        .then((response) => {
          dispatch({
            type: types.POST_PARTICIPANT_TICKET_REQUEST_SUCCESS,
            payload: {
              status: 'loaded',
              data: {
                token: response.data.payload.ticket.token,
                user: {
                  id: response.data.payload.id,
                  username: response.data.payload.username,
                  email: response.data.payload.email,
                },
              },
              error: null,
            },
          });
          return resolve(response.data.payload.ticket.token);
        })
        .catch((error) => {
          dispatch({
            type: types.POST_PARTICIPANT_TICKET_REQUEST_FAILURE,
            payload: {
              status: 'loaded',
              error,
            },
          });
          return reject(error);
        });
    });
}

export function postSessionAudioTicketRequest(brainstormId, token) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch({
        type: types.POST_PARTICIPANT_AUDIO_TICKET_REQUEST_START,
        payload: {
          status: 'loading',
        },
      });
      fetchSessionAudioTicket(brainstormId, token)
        .then((response) => {
          dispatch({
            type: types.POST_PARTICIPANT_AUDIO_TICKET_REQUEST_SUCCESS,
            payload: {
              status: 'loaded',
              data: {
                token: response.data.payload.ticket.token,
                user: {
                  id: response.data.payload.id,
                  username: response.data.payload.username,
                  email: response.data.payload.email,
                },
              },
              error: null,
            },
          });
          return resolve(response.data.payload.ticket.token);
        })
        .catch((error) => {
          dispatch({
            type: types.POST_PARTICIPANT_AUDIO_TICKET_REQUEST_FAILURE,
            payload: {
              status: 'loaded',
              error,
            },
          });
          return reject(error);
        });
    });
}

export function voidSession() {
  return (dispatch) =>
    new Promise((resolve) => {
      dispatch({
        type: types.VOID_SESSION,
        payload: {
          status: 'reset',
          error: null,
          data: null,
        },
      });
      resolve(true);
    });
}

export function postSessionPlay(brainstormId) {
  return (dispatch, getState) => {
    const state = getState();

    const { token } = state.auth;

    dispatch({
      type: types.POST_SESSION_PLAY,
    });

    return playSession(brainstormId, token);
  };
}

export function putSessionHold(brainstromId, params = {}) {
  return (dispatch, getState) => {
    const state = getState();

    dispatch({
      type: types.PUT_SESSION_HOLD,
    });

    return updateBrainstorm(brainstromId, params, state.auth.token);
  };
}

export function postSessionPause(brainstormId) {
  return (dispatch, getState) => {
    const state = getState();

    const { token } = state.auth;

    return new Promise((resolve, reject) => {
      dispatch({
        type: types.POST_SESSION_PAUSE_START,
        payload: {
          status: 'loading',
        },
      });
      pauseSession(brainstormId, token)
        .then(() => {
          dispatch({
            type: types.POST_SESSION_PAUSE_SUCCESS,
            payload: {
              status: 'loaded',
              error: null,
            },
          });
          return resolve(true);
        })
        .catch((error) => {
          dispatch({
            type: types.POST_SESSION_PAUSE_FAILURE,
            payload: {
              status: 'loaded',
              error,
            },
          });
          return reject(error);
        });
    });
  };
}

export function postSessionAddTime(brainstormId) {
  return (dispatch, getState) => {
    const state = getState();

    const { token } = state.auth;

    dispatch({
      type: types.POST_SESSION_ADD_TIME,
    });

    return addTimeSession(brainstormId, token);
  };
}

export function postSessionNext(brainstormId) {
  return (dispatch, getState) => {
    const state = getState();

    const { token } = state.auth;

    return new Promise((resolve, reject) => {
      dispatch({
        type: types.POST_SESSION_NEXT_START,
        payload: {
          status: 'loading',
        },
      });
      nextSession(brainstormId, token)
        .then(() => {
          dispatch({
            type: types.POST_SESSION_NEXT_SUCCESS,
            payload: {
              status: 'loaded',
              error: null,
            },
          });
          return resolve(true);
        })
        .catch((error) => {
          dispatch({
            type: types.POST_SESSION_NEXT_FAILURE,
            payload: {
              status: 'loaded',
              error,
            },
          });
          return reject(error);
        });
    });
  };
}

export function postChatMessage(brainstormId, messageType, data) {
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      const state = getState();

      const { data: token } = state.session.token;

      dispatch({
        type: types.POST_CHAT_MESSAGE_START,
        payload: {
          status: 'loading',
        },
      });
      submitChatMessage(brainstormId, messageType, data, token)
        .then((response) => {
          dispatch({
            type: types.POST_CHAT_MESSAGE_SUCCESS,
            payload: {
              status: 'loaded',
              data: response.data.payload,
            },
          });
          return resolve(response.data.payload);
        })
        .catch((error) => {
          dispatch({
            type: types.POST_CHAT_MESSAGE_FAILURE,
            payload: {
              status: 'loaded',
              error,
            },
          });
          return reject(error);
        });
    });
}

export function getChatMessages(brainstormId, criteria) {
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      const state = getState();

      const { data: token } = state.session.token;

      dispatch({
        type: types.FETCH_CHAT_MESSAGES_START,
        payload: {
          status: 'loading',
        },
      });
      fetchChatMessages(brainstormId, criteria, token)
        .then((response) => {
          const messages = response.data.payload ? response.data.payload : [];

          dispatch({
            type: types.FETCH_CHAT_MESSAGES_SUCCESS,
            payload: {
              status: response.data.payload ? 'loaded' : 'ended',
              data:
                messages.length > 0
                  ? [...state.session.messages.data, ...messages]
                  : state.session.messages.data,
            },
          });
          resolve(messages);
        })
        .catch((error) => {
          dispatch({
            type: types.FETCH_CHAT_MESSAGES_FAILURE,
            payload: {
              status: 'loaded',
              error,
            },
          });
          reject(error);
        });
    });
}

export function putMessages(message) {
  return (dispatch, getState) => {
    const state = getState();

    dispatch({
      type: types.UPDATE_CHAT_MESSAGES,
      payload: {
        data: Array.isArray(message)
          ? [...message, ...state.session.messages.data]
          : [message, ...state.session.messages.data],
      },
    });
  };
}

export function setAudio(toggle) {
  return (dispatch) => {
    dispatch({
      type: types.SET_AUDIO,
      payload: toggle,
    });
  };
}

export function postMutePartipcipant(brainstormId, participantId) {
  return (dispatch, getState) => {
    const state = getState();

    const { token } = state.auth;

    dispatch({
      type: types.POST_MUTE_PARTICIPANT,
    });

    return muteParicipant(brainstormId, participantId, token);
  };
}

export function postMuteAllPartipcipants(brainstormId) {
  return (dispatch, getState) => {
    const state = getState();

    const { token } = state.auth;

    dispatch({
      type: types.POST_MUTE_ALL_PARTICIPANTS,
    });

    return muteAllParicipants(brainstormId, token);
  };
}

export function postMuteSelf(brainstormId, toggle) {
  return (dispatch, getState) => {
    const state = getState();

    const { data: token } = state.session.token;

    dispatch({
      type: types.POST_MUTE_SELF,
    });

    return muteSelf(brainstormId, toggle ? 1 : 0, token);
  };
}
