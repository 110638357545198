import * as types from 'Stores/types';

import {
  fetchCard,
  fetchCust,
  fetchInvoice,
  postDefaultCard,
  postCreateCard,
} from 'Helpers/api/payment';

export function getCard() {
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      const state = getState();

      dispatch({
        type: types.GET_CARD_START,
        payload: {
          status: 'loading',
        },
      });

      fetchCard(state.auth.token)
        .then((response) => {
          dispatch({
            type: types.GET_CARD_SUCCESS,
            payload: {
              status: 'loaded',
              data: response.data.payload,
            },
          });
          resolve(response.data.payload);
        })
        .catch((error) => {
          dispatch({
            type: types.GET_CARD_FAILURE,
            payload: {
              status: 'failed',
              data: null,
              error,
            },
          });
          reject(error);
        });
    });
}

export function getCust() {
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      const state = getState();

      fetchCust(state.auth.token)
        .then((response) => {
          dispatch({
            type: types.GET_CUST_SUCCESS,
            payload: {
              status: 'loaded',
              data: response.data.payload,
            },
          });
          resolve(response.data.payload);
        })
        .catch((error) => {
          dispatch({
            type: types.GET_CUST_FAILURE,
            payload: {
              status: 'failed',
              data: null,
              error,
            },
          });
          reject(error);
        });
    });
}

export function getInvoice() {
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      const state = getState();

      dispatch({
        type: types.GET_INVOICE_START,
        payload: {
          status: 'loading',
        },
      });

      fetchInvoice(state.auth.token)
        .then((response) => {
          dispatch({
            type: types.GET_INVOICE_SUCCESS,
            payload: {
              status: 'loaded',
              data: response.data.payload,
            },
          });
          resolve(response.data.payload);
        })
        .catch((error) => {
          dispatch({
            type: types.GET_INVOICE_FAILURE,
            payload: {
              status: 'failed',
              data: null,
              error,
            },
          });
          reject(error);
        });
    });
}

export function setDefaultCard(id) {
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      const state = getState();

      dispatch({
        type: types.SET_DEFAULT_CARD_START,
        payload: {
          status: 'loading',
        },
      });

      postDefaultCard(state.auth.token, id)
        .then((response) => {
          dispatch({
            type: types.SET_DEFAULT_CARD_SUCCESS,
            payload: {
              status: 'loaded',
              data: response.data.payload,
            },
          });
          resolve(response.data.payload);
        })
        .catch((error) => {
          dispatch({
            type: types.SET_DEFAULT_CARD_FAILURE,
            payload: {
              status: 'failed',
              data: null,
              error,
            },
          });
          reject(error);
        });
    });
}

export function addCard(id) {
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      const state = getState();

      dispatch({
        type: types.ADD_CARD_START,
        payload: {
          status: 'loading',
        },
      });
      postCreateCard({ paymentMethodId: id }, state.auth.token)
        .then((response) => {
          dispatch({
            type: types.ADD_CARD_SUCCESS,
          });
          return resolve(response.data.payload);
        })
        .catch((error) => {
          console.debug(error);
          dispatch({
            type: types.ADD_CARD_FAILURE,
            payload: {
              status: 'loaded',
              data: null,
              error,
            },
          });
          return reject(error);
        });
    });
}
