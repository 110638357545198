import React from 'react';
import { default as SlickSlider } from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Slider = React.forwardRef((props, ref) => {
  const { children, ...rest } = props;

  const sliderSettings = {
    dots: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    variableWidth: true,
    infinite: false,
  };

  return (
    <SlickSlider {...sliderSettings} ref={ref} {...rest}>
      {children}
    </SlickSlider>
  );
});

export default Slider;
