import React, { createContext, useState } from 'react';

export const Context = createContext({});

export const defaultState = {
  logo: 'white',
  frame: 'default',
  background: {
    color: '#FCCE44',
  },
  dialog: {
    show: false,
  },
};

export const { Consumer } = Context;

export const Provider = (props) => {
  const { initState = defaultState, children } = props;

  const [theme, setTheme] = useState(initState);

  const changeThemeSetup = (setup) =>
    setTheme((prevState) => ({
      ...prevState,
      ...setup,
    }));

  const themeContext = {
    theme,
    setTheme,
    changeThemeSetup,
  };

  return <Context.Provider value={themeContext}>{children}</Context.Provider>;
};
