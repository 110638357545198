import React, { createContext, useState } from 'react';

import { usePrevious } from 'Helpers/hooks';

export const Context = createContext({});

export const { Consumer } = Context;

export const Provider = (props) => {
  const { children } = props;

  const [status, setStatus] = useState('initializing');
  const [socket, setSocket] = useState({ ready: false, data: null });

  const [statusChanger, setStatusChanger] = useState(null);

  const prevStatus = usePrevious(status);

  const delayChangeStatus = (status, time = 3000) => {
    const isTimeout = (object) => {
      const Timeout = setTimeout(() => {}, 0).constructor;
      return object instanceof Timeout;
    };

    if (isTimeout(statusChanger)) {
      clearTimeout(statusChanger);
    }

    return setStatusChanger(
      setTimeout(() => {
        setStatus(status);
      }, time)
    );
  };

  const getParticipantById = (id) =>
    socket.data.participants.find((participant) => participant.id === id);

  const sessionContext = {
    status,
    setStatus,
    delayChangeStatus,
    socket,
    setSocket,
    getParticipantById,
    prevStatus,
  };

  return <Context.Provider value={sessionContext}>{children}</Context.Provider>;
};
