import * as types from 'Stores/types';

import initState from 'Stores/states/session';

export function session(state = {}, action) {
  switch (action.type) {
    case types.SET_PARTICIPANT_TOKEN:
    case types.POST_PARTICIPANT_ACCEPT_START:
    case types.POST_PARTICIPANT_ACCEPT_SUCCESS:
    case types.POST_PARTICIPANT_ACCEPT_FAILURE:
      return {
        ...state,
        token: {
          ...state.token,
          ...action.payload,
        },
      };
    case types.POST_PARTICIPANT_TICKET_REQUEST_START:
    case types.POST_PARTICIPANT_TICKET_REQUEST_FAILURE:
      return {
        ...state,
        ticket: {
          ...state.ticket,
          ...action.payload,
        },
      };
    case types.VOID_SESSION:
      return {
        ...initState,
      };
    case types.POST_PARTICIPANT_PROFILE_START:
    case types.POST_PARTICIPANT_PROFILE_SUCCESS:
    case types.POST_PARTICIPANT_PROFILE_FAILURE:
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload,
        },
      };
    case types.POST_PARTICIPANT_TICKET_REQUEST_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          status: action.payload.status,
          data: {
            id: action.payload.data.user.id,
            username: action.payload.data.user.username,
            email: action.payload.data.user.email,
          },
          error: action.payload.error,
        },
        ticket: {
          ...state.ticket,
          status: action.payload.status,
          data: action.payload.data.token,
          error: action.payload.error,
        },
      };
    case types.POST_CHAT_MESSAGE_START:
    case types.POST_CHAT_MESSAGE_SUCCESS:
    case types.POST_CHAT_MESSAGE_FAILURE:
      return {
        ...state,
      };
    case types.FETCH_CHAT_MESSAGES_START:
    case types.FETCH_CHAT_MESSAGES_SUCCESS:
    case types.FETCH_CHAT_MESSAGES_FAILURE:
    case types.UPDATE_CHAT_MESSAGES:
      return {
        ...state,
        messages: {
          ...state.messages,
          ...action.payload,
        },
      };
    case types.POST_SESSION_NEXT_START:
    case types.POST_SESSION_NEXT_SUCCESS:
    case types.POST_SESSION_NEXT_FAILURE:
    case types.POST_SESSION_PAUSE_START:
    case types.POST_SESSION_PAUSE_SUCCESS:
    case types.POST_SESSION_PAUSE_FAILURE:
      return {
        ...state,
        transition: {
          ...state.transition,
          ...action.payload,
        },
      };
    case types.SET_AUDIO:
      return {
        ...state,
        audio: action.payload,
      };
    case types.POST_SESSION_PLAY:
    case types.POST_SESSION_ADD_TIME:
    case types.POST_PARTICIPANT_VALIDATE:
    case types.POST_MUTE_PARTICIPANT:
    case types.POST_MUTE_ALL_PARTICIPANTS:
    case types.POST_MUTE_SELF:
    case types.POST_PARTICIPANT_AUDIO_TICKET_REQUEST_START:
    case types.POST_PARTICIPANT_AUDIO_TICKET_REQUEST_FAILURE:
    case types.POST_PARTICIPANT_AUDIO_TICKET_REQUEST_SUCCESS:
    default:
      return state;
  }
}
