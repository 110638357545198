import React, { useMemo, useCallback } from 'react';

import styled from 'styled-components';

import Select, { components } from 'react-select';
import AsyncSelect from 'react-select/async';

import fontStyles from 'Mixins/styles/typography';

import CheckBox from 'Components/Elements/Form/CheckBox';
import { Text } from 'Components/Elements/TypeSet';
import { ReactComponent as ErrorIcon } from 'Assets/images/error.svg';
import { ReactComponent as DownIcon } from 'Assets/images/icons/down-large.svg';

import { useResponsive } from 'Helpers/hooks';

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      {props.selectProps.error ? <ErrorIcon /> : <DownIcon />}
    </components.DropdownIndicator>
  );
};

export const StyledOption = styled.div`
  cursor: pointer;
  background-color: #fff;
  display: flex;
  padding: ${(props) => (props.multiple ? '10px 30px' : '15px 20px')};

  flex-direction: column;

  > span {
    display: none;
  }

  :hover span {
    display: block;
  }

  &:hover {
    background-color: #fcce44;
  }
`;

export const StyledOptionWrapper = styled.div`
  display: flex;

  align-items: center;
`;

export const StyledCheckBox = styled(CheckBox)`
  pointer-events: none;
`;

const StyledGroup = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 10px 20px;
  margin-top: -5px;
  color: #999999;
  &:hover {
    background-color: #fcce44;
  }
`;

const StyledGroupHeading = styled.span`
  margin-left: 10px;
`;

const StyledGroupBadge = styled.span`
  background-color: #ebecf0;
  border-radius: 2em;
  color: #172b4d;
  font-size: 12px;
  line-height: 1em;
  min-width: 1px;
  padding: 0.16666666666667em 0.5em;
  position: absolute;
  right: 10px;
`;

// const MenuList = (props) => {
//   const { children, ...rest } = props;

//   const [opened, setOpened] = useState(-1);

//   const newChildren = useMemo(
//     () =>
//       Array.isArray(children)
//         ? children.map((child, index) => {
//             console.log(child);
//             return child.type === Group ? (
//               <div
//                 onClick={() =>
//                   setOpened((prev) => (prev === index ? -1 : index))
//                 }
//               >
//                 {opened !== index ? child.props.label : child}
//               </div>
//             ) : (
//               child
//             );
//           })
//         : children,
//     [children, opened]
//   );

//   return <components.MenuList {...rest}>{newChildren}</components.MenuList>;
// };
const { MenuList } = components;

const Group = (props) => {
  const { getValue, label, data, setValue, children, ...rest } = props;

  const currentValues = useMemo(() => getValue() || [], [getValue]);

  const selected = useMemo(
    () =>
      currentValues.length > 0
        ? data.options.every((item) =>
            currentValues.map((i) => i.value).includes(item.value)
          )
        : false,
    [currentValues, data.options]
  );

  const handleGroupSelect = useCallback(() => {
    const newSelected = data.options.reduce((acc, cur) => {
      return !acc.map((item) => item.value).includes(cur.value)
        ? [...acc, cur]
        : acc.filter((item) => item.value !== cur.value);
    }, currentValues);

    setValue(newSelected);
  }, [data.options, setValue, currentValues]);

  return (
    <components.Group {...rest}>
      <StyledGroup onClick={handleGroupSelect}>
        <StyledCheckBox
          checked={selected}
          onClick={(event) => {
            event.stopPropagation();
          }}
        />
        <StyledGroupHeading>{label}</StyledGroupHeading>
        <StyledGroupBadge>{data.options.length}</StyledGroupBadge>
      </StyledGroup>
      {children}
    </components.Group>
  );
};

export const Option = ({ innerProps, isSelected, isMulti, ...rest }) => {
  return (
    <StyledOption multiple={isMulti} selected={isSelected} {...innerProps}>
      <StyledOptionWrapper>
        {!!isMulti && (
          <StyledCheckBox
            checked={isSelected}
            onClick={(event) => {
              event.stopPropagation();
            }}
          />
        )}
        <components.Option
          isSelected={isSelected}
          isMulti={isMulti}
          {...rest}
        />
      </StyledOptionWrapper>
    </StyledOption>
  );
};

const SelectInput = React.forwardRef((props, ref) => {
  const {
    options,
    multiple = false,
    searchable = false,
    error = null,
    loadOptions,
    ...rest
  } = props;

  const screen = useResponsive();
  const isLG = screen.size('lg');

  const customStyles = useMemo(
    () => ({
      container: (styles, state) => {
        return {
          ...styles,
          display: 'inline-block',
          width: !state.selectProps.width ? 'auto' : state.selectProps.width,
          minWidth: isLG ? '14em' : undefined,
          '&:hover': { border: '0' },
          zIndex: 1,
        };
      },
      indicatorSeparator: (styles) => ({
        ...styles,
        backgroundColor: 'transparent',
      }),
      dropdownIndicator: (styles) => ({
        ...styles,
        padding: '0 2.5px',
        color: '#999999',
      }),
      control: (styles, state) => ({
        ...styles,
        boxShadow: 'none',
        borderWidth: '0',
        borderRadius: '0px',
        borderBottomWidth: '2px',
        borderColor: !state.selectProps.error
          ? !state.isFocused
            ? '#999999'
            : '#622de3'
          : '#c64f4b',
        backgroundColor: 'transparent',
        '&:hover': { borderColor: '#622de3' },
        cursor: 'pointer',
      }),
      option: (provided, state) => ({
        ...provided,
        ...fontStyles.textfield,
        color: state.isFocused ? '#666666;' : '#999999',
        backgroundColor: 'transparent !important',
        padding: '0 0 0 12.5px',
        cursor: 'pointer',
        whiteSpace: 'pre-wrap',
      }),
      placeholder: (styles) => ({
        ...styles,
        ...fontStyles.textfieldPlaceholder,
        color: '#999999',
        marginLeft: 0,
        paddingBottom: '7.5px',
        paddingTop: '7.5px',
      }),
      input: (styles) => ({
        ...styles,
        ...fontStyles.textfield,
      }),
      singleValue: (styles) => ({
        ...styles,
        ...fontStyles.textfield,
        marginLeft: 0,
        paddingBottom: '7.5px',
        paddingTop: '7.5px',
      }),
      valueContainer: (styles) => ({
        ...styles,
        padding: '7.5px 2.5px',
        marginLeft: 0,
      }),
      menu: (provided) => ({
        ...provided,
        borderRadius: '10px',
        boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
        border: 'solid 1px #666666',
        backgroundColor: '#ffffff',
        overflow: 'hidden',
      }),
      menuPortal: (base) => ({ ...base, zIndex: 99999 }),
    }),
    []
  );

  const Component = useMemo(() => (loadOptions ? AsyncSelect : Select), [
    loadOptions,
  ]);

  return (
    <>
      <Component
        ref={ref}
        styles={customStyles}
        components={{ DropdownIndicator, Option, Group, MenuList }}
        isSearchable={searchable}
        hideSelectedOptions={false}
        menuPortalTarget={document.body}
        isMulti={multiple}
        options={options}
        closeMenuOnSelect={!multiple}
        loadOptions={loadOptions}
        error={error}
        {...rest}
      />
      {!!error && <Text variant="error">{error.message}</Text>}
    </>
  );
});

SelectInput.displayName = 'Select';

export default SelectInput;
