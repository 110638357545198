import { captureException } from '@sentry/react';

export const logError = (error) => {
  if (process.env.TARGET_ENV !== 'development') {
    captureException(error);
  }

  if (process.env.TARGET_ENV !== 'production') {
    console.error(error);
  }
};
