const brainstorm = {
  categories: {
    status: 'initial',
    error: null,
    data: [],
  },
  brainstorms: {
    status: 'initial',
    error: null,
    data: [],
  },
  imagePresets: {
    status: 'initial',
    error: null,
    data: [],
  },
  form: {
    status: 'initial',
    error: null,
    data: null,
  },
  modules: {
    status: 'initial',
    error: null,
    data: [],
  },
  image: {
    status: 'initial',
    error: null,
    data: null,
  },
  documents: {
    status: 'initial',
    error: null,
    data: [],
  },
  participants: {
    status: 'initial',
    error: null,
    data: [],
  },
  invitation: {
    status: 'initial',
    error: null,
    data: [],
  },
};

export default brainstorm;
