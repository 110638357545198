import React from 'react';
import styled from 'styled-components';

import { useSelector, shallowEqual } from 'react-redux';
import i18n from '@/i18n';

import { device } from 'Helpers/styling';
import { colors } from 'Abstracts/variables';

// Elements
import { Row, Col } from 'Components/Elements/Grid';
import Card from 'Components/Elements/Card';
import Drawer from 'Components/Elements/Drawer';
import Link from 'Components/Elements/Link';
import { Text } from 'Components/Elements/TypeSet';
import Avatar from 'Components/Compounds/Avatar';

// Icons
import { ReactComponent as AccountIcon } from 'Assets/images/setting.svg';
import { ReactComponent as SimpleBSIcon } from 'Assets/images/brainstorm.svg';
import { ReactComponent as CloseIcon } from 'Assets/images/close.svg';

import { ReactComponent as LogoColour } from 'Assets/images/colour-logo.svg';

import { useResponsive, useConfirm } from 'Helpers/hooks';
import { EXPIRY_CTA } from 'Pages/Home';

const user = (state) => state.user;

const AccountDrawer = (props) => {
  const { show, onClose, onClick, outside = false } = props;

  const screen = useResponsive();
  const isDesktop = screen.size('md');
  const confirm = useConfirm();

  const reduxUser = useSelector(user, shallowEqual);

  const handleExpiry = () => {
    confirm(i18n.t('Brainstorms cannot be created'), EXPIRY_CTA).then((res) => {
      if (res) {
        history.push(`${langPrefix}/user`);
      }
    });
  };

  return (
    <Drawer
      show={show}
      maxWidth="400px"
      closable
      onClose={onClose}
      rounded={isDesktop}
      hideButton={!isDesktop}
      fullHeight
      outside={outside}
      noPadding
      style={{ overflow: 'visible' }}
      mountOnEnter
    >
      <>
        {!isDesktop && (
          <AccountDrawerHeader mx="0">
            <Col p="18px 16px" h-align="left" v-align="center">
              <CloseBtn onClick={onClose} width="30px" height="30px" />
            </Col>
          </AccountDrawerHeader>
        )}

        <AccountDrawerWrapper>
          <Card
            width="100%"
            padding={{ _: '0 40px 0', md: '80px 60px 20px' }}
            height="60%"
            borderRadius={{ _: '0', md: '50px 0 0 0' }}
            bg="#fff"
          >
            {!isDesktop && (
              <Row mr="0" ml="-20px" mt="12px" mb="28px">
                <a href={`${process.env.PROMOTE_URI}`}>
                  <LogoColour height="47px" width="175px" />
                </a>
              </Row>
            )}

            {reduxUser.data && (
              <Row mx="0" mb="10px">
                <Avatar
                  size={isDesktop ? 'large' : 'medium'}
                  name={`${reduxUser.data.name} ${reduxUser.data.surname}`}
                  picture={reduxUser.data.image || null}
                  color={colors.secondary}
                />
              </Row>
            )}

            {!!reduxUser.data && (
              <Row mx="0">
                <Text variant="h1" color="#333333">
                  {reduxUser.data.name} {reduxUser.data.surname}
                </Text>
              </Row>
            )}

            <Row mx="0" style={{ flexGrow: 1 }}>
              <Col p={{ _: 0, md: '0 0' }} span="12" v-align="center">
                <AccountDrawerMenu>
                  <Row mx="0">
                    <Col width="40px" span="auto" p="0">
                      <SimpleBSIcon />
                    </Col>
                    <Col span="auto" p="0" v-align="center" h-align="left">
                      {reduxUser.data && (
                        <Link
                          to={
                            reduxUser.data.isPlanActive ? '/manage/create' : '/'
                          }
                          onClick={
                            reduxUser.data.isPlanActive ? onClick : handleExpiry
                          }
                          style={{ textDecoration: 'none' }}
                        >
                          <Text variant="h4" color="#333333" ml="12px">
                            {i18n.t('CREATE BRAINSTORM')}
                          </Text>
                        </Link>
                      )}
                    </Col>
                  </Row>
                  <Row mx="0">
                    <Col width="40px" span="auto" p="0">
                      <AccountIcon />
                    </Col>
                    <Col span="auto" p="0" v-align="center" h-align="left">
                      <Link
                        to="/user"
                        onClick={onClick}
                        style={{ textDecoration: 'none' }}
                      >
                        <Text variant="h4" color="#333333" ml="12px">
                          {i18n.t('ACCOUNT')}
                        </Text>
                      </Link>
                    </Col>
                  </Row>
                </AccountDrawerMenu>
              </Col>
            </Row>
          </Card>
          <Card
            width="100%"
            bg="#f5f5f5"
            padding="60px 40px 60px 100px"
            borderRadius={{ _: 0, md: '0 0 0 50px' }}
            height="40%"
            justifyContent="flex-start"
          >
            <Row mx="0" mb="20px">
              <Link
                to="/logout"
                onClick={onClick}
                style={{ textDecoration: 'none' }}
              >
                <Text variant="paragraph" color="#333333">
                  {i18n.t('LOG OUT')}
                </Text>
              </Link>
            </Row>
            {process.env.VERSION >= 2.0 && (
              <Row mx="0" mb="20px">
                <Text variant="paragraph" color="#333333">
                  {i18n.t('LANGUAGE')}
                </Text>
              </Row>
            )}
            <Row mx="0" mb="20px">
              <a href={`${process.env.PROMOTE_URI}/blog`} target="__blank">
                <Text variant="paragraph" color="#333333">
                  {i18n.t('BLOG')}
                </Text>
              </a>
            </Row>
            <Row mx="0" mb="20px">
              <a href={`${process.env.PROMOTE_URI}/faq`} target="__blank">
                <Text variant="paragraph" color="#333333">
                  {i18n.t('HELP')}
                </Text>
              </a>
            </Row>
          </Card>
        </AccountDrawerWrapper>
      </>
    </Drawer>
  );
};

export default AccountDrawer;

const AccountDrawerHeader = styled(Row)`
  position: fixed;
  top: 0;
  z-index: 100;
  padding: 13.5px 15px 0;
`;

const AccountDrawerWrapper = styled.div`
  border-radius: 50px;
  height: 100%;
  ${device.sm`
    height: 100vh;
  `}

  ${device.md`
    box-shadow: -5px 0 10px 0 rgba(0, 0, 0, 0.16);  
  `}
`;

const AccountDrawerMenu = styled.div`
  display: inline-flex;
  flex-direction: column;
  height: 100%;
  max-height: 200px;
  justify-content: space-evenly;
`;

const CloseBtn = styled(CloseIcon)`
  position: absolute;
  right: 20px;
`;
