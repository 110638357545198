import React, { useEffect, useState, useRef, useCallback } from 'react';

import i18n from '@/i18n';

// Elements
import { Text } from 'Components/Elements/TypeSet';

import Avatar from 'Components/Compounds/Avatar';
import Icon from 'Components/Elements/Icon';
import File from 'Components/Elements/Form/File';
import { Row, Col } from 'Components/Elements/Grid';

// Utils
import { scrollTo, getFormatedTime } from 'Helpers/utils';
import { useResponsive } from 'Helpers/hooks';

import { ReactComponent as SendIcon } from 'Assets/images/icons/send.svg';

// Icons
import { ReactComponent as ImageIcon } from 'Assets/images/icons/image.svg';
import { ReactComponent as DocumentIcon } from 'Assets/images/document.svg';

// styles
import Linkify from 'linkifyjs/react';
import {
  ChatWrapper,
  ChatMessage,
  PopupWrapper,
  ChatBubbleIn,
  ChatInput,
  ChatInputWrapper,
  ChatBubbleOut,
  Separator,
  InsideWrapper,
  SentMessage,
  ReceivedMessage,
  SendIconWrapper,
} from './Chat.styles';

import ChatAsset from './ChatAsset';
import ChatImage from './ChatImage';

export default function Chat({
  data = [],
  dataHandler,
  lastHandler,
  fetchHandler,
  transparent = false,
  lastSeen = null,
  fadeColor,
}) {
  // hooks
  const chatRef = useRef(null);

  const screen = useResponsive();
  const isDesktop = screen.size('md');

  const scrollToBottom = useCallback(
    () => scrollTo(chatRef.current, { vertical: 'bottom' }),
    []
  );

  // states
  const [message, setMessage] = useState('');
  const [showImage, setShowImage] = useState([]);
  const [uploader, setUploader] = useState(false);

  // handle functions
  const handleChange = (event) => {
    setMessage(event.target.value);
  };

  const handleEnter = () => {
    if (!message) return;

    dataHandler('text', message);
    setMessage('');
  };

  const handleUploadImage = (files) => {
    dataHandler('image', files[0]);
    setUploader(false);
  };

  const handleUploadAsset = (files) => {
    dataHandler('asset', files[0]);
    setUploader(false);
  };

  const handlePullDown = () => {
    fetchHandler();
  };

  useEffect(() => {
    if (data.length > 0) {
      const lastest = data[0].id;

      lastHandler(lastest);

      const updatedData = [...showImage];
      updatedData.push(false);
      setShowImage(updatedData);

      if (lastest !== lastSeen) scrollToBottom();
    }
  }, [data]);

  const handleNewImageMessage = () => {
    scrollToBottom();
  };

  const handleOpenImage = (index) => {
    const updateOpenImage = [...showImage];
    updateOpenImage[index] = true;
    setShowImage(updateOpenImage);
  };

  const handleCloseImage = (index) => {
    const updateCloseImage = [...showImage];
    updateCloseImage[index] = false;
    setShowImage(updateCloseImage);
  };

  useEffect(() => {
    if (data.length > 0) {
      const newList = [];
      for (let i = 0; i < data.length; i++) {
        newList.push(false);
      }
      setShowImage(newList);
    }
  }, []);

  return (
    <ChatWrapper transparent={transparent}>
      <ChatMessage
        ref={chatRef}
        fadeColor={fadeColor}
        onPullDown={handlePullDown}
        reverse
        initialPosition="bottom"
        height={
          isDesktop
            ? 'calc(100% - 80px)'
            : transparent
            ? 'none'
            : 'calc(100% - 45px)'
        }
      >
        {data.length > 0 &&
          data.map((chat, index) =>
            chat.recipient ? (
              <ReceivedMessage key={index}>
                <Row mx="0">
                  <Col span="auto" width="60px" p="0">
                    <Avatar name={chat.user} style={{ marginRight: '10px' }} />
                  </Col>
                  <Col span="auto" p="0">
                    <InsideWrapper>
                      <Row mx="0">
                        <Col span="12" p="0">
                          {' '}
                          <Text
                            as="time"
                            variant="timestamp"
                            textAlign="left"
                            p="0 0 5px"
                            color={transparent ? 'white' : '#999999'}
                          >
                            {getFormatedTime(chat.timestamp, 'time')}
                          </Text>
                        </Col>

                        {chat.value && (
                          <Col span="12" p="0">
                            <ChatBubbleIn>
                              <Text
                                variant={isDesktop ? 'paragraph' : 'font-11'}
                                color="#666666"
                              >
                                <Linkify
                                  options={{
                                    target: '_blank',
                                  }}
                                >
                                  {chat.value}
                                </Linkify>
                              </Text>
                            </ChatBubbleIn>
                          </Col>
                        )}
                        {chat.thumb && (
                          <Col span="12" p="0">
                            <ChatImage
                              image={chat.image}
                              thumb={chat.thumb}
                              onClick={() => handleOpenImage(index)}
                              onClose={() => handleCloseImage(index)}
                              onLoad={handleNewImageMessage}
                              show={showImage[index]}
                              index={index}
                            />
                          </Col>
                        )}
                        {chat.asset && (
                          <Col span="12" p="0">
                            <ChatAsset
                              url={chat.asset}
                              fileSize={chat.sizeInKb}
                            />
                          </Col>
                        )}
                      </Row>
                    </InsideWrapper>
                  </Col>
                </Row>
              </ReceivedMessage>
            ) : (
              <SentMessage key={index}>
                <Row mx="0">
                  <Col span="12" p="0">
                    <InsideWrapper>
                      <Row mx="0">
                        <Col span="12" p="0">
                          {' '}
                          <Text
                            as="time"
                            variant="timestamp"
                            textAlign="right"
                            p="0 0 5px"
                            color={transparent ? 'white' : '#999999'}
                          >
                            {getFormatedTime(chat.timestamp, 'time')}
                          </Text>
                        </Col>

                        {chat.value && (
                          <Col span="12" p="0" h-align="right">
                            <ChatBubbleOut>
                              <Text
                                variant={isDesktop ? 'paragraph' : 'font-11'}
                                color="#666666"
                              >
                                <Linkify
                                  options={{
                                    target: '_blank',
                                  }}
                                >
                                  {chat.value}
                                </Linkify>
                              </Text>
                            </ChatBubbleOut>
                          </Col>
                        )}
                        {chat.thumb && (
                          <Col span="12" p="0" h-align="right">
                            {' '}
                            <ChatImage
                              rightAligned
                              image={chat.image}
                              thumb={chat.thumb}
                              onClick={() => handleOpenImage(index)}
                              onClose={() => handleCloseImage(index)}
                              onLoad={handleNewImageMessage}
                              show={showImage[index]}
                              index={index}
                            />
                          </Col>
                        )}
                        {chat.asset && (
                          <Col span="12" p="0" h-align="right">
                            <ChatAsset
                              url={chat.asset}
                              fileSize={chat.sizeInKb}
                            />
                          </Col>
                        )}
                      </Row>
                    </InsideWrapper>
                  </Col>
                </Row>
              </SentMessage>
            )
          )}
      </ChatMessage>

      {isDesktop && (
        <Separator transparent={transparent}>
          <hr />
        </Separator>
      )}

      <ChatInputWrapper transparent={transparent}>
        {!!uploader && (
          <PopupWrapper>
            {uploader === 'image' && (
              <>
                <File
                  placeholder="Upload image"
                  onUpload={handleUploadImage}
                  accept="image/*"
                  p="70px 0"
                  m="10px 0"
                  maxSize={2 * 1024 * 1024}
                  showList={false}
                />
              </>
            )}

            {uploader === 'asset' && (
              <>
                <File
                  placeholder="Upload asset"
                  onUpload={handleUploadAsset}
                  accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,
                text/plain, application/pdf"
                  p="70px 0"
                  m="10px 0"
                  maxSize={2 * 1024 * 1024}
                  showList={false}
                />
              </>
            )}
          </PopupWrapper>
        )}
        <Row mx="0">
          <Col
            width={
              message
                ? isDesktop
                  ? 'calc(100% - 85px)'
                  : 'calc(100% - 55px)'
                : isDesktop
                ? 'calc(100% - 130px)'
                : 'calc(100% - 105px)'
            }
            span="auto"
            p="0"
            v-align="center"
          >
            <ChatInput
              width="100%"
              noPaddingContainer
              framed
              placeholder={
                isDesktop ? i18n.t('Your message goes here') : i18n.t('Aa')
              }
              onChange={handleChange}
              onEnter={handleEnter}
              debounce={{ mode: 'none' }}
              value={message}
            />
          </Col>
          <Col
            width={
              message
                ? isDesktop
                  ? '85px'
                  : '55px'
                : isDesktop
                ? '130px'
                : '105px'
            }
            p="0"
            span="auto"
            v-align="center"
            h-align={message && !isDesktop ? 'right' : 'center'}
          >
            {message ? (
              <SendIconWrapper
                p="10px"
                width="45px"
                height="45px"
                transparent={transparent}
                onClick={handleEnter}
              >
                <SendIcon />
              </SendIconWrapper>
            ) : (
              <Row ml="0" mr={{ _: '-10px', md: 0 }} maxWidth="100px">
                <Col p="0" h-align="center">
                  <Icon
                    p="10px"
                    width="45px"
                    height="45px"
                    onClick={() =>
                      setUploader((prevState) =>
                        prevState !== 'image' ? 'image' : false
                      )
                    }
                  >
                    <ImageIcon width="30px" height="20px" />
                  </Icon>
                </Col>
                <Col p="0" h-align="center">
                  <Icon
                    p="10px"
                    width="45px"
                    height="45px"
                    onClick={() =>
                      setUploader((prevState) =>
                        prevState !== 'asset' ? 'asset' : false
                      )
                    }
                  >
                    <DocumentIcon width="24px" height="30px" />
                  </Icon>
                </Col>
              </Row>
            )}
          </Col>
        </Row>
      </ChatInputWrapper>
    </ChatWrapper>
  );
}
