import React from 'react';

import styled, { css } from 'styled-components';

import withRTC from 'Containers/withRTC';
import Avatar from 'Components/Compounds/Avatar';

import { ReactComponent as MutedIcon } from 'Assets/images/icons/muted.svg';

const UserAudio = (props) => {
  const { muted, ...rest } = props;

  return (
    <AvatarWrapper>
      {muted && <MutedIcon />}
      <AvatarWithVolume {...rest} />
    </AvatarWrapper>
  );
};

export default withRTC(UserAudio);

const AvatarWithVolume = styled(Avatar)`
  transition: box-shadow 0.2s ease-in-out;
  ${({ volume }) => {
    if (volume) {
      const shadowWidth = volume * 5;
      return css`
        box-shadow: 0 ${shadowWidth / 2}px ${shadowWidth}px 0 #fcce44;
      `;
    }
  }}
`;

const AvatarWrapper = styled.div`
  position: relative;
  svg {
    position: absolute;
    right: -5px;
    top: 0px;
    z-index: 2;
  }
`;
