import React, { createContext, useState } from 'react';

export const Context = createContext({});

export const defaultState = {};

export const { Consumer } = Context;

export const Provider = (props) => {
  const { initState = defaultState, children } = props;

  const [data, setData] = useState(initState);

  const setScopedData = (scopedData) => {
    if (scopedData && {}.toString.call(scopedData) === '[object Function]') {
      return setData((prevState) => ({
        ...prevState,
        ...scopedData(prevState),
      }));
    }
    return setData((prevState) => ({
      ...prevState,
      ...scopedData,
    }));
  };

  const resetScopedData = () => {
    return setData({});
  };

  const themeContext = {
    scopedData: data,
    setData,
    setScopedData,
    resetScopedData,
  };

  return <Context.Provider value={themeContext}>{children}</Context.Provider>;
};
