import fontStyles from 'Mixins/styles/typography';
import { colors } from 'Abstracts/variables';

import { darken } from 'polished';

const buttonVariants = {
  primary: {
    background: colors.primary,
    color: colors.dark,
    borderColor: colors.primary,
    '&:hover': {
      backgroundColor: '#ffbf00',
    },
  },
  secondary: {
    color: colors.dark,
    background: colors.light,
    borderColor: colors.primary,
    '&:hover': {
      backgroundColor: '#ffbf00',
    },
  },
  tertiary: {
    background: '#0083c6',
    color: colors.light,
    borderColor: '#0083c6',
    '&:hover': {
      borderColor: '#0083c6',
      backgroundColor: colors.light,
      color: '#0083c6',
    },
  },
  alert: {
    background: colors.red,
    color: colors.light,
    borderColor: colors.red,
    '&:hover': {
      backgroundColor: darken(0.1, colors.red),
      borderColor: darken(0.1, colors.red),
    },
  },
  outline: {
    background: colors.light,
    color: '#1383C6',
    borderColor: '#0083c6',
    '&:hover': {
      borderColor: '#0083c6',
      backgroundColor: colors.light,
      color: colors.dark,
    },
  },
  'outline-primary': {
    background: 'transparent',
    color: colors.primary,
    borderColor: colors.primary,
    '&:hover': {
      borderColor: colors.primary,
      backgroundColor: colors.primary,
      color: colors.dark,
    },
  },
  filled: {
    background: '#e1e1e1',
    color: colors.dark,
    borderColor: '#e1e1e1',
    '&:hover': {
      backgroundColor: darken(0.1, '#e1e1e1'),
    },
  },
  active: {
    ...fontStyles.question,
    border: 'solid 2px #0078bd',
    backgroundColor: '#0078bd',
    color: colors.light,
  },
  inActive: {
    ...fontStyles.question,
    border: 'solid 2px #0078bd',
    backgroundColor: colors.light,
    color: '#999999',
    fontWeight: 'normal',
  },
};

export default buttonVariants;
