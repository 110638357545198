import styled, { css } from 'styled-components';

import fontStyles from 'Mixins/styles/typography';

import { lighten, rgba } from 'polished';

import Card from 'Components/Elements/Card';
import Image from 'Components/Elements/Image';

import Text from 'Components/Elements/TypeSet/Text';

const getColor = (props) => {
  if (props.isDragAccept) {
    return '#00e676';
  }
  if (props.isDragReject) {
    return '#ff1744';
  }
  if (props.isDragActive) {
    return '#2196f3';
  }
  return '#eeeeee';
};

export const PlaceholderText = styled(Text)`
  margin-top: 8.5px;
  font-family: Roboto;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4em;
  letter-spacing: normal;
  text-align: center;
  transition: color 0.2s ease-in-out;
  ${(props) =>
    !!props.bold &&
    css`
      font-weight: bold;
    `};
  ${(props) =>
    !!props.underline &&
    css`
      text-decoration: underline;
    `};
  ${(props) =>
    !!props.error &&
    css`
      ${fontStyles.error}
      margin-top: 30px;
    `};
`;

export const FileText = styled(Text)`
  display: block;
  color: #999999;
`;

export const StyledInput = styled.input`
  display: none;
`;

export const StyledCard = styled(Card).attrs((props) => ({
  boxShadow: props.boxShadow || '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
  margin: props.margin || props.m || '8px',
}))`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  /* box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16); */
  border: solid 1px #666666;
  width: 100%;
  min-height: 170px;
  padding: 25px;
  border-color: ${(props) => getColor(props)};
  cursor: pointer;
  transition: border-color 0.2s ease-in-out;
  position: relative;
  /* margin-bottom: 8px; */
  &:hover {
    border-color: ${lighten('.2', '#999999')};
    ${PlaceholderText} {
      color: ${lighten('.2', '#0083c7')};
    }
  }
  ${(props) =>
    !!props.isError &&
    css`
      border-color: #c64f4b;
      &:hover {
        border-color: ${lighten('.2', '#c64f4b')};
        ${PlaceholderText} {
          color: ${lighten('.2', '#c64f4b')};
        }
      }
    `}
`;

export const FileOverlay = styled(Card)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${rgba('#fff', 0.75)};
  backdrop-filter: blur(1px);
  border-radius: inherit;
`;

export const FilePreviewImage = styled(Image)`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  opacity: 0.15;
`;
