import auth from './auth';
import user from './user';
import brainstorm from './brainstorm';
import session from './session';
import module from './module';
import payment from './payment';
import tour from './tour';

const initialState = {
  auth,
  user,
  brainstorm,
  session,
  module,
  payment,
  tour,
};

export default initialState;
