import axiosInstance from 'Helpers/api/_instance';

export const fetchProfile = (token) =>
  axiosInstance({
    method: 'get',
    url: `user/profile`,
    headers: { Authorization: token },
  });

export const updateProfile = (data, token) =>
  axiosInstance({
    method: 'put',
    url: `user/profile`,
    headers: { Authorization: token },
    data,
  });

export const fetchPublicProfile = (id, token) =>
  axiosInstance({
    method: 'get',
    url: `user/${id}`,
    headers: { Authorization: token },
  });

export const postProfilePicture = (data, token) =>
  axiosInstance({
    method: 'post',
    url: `user/picture`,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: token,
    },
    data,
  });

export const deleteProfilePicture = (token) =>
  axiosInstance({
    method: 'delete',
    url: `user/picture`,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: token,
    },
  });

export const postUpgrade = async (form, token) =>
  axiosInstance({
    method: 'post',
    url: `plan/add`,
    data: form,
    headers: { 'Content-Type': 'application/json', Authorization: token },
  });

export const postUnsubscribe = async (token) =>
  axiosInstance({
    method: 'post',
    url: `plan/unsubscribe`,
    headers: { 'Content-Type': 'application/json', Authorization: token },
  });

export const postCloseAccount = async (token) =>
  axiosInstance({
    method: 'delete',
    url: `user/account`,
    headers: { 'Content-Type': 'application/json', Authorization: token },
  });

export const postChangePlan = async (form, token) =>
  axiosInstance({
    method: 'post',
    url: `plan/change/${form.plan}`,
    data: form,
    headers: { 'Content-Type': 'application/json', Authorization: token },
  });
