import * as types from 'Stores/types';

import { checkToken, saveToken, signUp } from 'Helpers/api/auth';

export function setToken(data, save = false) {
  return (dispatch) =>
    new Promise((resolve) => {
      const prevToken = checkToken('moderator', 'cookies');

      const token =
        prevToken !== data ? saveToken(data, 'moderator', save) : data;

      dispatch({
        type: types.SET_TOKEN,
        payload: {
          token,
        },
      });

      resolve(true);
    });
}

export function setAuthenticate(data) {
  return (dispatch) =>
    new Promise((resolve) => {
      dispatch({
        type: types.SET_AUTHENTICATE,
        payload: {
          authenticated: data,
        },
      });

      resolve(true);
    });
}

export function postSignUp(data, registerToken) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      if (registerToken) {
        resolve(true);
      }

      dispatch({
        type: types.POST_SIGNUP_START,
      });

      signUp(data)
        .then((response) => {
          dispatch({
            type: types.POST_SIGNUP_SUCCESS,
          });

          resolve(response);
        })
        .catch((error) => {
          dispatch({
            type: types.POST_SIGNUP_FAILURE,
          });

          let errorInstance;

          switch (error.response.data.statusCode.code) {
            case -1012:
              errorInstance = new UserError(
                'You already have an account. Please login via e-mail'
              );
              break;
            default:
              errorInstance = new Error(error.response.data.statusCode.msg);
          }

          reject(errorInstance);
        });
    });
}
