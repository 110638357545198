import React from 'react';

import styled from 'styled-components';
import { background, color } from 'styled-system';

const BackgroundOverlay = styled.div`
  width: 100%;
  height: 100%;
  display: block;
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
  z-index: -1;
  ${background}
  ${color}
`;

const Background = (props) => {
  const { options, ...rest } = props;

  const { color, image, size, position } = options;

  return (
    <BackgroundOverlay
      backgroundColor={color}
      backgroundImage={image}
      backgroundSize={size}
      backgroundPosition={position}
      {...rest}
    />
  );
};

export default Background;
