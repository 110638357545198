import * as types from 'Stores/types';

import {
  fetchProfile,
  updateProfile,
  fetchPublicProfile,
  postProfilePicture,
  deleteProfilePicture,
  postUpgrade,
  postChangePlan,
  postUnsubscribe,
  postCloseAccount,
} from 'Helpers/api/user';

export function setUser() {
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      const state = getState();

      dispatch({
        type: types.SET_USER_START,
        payload: {
          status: 'loading',
        },
      });
      fetchProfile(state.auth.token)
        .then((response) => {
          dispatch({
            type: types.SET_USER_SUCCESS,
            payload: {
              status: 'loaded',
              data: response.data.payload,
            },
          });
          return resolve(response.data.payload);
        })
        .catch((error) => {
          console.debug(error);
          dispatch({
            type: types.SET_USER_FAILURE,
            payload: {
              status: 'loaded',
              data: null,
              error,
            },
          });
          return reject(error);
        });
    });
}

export function resetUser() {
  return (dispatch) =>
    new Promise((resolve) => {
      dispatch({
        type: types.RESET_USER,
        payload: {
          status: 'inital',
          error: null,
          data: null,
        },
      });

      resolve(true);
    });
}

export const putProfile = (data) => {
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      const state = getState();

      dispatch({
        type: types.PUT_USER_START,
        payload: {
          status: 'loading',
        },
      });
      updateProfile(data, state.auth.token)
        .then((response) => {
          dispatch({
            type: types.PUT_USER_SUCCESS,
            payload: {
              status: 'loaded',
              data: response.data.payload,
            },
          });

          return resolve(response.data.payload);
        })
        .catch((error) => {
          dispatch({
            type: types.PUT_USER_FAILURE,
            payload: {
              status: 'loaded',
              data: null,
              error,
            },
          });
          return reject(error);
        });
    });
};

export function getProfilePicture(id) {
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      const state = getState();

      dispatch({
        type: types.GET_PROFILE_PICTURE_START,
      });
      fetchPublicProfile(id, state.auth.token)
        .then((response) => {
          dispatch({
            type: types.GET_PROFILE_PICTURE_SUCCESS,
            payload: {
              image: response.data.payload.image,
            },
          });
          return resolve(response.data.payload);
        })
        .catch((error) => {
          dispatch({
            type: types.GET_PROFILE_PICTURE_FAILURE,
            payload: {
              status: 'loaded',
              data: null,
              error,
            },
          });
          return reject(error);
        });
    });
}

export function getProfilePictureByParticipant(id) {
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      const state = getState();

      const { token } = state.session;

      dispatch({
        type: types.GET_PROFILE_PICTURE_BY_PARTICIPANT_START,
      });
      fetchPublicProfile(id, token.data)
        .then((response) => {
          dispatch({
            type: types.GET_PROFILE_PICTURE_BY_PARTICIPANT_SUCCESS,
          });
          return resolve(response.data.payload);
        })
        .catch((error) => {
          dispatch({
            type: types.GET_PROFILE_PICTURE_BY_PARTICIPANT_FAILURE,
          });
          return reject(error);
        });
    });
}

export function setProfilePicture(data, id) {
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      const state = getState();

      dispatch({
        type: types.SET_PROFILE_PICTURE_START,
        payload: {
          status: 'loading',
        },
      });
      postProfilePicture(data, state.auth.token)
        .then(() => {
          dispatch(getProfilePicture(id));
          dispatch({
            type: types.SET_PROFILE_PICTURE_SUCCESS,
            payload: {
              status: 'loaded',
            },
          });
          return resolve(true);
        })
        .catch((error) => {
          dispatch({
            type: types.SET_PROFILE_PICTURE_FAILURE,
            payload: {
              status: 'loaded',
              data: null,
              error,
            },
          });
          return reject(error);
        });
    });
}

export function removeProfilePicture() {
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      const state = getState();
      const { id } = state.user.data;

      dispatch({
        type: types.DELETE_PROFILE_PICTURE_START,
        payload: {
          status: 'loading',
        },
      });
      deleteProfilePicture(state.auth.token)
        .then(() => {
          dispatch(getProfilePicture(id));
          dispatch({
            type: types.DELETE_PROFILE_PICTURE_SUCCESS,
            payload: {
              status: 'loaded',
            },
          });
          return resolve(true);
        })
        .catch((error) => {
          dispatch({
            type: types.DELETE_PROFILE_PICTURE_FAILURE,
          });
          return reject(error);
        });
    });
}

export function upgradeUser(data, type) {
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      const state = getState();
      const upgrade = type === 'upgrade';

      const postHandler = upgrade ? postUpgrade : postChangePlan;

      dispatch({
        type: types.UPGRADE_USER_START,
        payload: {
          status: 'loading',
        },
      });
      postHandler(data, state.auth.token)
        .then((response) => {
          dispatch({
            type: types.UPGRADE_USER_SUCCESS,
            payload: {
              status: 'loaded',
              data: response.data.payload,
            },
          });
          return resolve(response.data.payload);
        })
        .catch((error) => {
          console.debug(error);
          dispatch({
            type: types.UPGRADE_USER_FAILURE,
            payload: {
              status: 'loaded',
              data: null,
              error,
            },
          });
          return resolve(error.response.data);
        });
    });
}

export function unsubscribePlan() {
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      const state = getState();

      dispatch({
        type: types.UNSUBSCRIBE_USER_START,
        payload: {
          status: 'loading',
        },
      });
      postUnsubscribe(state.auth.token)
        .then((response) => {
          dispatch({
            type: types.UNSUBSCRIBE_USER_SUCCESS,
            payload: {
              status: 'loaded',
              data: response.data.payload,
            },
          });
          return resolve(response.data.payload);
        })
        .catch((error) => {
          console.debug(error);
          dispatch({
            type: types.UNSUBSCRIBE_USER_FAILURE,
            payload: {
              status: 'loaded',
              data: null,
              error,
            },
          });
          return reject(error);
        });
    });
}

export function closeAccount() {
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      const state = getState();

      dispatch({
        type: types.CLOSE_ACCOUNT_START,
        payload: {
          status: 'loading',
        },
      });
      postCloseAccount(state.auth.token)
        .then((response) => {
          dispatch({
            type: types.CLOSE_ACCOUNT_SUCCESS,
          });
          return resolve(response.data.payload);
        })
        .catch((error) => {
          console.debug(error);
          dispatch({
            type: types.CLOSE_ACCOUNT_FAILURE,
            payload: {
              status: 'loaded',
              data: null,
              error,
            },
          });
          return reject(error);
        });
    });
}
