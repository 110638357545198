import React, { createContext, useState, useCallback } from 'react';

import { useHistory } from 'react-router-dom';

import modules from 'Abstracts/modules';

import { useAsyncState } from 'Helpers/hooks';

export const Context = createContext({});

export const defaultState = {};

export const { Consumer } = Context;

export const Provider = (props) => {
  const { initState = defaultState, children } = props;

  const [state, setState] = useState(initState);

  const asyncSetState = useAsyncState([state, setState]);

  const [mounted, setMounted] = useState(false);

  const history = useHistory();

  const pushHistory = useCallback(
    ({ pathname, state }) => {
      return history.push(pathname, state);
    },
    [history]
  );

  const replaceHistory = useCallback(
    ({ pathname, state }) => {
      return history.push(pathname, state);
    },
    [history]
  );

  // func

  const updateField = (field, { target }, event) => {
    if (target === undefined) {
      return;
    }

    setState((prevState) => ({
      ...prevState,
      brainstorm: {
        ...prevState.brainstorm,
        [field]: target.value,
      },
    }));
  };

  const updateSelect = (field, event) => {
    setState((prevState) => ({
      ...prevState,
      brainstorm: {
        ...prevState.brainstorm,
        [field]: event,
      },
    }));
  };

  const updateError = (step, target, value) => {
    return new Promise(async (resolve) => {
      const newState = await asyncSetState((prevState) => {
        return {
          ...prevState,
          errors: {
            ...prevState.errors,
            [step]: {
              ...prevState.errors[step],
              [target]: value,
            },
          },
        };
      });

      return resolve(newState);
    });
  };

  const updateErrors = (step, data) => {
    return asyncSetState((prevState) => {
      return {
        ...prevState,
        errors: {
          ...prevState.errors,
          [step]: {
            ...prevState.errors[step],
            ...data,
          },
        },
      };
    });
  };

  const updateStep = (step) => {
    setState((prevState) => ({
      ...prevState,
      ui: {
        ...prevState.ui,
        step,
      },
    }));
  };

  const setThumbnail = (image) => {
    setState((prevState) => ({
      ...prevState,
      image,
    }));
  };

  const getModuleSetup = (type) => {
    return Object.values(modules)
      .find((item) => item.name === type)
      .fields.flat()
      .map((item) => item.name);
  };

  const manageContext = {
    state,
    setState,
    updateField,
    updateSelect,
    updateError,
    updateErrors,
    updateStep,
    pushHistory,
    replaceHistory,
    getModuleSetup,
    setThumbnail,
    mounted,
    setMounted,
  };

  return <Context.Provider value={manageContext}>{children}</Context.Provider>;
};
