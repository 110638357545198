import React, { useState, useLayoutEffect, useCallback } from 'react';
import styled from 'styled-components';

const RippleContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;

  span {
    transform: scale(0);
    border-radius: 100%;
    position: absolute;
    opacity: 0.75;
    background-color: ${(props) => props.color};
    animation-name: ripple;
    animation-duration: ${(props) => props.duration}ms;
    will-change: opacity, transform;
  }

  @keyframes ripple {
    to {
      opacity: 0;
      transform: scale(2);
    }
  }
`;

const useDebouncedRippleCleanUp = (rippleCount, duration, cleanUpFunction) => {
  useLayoutEffect(() => {
    let bounce = null;
    if (rippleCount > 0) {
      clearTimeout(bounce);

      bounce = setTimeout(() => {
        cleanUpFunction();
        clearTimeout(bounce);
      }, duration * 4);
    }

    return () => clearTimeout(bounce);
  }, [rippleCount, duration, cleanUpFunction]);
};

const Ripple = ({ duration = 850, color = '#fff', cleanRipples, ripples }) => {
  useDebouncedRippleCleanUp(ripples.length, duration, cleanRipples);

  return (
    <RippleContainer duration={duration} color={color}>
      {ripples.length > 0 &&
        ripples.map((ripple, index) => (
          <span
            key={`span${index}`}
            style={{
              top: ripple.y,
              left: ripple.x,
              width: ripple.size,
              height: ripple.size,
            }}
          />
        ))}
    </RippleContainer>
  );
};

export default Ripple;

export const useRipple = () => {
  const [ripples, setRipples] = useState([]);

  const addRipple = useCallback(
    (event) => {
      const rippleContainer = event.currentTarget.getBoundingClientRect();

      const size =
        rippleContainer.width > rippleContainer.height
          ? rippleContainer.width
          : rippleContainer.height;

      const x = event.clientX - rippleContainer.x - size / 2;
      const y = event.clientY - rippleContainer.y - size / 2;

      const newRipple = {
        x,
        y,
        size,
      };

      setRipples((prev) => [...prev, newRipple]);
    },
    [setRipples]
  );

  const cleanRipples = useCallback(() => {
    setRipples([]);
  }, [setRipples]);

  return [ripples, addRipple, cleanRipples];
};
