import React, {
  useState,
  useRef,
  useEffect,
  useMemo,
  useCallback,
} from 'react';

import { WebRTC } from 'Helpers/libs';

import Drawer from 'Components/Elements/Drawer';
import ScrollView from 'Components/Elements/ScrollView';
import Button from 'Components/Elements/Button';
import { Row, Col } from 'Components/Elements/Grid';
import Text from 'Components/Elements/TypeSet/Text';
// import Icon from 'Components/Elements/Icon';

import { logError } from '@/sentry';

import { useResponsive, useUnMounted } from 'Helpers/hooks';
import { colors } from 'Abstracts/variables';

import { ReactComponent as AudioIcon } from 'Assets/images/icons/audio-icon.svg';

import { useDispatch, useSelector } from 'react-redux';

import { isMobile } from 'react-device-detect';

import {
  setAudio,
  postMutePartipcipant,
  postMuteAllPartipcipants,
  postMuteSelf,
  postSessionAudioTicketRequest,
} from 'Stores/actions/session';

// import { ReactComponent as VolumeOn } from 'Assets/images/icons/volume-on.svg';
// import { ReactComponent as VolumeOff } from 'Assets/images/icons/volume-off.svg';
import { ReactComponent as MicOn } from 'Assets/images/icons/mic-on-chat.svg';
import { ReactComponent as MicOff } from 'Assets/images/icons/mic-off.svg';

import i18n from '@/i18n';
import { toast } from 'react-toastify';
import {
  UserContainer,
  UserHeader,
  UserContent,
  UserUserContainer,
  UserFooter,
  ModUtilWrapper,
} from './UserSection.styles';

import UserAudio from './UserAudio';

const TIMEOUT_INTERVAL = 5;

const UserSectionWithAudio = ({
  isFacilitator,
  brainstormId,
  participants,
  user,
  show,
  onClose,
  chiefStream,
}) => {
  const dispatch = useDispatch();

  const screen = useResponsive();
  const isDesktop = screen.size('md');

  const [consumers, setConsumers] = useState([]);

  const mutedSelf = useMemo(
    () =>
      participants.find((participant) => participant.id === user.id).isMuted,
    [participants, user.id]
  );

  const audioEnabled = useSelector((state) => state.session.audio);

  const [, checkUnMounted] = useUnMounted();

  const audioStream = useRef(null);
  const isConnecting = useRef(false);
  const retryRef = useRef(null);
  const retryCount = useRef(0);

  const rtcRoom = useMemo(() => brainstormId, []);
  const rtcUser = useMemo(() => ({ name: user.name, id: user.id }), []);

  const userToken = useSelector((state) => state.session.token.data) || null;

  const provokeUserAudio = useCallback(() => {
    chiefStream.current = audioStream.current?.getMediaStream();

    if (chiefStream.current) {
      ['removetrack', 'inactive'].forEach((eventName) => {
        chiefStream.current.addEventListener(eventName, () => {
          if (['removetrack', 'inactive'].includes(eventName)) {
            audioStream.current?.disconnect();
          }
        });
      });

      ['ended', 'mute', 'isolationchange'].forEach((eventName) => {
        chiefStream.current
          .getAudioTracks()[0]
          .addEventListener(eventName, () => {
            if (['ended', 'mute'].includes(eventName)) {
              audioStream.current?.disconnect();
            }
          });
      });
    }
  }, []);

  useEffect(() => {
    return () => {
      if (audioStream.current) {
        audioStream.current?.disconnect();
        audioStream.current = null;
      }
      chiefStream.current = null;
      setConsumers([]);
    };
  }, []);

  useEffect(() => {
    retryRef.current = () => {
      if (userToken && !audioStream.current && !isConnecting.current) {
        isConnecting.current = true;

        dispatch(postSessionAudioTicketRequest(brainstormId, userToken)).then(
          (ticket) => {
            audioStream.current = new WebRTC(rtcRoom, rtcUser, ticket, {
              muted: mutedSelf,
              disabled: !audioEnabled,
              transport: {
                retries: 0,
              },
            });

            audioStream.current.connect(
              () => {
                provokeUserAudio();
                retryCount.current = 0;
                isConnecting.current = false;

                toast.dismiss('serverError');
              },
              (error) => {
                // Error-logging for reconnecting scenarios
                if (error.message === '500') {
                  logError(
                    new Error('An error occurred when connecting to WebRTC.')
                  );

                  // Possibly unable to reconnect
                  toast.error(
                    'The audio chat function is having issue with your user connection.',
                    {
                      toastId: 'serverError',
                      autoClose: false,
                    }
                  );
                } else if (
                  !['transport closed', 'peer closed'].includes(error.message)
                ) {
                  logError(error);
                }

                // User error messages for Non-reconnecting scenarios
                if (error.name === 'NotAllowedError') {
                  toast.error(
                    'Please refresh your browser and grant the permssion for brainstorm to access your microphone, in order to participate in the audio chat.',
                    {
                      toastId: 'notAllowedError',
                      autoClose: false,
                    }
                  );
                } else if (
                  error.name === 'UnsupportedError' ||
                  error.message === 'RTCPeerConnection is not defined'
                ) {
                  toast.error(
                    'Your browser is not supported for the audio chat feature.',
                    {
                      toastId: 'unsupportedError',
                      autoClose: false,
                    }
                  );
                } else if (error.name === 'NotFoundError') {
                  toast.error(
                    'Audio chat feature will not be available because no audio input is deteched.',
                    {
                      toastId: 'notFoundError',
                      autoClose: false,
                    }
                  );
                } else {
                  // Reconnection
                  if (error.message !== 'request timeout') {
                    isConnecting.current = false;

                    audioStream.current?.disconnect();
                  } else {
                    toast.error(
                      `The audio chat server is currently busy, reconnecting in ${TIMEOUT_INTERVAL} seconds.`,
                      {
                        toastId: 'timeOutError',
                        autoClose: false,
                        closeButton: null,
                      }
                    );

                    setTimeout(() => {
                      toast.dismiss('timeOutError');

                      isConnecting.current = false;

                      audioStream.current?.disconnect();
                    }, TIMEOUT_INTERVAL * 1000);
                  }
                }
              }
            );

            audioStream.current?.onChange((target) => {
              if (!checkUnMounted()) {
                setConsumers(target.consumers);
              }
            });

            audioStream.current?.onFail((failure) => {
              logError(failure);

              isConnecting.current = false;

              audioStream.current?.disconnect();
            });

            audioStream.current?.onError((error) => {
              logError(error);

              isConnecting.current = false;

              audioStream.current?.disconnect();
            });

            audioStream.current?.onClose(() => {
              audioStream.current = null;
              chiefStream.current = null;

              if (!checkUnMounted()) {
                setConsumers([]);

                // Retry
                retryCount.current += 1;
                if (retryCount.current <= 2) {
                  retryRef.current();
                }
              }
            });
          }
        );
      }
    };
  }, [mutedSelf, audioEnabled]);

  const handleVisibilityChange = useCallback(() => {
    if (!document.hidden) {
      retryRef.current();
    }
  }, []);

  useEffect(() => {
    retryRef.current();

    if (isMobile) {
      document.addEventListener(
        'visibilitychange',
        handleVisibilityChange,
        false
      );
    }

    return () => {
      if (isMobile) {
        document.removeEventListener(
          'visibilitychange',
          handleVisibilityChange,
          false
        );
      }
    };
  }, []);

  const handleMute = (toggle) => {
    dispatch(postMuteSelf(brainstormId, toggle));
  };

  useEffect(() => {
    if (audioStream.current) {
      try {
        if (mutedSelf) {
          audioStream.current.disableMic();
        } else {
          audioStream.current.enableMic();
        }
      } catch (error) {
        logError(error);
      }
    }
  }, [mutedSelf]);

  const handleMuteParticipant = (participantId) => {
    dispatch(postMutePartipcipant(brainstormId, participantId));
  };

  const handleMuteAllParticipants = () => {
    dispatch(postMuteAllPartipcipants(brainstormId));
  };

  const handleToggleSound = (toggle) => {
    dispatch(setAudio(toggle));
  };

  const toggleSound = useCallback(() => handleToggleSound(!audioEnabled), [
    audioEnabled,
    handleToggleSound,
  ]);

  useEffect(() => {
    if (audioStream.current) {
      if (!audioEnabled) {
        audioStream.current?.disableSound();
      } else {
        audioStream.current?.enableSound();
      }
    }
  }, [audioEnabled]);

  const handleOpenChat = onClose;

  const userName = useMemo(
    () =>
      user
        ? user.name
          ? `${user.name} ${user.surname}`
          : user.username
          ? user.username
          : user.email
        : '',
    [user]
  );

  const isAllParticipantsMuted = useMemo(
    () =>
      participants &&
      participants.length > 0 &&
      participants
        .filter((participant) => participant.id !== user.id)
        .every((participant) => !!participant.isMuted),
    [participants, user.id]
  );

  return (
    <>
      <AudioIcon
        height="45px"
        onClick={handleOpenChat}
        style={{ cursor: 'pointer' }}
      />

      <Drawer
        show={show}
        closable
        hideButton
        outside
        noPadding
        maxWidth="450px"
        onClose={handleOpenChat}
      >
        <UserContainer>
          <UserHeader>
            <Row mx="0">
              <Col
                p="0"
                span={{ _: 4, md: 'auto' }}
                width={{ _: '115px', md: '70px' }}
                h-align="left"
                v-align="center"
              >
                {' '}
                <UserAudio
                  name={userName}
                  picture={user ? user.image : null}
                  muted={mutedSelf}
                  size={isDesktop ? 'normal' : 'extraLarge'}
                  color={colors.secondary}
                />
              </Col>
              <Col p="0" span={{ _: 8, md: 'auto' }} v-align="center">
                <Row mx="0">
                  <Col
                    p={{ _: '0 0 14px', md: 0 }}
                    span={{ _: '12', md: 'auto' }}
                    h-align="left"
                    v-align="center"
                  >
                    <Text variant="audioHeader" color="#666">
                      {userName.replace(/(.{10})..+/, '$1...')}
                    </Text>
                  </Col>
                  <Col
                    p="0"
                    span={{ _: '12', md: 'auto' }}
                    width="140px"
                    h-align="left"
                    v-align="center"
                  >
                    <ModUtilWrapper>
                      <Button
                        variant="secondary"
                        onClick={() => handleMute(!mutedSelf)}
                      >
                        {!mutedSelf ? i18n.t('Mute') : i18n.t('Unmute')}
                      </Button>
                      {/* <Icon
                        tooltip={
                          mutedSelf
                            ? i18n.t('Unmute myself')
                            : i18n.t('Mute myself')
                        }
                        ml="7.5px"
                        width="39px"
                        height="39px"
                        border={`1px solid ${colors.primary}`}
                        backgroundColor={colors.light}
                        onClick={() => handleMute(!mutedSelf)}
                      >
                        {!mutedSelf ? <MicOn /> : <MicOff />}
                      </Icon> */}
                      {/* <Icon
                        tooltip={
                          audioEnabled
                            ? i18n.t('Disable sound')
                            : i18n.t('Enable sound')
                        }
                        ml="7.5px"
                        width="39px"
                        height="39px"
                        border={`1px solid ${colors.primary}`}
                        backgroundColor={colors.light}
                        onClick={toggleSound}
                      >
                        {audioEnabled ? <VolumeOn /> : <VolumeOff />}
                      </Icon> */}
                    </ModUtilWrapper>
                  </Col>
                </Row>
              </Col>
            </Row>
          </UserHeader>
          <UserContent>
            <ScrollView height="100%">
              {!!participants &&
                participants.length > 0 &&
                participants
                  .filter((participant) => participant.id !== user.id)
                  .map((participant, index) => {
                    const consumer = consumers.find(
                      (consumer) => consumer.peerId === participant.id
                    );

                    const track = consumer ? consumer.consumer.track : null;
                    const unattending = consumer ? consumer.disabled : true;

                    if (track && unattending) {
                      track.enabled = false;
                    }

                    return (
                      <UserUserContainer key={index}>
                        <Row mx="0">
                          <Col
                            p="0 18px"
                            span="auto"
                            width="80px"
                            h-align="left"
                            v-align="center"
                          >
                            <UserAudio
                              key={index}
                              name={participant.username || participant.email}
                              track={track}
                              muted={participant.isMuted}
                              unattending={unattending}
                              disabled={!participant.connected}
                            />
                          </Col>
                          <Col p="0 18px" h-align="left" v-align="center">
                            <Text variant="audioUser" color="#666">
                              {(
                                participant.username || participant.email
                              ).replace(/(.{10})..+/, '$1...')}
                            </Text>
                          </Col>
                          <Col
                            p="0 18px"
                            span="auto"
                            h-align="right"
                            v-align="center"
                          >
                            <Button
                              variant="primary"
                              onClick={() =>
                                handleMuteParticipant(participant.id)
                              }
                              disabled={
                                !(isFacilitator && !participant.isMuted)
                              }
                            >
                              Mute
                            </Button>
                          </Col>
                        </Row>
                      </UserUserContainer>
                    );
                  })}
            </ScrollView>
          </UserContent>
          {isFacilitator && (
            <UserFooter>
              <Button
                variant="primary"
                onClick={() => handleMuteAllParticipants()}
                disabled={isAllParticipantsMuted}
              >
                Mute all
              </Button>
            </UserFooter>
          )}
        </UserContainer>
      </Drawer>
    </>
  );
};

export default UserSectionWithAudio;
