import { useDispatch } from 'react-redux';

import i18n from '@/i18n';
import { logError } from '@/sentry';

import { ReactComponent as RecordAudioIcon } from 'Assets/images/modules/record-audio.svg';

import {
  postUploadAudio,
  deleteUploadAudio,
  putUploadAudios,
  fetchSoundcloud,
} from 'Stores/actions/module';

import { objectToForm, getValidUrl } from 'Helpers/data-transform';

import { basicFields } from './_common';

const parseData = ({ data, participants }) =>
  data.moduleUploadAudio.data
    .filter((item) => !!item.submitted)
    .map((datum) => {
      let participantData = participants.find(
        (participant) => participant.id === datum.user
      );

      return {
        ...datum,
        user: {
          id: participantData.id,
          name: participantData.name || participantData.username,
        },
      };
    });

const getHandler = ({ brainstormId, user, participants, data }) => {
  const dispatch = useDispatch();

  const handler = ({ type, ...payload }) => {
    let formData;

    switch (type) {
      case 'upload':
        let node = 'audio';

        if (payload.dataType === 'spotify') {
          node = 'spotify';

          formData = objectToForm({
            [node]: payload.file,
          });

          dispatch(postUploadAudio(brainstormId, data.id, formData))
            .then((response) => {
              payload.onDone(
                response.data.payload.id,
                response.data.payload.audio
              );
              payload.onProgress(100);
            })
            .catch((error) => {
              logError(error);
              payload.onError(error);
            });

          return;
        }

        if (payload.dataType === 'soundcloud') {
          node = 'soundcloud';

          formData = objectToForm({
            [node]: payload.file,
          });

          dispatch(postUploadAudio(brainstormId, data.id, formData))
            .then((response) => {
              payload.onDone(
                response.data.payload.id,
                response.data.payload.audio
              );
              payload.onProgress(100);
            })
            .catch((error) => {
              logError(error);
              payload.onError(error);
            });

          return;
        }

        formData = objectToForm({
          [node]: payload.file,
        });

        dispatch(
          postUploadAudio(
            brainstormId,
            data.id,
            formData,
            payload.onProgress,
            payload.cancelToken
          )
        )
          .then((response) => {
            payload.onDone(
              response.data.payload.id,
              response.data.payload.audio
            );
            payload.onProgress(100);
          })
          .catch((error) => {
            logError(error);
            payload.onError(error);
          });
        break;
      case 'remove':
        dispatch(deleteUploadAudio(brainstormId, data.id, payload.id)).catch(
          logError
        );
        break;
      case 'submit':
        dispatch(
          putUploadAudios(
            brainstormId,
            data.id,
            Object.keys(payload.files)
              .filter((key) => !!payload.files[key].id)
              .reduce(
                (acc, key) => ({
                  ...acc,
                  [key]: payload.files[key],
                }),
                {}
              )
          )
        ).then((res) => {
          res.forEach((item) => {
            if (item instanceof Error) {
              logError(item);
            }
          });
        });
        break;
    }
  };

  return handler;
};

const getAnswer = (datum) => datum.audio;

const uploadAudio = {
  name: 'uploadAudio',
  obj: i18n.t('Collect audible inspirations'),
  info: i18n.t(
    'Participants share music/words/noises inspired by your question.'
  ),
  icon: RecordAudioIcon,
  label: i18n.t('Audio Collection'),
  unit: i18n.t('audio clip'),
  endpoint: 'upload-audio',
  color: '#F58D84',
  getHandler,
  parseData,
  getAnswer,
  fields: [...basicFields],
  methodologies: [
    {
      label: i18n.t('Personification'),
      value: 'personification',
      description: i18n.t(`If _____ had a voice, what would it sound like?`),
    },
    {
      label: i18n.t('Idea Generator'),
      value: 'ideaGenerator',
      description: i18n.t(
        `When you think of _____ what sound(s) comes to mind?`
      ),
    },
    {
      label: i18n.t('Comparison'),
      value: 'comparison',
      description: i18n.t(
        `Share a sound/song that expresses how you feel about _____.`
      ),
    },
    {
      label: i18n.t('Oxymoron'),
      value: 'oxymoron',
      description: i18n.t(
        `What would be the exact opposite of _____ sound like?`
      ),
    },
    {
      label: i18n.t('Hyperbole'),
      value: 'hyperbole',
      description: i18n.t(
        `What would be an exaggerated version of _____ sound like?`
      ),
    },
    {
      label: i18n.t('Methaphor'),
      value: 'methaphor',
      description: i18n.t(`If _____ were a song, what would it be? `),
    },
    {
      label: i18n.t('N/A'),
      value: 'none',
      description: i18n.t(``),
    },
  ],
  compliment: [
    i18n.t('That sounds right!'),
    i18n.t('Take a listen.'),
    i18n.t('Explore our soundscape.'),
    i18n.t('Listen to this.'),
    i18n.t('Sounds like inspiration!'),
  ],
  listed: true,
};

export default uploadAudio;
