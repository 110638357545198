import axios from 'axios';

import { objectHtmlDecode } from 'Helpers/utils';

const axiosInstance = axios.create({
  baseURL: process.env.API_URI,
  headers: {
    'content-type': 'application/json',
  },
  responseType: 'json',
});

axiosInstance.interceptors.response.use(
  (response) => {
    return {
      ...response,
      data: objectHtmlDecode(response.data),
    };
  },
  (error) => Promise.reject(error)
);

export default axiosInstance;
