import React from 'react';

import styled, { keyframes } from 'styled-components';
import { rgba, darken, lighten } from 'polished';

import { device } from 'Helpers/styling';
import { colors } from 'Abstracts/variables';

import { ToastContainer, cssTransition } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.minimal.css';

import Icon from 'Components/Elements/Icon';
import { ReactComponent as CrossIcon } from 'Assets/images/icons/cross.svg';

const CloseButton = ({ closeToast }) => {
  return (
    <CloseIcon p="3.75px" onClick={closeToast}>
      <CrossIcon width="13.75px" height="13.75px" />
    </CloseIcon>
  );
};

const fadeIn = keyframes`
  from {
    transform: translateX(100%);
    opacity: 0;
  }

  to {
    transform: translateX(0);
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    transform: translateX(0);
    opacity: 1;
  }

  to {
    transform: translateX(100%);
    opacity: 0;
  }
`;

const Zoom = cssTransition({
  duration: [250, 400],
  enter: 'fadeIn',
  exit: 'fadeOut',
});

const StyledContainer = styled(ToastContainer).attrs(() => ({
  closeButton: CloseButton,
  transition: Zoom,
}))`
  position: fixed;
  /* height: 100%; */
  top: 90px;
  right: 30px;
  z-index: 9999;
  max-width: calc(100% - 60px);
  width: 100%;
  padding-top: 90px;

  ${device.md`
    width: auto;
    top: 120px;
    right: 40px;
  `}
  &.Toastify__toast-container--top-center {
    left: 50%;
    right: auto;
    transform: translateX(-50%);
  }
  .Toastify__toast {
    border: 1px solid ${lighten(0.2, rgba(colors.grey2, 0.9))};
    border-radius: 10px;
    background-color: ${darken(0.2, rgba(colors.secondary, 0.9))};
    margin-bottom: 10px;
    position: absolute;
    right: 0;
    width: 100%;
    ${device.md`
    width: auto;
  `}
    &:nth-child(1) {
      top: 20px;
    }
    &:nth-child(2) {
      top: 40px;
    }
    &:nth-child(3) {
      top: 60px;
    }
    &:not(:last-of-type) {
      > div:not(.Toastify__toast-body) {
        &:nth-child(2) {
          display: none;
        }
      }
    }
  }
  .Toastify__toast--error {
    border-color: ${rgba(colors.red, 0.9)};
  }
  .Toastify__toast--warning {
  }
  .Toastify__toast--success {
    border-color: ${rgba(colors.primary, 0.9)};
  }
  .Toastify__toast-body {
    color: ${colors.light};
    padding: 20px 25px;
    min-width: 100%;

    ${device.md`
      min-width: 400px;
    `}
  }
  .Toastify__progress-bar {
  }
  .Toastify__close-button {
  }
  .fadeIn {
    animation-name: ${fadeIn};
  }
  .fadeOut {
    animation-name: ${fadeOut};
  }
`;

export default StyledContainer;

const CloseIcon = styled(Icon)`
  background: ${colors.primary};
  height: 30px;
  width: 30px;
  position: absolute;
  top: -15px;
  right: -15px;
`;
