import styled from 'styled-components';

import { device } from 'Helpers/styling';
import { colors } from 'Abstracts/variables';
import { lighten } from 'polished';

export const BoundaryWrapper = styled.div`
  width: calc(100% + 16px);
  margin: -8px;
  padding: 40px 0 350px;

  ${({ bg }) =>
    bg && `background: ${colors.light} url(${bg}) no-repeat center bottom;`};

  ${device.md`
    width: 100%;
    max-width: 700px;
    margin: 45px auto;
    padding: 0 0 350px;
  `};
`;

export const BoundaryTitleContainer = styled.div`
  display: block;
  width: 100%;
  height: 120px;
  overflow: hidden;
  position: relative;
  > img {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }
  ${device.sm`
    height: 170px;
    > img {
      top: -15px;
    }
  `};
`;

export const BoundaryLink = styled.a`
  color: ${colors.blue};
  &:hover {
    color: ${lighten(0.1, colors.blue)};
  }
`;

export const WidgetToggle = styled.div`
  position: fixed;
  right: 10px;
  bottom: 10px;
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  background-color: ${colors.primary};
  transition: background-color 0.2s ease-in-out;
  cursor: pointer;
  &:hover {
    background-color: ${lighten(0.1, colors.primary)};
  }
`;
