import { css } from 'styled-components';

import { colors } from 'Abstracts/variables';

export { default as colorStyles } from './color';

export { default as layoutStyles } from './layout';

export { default as fontStyles } from './typography';

export const scrollBar = css`
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    margin: 20px 0;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: ${colors.grey3};
  }
`;
