export const objectToForm = (object) => {
  let formData = new FormData();

  Object.entries(object).forEach(([key, value]) => {
    formData.append(key, value);
  });

  return formData;
};

export const removeProp = (obj, propToDelete) => {
  for (let property in obj) {
    if (obj.hasOwnProperty(property)) {
      if (typeof obj[property] === 'object') {
        removeProp(obj[property], propToDelete);
      } else if (property === propToDelete && obj[property] === true) {
        delete obj[property];
      }
    }
  }
};

export const getValidUrl = (url = '') => {
  let newUrl = window.decodeURIComponent(url);
  newUrl = newUrl.trim().replace(/\s/g, '');

  if (/^(:\/\/)/.test(newUrl)) {
    return `http${newUrl}`;
  }
  if (!/^(f|ht)tps?:\/\//i.test(newUrl)) {
    return `https://${newUrl}`;
  }

  return newUrl;
};
