import { useDispatch } from 'react-redux';

import i18n from '@/i18n';

import { ReactComponent as TypeSomethingIcon } from 'Assets/images/modules/type-something.svg';

import { postTypeSomething } from 'Stores/actions/module';

import { basicFields } from './_common';

const parseData = ({ participants, data }) => {
  const transformUserDataToSentences = (datum) => {
    let participantData = participants.find(
      (participant) => participant.id === datum.user
    );

    let userData = {
      id: 0,
      name: 'N/A',
    };

    if (participantData) {
      userData = {
        id: participantData.id,
        name: participantData.name || participantData.username,
      };
    }

    const sentenceData = datum.values.map((sentenceDatum) => ({
      value: sentenceDatum.value,
      id: sentenceDatum.id,
      user: userData,
    }));

    return sentenceData;
  };

  return data.moduleType.data.reduce(
    (acc, current) => [...acc, ...transformUserDataToSentences(current)],
    []
  );
};

const getHandler = ({ brainstormId, user, participants, data }) => {
  const dispatch = useDispatch();

  const processedData = parseData({ participants, data });

  let currentUserData = processedData
    .filter((datum) => datum.user.id === user.id)
    .map((datum) => datum.value);

  const handler = (value) => {
    const payload = [...currentUserData, ...value];

    dispatch(postTypeSomething(brainstormId, data.id, payload));
  };

  return handler;
};

const getAnswer = (datum) => datum.value;

const type = {
  name: 'type',
  obj: i18n.t('Uncover idea associations'),
  info: i18n.t('Participants share ideas inspired by your question.'),
  icon: TypeSomethingIcon,
  label: i18n.t('Idea Storm'),
  unit: i18n.t('sentence'),
  endpoint: 'type-something',
  color: '#9E78FB',
  getHandler,
  parseData,
  getAnswer,
  fields: [...basicFields],
  methodologies: [
    {
      label: i18n.t('Personification'),
      value: 'personification',
      description: i18n.t(
        `If your _____ was a [Disney character/celebrity/historical figure/etc.], who would it be?`
      ),
    },
    {
      label: i18n.t('Idea Generator'),
      value: 'ideaGenerator',
      description: i18n.t(
        `Share whatever comes to mind when you think of _____.`
      ),
    },
    {
      label: i18n.t('Comparison'),
      value: 'comparison',
      description: i18n.t(`How do you think _____ compares to _____?`),
    },
    {
      label: i18n.t('Oxymoron'),
      value: 'oxymoron',
      description: i18n.t(`What kind of _____ would be the opposite of _____?`),
    },
    {
      label: i18n.t('Methaphor'),
      value: 'methaphor',
      description: i18n.t(
        `What kind of [animal/weather/dance/etc] would be a good visual representation of _____?`
      ),
    },
    {
      label: i18n.t('Hyperbole'),
      value: 'hyperbole',
      description: i18n.t(
        `Share how you would exagerate/amplify the purpose of _____?`
      ),
    },
    {
      label: i18n.t('SWOT'),
      value: 'swot',
      description: i18n.t(`List a strength, weakness, opportunity and threat for _____. 

          Strengths:
          Weaknesses: 
          Opportunities:
          Threats:`),
    },
    {
      label: i18n.t('N/A'),
      value: 'none',
      description: i18n.t(``),
    },
  ],
  compliment: [
    i18n.t('Good thought!'),
    i18n.t('Nice idea!'),
    i18n.t('Wow, food for thought.'),
    i18n.t('Imagine that!'),
    i18n.t('Hmmm, interesting.'),
  ],
  listed: true,
};

export default type;
