import React, { useEffect, useState, useMemo, useRef, useContext } from 'react';

import { logError } from '@/sentry';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  getBrainstorms,
  getBrainstormsNext,
  resetBrainstorms,
  deleteBrainstorm,
} from 'Stores/actions/brainstorm';
import i18n, { getLangPrefix } from '@/i18n';
import Ripple, { useRipple } from 'Components/Elements/Ripple';

import { useConfirm, useSticky, useResponsive, useEdge } from 'Helpers/hooks';

import { DataContext } from 'Contexts';

// Elements
import { Text } from 'Components/Elements/TypeSet';
import { Col, Row, Container } from 'Components/Elements/Grid';
import Card from 'Components/Elements/Card';
import Interlude from 'Components/Compounds/Interlude';

// Icons
import { ReactComponent as AddIcon } from 'Assets/images/icons/add.svg';

import { CTA, MobileCTA } from './Home.styles';
import Articles from './Articles';
import SessionList from './SessionList';

const DEFAULT_FILTER = {
  limit: 12,
  order: 'DESC',
};

export const EXPIRY_CTA = {
  copy: {
    body: i18n.t(
      'Brainstorms are locked, because your subscription has been paused or expired. To create a brainstorm, please choose your plan'
    ),
    button: {
      confirm: i18n.t('Choose a plan'),
      reject: i18n.t('Cancel'),
    },
  },
};

export const REMOVE_CTA = {
  copy: {
    button: {
      confirm: i18n.t('No'),
      reject: i18n.t('Yes'),
    },
  },
};

const Home = () => {
  const { scopedData, setScopedData } = useContext(DataContext);

  const confirm = useConfirm();

  const brainstorms =
    useSelector((state) => state.brainstorm.brainstorms, []) || [];

  const rightRef = useRef();
  const rightRefHeight = useEdge(rightRef, 'bottom', [brainstorms.status]);

  const user = useSelector((state) => state.user) || '';

  const [ripples, addRipple, cleanRipples] = useRipple();

  const [selectedFilter, setSelectedFilter] = useState({
    label: i18n.t('All'),
    value: 'all',
  });

  const screen = useResponsive();
  const isDesktop = screen.size('md');

  const [mobileCtaRef, mobileCtaStyle] = useSticky('top', 68, true);

  const [keyword, setKeyword] = useState('');

  const [mounted, setMounted] = useState(false);

  const [isFetching, setFetching] = useState(false);

  const [pagination, setPagination] = useState(null);

  const dispatch = useDispatch();
  const history = useHistory();

  const fetchBrainstorms = async (
    filterCreteria,
    page = 1,
    mounted = false
  ) => {
    setFetching(true);

    try {
      const res =
        page <= 1
          ? await dispatch(
              getBrainstorms({ ...filterCreteria, page: 1 }, mounted)
            )
          : await dispatch(getBrainstormsNext({ ...filterCreteria, page }));

      setPagination(res.pagination);
    } catch (error) {
      logError(error);
    }

    setFetching(false);
  };

  const langPrefix = useMemo(() => getLangPrefix(i18n), [i18n.language]);

  const handleOnClick = () => {
    if (user.data.isPlanActive) {
      history.push(`${langPrefix}/manage/create`);
      return;
    }

    handleExpiry();
  };

  const handleRemove = (id) => {
    confirm(
      i18n.t('Are you sure you want to Delete the brainstorm?'),
      REMOVE_CTA
    ).then((res) => {
      if (!res) {
        dispatch(deleteBrainstorm(id));
      }
    });
  };

  const handleExpiry = () => {
    confirm(i18n.t('Brainstorms cannot be created'), EXPIRY_CTA).then((res) => {
      if (res) {
        history.push(`${langPrefix}/user`);
      }
    });
  };

  const filterCreteria = useMemo(() => {
    let creteria = { ...DEFAULT_FILTER };

    if (keyword) {
      creteria.search = keyword;
    }

    if (selectedFilter.value && selectedFilter.value !== 'all') {
      creteria.status = selectedFilter.value;
    }

    return creteria;
  }, [selectedFilter, keyword]);

  const handleFetch = () => {
    const nextPage = pagination.next;

    if (pagination && nextPage && nextPage <= pagination.totalPage) {
      fetchBrainstorms(filterCreteria, nextPage, mounted);
    }
  };

  useEffect(() => {
    fetchBrainstorms(filterCreteria, 1, mounted);
  }, [filterCreteria]);

  useEffect(() => {
    if (
      (brainstorms.status === 'loaded' || brainstorms.status === 'updated') &&
      (!filterCreteria.search || filterCreteria.search === '')
    ) {
      if (!!brainstorms.data && brainstorms.data.length > 0) {
        setScopedData({
          isNewUser: false,
        });
      } else {
        setScopedData({
          isNewUser: true,
        });
      }
    }
  }, [brainstorms]);

  useEffect(() => {
    setMounted(true);

    return () => {
      dispatch(resetBrainstorms());
    };
  }, []);

  const emptyBrainstorm = (
    <Card bg="#fff" padding={{ _: '20px', md: '34px 40px' }} maxWidth="1024px">
      <Row mx="0" px="0" mb="32px">
        <Text variant="h2" color="#333333">
          {i18n.t('Here are some tips to get you started')}:
        </Text>
      </Row>
      <Articles />
    </Card>
  );

  const containingBrainstorms = user.data && (
    <SessionList
      selectedFilter={selectedFilter}
      data={brainstorms}
      setSelectedFilter={setSelectedFilter}
      keyword={keyword}
      setKeyword={setKeyword}
      handleFetch={handleFetch}
      isPlanActive={user.data.isPlanActive}
      height={`${(rightRefHeight > 650 ? rightRefHeight : 650) - 80}px`}
      fetching={isFetching}
      handleRemove={handleRemove}
    />
  );

  const leftSection = (
    <Card
      bg="#fff"
      fullWidth
      padding={{ _: '40px 20px', md: '48px 20px 32px' }}
    >
      <Row mx="0" px="0">
        <Text variant="h1" color="#333333" ml="16px" mr="10px">
          {i18n.t('Welcome!')}
        </Text>
      </Row>
      <Row mx="0" px="0">
        {user.data && (
          <Text variant="h1" color="#333333" ml="16px" mr="10px">
            {`${user.data.name} ${user.data.surname}`}
          </Text>
        )}
      </Row>
      <Row mx="0" px="0" mt="38px" mb="56px">
        <Text variant="paragraph" color="#666666" ml="16px" mr="10px">
          {i18n.t(
            'BRAINSTORM by Mediatropy is a creative collaborative tool allowing you and your team to generate great ideas!'
          )}
        </Text>
      </Row>
      <Row mx="0" px="0">
        <CTA
          fullWidth
          bg="#622de3"
          borderRadius="15px"
          onClick={handleOnClick}
          onMouseDown={(event) => addRipple(event)}
          style={{ cursor: 'pointer' }}
          height="192px"
          justifyContent="center"
        >
          <Row mx="0" px="0" justifyContent="center">
            <AddIcon width="30px" height="30px" />
          </Row>
          <Row mx="0" px="0" justifyContent="center">
            <Text variant="h2" color="#fff" mt="14px">
              {i18n.t('Create')}
            </Text>
          </Row>
          <Row mx="0" px="0" justifyContent="center">
            <Text variant="h2" color="#fff">
              {i18n.t('New Brainstorm')}
            </Text>
          </Row>
          <Ripple
            duration={500}
            ripples={ripples}
            cleanRipples={cleanRipples}
          />
        </CTA>
        {!isDesktop && (
          <MobileCTA
            ref={mobileCtaRef}
            style={{ ...mobileCtaStyle, zIndex: 10 }}
            onClick={handleOnClick}
          >
            <AddIcon width="30px" height="30px" />

            <Text variant="font-10" color="#fff" ml="10px">
              {i18n.t('Create New Brainstorm')}
            </Text>
          </MobileCTA>
        )}
      </Row>
    </Card>
  );

  const checkedUser = scopedData.isNewUser !== undefined;

  const hasBrainstorm = !!brainstorms.data && scopedData.isNewUser === false;

  const rightSection = scopedData.isNewUser
    ? emptyBrainstorm
    : containingBrainstorms;

  return (
    <>
      {(!checkedUser ||
        ![
          'loading',
          'loaded',
          'updating',
          'updated',
          'filtering',
          'filtered',
        ].includes(brainstorms.status)) && <Interlude />}
      <Container
        fluid
        px={{ md: '50px' }}
        maxWidth={{ _: hasBrainstorm ? undefined : '100%', lg: '1680px' }}
        overflow={!hasBrainstorm ? 'hidden' : undefined}
      >
        <Row
          mx="0"
          px="0"
          pt={{ md: '80px' }}
          alignItems={
            !hasBrainstorm && !!scopedData.isNewUser ? 'flex-end' : 'flex-start'
          }
        >
          <Col
            span={{ md: 'auto', _: 12 }}
            px={{ _: 0, md: '12px' }}
            pt={{ _: '40px', md: 0 }}
            pb={{ _: 0, md: hasBrainstorm && '40px' }}
            width={{ md: '100%' }}
            maxWidth={{ md: '400px' }}
          >
            {leftSection}
          </Col>
          <Col
            span={{ md: 'auto', _: 12 }}
            height={{
              md: hasBrainstorm ? `${rightRefHeight}px` : 'inherit',
            }}
            minHeight={hasBrainstorm ? '650px' : '0px'}
            mb={{ md: hasBrainstorm ? '-84px' : '0' }}
            px={{ _: 0, md: '12px' }}
            pt={{ _: '40px', md: 0 }}
            pb="0"
            width={{ md: '100%' }}
            maxWidth={{ md: 'calc(100% - 400px)' }}
            justifyContent={!hasBrainstorm && 'flex-end'}
            ref={rightRef}
          >
            {rightSection}
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default Home;
