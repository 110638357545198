import React, {
  useState,
  useRef,
  useCallback,
  useEffect,
  useMemo,
} from 'react';

import i18n, { getLangPrefix } from '@/i18n';

import ProjectCard from 'Components/Compounds/Cards/Project';
import SkeletonProjectCard from 'Components/Compounds/Skeleton/ProjectCard';

import { Text } from 'Components/Elements/TypeSet';
import ScrollView from 'Components/Elements/ScrollView';
import Select from 'Components/Elements/Form/Select';
import Input from 'Components/Elements/Form/Input';
import Card from 'Components/Elements/Card';
import Icon from 'Components/Elements/Icon';
import { Col, Row } from 'Components/Elements/Grid';

import { scrollTo } from 'Helpers/utils';
import { useResponsive } from 'Helpers/hooks';

import { ReactComponent as MagnifierIcon } from 'Assets/images/icons/magnifier.svg';
import { ReactComponent as SettingIcon } from 'Assets/images/icons/setting.svg';
import { ReactComponent as TrashIcon } from 'Assets/images/icons/trash-can.svg';

import {
  DataWrapper,
  OverflowContainer,
  UtilContainer,
  SearchWrapper,
  RemoveWrapper,
  FilterWrapper,
  Filter,
  SkeletonWrapper,
  SessionListTop,
  NoFoundContainter,
  StyledMessageBox,
} from './Home.styles';

const SessionList = (props) => {
  const {
    data: brainstorms,
    selectedFilter,
    setSelectedFilter,
    setKeyword,
    keyword,
    handleFetch,
    height,
    fetching,
    handleRemove,
    isPlanActive,
  } = props;

  const [utility, setUtility] = useState('none');
  const [hasShadow, setShadow] = useState(false);
  const [message, setMessage] = useState(selectedFilter.label);

  const screen = useResponsive();
  const isMD = screen.size('md');
  const isSM = screen.size('sm');
  const isLG = screen.size('lg');

  const viewRef = useRef(null);

  const filterOptions = useMemo(
    () => [
      {
        label: !isLG ? i18n.t('Show All') : i18n.t('All'),
        value: 'all',
      },
      {
        label: i18n.t('Draft'),
        value: 'draft',
      },
      {
        label: i18n.t('Scheduled'),
        value: 'pending',
      },
      {
        label: i18n.t('Completed'),
        value: 'ended',
      },
    ],
    [isLG]
  );

  const handleScroll = useCallback((event) => {
    const { target } = event;

    const currentY = target.scrollTop;
    const maxY = target.scrollHeight - target.clientHeight;

    const hasScroll = maxY > 0;

    if (hasScroll) {
      if (currentY === 0) {
        setShadow(false);
      } else {
        setShadow(true);
      }
    }
  }, []);

  const handleKeywordChange = (event) => {
    let { value } = event.target;
    value = value.trim();
    setKeyword(value);
  };

  useEffect(() => {
    viewRef.current.addEventListener('scroll', handleScroll, { passive: true });

    return () => viewRef.current.removeEventListener('scroll', handleScroll);
  }, [viewRef.current]);

  const handleFilter = (selected) => {
    setMessage(selected.label);
    setSelectedFilter(selected);
  };

  const handleMobileFilter = (event) => {
    const {
      target: { value },
    } = event;

    const selected = filterOptions.find((option) => option.value === value);
    setMessage(selected.label);
    setSelectedFilter(selected);
  };

  const handleToggleSearch = () => {
    setUtility((prevState) =>
      prevState !== 'searching' ? 'searching' : 'none'
    );
  };

  const handleSearchBlur = () => {
    if (!keyword || keyword.trim() === '') {
      setUtility('none');
    }
  };

  const handleToggleRemover = () => {
    setUtility((prevState) => (prevState !== 'removing' ? 'removing' : 'none'));
  };

  const handlePullUp = useCallback(() => {
    handleFetch();
  }, [handleFetch]);

  useEffect(() => {
    if (brainstorms.status === 'loaded') {
      scrollTo(viewRef.current, { vertical: 'top' });
    }
  }, [brainstorms.status]);

  useEffect(() => {
    if (keyword !== '') {
      setMessage(keyword);
    }
  }, [keyword]);

  // For mobile
  const handleMobileFetch = useCallback(
    (event) => {
      if (isLG) return;

      const hasScroll = document.body.offsetHeight > window.innerHeight;

      if (hasScroll) {
        // Scroll position

        if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
          if (!fetching) handlePullUp();
        }
      }
    },
    [fetching]
  );

  useEffect(() => {
    window.addEventListener('scroll', handleMobileFetch, { passive: true });

    return () => window.removeEventListener('scroll', handleMobileFetch);
  }, [handleMobileFetch]);

  // Render
  const searcher = (
    <Row mx="0">
      <Col
        span="auto"
        maxWidth={utility === 'searching' ? '35px' : '100%'}
        p="0"
      >
        <Icon width="30px" height="30px" onClick={handleToggleSearch}>
          <MagnifierIcon width="16px" height="16px" />
        </Icon>
      </Col>
      <Col
        span="auto"
        maxWidth={utility === 'searching' ? 'calc(100% - 35px)' : '0'}
        p="0"
        v-align="center"
      >
        <Input
          transparent
          onChange={handleKeywordChange}
          onBlur={handleSearchBlur}
          value={keyword}
        />
      </Col>
    </Row>
  );

  const remover = (
    <Icon width="30px" height="30px" onClick={handleToggleRemover}>
      <TrashIcon width="12px" height="18px" />
    </Icon>
  );

  const filter = (
    <Row mx="0">
      <Col p="0">
        {/* {isLG ? ( */}
        <Filter>
          {isLG && (
            <Text variant="paragraph" color="#666666" mr="12px">
              {i18n.t('Show')}
            </Text>
          )}
          <Select
            width="100%"
            onChange={handleFilter}
            options={filterOptions}
            defaultValue={filterOptions[0]}
          />
        </Filter>
        {/* ) : (
          <>
            <SettingIcon />
            <select
              onChange={handleMobileFilter}
              defaultValue={filterOptions[0]}
            >
              {filterOptions.map((option, index) => (
                <option key={index} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </>
        )} */}
      </Col>
    </Row>
  );

  const langPrefix = useMemo(() => getLangPrefix(i18n), [i18n.language]);

  const brainstormCards =
    brainstorms.status === 'loaded' &&
    brainstorms.data.map((brainstorm, index) => (
      <Col key={index} p="0" span={{ _: 12, lg: 6, xxl: 4 }}>
        <ProjectCard
          key={brainstorm.ID}
          data={brainstorm}
          to={`${langPrefix}/session/${brainstorm.ID}`}
          clickable={brainstorm.status !== 'draft'}
          isPlanActive={isPlanActive}
          removing={utility === 'removing'}
          removeHandler={() => handleRemove(brainstorm.ID)}
        />
      </Col>
    ));

  const notFound = (
    <NoFoundContainter>
      <StyledMessageBox>
        <Text variant="paragraph" color="#7945fa">
          {i18n.t(`Your search for "{{message}}" did not match any results.`, {
            message,
          })}
        </Text>
      </StyledMessageBox>
    </NoFoundContainter>
  );

  return (
    <Card
      borderRadius={{ _: '30px', md: '30px 30px 0 0' }}
      p="0"
      width="100%"
      height="100%"
    >
      <SessionListTop
        fullWidth
        padding={{ md: '25px 20px 0' }}
        boxShadow={hasShadow ? '3px 3px 10px 0 rgba(0, 0, 0, 0.16)' : ''}
        style={{ zIndex: '1', overflow: 'visible' }}
      >
        <Row px="10px" mx="0" justifyContent="space-between">
          <Col span={{ _: 12 }} alignItems="center" px="0">
            <Row px="0">
              <Col
                span="12"
                p={{ _: '0 0 10px', lg: 0 }}
                justifyContent="center"
              >
                <OverflowContainer>
                  <Text variant="h2" color="#333333">
                    {i18n.t('Your brainstorms')}
                  </Text>
                </OverflowContainer>

                <UtilContainer
                  width={
                    utility === 'searching'
                      ? isSM
                        ? isLG
                          ? 'calc(100% - 240px)'
                          : '100%'
                        : '100%'
                      : undefined
                  }
                >
                  <SearchWrapper activated={utility === 'searching'}>
                    {searcher}
                  </SearchWrapper>
                  {isLG && (
                    <>
                      <RemoveWrapper activated={utility === 'removing'}>
                        {remover}
                      </RemoveWrapper>
                      <FilterWrapper>{filter}</FilterWrapper>
                    </>
                  )}
                </UtilContainer>
              </Col>
            </Row>
            {!isLG && (
              <Row px="0">
                <Col span="auto" p="0" maxWidth="110px">
                  <RemoveWrapper
                    activated={utility === 'removing'}
                    onClick={handleToggleRemover}
                  >
                    {remover}

                    <Text
                      variant="paragraph"
                      color={utility === 'removing' ? '#622DE3' : '#666666'}
                      mr="12px"
                      mt={{ _: '1px' }}
                    >
                      {i18n.t('Delete')}
                    </Text>
                  </RemoveWrapper>
                </Col>
                <Col span="auto" p="0" maxWidth="calc(100% - 110px)">
                  <FilterWrapper>{filter}</FilterWrapper>
                </Col>
              </Row>
            )}
          </Col>
        </Row>
      </SessionListTop>
      <Card
        fullWidth
        padding={{ _: '10px 0', md: '0 20px' }}
        maxHeight="inherit"
        flexGrow="1"
      >
        <Row
          px="10px"
          mx="0"
          mb={!isLG && '20px'}
          justifyContent="space-between"
        >
          <Col span={{ _: 12 }} alignItems="center" p="0">
            <ScrollView
              ref={viewRef}
              height={height}
              onPullUp={handlePullUp}
              fetching={fetching}
              disabled={!isLG}
            >
              <DataWrapper>
                <Row mx="0">
                  {['loading', 'filtering'].includes(brainstorms.status) &&
                    Array(12)
                      .fill()
                      .map((_, index) => (
                        <Col key={index} p="0" span={{ _: 12, lg: 6, xxl: 4 }}>
                          <SkeletonWrapper
                            transpanrent
                            rounded={false}
                            m="14px 10px"
                            p={{ md: 0 }}
                          >
                            <SkeletonProjectCard />
                          </SkeletonWrapper>
                        </Col>
                      ))}

                  {['loaded', 'filtered', 'updating', 'updated'].includes(
                    brainstorms.status
                  ) &&
                    !!brainstorms.data &&
                    brainstormCards}

                  {['loaded', 'updated', 'filtered'].includes(
                    brainstorms.status
                  ) &&
                    (!brainstorms.data || brainstorms.data.length === 0) &&
                    notFound}
                </Row>
              </DataWrapper>
            </ScrollView>
          </Col>
        </Row>
      </Card>
    </Card>
  );
};

export default SessionList;
