import isFilled from 'Helpers/validations/isFilled';
import isFilledEither from 'Helpers/validations/isFilledEither';

const validator = (rules, value, cb) => {
  if (
    !(rules.includes(isFilled) || rules.includes(isFilledEither)) &&
    value === ''
  ) {
    cb(null);
    return;
  }

  const failure = rules.find((rule) =>
    typeof rule === 'object'
      ? rule.name(value) === false
      : rule(value) === false
  );

  if (failure) {
    if (typeof cb === 'function') {
      cb(failure);
    }
  } else if (typeof cb === 'function') cb(null);
};

export default validator;
