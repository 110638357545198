import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';

dayjs.extend(advancedFormat);

const getFormatedTime = (t, output) => {
  if (!t) return '';

  if (output === 'time') {
    return dayjs(t).format('h:mma');
  }
  if (output === 'date') {
    return dayjs(t).format('Do MMMM YYYY');
  }
  if (output === 'ISO') {
    return dayjs(t).toISOString();
  }
  if (output === 'ISO8601') {
    return `${dayjs(t).format().slice(0, -3)}00`;
  }
  return dayjs(t).format('DD/MM/YYYY h:mma');
};

export default getFormatedTime;
