import axiosInstance from 'Helpers/api/_instance';

import { objectToForm } from 'Helpers/data-transform';

export const validateParticipantToken = (brainstormId, key) =>
  axiosInstance({
    method: 'post',
    url: `brainstorm/${brainstormId}/validate/${key}`,
    headers: { 'Content-Type': 'application/json' },
  });

export const fetchParticipantToken = (brainstormId, key) =>
  axiosInstance({
    method: 'post',
    url: `brainstorm/${brainstormId}/accept/${key}`,
    headers: { 'Content-Type': 'application/json' },
  });

export const setParticipantProfle = (brainstormId, user, token) =>
  axiosInstance({
    method: 'post',
    url: `participant/brainstorm/${brainstormId}/set-profile`,
    data: user,
    headers: { 'Content-Type': 'application/json', Authorization: token },
  });

export const fetchSessionTicket = (brainstormId, token) =>
  axiosInstance({
    method: 'get',
    url: `participant/brainstorm/${brainstormId}/ticket`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  });

export const fetchSessionAudioTicket = (brainstormId, token) =>
  axiosInstance({
    method: 'get',
    url: `participant/brainstorm/${brainstormId}/ticket`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
    params: {
      audio: true,
    },
  });

export const playSession = (brainstormId, token) =>
  axiosInstance({
    method: 'post',
    url: `brainstorm/${brainstormId}/play`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  });

export const pauseSession = (brainstormId, token) =>
  axiosInstance({
    method: 'post',
    url: `brainstorm/${brainstormId}/pause`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  });

export const addTimeSession = (brainstormId, token) =>
  axiosInstance({
    method: 'post',
    url: `brainstorm/${brainstormId}/add-time`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  });

export const nextSession = (brainstormId, token) =>
  axiosInstance({
    method: 'post',
    url: `brainstorm/${brainstormId}/next`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  });

export const fetchChatMessages = (brainstormId, criteria, token) =>
  axiosInstance({
    method: 'get',
    url: `participant/brainstorm/${brainstormId}/message`,
    params: criteria,
    headers: { 'Content-Type': 'application/json', Authorization: token },
  });

export const submitChatMessage = (brainstormId, type, data, token) => {
  let content;
  let endpoint;
  switch (type) {
    case 'image':
      endpoint = 'image';
      content = {
        image: data,
      };
      break;
    case 'asset':
      endpoint = 'asset';
      content = {
        asset: data,
      };
      break;
    default:
      endpoint = null;
      content = {
        content: data,
      };
  }

  const payload = objectToForm(content);

  return axiosInstance({
    method: 'post',
    url: !endpoint
      ? `participant/brainstorm/${brainstormId}/message`
      : `participant/brainstorm/${brainstormId}/message/${endpoint}`,
    data: payload,
    headers: { 'Content-Type': 'application/json', Authorization: token },
  });
};

export const muteParicipant = (brainstormId, participantId, token) =>
  axiosInstance({
    method: 'post',
    url: `brainstorm/${brainstormId}/participant/${participantId}/mute`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  });

export const muteAllParicipants = (brainstormId, token) =>
  axiosInstance({
    method: 'post',
    url: `brainstorm/${brainstormId}/mute-all`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  });

export const muteSelf = (brainstormId, toggle, token) =>
  axiosInstance({
    method: 'post',
    url: `participant/brainstorm/${brainstormId}/mute/${toggle}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  });
