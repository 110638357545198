import React, { forwardRef, useRef, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

import PropTypes from 'prop-types';

import { OverlayWrapper } from './Overlay.styles';

const HEADER_ID = '#main-header';

const Overlay = forwardRef((props, ref) => {
  const { fullHeight, outside = false, ...rest } = props;

  const isMountedRef = useRef(false);
  const overlayRef = useRef(document.createElement('div'));
  const containerRef = useRef(document.getElementById('overlay-container'));

  const [spare, setSpare] = useState(0);

  // handlers
  const handleHeaderDetect = () => {
    if (fullHeight || !isMountedRef.current) return;

    const header = document.querySelector(HEADER_ID);

    const headerHeight = header
      ? parseInt(window.getComputedStyle(header).height, 10)
      : 0;

    if (spare !== headerHeight) setSpare(headerHeight);
  };

  useEffect(() => {
    isMountedRef.current = true;

    handleHeaderDetect();

    setTimeout(handleHeaderDetect);

    window.addEventListener('resize', handleHeaderDetect);

    if (containerRef.current) containerRef.current.prepend(overlayRef.current);

    return () => {
      window.removeEventListener('resize', handleHeaderDetect);

      isMountedRef.current = false;

      if (containerRef.current)
        containerRef.current.removeChild(overlayRef.current);
    };
  }, []);

  const overlayInstance = <OverlayWrapper ref={ref} spare={spare} {...rest} />;

  return !outside
    ? overlayInstance
    : createPortal(overlayInstance, overlayRef.current);
});

Overlay.propTypes = {
  position: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  fullHeight: PropTypes.bool,
  transparent: PropTypes.bool,
};

Overlay.displayName = 'Overlay';

export default Overlay;
