const isFilled = (value) => {
  if (value === undefined) {
    return false;
  }

  if (typeof value === 'string') {
    return value.trim() !== '';
  }
  if (typeof value === 'object') {
    if (Array.isArray(value)) {
      return value.length > 0;
    }
    return !!value;
  }
  return !!value;
};

export default isFilled;
