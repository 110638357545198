import styled from 'styled-components';

import { device } from 'Helpers/styling';

export const UserContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const UserHeader = styled.div`
  border-bottom: 2px solid #b7b7b7;
  padding: 35px 35px;
  ${device.md`
padding: 35px 60px;
`}
  display: flex;
  align-content: center;
`;

export const UserContent = styled.div`
  flex: 1 1 auto;
  overflow: hidden;
  padding: 10px;
`;

export const UserUserContainer = styled.div`
  padding: 14px 10px;
  ${device.md`
padding: 14px 32px;
`}
`;

export const UserFooter = styled.div`
  background-color: #b7b7b7;
  padding: 25px 0;
  display: flex;
  justify-content: center;
`;

export const ModUtilWrapper = styled.div`
  display: inline-flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  ${device.md`
      justify-content: flex-end;
  `}
`;
