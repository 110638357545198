import { useRef } from 'react';

import { isObjectEqual } from 'Helpers/utils';

const useDeepMemo = (factory, dep) => {
  const ref = useRef();

  if (!ref.current || !isObjectEqual(dep, ref.current.dep)) {
    ref.current = { dep, value: factory() };
  }

  return ref.current.value;
};

export default useDeepMemo;
