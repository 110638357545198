import * as types from 'Stores/types';

export function auth(state = {}, action) {
  switch (action.type) {
    case types.SET_TOKEN:
    case types.SET_AUTHENTICATE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
