import React, { useEffect } from 'react';

import { logError } from '@/sentry';

import { toast } from 'react-toastify';

import ToastConfirm from 'Components/Elements/Toast/Confirm';

const isDev = process.env.TARGET_ENV === 'development';

const withServiceWorker = (Component) => {
  const WrappedComponent = (props) => {
    useEffect(() => {
      if (!isDev && 'serviceWorker' in navigator) {
        window.addEventListener('load', () => {
          navigator.serviceWorker
            .register('/service-worker.js')
            .then((registration) => {
              let isAppOnline = navigator.onLine;

              window.addEventListener('online', () => {
                if (!isAppOnline) {
                  toast('The connectivity is back');
                  isAppOnline = true;
                }
              });

              window.addEventListener('offline', () => {
                toast.warn('The app is running offline');
                isAppOnline = false;
              });

              registration.onupdatefound = () => {
                const installingWorker = registration.installing;
                installingWorker.onstatechange = () => {
                  if (installingWorker.state === 'installed') {
                    if (navigator.serviceWorker.controller) {
                      toast.info(
                        <ToastConfirm
                          copy={{
                            body: `New update available!`,
                            confirm: 'Refresh',
                            reject: 'Cancel',
                          }}
                          handlers={{
                            confirm: () => {
                              window.location.reload();
                            },
                            reject: () => {
                              toast.dismiss();
                            },
                          }}
                        />,
                        {
                          autoClose: false,
                        }
                      );
                    }
                  }
                };
              };
            })
            .catch(logError);
        });
      } else if ('serviceWorker' in navigator) {
        navigator.serviceWorker.getRegistrations().then((registrations) => {
          for (let registration of registrations) {
            if (
              registration.active.scriptURL ===
              `${window.location.origin}/service-worker.js`
            ) {
              registration.unregister();
            }
          }
        });
      }
    }, []);

    return <Component {...props} />;
  };

  WrappedComponent.displayName = `ComponentWithServiceWorker`;

  return WrappedComponent;
};

export default withServiceWorker;
