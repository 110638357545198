import React from 'react';
import fontStyles from 'Mixins/styles/typography';

import styled from 'styled-components';
import {
  compose,
  variant,
  space,
  color,
  border,
  typography,
  layout,
  system,
} from 'styled-system';
import colorStyles from 'Mixins/styles/color';

const Component = styled.span`
  ${fontStyles.text};
  ${variant({
    prop: 'tone',
    variants: colorStyles,
  })};
  ${variant({
    variants: fontStyles,
  })};
  ${compose(space, layout, color, border, typography)};
  ${system({
    textTransform: true,
    textDecoration: true,
    textOverflow: true,
    cursor: true,
    whiteSpace: true,
  })};
`;

const Text = (props) => {
  return <Component {...props} />;
};

export default Text;
