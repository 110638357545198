// Ref: https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollIntoView

import smoothscroll from 'smoothscroll-polyfill';

smoothscroll.polyfill();

const defaultOptions = {
  type: 'default',
  horizontal: 'left',
  vertical: 'top',
  behavior: 'smooth',
};

const scrollTo = (element, userOptions = {}) => {
  const options = { ...defaultOptions, ...userOptions };

  if (!element) return;

  const scroller = (scrollOptions) => {
    switch (options.type) {
      case 'into':
        return element.scrollIntoView(scrollOptions);
      default:
        return element.scroll(scrollOptions);
    }
  };

  const parseVertical = (verticalOption) => {
    switch (verticalOption) {
      case 'bottom':
        switch (options.type) {
          case 'into':
            return 'end';
          default:
            return element.scrollHeight;
        }
      case 'center':
        switch (options.type) {
          case 'into':
            return 'center';
          default:
            return element.scrollHeight / 2;
        }
      case 'top':
      default:
        switch (options.type) {
          case 'into':
            return 'start';
          default:
            return 0;
        }
    }
  };

  const parseHorizonal = (horiziontalOption) => {
    switch (horiziontalOption) {
      case 'right':
        switch (options.type) {
          case 'into':
            return 'end';
          default:
            return element.scrollWidth;
        }
      case 'center':
        switch (options.type) {
          case 'into':
            return 'center';
          default:
            return element.scrollWidth / 2;
        }
      case 'left':
      default:
        switch (options.type) {
          case 'into':
            return 'start';
          default:
            return 0;
        }
    }
  };

  const parseOption = (options) => {
    switch (options.type) {
      case 'into':
        return {
          block: parseVertical(options.vertical),
          inline: parseHorizonal(options.horizontal),
          behavior: options.behavior,
        };
      default:
        return {
          top: parseVertical(options.vertical),
          left: parseHorizonal(options.horizontal),
          behavior: options.behavior,
        };
    }
  };

  return scroller(parseOption(options));
};

export default scrollTo;
