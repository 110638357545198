import React, { useEffect, useState, useContext, useRef } from 'react';
import { createPortal } from 'react-dom';

import { ThemeContext } from 'Contexts';

import { ReactComponent as SimpleLogo } from 'Assets/images/brainstorm-logo.svg';
import { InterludeWrapper } from './Interlude.styles';

const Interlude = ({ outside = true }) => {
  const { setTheme } = useContext(ThemeContext);

  const mountRef = useRef(document.getElementById('interlude'));
  const elRef = useRef(document.createElement('div'));

  useEffect(() => {
    if (outside) mountRef.current.appendChild(elRef.current);
    return () => {
      if (outside) mountRef.current.removeChild(elRef.current);
    };
  }, []);

  useEffect(() => {
    let prevFrame;
    setTheme((prevState) => {
      prevFrame = prevState.frame;
      return { ...prevState, frame: 'none' };
    });
    return () => setTheme((prevState) => ({ ...prevState, frame: prevFrame }));
  }, []);

  const interludeInstance = (
    <InterludeWrapper>
      <SimpleLogo width="80px" height="80px" />
    </InterludeWrapper>
  );

  return !outside
    ? interludeInstance
    : createPortal(interludeInstance, elRef.current);
};

export default Interlude;
