import React, { useRef, useState, useEffect, useCallback } from 'react';

import Lottie from 'Components/Elements/Lottie';
import Card from 'Components/Elements/Card';

import sessionWait from 'Assets/animation/session-wait.json';
import { ViewWrapper, ViewContainer, FadeOverlay } from './ScrollView.styles';

const ScrollView = React.forwardRef((props, ref) => {
  const {
    children,
    fadeColor = 'transparent',
    onPullDown,
    onPullUp,
    fetching = false,
    disabled = false,
    reverse = false,
    height = 'auto',
    initialPosition = null,
    ...rest
  } = props;

  const viewRef = useRef(null);

  const [position, setPosition] = useState(initialPosition);

  useEffect(() => {
    const currentY = !reverse
      ? viewRef.current.scrollTop
      : viewRef.current.scrollTop * -1;

    const maxY = viewRef.current.scrollHeight - viewRef.current.clientHeight;
    const hasScroll = maxY > 0;

    if (hasScroll) {
      // Scroll position
      if (!reverse ? currentY <= 0 : currentY === maxY) {
        setPosition('top');
      } else if (!reverse ? currentY < maxY : maxY > currentY && currentY > 0) {
        setPosition('middle');
      } else {
        setPosition('bottom');
      }
    }
  }, []);

  const handleScroll = useCallback(
    (event) => {
      if (disabled) return;

      const { target } = event;

      const currentY = !reverse ? target.scrollTop : target.scrollTop * -1;
      const maxY = target.scrollHeight - target.clientHeight;

      const hasScroll = maxY > 0;

      if (hasScroll) {
        // Scroll position
        if (!reverse ? currentY <= 0 : currentY === maxY) {
          setPosition('top');
          if (!!onPullDown && !fetching) onPullDown();
        } else if (
          !reverse ? currentY < maxY : maxY > currentY && currentY > 0
        ) {
          setPosition('middle');
        } else {
          setPosition('bottom');
          if (!!onPullUp && !fetching) onPullUp();
        }
      }
    },
    [disabled, setPosition, onPullDown, onPullUp, fetching]
  );

  useEffect(() => {
    viewRef.current.addEventListener('scroll', handleScroll, { passive: true });

    return () => viewRef.current.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  useEffect(() => {
    if (ref) ref.current = viewRef.current;
  }, [viewRef.current]);

  if (disabled) {
    return (
      <ViewContainer ref={viewRef} disabled={disabled} {...rest}>
        {children}
        {fetching && (
          <Card
            transparent
            rouned={false}
            mt={{ _: '15px', md: '20px' }}
            mb={{ _: '0', md: '20px' }}
            width="100%"
          >
            <Lottie
              data={sessionWait}
              width={100}
              height={40}
              isPaused={false}
            />
          </Card>
        )}
      </ViewContainer>
    );
  }

  return (
    <ViewWrapper maxHeight={height}>
      <FadeOverlay
        color={fadeColor}
        location="top"
        show={!!position && position !== 'top'}
      />
      <ViewContainer ref={viewRef} reverse={reverse} {...rest}>
        {children}

        {fetching && (
          <Card transparent rouned={false} my="20px" width="100%">
            <Lottie
              data={sessionWait}
              width={100}
              height={40}
              isPaused={false}
            />
          </Card>
        )}
      </ViewContainer>
      <FadeOverlay
        color={fadeColor}
        location="bottom"
        show={!!position && position !== 'bottom'}
      />
    </ViewWrapper>
  );
});

ScrollView.displayName = 'ScrollView';

export default ScrollView;
