import i18n from '@/i18n';

export const EXPIRED_FACILITATOR_CTA = {
  copy: {
    body: i18n.t(
      'Brainstorms are locked, because your subscription has been paused or expired. To create a brainstorm, please choose your plan'
    ),
    button: {
      confirm: i18n.t('Choose a plan'),
      reject: i18n.t('Cancel'),
    },
  },
};
