import i18n from '@/i18n';

const articles = [
  {
    source: i18n.t('From the Blog'),
    title: i18n.t('How to Build Engaging Brainstorming Sessions'),
    link: `${process.env.PROMOTE_URI}/how-to-build-engaging-brainstorming-sessions/`,
  },
  {
    source: i18n.t('From the Blog'),
    title: i18n.t('10 Methodologies for Unboxing Your Creativity'),
    link: `${process.env.PROMOTE_URI}/10-methodologies-for-unboxing-your-creativity/`,
  },
  {
    source: i18n.t('From the How It Works page'),
    title: i18n.t('How To Use BRAINSTORM To Generate Ideas'),
    link: `${process.env.PROMOTE_URI}/how-its-works/`,
  },
];

export default articles;
