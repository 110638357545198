import React from 'react';
import i18n from '@/i18n';
import Slider from 'Components/Elements/Slider';
import Card from 'Components/Elements/Card';
import { Text } from 'Components/Elements/TypeSet';
import { Row } from 'Components/Elements/Grid';

import articles from 'Abstracts/articles';

const sliderSettings = {
  slidesToScroll: 1,
  arrow: false,
  slidesToShow: 1,
  dots: false,
};

export default function Articles() {
  return (
    <Slider {...sliderSettings}>
      {articles.length > 0 &&
        articles.map((article, index) => (
          <Card
            key={index}
            bg="#ffbf00"
            border="solid 1px #622de3"
            borderRadius="20px"
            minWidth="272px"
            maxWidth="300px"
            height="246px"
            padding={{ _: '20px 25px', md: '24px 20px' }}
            marginRight="20px"
          >
            <Row mx="0" px="0">
              <Text color="#999999" variant="tipsNotes">
                {article.source}
              </Text>
            </Row>
            <Row mx="0" px="0" mt="18px" mb="16px" height="60%">
              <Text color="#333333" variant="cardTitle-6" fontWeight="normal">
                {article.title}
              </Text>
            </Row>
            <Row mx="0" px="0">
              <a
                href={article.link}
                target="_blank"
                style={{ textDecoration: 'none' }}
              >
                <Text
                  color="#0083c6"
                  variant="paragraph"
                  fontWeight="500"
                  fontStyle="italic"
                >
                  {i18n.t('Read More')}
                </Text>
              </a>
            </Row>
          </Card>
        ))}
    </Slider>
  );
}
