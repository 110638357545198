import styled, { css } from 'styled-components';

import fontStyles from 'Mixins/styles/typography';
import { colors } from 'Abstracts/variables';

export const TooltipWrapper = styled.div`
  display: inline-block;
`;

export const TooltipText = styled.div`
  /* min-width: 120px; */
  max-width: 350px;
  /* width: 350px; */
  background-color: ${colors.light};
  color: ${colors.grey2};
  ${fontStyles.tooltip}
  text-align: center;
  border-radius: 7.5px;
  /* overflow: hidden; */
  border: solid 1px ${colors.grey1};
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  padding: 8px 12.5px 8px 11px;
  position: relative;
  opacity: 0;
  ${(props) =>
    props.show &&
    css`
      opacity: 1;
    `}
`;

export const TooltipArrow = styled.div`
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: 0;
  border-width: 5px;
  border-style: solid;
  border-color: ${colors.light} transparent transparent transparent;
  z-index: 2;
  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    border-width: 4px;
    border-style: solid;
    border-color: ${colors.light} transparent transparent transparent;
  }
  ${(props) =>
    props.placement &&
    props.placement.indexOf('top') !== -1 &&
    css`
      top: auto;
      bottom: 0;
      margin-bottom: -10px;

      border-color: ${colors.grey1} transparent transparent transparent;
      &:before {
        margin-top: -5px;
        margin-left: -4px;
        border-color: ${colors.light} transparent transparent transparent;
      }
    `};
  ${(props) =>
    props.placement &&
    props.placement.indexOf('bottom') !== -1 &&
    css`
      top: 0%;
      margin-top: -10px;
      border-color: transparent transparent ${colors.grey1} transparent;
      &:before {
        margin-top: -3px;
        margin-left: -4px;
        border-color: transparent transparent ${colors.light} transparent;
      }
    `};
  ${(props) =>
    props.placement &&
    props.placement === 'bottom-start' &&
    css`
      margin-left: 0;
    `};
  ${(props) =>
    props.placement &&
    props.placement === 'bottom-end' &&
    css`
      left: 100% !important;
      transform: translateX(-100%) !important;
      margin-left: -15px;
    `}
`;
