import styled, { css } from 'styled-components';

import { device } from 'Helpers/styling';

import { space, padding } from 'styled-system';

import { Container } from 'Components/Elements/Grid';

import { scrollBar } from 'Mixins/styles';

import { colors } from 'Abstracts/variables';

import { ReactComponent as CloseIcon } from 'Assets/images/close.svg';

export const DrawerContainer = styled(Container)`
  padding: 0;
  margin: 0;
  position: relative;
  background: #f5f5f5;
  display: flex;
  flex-direction: column;

  ${(props) =>
    !!props.maxWidth &&
    css`
      max-width: ${props.maxWidth};
    `}

  height: 100%;
  ${(props) =>
    !!props.rounded &&
    css`
      border-radius: 50px 0 0 50px;
      background: #fff;
    `}

  ${device.md`
    box-shadow: -5px 0 10px 0 rgba(0, 0, 0, 0.16);  
  `}
`;

export const DrawerHeader = styled.div`
  box-sizing: border-box;
  z-index: 2;
  width: 100%;
  background: #fff;
  display: flex;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  flex: none;
  flex-direction: column;
  padding-top: 30px;
  padding-bottom: 25px;
  padding-left: 35px;
  padding-right: 35px;
  ${device.md`
      padding-top: 40px;
      padding-bottom: 40px;
      padding-left: 60px;
      padding-right: 80px;
    `};

  ${padding}
`;

export const TitleIconWrapper = styled.div`
  position: relative;
  width: 100%;
  text-align: left;
`;

export const DrawerContent = styled.div`
  box-sizing: border-box;
  overflow-y: auto;
  width: 100%;
  display: flex;
  flex: 1;
  padding-left: ${(props) => (props.paddingLeft ? props.paddingLeft : '35px')};
  padding-right: ${(props) =>
    props.paddingRight ? props.paddingRight : '35px'};
  padding-top: ${(props) => (props.closable ? '45px' : '15px')};
  padding-bottom: 37px;
  display: flex;
  align-items: flex-start;
  width: 100%;
  margin: 0;
  ${space};
  ${(props) =>
    !!props.noPadding &&
    css`
      padding: 0;
    `}

  ${(props) =>
    props.moduleEditor &&
    `
        ${device.md`
        padding-left: 40px;
        padding-right: 40px;
        `}
      `}

  ${scrollBar};
  &::-webkit-scrollbar-track {
      margin: 0;
  } 
`;

export const DrawerCloseButton = styled(CloseIcon)`
  position: absolute;
  right: 40px;
  top: 40px;
  width: 30px;
  height: 30px;
  &:hover {
    cursor: pointer;
  }
  &:focus {
    outline: none;
  }
`;
