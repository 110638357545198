import * as types from 'Stores/types';

export function payment(state = {}, action) {
  switch (action.type) {
    case types.GET_CARD_SUCCESS:
      return { ...state, card: { ...state.card, ...action.payload } };
    case types.GET_CUST_SUCCESS:
      return {
        ...state,
        customer: { ...state.customer, ...action.payload },
      };
    case types.GET_INVOICE_SUCCESS:
      return { ...state, invoice: { ...state.invoice, ...action.payload } };
    default:
      return state;
  }
}
