import i18n from '@/i18n';

const manageBrainstormTips = [
  {
    name: 'create-brief',
    label: i18n.t('Create Brief'),
    tips: [
      {
        name: 'tip-1',
        label: i18n.t('Set the Stage'),
        content: i18n.t(
          'Your brief is where you clearly identify your brainstorm’s goal, lay out the context, and define the parameters for your session. Use the examples and drop down menus provided to guide you on your way.'
        ),
      },
      {
        name: 'tip-2',
        label: i18n.t('Define Success'),
        content: i18n.t(
          `Tell your participants exactly what you're after. Indicate the deliverables you're looking for and share the project's relevant KPIs to fuel and direct your participants' ideation.`
        ),
      },
      {
        name: 'tip-3',
        label: i18n.t('Save Your Work'),
        content: i18n.t(
          'Note the save button at the top right of your screen. Use it at any point if you need to exit the creation process before completion to avoid losing any of your work.'
        ),
      },
    ],
  },
  {
    name: 'build-brainstorm',
    label: i18n.t('Build Brainstorm'),
    tips: [
      {
        name: 'tip-1',
        label: i18n.t('Structure the Session'),
        content: i18n.t(
          'Each of our customizable, purpose-built modules provides a grab bag of engaging questions and functions designed to get the ideas flowing. Use them as building blocks for structuring your session.'
        ),
      },
      {
        name: 'tip-2',
        label: i18n.t('Set your Questions'),
        content: i18n.t(
          'Every module has a menu of methodologies with fill-in-the-blank question or a preplaced sample prompt. Use them to help you side-step mental blocks and set your questions and prompts quickly.'
        ),
      },
    ],
  },
  {
    name: 'review-brainstorm',
    label: i18n.t('Review Brainstorm'),
    tips: [
      {
        name: 'tip-1',
        label: i18n.t('Check your Work'),
        content: i18n.t(
          'Did you miss anything? This is your chance to add or change details in your brief or questions before inviting your guests.'
        ),
      },
      {
        name: 'tip-2',
        label: i18n.t('Adjust your Flow'),
        content: i18n.t(
          'Make sure your brainstorm’s question order, length and module flow work for you. You can drag and drop your modules to re-order them by clicking the edit function above your summary.'
        ),
      },
      {
        name: 'tip-3',
        label: i18n.t('Make Changes'),
        content: i18n.t(
          'To make changes in your brief or modules, simply click the notepad and pencil icon above each summary box.'
        ),
      },
    ],
  },
  {
    name: 'invite-paritipcants',
    label: i18n.t('Invite Participants'),
    tips: [
      {
        name: 'tip-1',
        label: i18n.t('Add Supplementary Info'),
        content: i18n.t(
          'Have a supporting document or url link that could help your invitees prepare for the brainstorm? Attach it in Step 4 using the "Additional Info" box in the bottom left of the  page.'
        ),
      },
      {
        name: 'tip-2',
        label: i18n.t('Make it Personal'),
        content: i18n.t(
          `Add a short message for your guests to personalize your invite. This will be sent out along with your name and the brainstorm's title, time, date and a link to your brief.`
        ),
      },
    ],
  },
];

export default manageBrainstormTips;
