import React, { useRef, useEffect } from 'react';

import { Transition } from 'react-transition-group';

import Overlay from 'Components/Elements/Overlay/WithTransition';

import { v4 as uuidv4 } from 'uuid';

import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

import { ModalContainer, ModalContent, ModalCloseButton } from './Modal.styles';

const DURATION = 200;
const MODAL_DURATION = 250;
const MODAL_DURATION_OUT = 150;

const modalDefaultStyle = {
  transition: `opacity ${MODAL_DURATION}ms ease-in-out ${DURATION}ms`,
  opacity: 0,
  zIndex: -1,
};

const modalTransitionStyles = {
  entering: { opacity: 0, zIndex: 0 },
  entered: { opacity: 1, zIndex: 0 },
  exiting: {
    transition: `opacity ${MODAL_DURATION_OUT}ms ease-in-out ${DURATION}ms`,
    opacity: 0,
    zIndex: 0,
  },
  exited: {
    transition: 'none',
    opacity: 0,
    zIndex: -1,
  },
};

const addToModals = (id) => {
  window.modals = window.modals ? [...window.modals, id] : [id];
};

const removeFromModals = (id) => {
  if (!!window.modals && window.modals.length > 0) {
    window.modals = window.modals.filter((item) => item !== id);
  }
};

const isCurrentModal = (id) => {
  if (!!window.modals && window.modals.length > 0) {
    return window.modals[window.modals.length - 1] === id;
  }
  return false;
};

const Modal = (props) => {
  const {
    children,
    show = false,
    outside = false,
    onClose,
    closable = true,
    fullHeight = false,
    fullPage = false,
    transparent = false,
    fluid = false,
    position = 'center',
    maxWidth = '100%',
    maxHeight = '100%',
    width = '100%',
    blurred = false,
    contentHeight = null,
    mountOnEnter = false,
    unmountOnExit = false,
    onExited,
    ...rest
  } = props;

  const node = useRef();
  const idRef = useRef();

  useEffect(() => {
    idRef.current = uuidv4();

    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  useEffect(() => {
    if (show) {
      addToModals(idRef.current);

      if (window.modals.length === 1) {
        disableBodyScroll(node.current);
      }
    } else {
      removeFromModals(idRef.current);

      if (window.modals && window.modals.length === 0) {
        clearAllBodyScrollLocks();
      }
    }
  }, [show]);

  useEffect(() => {
    return () => {
      removeFromModals(idRef.current);

      if (window.modals && window.modals.length === 0) {
        clearAllBodyScrollLocks();
      }
    };
  }, []);

  const handleClick = (event) => {
    if (!isCurrentModal(idRef.current)) return;

    event.stopPropagation();

    if (!node.current || !closable) return;

    const isOverlay = node.current === event.target;

    const isNotContent = !node.current.contains(event.target);

    if (isOverlay || isNotContent) {
      onClose();
    }
  };

  return (
    <Transition
      in={show}
      timeout={DURATION + MODAL_DURATION}
      mountOnEnter={mountOnEnter}
      unmountOnExit={unmountOnExit}
      onExited={onExited}
    >
      {(state) => (
        <Overlay
          ref={node}
          transparent={transparent}
          position={position}
          blurred={blurred}
          fullHeight={fullHeight}
          transitionStatus={state}
          transitionDuration={DURATION}
          outside={outside}
        >
          <ModalContainer
            width={width}
            maxWidth={maxWidth}
            maxHeight={maxHeight}
            fluid={fluid}
            fullPage={fullPage}
            style={{
              ...modalDefaultStyle,
              ...modalTransitionStyles[state],
            }}
          >
            {!!closable && (
              <ModalCloseButton fullPage={fullPage} onClick={onClose} />
            )}
            <ModalContent
              fullPage={fullPage}
              closable={closable}
              contentHeight={contentHeight}
              {...rest}
            >
              {children}
            </ModalContent>
          </ModalContainer>
        </Overlay>
      )}
    </Transition>
  );
};

export default Modal;
