import React, { useEffect } from 'react';

import i18n from '@/i18n';

const Locale = (props) => {
  const { lang = process.env.DEFAULT_LOCALE, children } = props;

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [lang]);

  return <>{children}</>;
};

export default Locale;
