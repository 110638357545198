import * as types from 'Stores/types';

import {
  fetchCategories,
  fetchBrainstorm,
  fetchBrainstormByParticipant,
  fetchBrainstorms,
  fetchBrainstormPresets,
  createBrainstorm,
  updateBrainstorm,
  removeBrainstorm,
  pushBrainstormModules,
  removeBrainstormModules,
  sortBrainstormModules,
  setBrainstormImage,
  setBrainstormImagePreset,
  createBrainstormDocuments,
  removeBrainstormDocuments,
  inviteBrainstormUsers,
} from 'Helpers/api/brainstorm';

import errorMsg from 'Abstracts/errorMessages/manageBrainstorm';

export function getCategories(params = {}) {
  return (dispatch, getState) => {
    const state = getState();

    dispatch({
      type: types.GET_CATEGORIES_START,
      payload: {
        status: 'loading',
      },
    });
    fetchCategories(params, state.auth.token)
      .then((response) => {
        dispatch({
          type: types.GET_CATEGORIES_SUCCESS,
          payload: {
            status: 'loaded',
            data: response.data.payload.categories,
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: types.GET_CATEGORIES_FAILURE,
          payload: {
            status: 'loaded',
            error,
          },
        });
      });
  };
}

export function getBrainstorm(id) {
  return (dispatch, getState) => {
    const state = getState();

    dispatch({
      type: types.GET_BRAINSTORM_START,
      payload: {
        status: 'loading',
      },
    });
    fetchBrainstorm(id, state.auth.token)
      .then((response) => {
        dispatch({
          type: types.GET_BRAINSTORM_SUCCESS,
          payload: {
            status: 'loaded',
            data: response.data.payload,
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: types.GET_BRAINSTORM_FAILURE,
          payload: {
            status: 'loaded',
            error,
          },
        });
      });
  };
}

export function getBrainstormByParticipant(id) {
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      (async () => {
        const state = getState();

        const { token } = state.session;

        dispatch({
          type: types.GET_BRAINSTORM_BY_PARICIPANT_START,
        });

        try {
          const response = await fetchBrainstormByParticipant(id, token.data);

          dispatch({
            type: types.GET_BRAINSTORM_BY_PARICIPANT_SUCCESS,
          });

          resolve(response.data.payload);
        } catch (error) {
          dispatch({
            type: types.GET_BRAINSTORM_BY_PARICIPANT_FAILURE,
          });
          reject(error);
        }
      })();
    });
}

export function getBrainstorms(params = {}, isFiltered = false) {
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      const state = getState();

      const status = !isFiltered
        ? {
            process: 'loading',
            done: 'loaded',
          }
        : {
            process: 'filtering',
            done: 'filtered',
          };

      dispatch({
        type: types.GET_BRAINSTORMS_START,
        payload: {
          status: status.process,
        },
      });
      fetchBrainstorms(params, state.auth.token)
        .then((response) => {
          dispatch({
            type: types.GET_BRAINSTORMS_SUCCESS,
            payload: {
              status: status.done,
              data: response.data.payload.brainstorms || [],
            },
          });
          return resolve(response.data.payload);
        })
        .catch((error) => {
          dispatch({
            type: types.GET_BRAINSTORMS_FAILURE,
            payload: {
              status: status.done,
              error,
            },
          });
          return reject(error);
        });
    });
}

export function getBrainstormsNext(params = {}) {
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      const state = getState();

      dispatch({
        type: types.GET_BRAINSTORMS_NEXT_START,
        payload: {
          status: 'updating',
        },
      });
      fetchBrainstorms(params, state.auth.token)
        .then((response) => {
          dispatch({
            type: types.GET_BRAINSTORMS_NEXT_SUCCESS,
            payload: {
              status: 'updated',
              data: response.data.payload.brainstorms,
            },
          });
          return resolve(response.data.payload);
        })
        .catch((error) => {
          dispatch({
            type: types.GET_BRAINSTORMS_NEXT_FAILURE,
            payload: {
              status: 'updated',
              error,
            },
          });
          return reject(error);
        });
    });
}

export function resetBrainstorms() {
  return (dispatch) =>
    new Promise((resolve) => {
      dispatch({
        type: types.RESET_BRAINSTORMS,
        payload: {
          status: 'initial',
          error: null,
          data: [],
        },
      });

      resolve(true);
    });
}

export function getBrainstormImagePresets() {
  return (dispatch, getState) => {
    const state = getState();

    dispatch({
      type: types.GET_BRAINSTORM_IMAGE_PRESET_START,
      payload: {
        status: 'loading',
      },
    });
    fetchBrainstormPresets(state.auth.token)
      .then((response) => {
        dispatch({
          type: types.GET_BRAINSTORM_IMAGE_PRESET_SUCCESS,
          payload: {
            status: 'loaded',
            data: response.data.payload,
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: types.GET_BRAINSTORM_IMAGE_PRESET_FAILURE,
          payload: {
            status: 'loaded',
            error,
          },
        });
      });
  };
}

export function postBrainstorm(params = {}) {
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      const state = getState();

      const status =
        params.status === 'draft'
          ? {
              process: 'saving',
              done: 'saved',
            }
          : {
              process: 'sending',
              done: 'sent',
            };

      dispatch({
        type: types.POST_BRAINSTORM_START,
        payload: {
          status: status.process,
          error: null,
        },
      });
      createBrainstorm(params, state.auth.token)
        .then((response) => {
          dispatch({
            type: types.POST_BRAINSTORM_SUCCESS,
            payload: {
              status: status.done,
              data: response.data.payload,
            },
          });
          return resolve(response.data.payload);
        })
        .catch((error) => {
          dispatch({
            type: types.POST_BRAINSTORM_FAILURE,
            payload: {
              status: status.done,
              error,
            },
          });

          const errorInstance = errorMsg[error.response.data.statusCode.code]
            ? new UserError(errorMsg[error.response.data.statusCode.code])
            : new Error(error.response.data.statusCode.msg);

          return reject(errorInstance);
        });
    });
}

export function putBrainstorm(brainstromId, params = {}) {
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      const state = getState();

      const status =
        params.status === 'draft'
          ? {
              process: 'saving',
              done: 'saved',
            }
          : {
              process: 'sending',
              done: 'sent',
            };

      dispatch({
        type: types.PUT_BRAINSTORM_START,
        payload: {
          status: status.process,
          error: null,
        },
      });
      updateBrainstorm(brainstromId, params, state.auth.token)
        .then((response) => {
          dispatch({
            type: types.PUT_BRAINSTORM_SUCCESS,
            payload: {
              status: status.done,
              data: response.data.payload,
            },
          });
          return resolve(response.data.payload);
        })
        .catch((error) => {
          dispatch({
            type: types.PUT_BRAINSTORM_FAILURE,
            payload: {
              status: status.done,
              error,
            },
          });

          const errorInstance = errorMsg[error.response.data.statusCode.code]
            ? new UserError(errorMsg[error.response.data.statusCode.code])
            : new Error(error.response.data.statusCode.msg);

          return reject(errorInstance);
        });
    });
}

export function deleteBrainstorm(brainstromId, params = {}) {
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      const state = getState();

      dispatch({
        type: types.DELETE_BRAINSTORM_START,
        payload: {
          status: 'loading',
        },
      });
      removeBrainstorm(brainstromId, params, state.auth.token)
        .then((response) => {
          dispatch({
            type: types.DELETE_BRAINSTORM_SUCCESS,
            payload: {
              status: 'loaded',
              data: brainstromId,
            },
          });
          return resolve(response.data.payload);
        })
        .catch((error) => {
          dispatch({
            type: types.DELETE_BRAINSTORM_FAILURE,
            payload: {
              status: 'loaded',
              error,
            },
          });

          return reject(
            new Error(
              errorMsg[error.response.data.statusCode.code]
                ? errorMsg[error.response.data.statusCode.code]
                : error.response.data.statusCode.msg
            )
          );
        });
    });
}

export function voidBrainStorm() {
  return (dispatch) =>
    new Promise((resolve) => {
      dispatch({
        type: types.VOID_BRAINSTORM,
        payload: {
          status: 'reset',
        },
      });
      resolve(true);
    });
}

export function postBrainstormModules(brainstromId, modules) {
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      const state = getState();

      dispatch({
        type: types.POST_BRAINSTORM_MODULES_START,
        payload: {
          status: 'loading',
          data: [],
        },
      });
      pushBrainstormModules(brainstromId, modules, state.auth.token)
        .then((response) => {
          dispatch({
            type: types.POST_BRAINSTORM_MODULES_SUCCESS,
            payload: {
              status: 'loaded',
              data: response,
            },
          });
          return resolve(response);
        })
        .catch((error) => {
          dispatch({
            type: types.POST_BRAINSTORM_MODULES_FAILURE,
            payload: {
              status: 'loaded',
              data: [],
              error,
            },
          });
          return reject(error);
        });
    });
}

export function putBrainstormModules(brainstromId, modules) {
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      const state = getState();

      dispatch({
        type: types.PUT_BRAINSTORM_MODULES_START,
        payload: {
          status: 'loading',
        },
      });
      pushBrainstormModules(brainstromId, modules, state.auth.token)
        .then((response) => {
          dispatch({
            type: types.PUT_BRAINSTORM_MODULES_SUCCESS,
            payload: {
              status: 'loaded',
              data: response,
            },
          });
          return resolve(response);
        })
        .catch((error) => {
          dispatch({
            type: types.PUT_BRAINSTORM_MODULES_FAILURE,
            payload: {
              status: 'loaded',
              error,
            },
          });
          return reject(error);
        });
    });
}

export function deleteBrainstormModules(brainstromId, modules) {
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      const state = getState();

      dispatch({
        type: types.DELETE_BRAINSTORM_MODULES_START,
        payload: {
          status: 'loading',
        },
      });
      removeBrainstormModules(brainstromId, modules, state.auth.token)
        .then((response) => {
          dispatch({
            type: types.DELETE_BRAINSTORM_MODULES_SUCCESS,
            payload: {
              status: 'loaded',
              data: response,
            },
          });
          return resolve(response);
        })
        .catch((error) => {
          dispatch({
            type: types.DELETE_BRAINSTORM_MODULES_FAILURE,
            payload: {
              status: 'loaded',
              error,
            },
          });
          return reject(error);
        });
    });
}

export function postBrainstormModuleSort(brainstromId, modules) {
  return (dispatch, getState) => {
    const state = getState();

    dispatch({
      type: types.POST_BRAINSTORM_MODULE_SORT,
    });

    return sortBrainstormModules(brainstromId, modules, state.auth.token);
  };
}

export function postBrainstormImage(brainstromId, image) {
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      const state = getState();

      dispatch({
        type: types.POST_BRAINSTORM_IMAGE_START,
        payload: {
          status: 'loading',
        },
      });
      setBrainstormImage(brainstromId, image, state.auth.token)
        .then((response) => {
          dispatch({
            type: types.POST_BRAINSTORM_IMAGE_SUCCESS,
            payload: {
              status: 'loaded',
              data: response.data.payload,
            },
          });
          return resolve(response.data.payload);
        })
        .catch((error) => {
          dispatch({
            type: types.POST_BRAINSTORM_IMAGE_FAILURE,
            payload: {
              status: 'loaded',
              error,
            },
          });
          return reject(error);
        });
    });
}

export function putBrainstormImage(brainstromId, image) {
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      const state = getState();

      dispatch({
        type: types.PUT_BRAINSTORM_IMAGE_START,
        payload: {
          status: 'loading',
        },
      });
      setBrainstormImage(brainstromId, image, state.auth.token)
        .then((response) => {
          dispatch({
            type: types.PUT_BRAINSTORM_IMAGE_SUCCESS,
            payload: {
              status: 'loaded',
              data: response.data.payload,
            },
          });
          return resolve(response.data.payload);
        })
        .catch((error) => {
          dispatch({
            type: types.PUT_BRAINSTORM_IMAGE_FAILURE,
            payload: {
              status: 'loaded',
              error,
            },
          });
          return reject(error);
        });
    });
}

export function postBrainstormImagePreset(brainstromId, presetId) {
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      const state = getState();

      dispatch({
        type: types.POST_BRAINSTORM_IMAGE_PRESET_START,
        payload: {
          status: 'loading',
        },
      });
      setBrainstormImagePreset(brainstromId, presetId, state.auth.token)
        .then((response) => {
          dispatch({
            type: types.POST_BRAINSTORM_IMAGE_PRESET_SUCCESS,
            payload: {
              status: 'loaded',
              data: response.data.payload.image,
            },
          });
          return resolve(response.data.payload);
        })
        .catch((error) => {
          dispatch({
            type: types.POST_BRAINSTORM_IMAGE_PRESET_FAILURE,
            payload: {
              status: 'loaded',
              error,
            },
          });
          return reject(error);
        });
    });
}

export function putBrainstormImagePreset(brainstromId, presetId) {
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      const state = getState();

      dispatch({
        type: types.PUT_BRAINSTORM_IMAGE_PRESET_START,
        payload: {
          status: 'loading',
        },
      });
      setBrainstormImagePreset(brainstromId, presetId, state.auth.token)
        .then((response) => {
          dispatch({
            type: types.PUT_BRAINSTORM_IMAGE_PRESET_SUCCESS,
            payload: {
              status: 'loaded',
              data: response.data.payload.image,
            },
          });
          return resolve(response.data.payload);
        })
        .catch((error) => {
          dispatch({
            type: types.PUT_BRAINSTORM_IMAGE_PRESET_FAILURE,
            payload: {
              status: 'loaded',
              error,
            },
          });
          return reject(error);
        });
    });
}

export function postBrainstormDocuments(brainstromId, documents) {
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      const state = getState();

      dispatch({
        type: types.POST_BRAINSTORM_DOCUMENTS_START,
        payload: {
          status: 'loading',
        },
      });
      createBrainstormDocuments(brainstromId, documents, state.auth.token)
        .then((response) => {
          dispatch({
            type: types.POST_BRAINSTORM_DOCUMENTS_SUCCESS,
            payload: {
              status: 'loaded',
              data: response,
            },
          });
          return resolve(response);
        })
        .catch((error) => {
          dispatch({
            type: types.POST_BRAINSTORM_DOCUMENTS_FAILURE,
            payload: {
              status: 'loaded',
              error,
            },
          });
          return reject(error);
        });
    });
}

export function deleteBrainstormDocuments(brainstromId, files) {
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      const state = getState();

      dispatch({
        type: types.DELETE_BRAINSTORM_DOCUMENTS_START,
        payload: {
          status: 'loading',
        },
      });
      removeBrainstormDocuments(brainstromId, files, state.auth.token)
        .then((response) => {
          dispatch({
            type: types.DELETE_BRAINSTORM_DOCUMENTS_SUCCESS,
            payload: {
              status: 'loaded',
              data: response,
            },
          });
          return resolve(response);
        })
        .catch((error) => {
          dispatch({
            type: types.DELETE_BRAINSTORM_DOCUMENTS_FAILURE,
            payload: {
              status: 'loaded',
              error,
            },
          });
          return reject(error);
        });
    });
}

export function postBrainstormInvitation(brainstromId, invitation) {
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      const state = getState();

      dispatch({
        type: types.POST_BRAINSTORM_INVITATION_START,
        payload: {
          status: 'loading',
        },
      });
      inviteBrainstormUsers(brainstromId, invitation, state.auth.token)
        .then((response) => {
          dispatch({
            type: types.POST_BRAINSTORM_INVITATION_SUCCESS,
            payload: {
              status: 'loaded',
              data: response.data.payload.participants,
            },
          });
          return resolve(response.data.payload);
        })
        .catch((error) => {
          dispatch({
            type: types.POST_BRAINSTORM_INVITATION_FAILURE,
            payload: {
              status: 'loaded',
              error,
            },
          });
          return reject(error);
        });
    });
}
