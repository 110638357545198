const GET_CARD_START = 'GET_CARD_START';
const GET_CARD_SUCCESS = 'GET_CARD_SUCCESS';
const GET_CARD_FAILURE = 'GET_CARD_FAILURE';

const GET_CUST_SUCCESS = 'GET_CUST_SUCCESS';
const GET_CUST_FAILURE = 'GET_CUST_FAILURE';

const GET_INVOICE_START = 'GET_INVOICE_START';
const GET_INVOICE_SUCCESS = 'GET_INVOICE_SUCCESS';
const GET_INVOICE_FAILURE = 'GET_INVOICE_FAILURE';

const SET_DEFAULT_CARD_START = 'SET_DEFAULT_CARD_START';
const SET_DEFAULT_CARD_SUCCESS = 'SET_DEFAULT_CARD_SUCCESS';
const SET_DEFAULT_CARD_FAILURE = 'SET_DEFAULT_CARD_FAILURE';

const ADD_CARD_START = 'ADD_CARD_START';
const ADD_CARD_SUCCESS = 'ADD_CARD_SUCCESS';
const ADD_CARD_FAILURE = 'ADD_CARD_FAILURE';

export {
  GET_CARD_START,
  GET_CARD_SUCCESS,
  GET_CARD_FAILURE,
  GET_CUST_SUCCESS,
  GET_CUST_FAILURE,
  GET_INVOICE_START,
  GET_INVOICE_SUCCESS,
  GET_INVOICE_FAILURE,
  SET_DEFAULT_CARD_START,
  SET_DEFAULT_CARD_SUCCESS,
  SET_DEFAULT_CARD_FAILURE,
  ADD_CARD_START,
  ADD_CARD_SUCCESS,
  ADD_CARD_FAILURE,
};
