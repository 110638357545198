const events = ['click'];

const unlockAudio = (data) => {
  return new Promise((resolve, reject) => {
    try {
      events.forEach((eventName) => {
        const unlockAudio = async () => {
          // clear

          let audio = data instanceof Audio ? data : new Audio(data);

          await audio.play();

          audio.pause();
          audio.currentTime = 0;

          if (!data instanceof Audio) {
            audio = null;
          }

          resolve(audio);
        };

        document.body.addEventListener(eventName, unlockAudio, {
          once: true,
          passive: false,
        });
      });
    } catch (error) {
      reject(error);
    }
  });
};

export default unlockAudio;
