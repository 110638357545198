import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import enTranslation from 'Localization/en/translation.json';
import cnTranslation from 'Localization/cn/translation.json';

import { locales } from 'Abstracts/variables';

const resources = {
  en: { translation: enTranslation },
  cn: { translation: cnTranslation },
};

export const langPath = `/:locale(${locales.join('|')})?`;

i18n.use(initReactI18next).init({
  lng: process.env.DEFAULT_LOCALE,
  nsSeparator: false,
  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
  resources,
});

export const getLangPrefix = (instance) =>
  instance.language === process.env.DEFAULT_LOCALE
    ? ''
    : `/${instance.language}`;

export default i18n;
