import styled, { css } from 'styled-components';
import { rgba, darken } from 'polished';

import { variant } from 'styled-system';

import { scrollBar } from 'Mixins/styles';

import { colors } from 'Abstracts/variables';

export const OverlayWrapper = styled.div`
  /* Layout */
  display: flex;
  align-content: center;
  align-items: flex-end;

  ${scrollBar};
  &::-webkit-scrollbar-track {
      background-color: ${colors.dark};
      margin: 0;
  } 
  ${variant({
    prop: 'position',
    variants: {
      top: {
        justifyContent: 'flex-start',
      },
      center: {
        justifyContent: 'center',
      },
      bottom: {
        justifyContent: 'flex-end',
      },
    },
  })};


  flex-direction: column;
  width: 100%;
  height: ${({ spare }) => `calc(100% - ${spare}px)` || '100%'};
  /* Scrolling */
  overflow-x: hidden;
  overflow-y: auto;
  /* position */
  position: fixed;
  top: ${({ spare }) => `${spare}px` || 0};
  left: 0;
  /* Other */
  z-index: 1003;
  &:before {
    content: '';
    width: 100%;
    height: inherit;
    display: block;
    position: fixed;
    ${({ blurred }) =>
      !!blurred &&
      css`
        backdrop-filter: blur(5px);
      `}
    background-color: ${({ transparent }) =>
      transparent ? 'transparent' : darken(0.2, rgba('#3D1D8B', 0.6))};
      
  }
`;
