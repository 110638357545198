import React, { useState } from 'react';

import Tippy from '@tippyjs/react/headless';

import { TooltipText, TooltipWrapper, TooltipArrow } from './Tooltip.styles';

const Tooltip = (props) => {
  const [arrow, setArrow] = useState(null);
  const { children, content, placement = 'bottom-start', ...rest } = props;

  const [visible, setVisible] = useState(false);
  const show = () => setVisible(true);
  const hide = () => setVisible(false);

  return (
    <Tippy
      {...rest}
      render={(attrs) => {
        return (
          <TooltipText show={visible} {...attrs}>
            {content}
            <TooltipArrow ref={setArrow} placement={attrs['data-placement']} />
          </TooltipText>
        );
      }}
      popperOptions={{
        modifiers: [
          {
            name: 'arrow',
            options: {
              element: arrow,
            },
          },
          {
            name: 'offset',
            options: {
              offset: [0, 10],
            },
          },
        ],
      }}
      placement={placement}
    >
      <TooltipWrapper onMouseEnter={show} onMouseLeave={hide}>
        {children}
      </TooltipWrapper>
    </Tippy>
  );
};

export default Tooltip;
