export const basicFields = [
  {
    name: 'methodology',
    required: false,
  },
  {
    name: 'title',
    required: true,
  },
  {
    name: 'durationInSeconds',
    required: true,
  },
];
