import * as types from 'Stores/types';

export function tour(state = {}, action) {
  switch (action.type) {
    case types.SET_TOUR:
      return { ...state, tour: { ...state.tour, ...action.payload } };
    default:
      return state;
  }
}
