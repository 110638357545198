import React, { createContext, useState } from 'react';

import { useAsyncState } from 'Helpers/hooks';

import modules from 'Abstracts/modules';

export const Context = createContext({});

export const { Consumer } = Context;

export const Provider = (props) => {
  const { initState, children } = props;

  const [state, setState] = useState(initState);
  const asyncSetState = useAsyncState([state, setState]);

  const updateError = (step, target, value) => {
    return new Promise(async (resolve) => {
      const newState = await asyncSetState((prevState) => ({
        ...prevState,
        errors: {
          ...prevState.errors,
          [step]: {
            ...prevState.errors[step],
            [target]: value,
          },
        },
      }));

      return resolve(newState);
    });
  };

  const updateErrors = (step, data) => {
    return new Promise(async (resolve) => {
      const newState = await asyncSetState((prevState) => ({
        ...prevState,
        errors: {
          ...prevState.errors,
          [step]: {
            ...prevState.errors[step],
            ...data,
          },
        },
      }));

      return resolve(newState);
    });
  };

  const getModuleSetup = (type) => {
    const basic = ['durationInSeconds'];
    switch (type) {
      case modules.KEYWORD_GENERATION.name:
        return [...basic, 'methodology'];
      case modules.AUDIO_VISUAL.name:
        return [...basic, ['referenceFile', 'referenceUrl']];
      case modules.UPLOAD_IMAGE.name:
        return [...basic, 'methodology'];
      case modules.TYPE_SOMETHING.name:
        return [...basic, 'methodology'];
      case modules.UPLOAD_AUDIO.name:
        return [...basic, 'methodology'];
      default:
        return basic;
    }
  };

  const brainstormContext = {
    state,
    setState,
    updateError,
    updateErrors,
    getModuleSetup,
  };

  return (
    <Context.Provider value={brainstormContext}>{children}</Context.Provider>
  );
};
