const SET_USER_START = 'SET_USER_START';
const SET_USER_SUCCESS = 'SET_USER_SUCCESS';
const SET_USER_FAILURE = 'SET_USER_FAILURE';

const RESET_USER = 'RESET_USER';

const PUT_USER_START = 'PUT_USER_START';
const PUT_USER_SUCCESS = 'PUT_USER_SUCCESS';
const PUT_USER_FAILURE = 'PUT_USER_FAILURE';

const UPGRADE_USER_START = 'UPGRADE_USER_START';
const UPGRADE_USER_SUCCESS = 'UPGRADE_USER_SUCCESS';
const UPGRADE_USER_FAILURE = 'UPGRADE_USER_FAILURE';

const UNSUBSCRIBE_USER_START = 'UNSUBSCRIBE_USER_START';
const UNSUBSCRIBE_USER_SUCCESS = 'UNSUBSCRIBE_USER_SUCCESS';
const UNSUBSCRIBE_USER_FAILURE = 'UNSUBSCRIBE_USER_FAILURE';

const CLOSE_ACCOUNT_START = 'CLOSE_ACCOUNT_START';
const CLOSE_ACCOUNT_SUCCESS = 'CLOSE_ACCOUNT_SUCCESS';
const CLOSE_ACCOUNT_FAILURE = 'CLOSE_ACCOUNT_FAILURE';

const GET_PROFILE_PICTURE_START = 'GET_PROFILE_PICTURE_START';
const GET_PROFILE_PICTURE_SUCCESS = 'GET_PROFILE_PICTURE_SUCCESS';
const GET_PROFILE_PICTURE_FAILURE = 'GET_PROFILE_PICTURE_FAILURE';

const GET_PROFILE_PICTURE_BY_PARTICIPANT_START =
  'GET_PROFILE_PICTURE_BY_PARTICIPANT_START';
const GET_PROFILE_PICTURE_BY_PARTICIPANT_SUCCESS =
  'GET_PROFILE_PICTURE_BY_PARTICIPANT_SUCCESS';
const GET_PROFILE_PICTURE_BY_PARTICIPANT_FAILURE =
  'GET_PROFILE_PICTURE_BY_PARTICIPANT_FAILURE';

const SET_PROFILE_PICTURE_START = 'SET_PROFILE_PICTURE_START';
const SET_PROFILE_PICTURE_SUCCESS = 'SET_PROFILE_PICTURE_SUCCESS';
const SET_PROFILE_PICTURE_FAILURE = 'SET_PROFILE_PICTURE_FAILURE';

const DELETE_PROFILE_PICTURE_START = 'DELETE_PROFILE_PICTURE_START';
const DELETE_PROFILE_PICTURE_SUCCESS = 'DELETE_PROFILE_PICTURE_SUCCESS';
const DELETE_PROFILE_PICTURE_FAILURE = 'DELETE_PROFILE_PICTURE_FAILURE';

export {
  SET_USER_START,
  SET_USER_SUCCESS,
  SET_USER_FAILURE,
  RESET_USER,
  PUT_USER_START,
  PUT_USER_SUCCESS,
  PUT_USER_FAILURE,
  UPGRADE_USER_START,
  UPGRADE_USER_SUCCESS,
  UPGRADE_USER_FAILURE,
  UNSUBSCRIBE_USER_START,
  UNSUBSCRIBE_USER_SUCCESS,
  UNSUBSCRIBE_USER_FAILURE,
  CLOSE_ACCOUNT_START,
  CLOSE_ACCOUNT_SUCCESS,
  CLOSE_ACCOUNT_FAILURE,
  GET_PROFILE_PICTURE_START,
  GET_PROFILE_PICTURE_SUCCESS,
  GET_PROFILE_PICTURE_FAILURE,
  GET_PROFILE_PICTURE_BY_PARTICIPANT_START,
  GET_PROFILE_PICTURE_BY_PARTICIPANT_SUCCESS,
  GET_PROFILE_PICTURE_BY_PARTICIPANT_FAILURE,
  SET_PROFILE_PICTURE_START,
  SET_PROFILE_PICTURE_SUCCESS,
  SET_PROFILE_PICTURE_FAILURE,
  DELETE_PROFILE_PICTURE_START,
  DELETE_PROFILE_PICTURE_SUCCESS,
  DELETE_PROFILE_PICTURE_FAILURE,
};
