import React, { useState, useEffect } from 'react';

import styled, { css } from 'styled-components';

import { variant, space } from 'styled-system';
import Text from 'Components/Elements/TypeSet/Text';

const StyledLabel = styled.label`
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  ${space}
`;

const StyledLabelText = styled(Text)`
  color: #999999;
  margin-left: 12.5px;
`;

const CheckBoxWrapper = styled.div`
  position: relative;
  ${variant({
    variants: {
      normal: {
        width: '30px',
        height: '30px',
      },
      small: {
        width: '26px',
        height: '26px',
      },
    },
  })};
`;

const StyledCheckBox = styled.input`
  display: none;
`;

const CheckMark = styled.span`
  position: relative;
  box-sizing: border-box;
  display: block;
  width: inherit;
  height: inherit;
  border-radius: 8px;
  border: solid 1px #707070;
  background-color: #ebebeb;
  &:after {
    opacity: 0;
    content: '';
    position: absolute;
    top: 2px;
    left: 8px;
    width: 8px;
    height: 18px;
    border: solid white;
    border-width: 0 4px 4px 0;
    transform: rotate(45deg);
    transition: opacity 0.2s ease-in-out;
    box-sizing: border-box;
  }
  ${(props) =>
    props.checked &&
    css`
      background-color: #622de3;
      &:after {
        opacity: 1;
      }
    `}

  ${(props) =>
    props.error &&
    css`
      border: 1px solid #c64f4b;
    `}
`;

const CheckBox = React.forwardRef((props, ref) => {
  const {
    componentLabel = null,
    label = '',
    checked = false,
    fontSize = '16px',
    fontStyle = 'normal',
    variant = 'normal',
    padding = '',
    onChange,
    error,
    ...rest
  } = props;

  const [selected, setSelected] = useState(checked);

  const handleChange = (event) => {
    if (onChange) onChange(event);

    setSelected(event.target.checked);
  };

  useEffect(() => {
    setSelected(checked);
  }, [checked]);

  return (
    <StyledLabel {...rest}>
      <CheckBoxWrapper variant={variant}>
        <StyledCheckBox
          type="checkbox"
          checked={selected}
          onChange={handleChange}
          ref={ref}
          {...rest}
        />
        <CheckMark checked={selected} error={error} />
      </CheckBoxWrapper>
      {!!label && (
        <StyledLabelText
          variant="textfield"
          fontSize={fontSize}
          fontStyle={fontStyle}
        >
          {label}
        </StyledLabelText>
      )}
      {!!componentLabel && <>{componentLabel}</>}
    </StyledLabel>
  );
});

export default CheckBox;
