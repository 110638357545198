import React from 'react';
import styled from 'styled-components';

import fontStyles from 'Mixins/styles/typography';
import colorStyles from 'Mixins/styles/color';

import {
  compose,
  space,
  color,
  layout,
  variant,
  typography,
  system,
} from 'styled-system';

const Component = styled.p`
  margin: 0;
  white-space: pre-wrap;
  ${variant({
    prop: 'tone',
    variants: colorStyles,
  })}
    ${variant({
      variants: fontStyles,
    })}

  ${system({
    textShadow: true,
  })}
  
  ${compose(space, color, typography, layout)};
`;

const Paragraph = React.forwardRef((props, ref) => {
  return <Component ref={ref} {...props} />;
});

export default Paragraph;
