import React from 'react';

import i18n from '@/i18n';
import { Trans } from 'react-i18next';
import { showReportDialog } from '@sentry/react';

import { useResponsive } from 'Helpers/hooks';

import { Text, Paragraph } from 'Components/Elements/TypeSet';
import ArtBoard from 'Components/Elements/ArtBoard';
import Image from 'Components/Elements/Image';
import { Col, Row, Container } from 'Components/Elements/Grid';
import Button from 'Components/Elements/Button';

import ErrorTitle from 'Assets/images/error-title.svg';
import ErrorBG from 'Assets/images/backgrounds/error-bg.svg';

import {
  BoundaryWrapper,
  BoundaryTitleContainer,
  BoundaryLink,
  WidgetToggle,
} from './ErrorBoundary.styles';

const isNotDev = process.env.TARGET_ENV !== 'development';

const BoundaryPlaceHolder = ({ eventId }) => {
  const screen = useResponsive();
  const isDesktop = screen.size('md');

  const openToggle = () => {
    showReportDialog({ eventId, lang: i18n.language });
  };

  return (
    <>
      <ArtBoard>
        <BoundaryWrapper bg={ErrorBG}>
          <Container p="0" maxWidth={{ _: '300px', md: undefined }}>
            <Row mx="0">
              <Col p="0" span="12" h-align="center">
                <BoundaryTitleContainer>
                  <Image
                    src={ErrorTitle}
                    alt={i18n.t(`BRAINSTORM Online (Error page)`)}
                  />
                </BoundaryTitleContainer>
              </Col>
              <Col p="0" span="12" h-align="center">
                <Text
                  variant={isDesktop ? 'h1' : 'h2'}
                  textAlign="center"
                  mb="19px"
                >
                  {i18n.t(`An error has occurred`)}
                </Text>
              </Col>
              <Col p="0" span="12" h-align="center">
                <Paragraph variant="font-12" mb="28px" maxWidth="550px">
                  <Trans>
                    Please try to refresh the page or go back to previous page.{' '}
                    <br />
                    If the error persists, please contact BRAINSTORM Online at{' '}
                    <BoundaryLink href="mailto:support@brainstorm-online.io">
                      support@brainstorm-online.io
                    </BoundaryLink>
                  </Trans>
                </Paragraph>
              </Col>
              <Col p="0" span="12" h-align="center">
                <Button variant="primary" onClick={() => window.history.back()}>
                  {i18n.t(`Back`)}
                </Button>
              </Col>
            </Row>
          </Container>
        </BoundaryWrapper>
      </ArtBoard>
      {process.env.VERSION >= 2.0 && isNotDev && (
        <WidgetToggle onClick={openToggle}>Report</WidgetToggle>
      )}
    </>
  );
};

export default BoundaryPlaceHolder;
