import styled from 'styled-components';
import { space, variant } from 'styled-system';
import fontStyles from 'Mixins/styles/typography';

const Bullet = styled.div.attrs((props) => ({
  selected: props.selected ? '#fff' : '#B7B7B7',
  background: props.selected ? '#0082c8' : 'transparent',
  border: props.selected ? 'none' : '1px solid #B7B7B7',
}))`
  ${space}

  border-radius: 25px;
  border: ${(props) => props.border};
  display: inline-block;
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  background: ${(props) => props.background};
  color: ${(props) => props.selected};
  cursor: pointer;
  overflow-wrap: anywhere;
  width: ${(props) => props.width};
  font-size: ${(props) => props.fontSize};

  & > svg {
    margin-left: 20px;
    cursor: pointer;
  }

  &:hover {
    background: ${(props) => props.background};
    color: ${(props) => props.selected};
  }
  &:focus {
    outline: none;
  }

  &.tag {
    border-color: #0083c8;
    color: #0083c8;
  }

  &.timer {
    position: relative;
    top: -12px;
    left: 15px;
    text-align: center;
  }

  ${variant({
    variants: {
      email: {
        ...fontStyles.tipsNotes,
        display: 'flex',
        alignItems: 'center',
        borderRadius: '50px',
        border: 'solid 1px #0083c7',
        color: '#999999',
        marginBottom: '16px',
        marginRight: '10px',
      },
      'email-disabled': {
        ...fontStyles.tipsNotes,
        display: 'flex',
        alignItems: 'center',
        borderRadius: '50px',
        border: 'solid 1px #b7b7b7',
        color: '#b7b7b7',
        marginBottom: '16px',
        marginRight: '10px',
        backgroundColor: '#e1e1e1',
      },
      'email-blue': {
        ...fontStyles.tipsNotes,
        display: 'flex',
        alignItems: 'center',
        borderRadius: '50px',
        border: 'solid 1px #0083c7',
        color: '#0083c6',
        marginBottom: '16px',
        marginRight: '10px',
      },
      keyword: {
        ...fontStyles.paragraph,
        color: '#666666',
        borderRadius: '20px',
        border: 'solid 1px #707070',
        display: 'inline-block',
        marginBottom: '14px',
        marginRight: '20px',
        cursor: 'default',
        '&:hover': {
          color: '#666666',
        },
      },
      'keyword-result': {
        ...fontStyles.paragraph,
        backgroundColor: '#fff',
        color: '#666666',
        borderRadius: '24px',
        border: 'solid 1px #707070',
        display: 'inline-block',
        margin: '12.5px 10px',
        cursor: 'default',
        '&:hover': {
          color: '#666666',
        },
      },
    },
  })}
`;

export default Bullet;
