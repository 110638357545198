import { isFilled } from 'Helpers/validations';

const isFilledOther = (otherValues) => () => {
  if (Array.isArray(otherValues)) {
    return otherValues.every((otherValue) => isFilled(otherValue));
  }
  return isFilled(otherValues);
};

export default isFilledOther;
