import React from 'react';

import { ErrorBoundary as SentryErrorBoundary } from '@sentry/react';
import BoundaryPlaceHolder from './PlaceHolder';

const ErrorBoundary = (props) => (
  <SentryErrorBoundary
    fallback={(innerProps) => <BoundaryPlaceHolder {...innerProps} />}
    {...props}
  />
);

export default ErrorBoundary;
