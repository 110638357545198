import styled, { css } from 'styled-components';

import Icon from 'Components/Elements/Icon';
import Text from 'Components/Elements/TypeSet/Text';
import Image from 'Components/Elements/Image';

import { colors } from 'Abstracts/variables';
import { border } from 'styled-system';

export const AvatarText = styled(Text)`
  color: ${({ color }) => color || colors.blue};
  ${({ disabled }) =>
    !!disabled &&
    css`
      color: #999999;
    `};
`;

export const AvatarIcon = styled(Icon)`
  border: solid 2px ${({ color }) => color || colors.blue};
  background-color: #ffffff;

  ${Image} {
    object-fit: cover;
    height: 100%;
    width: 100%;
    max-width: 100%;
  }

  ${({ size }) => {
    switch (size) {
      case 'tiny':
        return css`
          width: 26px;
          height: 26px;
        `;
      case 'light':
        return css`
          width: 45px;
          height: 45px;
        `;
      case 'lightMedium':
        return css`
          width: 50px;
          height: 50px;
        `;
      case 'medium':
        return css`
          width: 55px;
          height: 55px;
        `;
      case 'large':
        return css`
          width: 65px;
          height: 65px;
        `;
      case 'extraLarge':
        return css`
          width: 80px;
          height: 80px;
        `;
      case 'extraExtraLarge':
        return css`
          width: 145px;
          height: 145px;
          border-color: ${colors.secondary};
          border-width: 4px;
          ${AvatarText} {
            color: ${colors.secondary};
          }
        `;
      default:
        return css`
          width: 40px;
          height: 40px;
        `;
    }
  }};

  ${({ disabled }) =>
    !!disabled &&
    css`
      border-color: #999999;
      background-color: #e1e1e1;
    `};

  ${border}
`;
