import { useRef, useEffect } from 'react';

const useUnMounted = () => {
  const ref = useRef(false);

  useEffect(() => {
    return () => {
      ref.current = true;
    };
  }, []);

  // [unMounted, checkUnMounted]
  return [ref.current, () => ref.current];
};

export default useUnMounted;
