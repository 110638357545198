import { useState, useEffect, useCallback } from 'react';

import { breakpoints } from 'Abstracts/variables';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export default function useResponsive() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const isSize = useCallback(
    (size) => windowDimensions.width >= parseInt(breakpoints[size], 10),
    [windowDimensions]
  );

  const screen = {
    ...windowDimensions,
    size: isSize,
  };

  return screen;
}
