import React from 'react';

import styled from 'styled-components';

import Ripple, { useRipple } from 'Components/Elements/Ripple';
import Tooltip from 'Components/Elements/Tooltip';

import { rgba } from 'polished';

import { colors } from 'Abstracts/variables';

import { IconWrapper } from './Icon.styles';

const Icon = (props) => {
  const {
    children,
    tooltip = '',
    tooltipPlacement = 'bottom-start',
    hoverColor = rgba(colors.dark, 0.05),
    rounded = true,
    onClick = () => {},
    onMouseDown = () => {},
    ripple = { color: rgba(colors.dark, 0.25), duration: 500 },
    ...rest
  } = props;

  const [ripples, addRipple, cleanRipples] = useRipple();

  const handleClick = (event) => {
    event.stopPropagation();
    onClick(event);
  };

  const iconContent = (
    <IconWrapper
      rounded={rounded}
      hoverColor={hoverColor}
      onClick={handleClick}
      onMouseDown={(event) => {
        onMouseDown(event);
        addRipple(event);
      }}
      {...rest}
    >
      {children}
      {!!onClick && (
        <Ripple
          color={ripple.color}
          duration={ripple.duration}
          ripples={ripples}
          cleanRipples={cleanRipples}
        />
      )}
    </IconWrapper>
  );

  if (!tooltip) {
    return iconContent;
  }

  return (
    <Tooltip content={tooltip} placement={tooltipPlacement}>
      {iconContent}
    </Tooltip>
  );
};

export default styled(Icon)``;
