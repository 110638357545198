const isTextLength = ({ max, min }) => (string) => {
  if (!string) return true;

  let stringLength = string.length;

  if (!!max && stringLength > max) return false;
  if (!!min && stringLength < min) return false;

  return true;
};

export default isTextLength;
