import { useState, useEffect } from 'react';

const facebookProps = {
  appId: process.env.FACEBOOK_APP_ID,
  language: 'EN',
  version: '3.1',
  fields: ['email', 'name', 'last_name'],
  onFailure: (error) => {
    // console.log(error);
  },
};

const getWindow = () => {
  return window;
};

const getUserInfo = (props, state, setState) => {
  let currentUser;

  getWindow().FB.api(
    '/me',
    { locale: props.language, fields: props.fields.join(',') },
    (response) => {
      currentUser = response;
      setState({
        ...state,
        isLoggedIn: true,
        currentUser,
        isProcessing: false,
        loaded: true,
      });
    }
  );
};

const checkLoginCallback = (response, props, state, setState) => {
  if (response.status === 'connected') {
    getUserInfo(props, state, setState);
  } else {
    setState({
      ...state,
      isLoggedIn: false,
      currentUser: undefined,
      isProcessing: false,
    });
  }
};

const setFacekbookAsyncInit = (props, state, setState) => {
  getWindow().fbAsyncInit = () => {
    getWindow().FB.init({
      version: `v${props.version}`,
      appId: `${props.appId}`,
      xfbml: false,
      cookie: false,
    });
    setState({ ...state, isSdkLoaded: true });
    getWindow().FB.getLoginStatus((response) =>
      checkLoginCallback(response, props, state, setState)
    );
  };
};

const loadSdkAsynchronously = (props) => {
  ((doc, script, sdkId) => {
    const newScriptElement = doc.createElement(script);
    newScriptElement.id = sdkId;
    newScriptElement.src = `https://connect.facebook.net/${props.language}/sdk.js`;
    doc.head.appendChild(newScriptElement);
    let fbRoot = doc.getElementById('fb-root');
    if (!fbRoot) {
      fbRoot = doc.createElement('div');
      fbRoot.id = 'fb-root';
      doc.body.appendChild(fbRoot);
    }
  })(document, 'script', 'facebook-jssdk');
};
const loginCallback = async (res, props, state, setState, callback) => {
  let currentUser;

  await getWindow().FB.api(
    '/me',
    { locale: props.language, fields: props.fields.join(',') },
    (response) => {
      currentUser = response;
      setState({
        ...state,
        isLoggedIn: true,
        currentUser,
        isProcessing: false,
        loaded: true,
      });

      if (res.authResponse.accessToken === null) return;

      const form = {
        access_token: res.authResponse.accessToken,
        ...currentUser,
      };

      try {
        callback(form);
      } catch (e) {
        // console.log(e);
      }
    }
  );
};

const logoutCallback = (response, props, state, setState) => {
  if (response.authResponse) {
    setState({
      ...state,
      isLoggedIn: false,
      currentUser: undefined,
      isProcessing: false,
    });
  } else if (props.onFailure) {
    props.onFailure(response);
  }
};

export const useFacebookLogin = ({ onSuccess = () => {} }) => {
  const props = facebookProps;

  const [state, setState] = useState({
    isSdkLoaded: false,
    isProcessing: false,
    isLoggedIn: false,
    loaded: false,
  });

  const login = () => {
    setState({ ...state, isProcessing: true });
    getWindow().FB.login((response) => {
      loginCallback(response, props, state, setState, onSuccess);
    });
  };

  const logout = () => {
    setState({ ...state, isProcessing: true });
    getWindow().FB.logout((response) =>
      logoutCallback(response, props, state, setState)
    );
  };

  useEffect(() => {
    setState({ ...state, isProcessing: true });
    setFacekbookAsyncInit(props, state, setState);
    loadSdkAsynchronously(props, state);
  }, [props.appId, props.fields.join(','), props.language, props.version]);

  return { state, login, logout };
};

export default useFacebookLogin;
