import axiosInstance from 'Helpers/api/_instance';

import { getModuleBy } from 'Abstracts/modules';

import { objectToForm } from 'Helpers/data-transform';
import isProvider from '../validations/isProvider';

export const fetchCategories = (criteria, token) =>
  axiosInstance({
    method: 'get',
    url: `brainstorm/categories`,
    params: criteria,
    headers: { Authorization: token },
  });

export const getModules = (token) =>
  axiosInstance({
    method: 'get',
    url: `brainstorm/modules`,
    headers: { Authorization: token },
  });

export const fetchBrainstorm = (id, token) =>
  axiosInstance({
    method: 'get',
    url: `brainstorm/${id}`,
    headers: { Authorization: token },
  });

export const fetchBrainstormByParticipant = (id, token) =>
  axiosInstance({
    method: 'get',
    url: `participant/brainstorm/${id}`,
    headers: { Authorization: token },
  });

export const fetchBrainstorms = (criteria, token) =>
  axiosInstance({
    method: 'get',
    url: `brainstorm`,
    params: criteria,
    headers: { Authorization: token },
  });

export const fetchBrainstormPresets = (token) =>
  axiosInstance({
    method: 'get',
    url: `brainstorm/presets`,
    headers: { Authorization: token },
  });

export const createBrainstorm = (criteria, token) =>
  axiosInstance({
    method: 'post',
    url: `brainstorm`,
    data: criteria,
    headers: { Authorization: token },
  });

export const updateBrainstorm = (brainstormId, criteria, token) =>
  axiosInstance({
    method: 'put',
    url: `brainstorm/${brainstormId}`,
    data: criteria,
    headers: { Authorization: token },
  });

export const removeBrainstorm = (brainstormId, criteria, token) =>
  axiosInstance({
    method: 'delete',
    url: `brainstorm/${brainstormId}`,
    data: criteria,
    headers: { Authorization: token },
  });

const pushBrainstormModule = (brainstormId, module, token) => {
  let { type, name, referenceURL, referenceFile, ...data } = module;

  let postData = data;

  const getAppropriateReferenceApi = (value) => {
    if (typeof value === 'string') {
      if (isProvider('youtube')) {
        return {
          node: 'youtube',
          type: 'video',
        };
      }
      if (isProvider('vimeo')) {
        return {
          node: 'vimeo',
          type: 'video',
        };
      }
      return false;
    }

    let file = value;

    const referenceFileMime = file.type;

    switch (referenceFileMime) {
      // Audio
      case 'audio/mpeg':
      case 'audio/mp4':
      case 'audio/aac':
      case 'audio/webm':
        return {
          node: 'audio',
          type: 'audio',
        };
      // Video
      case 'video/mpeg':
      case 'video/webm':
      case 'video/mp4':
        return {
          node: 'video',
          type: 'video',
        };
      // Image
      case 'image/bmp':
      case 'image/gif':
      case 'image/jpeg':
      case 'image/png':
      case 'image/svg+xml':
      case 'image/tiff':
      case 'image/webp':
        return {
          node: 'image',
          type: 'image',
        };
      default:
        return false;
    }
  };

  const referenceURLApi = referenceURL
    ? getAppropriateReferenceApi(referenceURL)
    : null;

  if (referenceURLApi) {
    postData[referenceURLApi.node] = referenceURLApi;
    type = referenceURLApi.type;
  }

  const referenceFileApi = referenceFile
    ? getAppropriateReferenceApi(referenceFile)
    : null;

  if (referenceFileApi) {
    postData[referenceFileApi.node] = referenceFile;
    type = referenceFileApi.type;
  }

  if (type === 'type') {
    postData.type = 'type-something';
  }

  let { endpoint } = getModuleBy('name', type);

  if (endpoint) {
    return () =>
      axiosInstance({
        method: !module.id ? 'post' : 'put',
        url: !module.id
          ? `brainstorm/${brainstormId}/module/${endpoint}`
          : `brainstorm/${brainstormId}/module/${module.id}/${endpoint}`,
        data: objectToForm(postData),
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: token,
        },
      });
  }

  return () => new Promise((res) => res(true));
};

const removeBrainstormModule = (brainstormId, module, token) => {
  return () =>
    axiosInstance({
      method: 'delete',
      url: `brainstorm/${brainstormId}/module/${module.id}`,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: token,
      },
    });
};

export const pushBrainstormModules = (brainstormId, items, token) =>
  new Promise(async (resolve, reject) => {
    try {
      const jobs = items.map((module) => {
        return {
          data: module,
          action: pushBrainstormModule(brainstormId, module, token),
        };
      });

      const output = await jobs.reduce(async (previousPromise, currentJob) => {
        const handler = (result) => {
          return currentJob
            .action()
            .then((res) => {
              if (currentJob.data.id) {
                // put
                return [...result, currentJob.data];
              }
              // post
              let existingModules = res.data.payload.modules;

              return [
                ...result,
                {
                  ...currentJob.data,
                  id: existingModules[existingModules.length - 1].id,
                },
              ];
            })
            .catch((error) => {
              throw error;
            });
        };

        return previousPromise.then(handler);
      }, Promise.resolve([]));

      resolve(output);
    } catch (error) {
      reject(error);
    }
  });

export const removeBrainstormModules = (brainstormId, items, token) =>
  new Promise((resolve, reject) => {
    try {
      const jobs = items.map((module) =>
        removeBrainstormModule(brainstormId, module, token)
      );

      const response = jobs.reduce(
        async (previousPromise, currentPromise, index, array) => {
          const isLast = array.length - 1 === index;

          await previousPromise;

          return !isLast
            ? currentPromise()
            : currentPromise().then((res) => res.data.payload.modules);
        },
        Promise.resolve()
      );

      resolve(response);
    } catch (error) {
      reject(error);
    }
  });

export const sortBrainstormModules = (brainstormId, items, token) =>
  axiosInstance({
    method: 'post',
    url: `brainstorm/${brainstormId}/module/sort`,
    data: { modules: items },
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: token,
    },
  });

export const setBrainstormImage = (brainstormId, image, token) => {
  let data = { image };

  const formData = objectToForm(data);

  return axiosInstance({
    method: 'post',
    url: `brainstorm/${brainstormId}/image`,
    data: formData,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: token,
    },
  });
};

export const setBrainstormImagePreset = (brainstormId, presetId, token) => {
  return axiosInstance({
    method: 'post',
    url: `brainstorm/${brainstormId}/preset/${presetId}`,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: token,
    },
  });
};

export const createBrainstormDocuments = (brainstormId, documents, token) =>
  new Promise(async (resolve, reject) => {
    try {
      const jobs = documents.map((document) => () => {
        let data = { document, name: document.name, description: '' };

        const formData = objectToForm(data);

        return axiosInstance({
          method: 'post',
          url: `brainstorm/${brainstormId}/document`,
          data: formData,
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization: token,
          },
        });
      });

      const response = await jobs.reduce(
        async (previousPromise, currentJob) => {
          await previousPromise;

          return currentJob();
        },
        Promise.resolve()
      );

      resolve(response.data.payload.documents);
    } catch (error) {
      reject(error);
    }
  });

const removeBrainstormDocument = (brainstormId, file, token) => {
  return () =>
    axiosInstance({
      method: 'delete',
      url: `brainstorm/${brainstormId}/document/${file.id}`,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: token,
      },
    });
};

export const removeBrainstormDocuments = (brainstormId, files, token) =>
  new Promise((resolve, reject) => {
    try {
      const jobs = files.map((file) =>
        removeBrainstormDocument(brainstormId, file, token)
      );

      const response = jobs.reduce(
        async (previousPromise, currentPromise, index, array) => {
          const isLast = array.length - 1 === index;

          await previousPromise;

          return !isLast
            ? currentPromise()
            : currentPromise().then((res) => res.data.payload.documents);
        },
        Promise.resolve()
      );

      resolve(response);
    } catch (error) {
      reject(error);
    }
  });

export const inviteBrainstormUsers = (brainstormId, invitation, token) =>
  axiosInstance({
    method: 'post',
    url: `brainstorm/${brainstormId}/user`,
    data: {
      message: invitation.message,
      emails: invitation.list,
    },
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  });
