import * as types from 'Stores/types';

import {
  submitKeywords,
  uploadImage,
  removeImage,
  updateImage,
  uploadAudio,
  getSoundcloud,
  removeAudio,
  updateAudio,
  uploadVideo,
  removeVideo,
  updateVideo,
  submitTypeSomething,
  submitPickColor,
  voteModule,
  requestVote,
  voteSelectOptions,
} from 'Helpers/api/module';

import { promiseChain } from 'Helpers/utils';

export function postKeywords(brainstormId, moduleId, data) {
  return (dispatch, getState) => {
    const state = getState();

    const { data: token } = state.session.token;

    dispatch({
      type: types.POST_KEYWORDS,
    });

    return submitKeywords(brainstormId, moduleId, data, token);
  };
}

export function postUploadImage(
  brainstormId,
  moduleId,
  data,
  progresHandler = () => {},
  cancelToken
) {
  return (dispatch, getState) => {
    const state = getState();

    const { data: token } = state.session.token;

    dispatch({
      type: types.POST_UPLOAD_IMAGE,
    });

    return uploadImage(
      brainstormId,
      moduleId,
      data,
      progresHandler,
      cancelToken,
      token
    );
  };
}

export function deleteUploadImage(brainstormId, moduleId, fileId) {
  return (dispatch, getState) => {
    const state = getState();

    const { data: token } = state.session.token;

    dispatch({
      type: types.DELETE_UPLOAD_IMAGE,
    });

    return removeImage(brainstormId, moduleId, fileId, token);
  };
}

export function putUploadImages(brainstormId, moduleId, files) {
  return (dispatch, getState) => {
    const state = getState();

    const { data: token } = state.session.token;

    dispatch({
      type: types.PUT_UPLOAD_IMAGES,
    });

    return new Promise((resolve) => {
      let jobs = Object.values(files).map((file) => () =>
        updateImage(brainstormId, moduleId, file.id, token)
      );

      promiseChain(jobs).then(resolve);
    });
  };
}

export function postUploadAudio(
  brainstormId,
  moduleId,
  data,
  progresHandler = () => {},
  cancelToken
) {
  return (dispatch, getState) => {
    const state = getState();

    const { data: token } = state.session.token;

    dispatch({
      type: types.POST_UPLOAD_AUDIO,
    });

    return uploadAudio(
      brainstormId,
      moduleId,
      data,
      progresHandler,
      cancelToken,
      token
    );
  };
}

export function fetchSoundcloud(brainstormId, data) {
  return (dispatch, getState) => {
    const state = getState();

    const { data: token } = state.session.token;

    dispatch({
      type: types.POST_SOUNDCLOUD,
    });

    return getSoundcloud(brainstormId, data, token);
  };
}

export function deleteUploadAudio(brainstormId, moduleId, fileId) {
  return (dispatch, getState) => {
    const state = getState();

    const { data: token } = state.session.token;

    dispatch({
      type: types.DELETE_UPLOAD_AUDIO,
    });

    return removeAudio(brainstormId, moduleId, fileId, token);
  };
}

export function putUploadAudios(brainstormId, moduleId, files) {
  return (dispatch, getState) => {
    const state = getState();

    const { data: token } = state.session.token;

    dispatch({
      type: types.PUT_UPLOAD_AUDIOS,
    });

    return new Promise((resolve) => {
      let jobs = Object.values(files).map((file) => () =>
        updateAudio(brainstormId, moduleId, file.id, token)
      );
      promiseChain(jobs).then(resolve);
    });
  };
}

export function postUploadVideo(
  brainstormId,
  moduleId,
  data,
  progresHandler = () => {}
) {
  return (dispatch, getState) => {
    const state = getState();

    const { data: token } = state.session.token;

    dispatch({
      type: types.POST_UPLOAD_VIDEO,
    });

    return uploadVideo(brainstormId, moduleId, data, progresHandler, token);
  };
}

export function deleteUploadVideo(brainstormId, moduleId, fileId) {
  return (dispatch, getState) => {
    const state = getState();

    const { data: token } = state.session.token;

    dispatch({
      type: types.DELETE_UPLOAD_VIDEO,
    });

    return removeVideo(brainstormId, moduleId, fileId, token);
  };
}

export function putUploadVideos(brainstormId, moduleId, files) {
  return (dispatch, getState) => {
    const state = getState();

    const { data: token } = state.session.token;

    dispatch({
      type: types.PUT_UPLOAD_VIDEOS,
    });

    return new Promise((resolve) => {
      let jobs = Object.values(files).map((file) => () =>
        updateVideo(brainstormId, moduleId, file.id, token)
      );

      promiseChain(jobs).then(resolve);
    });
  };
}

export function postTypeSomething(brainstormId, moduleId, data) {
  return (dispatch, getState) => {
    const state = getState();

    const { data: token } = state.session.token;

    dispatch({
      type: types.POST_TYPE_SOMETHING,
    });

    return submitTypeSomething(brainstormId, moduleId, data, token);
  };
}

export function postPickColor(brainstormId, moduleId, data) {
  return (dispatch, getState) => {
    const state = getState();

    const { data: token } = state.session.token;

    dispatch({
      type: types.POST_PICK_COLOR,
    });

    return submitPickColor(brainstormId, moduleId, data, token);
  };
}

export function postVotingModule(brainstormId, moduleId, data) {
  return (dispatch, getState) => {
    const state = getState();

    const { data: token } = state.session.token;

    dispatch({
      type: types.POST_VOTE_MODULE,
    });

    return voteModule(brainstormId, moduleId, data, token);
  };
}

export function postRequestVote(brainstormId, moduleId) {
  return (dispatch, getState) => {
    const state = getState();

    const { data: token } = state.session.token;

    dispatch({
      type: types.POST_REQUEST_VOTE,
    });

    return requestVote(brainstormId, moduleId, token);
  };
}

export function postVoteSelect(brainstormId, moduleId, data) {
  return (dispatch, getState) => {
    const state = getState();

    const { data: token } = state.session.token;

    dispatch({
      type: types.POST_VOTE_SELECT,
    });

    return voteSelectOptions(brainstormId, moduleId, data, token);
  };
}
