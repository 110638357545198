import styled, { css } from 'styled-components';

import { space, layout } from 'styled-system';
import { scrollBar } from 'Mixins/styles';

import { colors } from 'Abstracts/variables';
import { device } from 'Helpers/styling';

import fontStyles from 'Mixins/styles/typography';

export const StyledInput = styled.input`
  border: 0;
  padding: 10px 0px;
  background-color: transparent;
  color: #000000;
  width: 100%;
  resize: none;

  ${({ noPadding }) =>
    !!noPadding &&
    css`
      padding: 0;
    `};

  ${fontStyles.textfield};

  &::placeholder {
    ${fontStyles.textfieldPlaceholder}
  }

  &:focus {
    outline: none;
  }

  &::placeholder {
    ${fontStyles.textfieldPlaceholder}
    color: #999999;
  }

  ${({ transparent }) =>
    !!transparent &&
    css`
      padding: 0;
    `};

  ${({ framed, as }) => css`
    ${framed &&
    css`
      line-height: 19px;
      padding: 10px 1.8em;
      &::placeholder {
        ${device.md`
        text-align: center;
    `}
      }
    `}
    ${as === 'textarea' &&
    css`
      text-align: left;
      padding: 0;
      line-height: 1.65em;
      ${scrollBar}

      color: #666;

      &::placeholder {
        text-align: left;
      }
    `}
  `}
`;

export const MidIconWrapper = styled.div`
  display: inline-block;
  padding: 0 10px 0 5px;
  max-width: 60px;
  svg {
    max-width: 100%;
    cursor: pointer;
  }
`;

export const EndIconWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0 10px 0 5px;
  ${({ textarea }) =>
    textarea &&
    css`
      padding: 0 0 2.5px;
    `}
  max-width: 100%;
  svg {
    max-width: 100%;
    cursor: pointer;
  }
  &:hover {
    svg {
      opacity: 0.5;
    }
  }
`;

export const ErrorWrapper = styled.div`
  ${fontStyles.error}

  color: #c64f4b;
  position: absolute;
  bottom: 0;
  padding-bottom: 7px;
  ${(props) =>
    !!props.framed &&
    `
      padding-left: 5px;
    `}

  ${({ noPaddingContainer }) =>
    !!noPaddingContainer &&
    css`
      position: relative;
    `};
`;

export const SuccessWrapper = styled.div`
  ${fontStyles.success}

  color: #2ee080;
  line-height: 1;
  position: absolute;
  bottom: 0;
  padding-bottom: 7px;
  ${(props) =>
    !!props.framed &&
    `
      padding-left: 5px;
    `}

  ${({ noPaddingContainer }) =>
    !!noPaddingContainer &&
    css`
      position: relative;
    `};
`;

export const InputContainer = styled.div`
  display: inline-flex;
  flex-direction: column;
  position: relative;
  padding-bottom: 26.5px;
  max-width: 100%;

  ${layout}

  & .error-icon {
    position: absolute;
    right: 4px;
  }

  ${({ noPaddingContainer, transparent }) =>
    (!!noPaddingContainer || !!transparent) &&
    css`
      padding-bottom: 0;
    `};
`;

export const InputWrapper = styled.div`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 2px solid #999999;

  width: 100%;

  ${space};

  ${({ transparent, framed, focusing, focusError, textarea, noPadding }) => css`
    ${framed &&
    css`
      border-radius: 20px;
      border: solid 1px ${colors.grey5};
      background-color: ${colors.light};
    `};

    ${focusing &&
    framed &&
    css`
      border: 1px solid #622de3;
    `};

    ${focusError &&
    framed &&
    css`
      border: 1px solid #c64f4b;
    `};

    ${focusing &&
    !framed &&
    css`
      border-bottom: 2px solid #622de3;
    `};

    ${focusError &&
    !framed &&
    css`
      border-bottom: 2px solid #c64f4b;
    `};
    ${transparent &&
    css`
      border: 0;
    `};

    ${textarea &&
    css`
      background-color: transparent;
      padding: 14px 16px;
      display: inline-block;
      border: 1px solid #666;
      border-radius: 10px;
      color: #666;
      ${noPadding &&
      css`
        background-color: transparent;
        padding: 14px 0;
        display: inline-block;
      `};
      ${focusError &&
      css`
        border-color: #c64f4b;
      `};
    `};
  `}
`;
