import { useState, useEffect, useRef, useLayoutEffect } from 'react';

function getBoundingClientRect(element) {
  let rect = element.getBoundingClientRect();
  return {
    top: rect.top,
    right: rect.right,
    bottom: rect.bottom,
    left: rect.left,
    width: rect.width,
    height: rect.height,
    x: rect.x,
    y: rect.y,
  };
}

export default function useDimensions() {
  const ref = useRef(null);
  const [dimensions, setDimensions] = useState({});

  function handleResize() {
    if (ref.current) {
      setDimensions(getBoundingClientRect(ref.current));
    }
  }

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return [ref, dimensions];
}
