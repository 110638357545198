import React from 'react';
import { Row } from 'Components/Elements/Grid';
import { ReactComponent as Logo } from 'Assets/images/logo-white.svg';

export default function TransparentHeader() {
  return (
    <Row
      span={{ _: 12 }}
      bg="transparent"
      mx="0"
      minHeight="80px"
      style={{ zIndex: 100 }}
      padding={{ md: '10px 20px 10px 50px' }}
    >
      <Logo />
    </Row>
  );
}
