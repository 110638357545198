import React, { useRef, useEffect, useState, useCallback } from 'react';

import styled from 'styled-components';

import validator from 'Helpers/validations';

import { ReactComponent as ErrorIcon } from 'Assets/images/error.svg';
import { ReactComponent as CheckIcon } from 'Assets/images/check-green.svg';

import {
  StyledInput,
  MidIconWrapper,
  EndIconWrapper,
  ErrorWrapper,
  SuccessWrapper,
  InputContainer,
  InputWrapper,
} from './Input.styles';

const Input = React.forwardRef((props, ref) => {
  let {
    type = 'text',
    value = '',
    prefixIcon = null,
    postfixIcon = null,
    textarea = false,
    rules,
    onFocus = () => false,
    onBlur = () => false,
    onPaste,
    onChange,
    onEnter,
    onError = () => false,
    error = null,
    success = null,
    focusing = false,
    validateOnMount = false,
    debounce = {
      mode: 'all',
      interval: 500,
    },
    width = '14em',
    noPadding,
    noPaddingContainer,
    framed,
    margin,
    padding,
    bg,
    transparent = false,
    ...rest
  } = props;

  debounce =
    debounce.mode === 'all' && !!onEnter
      ? { ...debounce, mode: 'validation' }
      : debounce;

  const debounced = debounce.mode !== 'none' && debounce.interval >= 0;

  const styleProps = { width, margin, padding };
  const inputProps = { type };
  let extraProps = !textarea ? inputProps : {};
  if ((!debounced || debounce.mode !== 'all') && onChange !== undefined) {
    extraProps = { ...extraProps, value };
  }

  const [isFocus, setFocus] = useState(focusing);

  const targetRef = useRef(null);
  const timer = useRef(null);
  const timerCb = useRef(null);

  useEffect(() => {
    if (!ref) return;

    if (typeof ref === 'function') {
      ref(targetRef.current);
    } else {
      ref.current = targetRef.current;
    }
  }, [ref]);

  // Assigning with new value
  useEffect(() => {
    if (
      debounced &&
      debounce.mode === 'all' &&
      value !== targetRef.current.value
    ) {
      if (rules) validator(rules, value, handleError);
      targetRef.current.value = value;
    }
  }, [value]);

  const handleChange = (event) => {
    let oldEvent = { ...event };

    if (!debounced) {
      if (onChange !== undefined) onChange(event);
      if (rules) validator(rules, targetRef.current.value, handleError);
    } else if (oldEvent.nativeEvent.inputType === 'insertFromPaste') {
      if (onChange !== undefined) onChange(oldEvent);
      if (rules) validator(rules, targetRef.current.value, handleError);
    } else {
      if (debounce.mode === 'validation' && onChange !== undefined) {
        onChange(oldEvent);
      }

      // Remove timeout as it is still typing
      if (timer.current !== null) {
        clearTimeout(timer.current);
      }
      // Add new timeout for 1 sec later, until it stop typing
      timerCb.current = () => {
        if (debounce.mode !== 'validation' && onChange !== undefined) {
          onChange(oldEvent);
        }
        if (rules) validator(rules, targetRef.current.value, handleError);
      };

      timer.current = setTimeout(timerCb.current, debounce.interval);
    }
  };

  const handleFocus = useCallback(
    (event) => {
      onFocus(event);
      setFocus(true);
    },
    [onFocus, setFocus]
  );

  const handleBlur = useCallback(
    (event) => {
      if (debounced && timer.current) {
        // Clear input debounce
        clearTimeout(timer.current);
        timerCb.current();
      }

      onBlur(event);
      setFocus(false);
    },
    [onBlur, setFocus]
  );

  const handlePaste = useCallback(
    (event) => {
      if (onPaste) {
        onPaste(event);
      }
    },
    [onPaste]
  );

  const handleKeyDown = useCallback(
    (event) => {
      if (event.keyCode === 13 && !textarea) {
        if (debounced && timer.current) {
          // Clear input debounce
          clearTimeout(timer.current);
          timerCb.current();
        }

        if (onEnter) {
          onEnter(event);
        }
      }
    },
    [onEnter]
  );

  const handleError = useCallback(
    (params) => {
      if (typeof params === 'object') {
        if (params === null) {
          // No error
          onError(false);
        } else {
          // Error with message
          onError(params);
        }
      } else {
        // error without message
        onError(!!params);
      }
    },
    [onError]
  );

  useEffect(() => {
    if (!!validateOnMount && rules)
      validator(rules, targetRef.current.value, handleError);

    if (debounced) {
      return () => timer.current !== null && clearTimeout(timer.current);
    }
  }, []);

  return (
    <InputContainer
      noPaddingContainer={noPaddingContainer}
      transparent={transparent}
      {...styleProps}
    >
      <InputWrapper
        framed={framed}
        focusing={isFocus}
        textarea={textarea}
        focusError={error}
        style={{ backgroundColor: bg }}
        transparent={transparent}
        noPadding={noPadding}
      >
        {!!prefixIcon && <MidIconWrapper>{prefixIcon}</MidIconWrapper>}
        <StyledInput
          ref={targetRef}
          framed={framed}
          noPadding={noPadding}
          onKeyDown={handleKeyDown}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onPaste={handlePaste}
          transparent={transparent}
          as={!textarea ? 'input' : 'textarea'}
          {...extraProps}
          {...rest}
        />
        {!textarea && !!error && <ErrorIcon className="error-icon" />}
        {!!postfixIcon && !textarea && !error && (
          <MidIconWrapper>{postfixIcon}</MidIconWrapper>
        )}
        {!!postfixIcon && !!textarea && (
          <EndIconWrapper textarea={textarea}>{postfixIcon}</EndIconWrapper>
        )}
      </InputWrapper>
      {!!error && !!error.message && (
        <ErrorWrapper noPaddingContainer={noPaddingContainer} framed={framed}>
          {error.message}
        </ErrorWrapper>
      )}
      {!!success && !!success.message && (
        <SuccessWrapper noPaddingContainer={noPaddingContainer} framed={framed}>
          {success.message}
        </SuccessWrapper>
      )}
    </InputContainer>
  );
});

Input.displayName = 'Input';

export default styled(Input)``;
