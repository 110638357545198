import { useState, useEffect, useCallback, useRef, useMemo } from 'react';

export default function useSticky(direction, payload, hideBeforeReach = false) {
  const ref = useRef(null);

  const [position, setPosition] = useState('relative');

  const handleScroll = useCallback(() => {
    if (!ref.current) return;

    let positionValue;

    let checker = document.createElement('div');
    checker.style.width = '100%';

    ref.current.parentNode.insertBefore(checker, ref.current);

    if (checker.offsetTop - window.scrollY - payload <= 0) {
      positionValue = 'fixed';
    } else {
      positionValue = 'relative';
    }

    ref.current.parentNode.removeChild(checker);

    setPosition(positionValue);
  }, [position, direction, payload]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  const style = useMemo(
    () => ({
      transition: hideBeforeReach ? `transform .2s ease-in-out .2s` : '',
      [direction]: hideBeforeReach || position === 'fixed' ? payload : 0,
      transform:
        position !== 'fixed'
          ? 'translateY(calc(-100% - 20px))'
          : 'translateY(0)',
      position: hideBeforeReach ? 'fixed' : position,
    }),
    [direction, payload, position]
  );

  return [ref, style];
}
