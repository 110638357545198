import Cookies from 'js-cookie';
import axiosInstance from 'Helpers/api/_instance';

const key = {
  participant: 'userToken',
  moderator: 'modToken',
};

export const saveToken = (data, type = 'participant', save = false) => {
  if (!data) {
    Cookies.remove(key[type]);
  } else {
    let cookiesOption = {
      sameSite: 'strict',
      secure: process.env.TARGET_ENV !== 'development',
    };

    if (save) {
      cookiesOption.expires = 14;
    }

    Cookies.set(key[type], data, cookiesOption);
  }

  return data;
};

export const checkToken = (type = 'participant') => {
  let token;

  token = Cookies.get(key[type]);

  return token || null;
};

export const logIn = async (user) => {
  try {
    const response = await axiosInstance({
      method: 'post',
      url: `login`,
      data: {
        email: user.email,
        password: user.password,
      },
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const signUp = (form) =>
  axiosInstance({
    method: 'post',
    url: `signup`,
    data: form,
  });

export const checkAvailable = async (input, type) => {
  if (!input) return;

  try {
    const response = await axiosInstance({
      method: 'post',
      url: `check-available`,
      data: {
        username: type === 'username' ? input : '',
        email: type === 'email' ? input : '',
      },
    });
    return response.data.payload;
  } catch (error) {
    return error.response.data;
  }
};

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const isAvailable = async (value, type) => {
  if (!value) return;

  // await sleep(500);
  const res = await checkAvailable(value, type);

  switch (type) {
    case 'email':
      if (res.email === 'not available') {
        return false;
      }
      break;
    case 'username':
      if (res.username === 'not available') return false;
      break;
  }

  return true;
};

export const isReg = async (value, type) => {
  if (!value) return;

  // await sleep(500);
  const res = await checkAvailable(value, type);

  if (res.email === 'not available') {
    return true;
  }
  return false;
};

export const loginWithGoogle = async (token) => {
  try {
    const response = await axiosInstance({
      method: 'post',
      url: `google/login`,
      data: {
        token,
      },
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const loginWithFacebook = async (token) => {
  try {
    const response = await axiosInstance({
      method: 'post',
      url: `fb/login`,
      data: {
        token,
      },
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const signUpConfirm = async (token) => {
  try {
    const response = await axiosInstance({
      method: 'post',
      url: `signup/confirm`,
      data: {
        token,
      },
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const forget = async (email) => {
  try {
    const response = await axiosInstance({
      method: 'post',
      url: `forget`,
      data: {
        email,
      },
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const forgetConfirm = async (token, password) => {
  try {
    const response = await axiosInstance({
      method: 'post',
      url: `forget/reset`,
      data: {
        token,
        password,
      },
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const changePassword = async (token, oldPassword, newPassword) => {
  try {
    const response = await axiosInstance({
      method: 'put',
      url: `user/password`,
      headers: { Authorization: token },
      data: {
        oldPassword,
        newPassword,
      },
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
