import React from 'react';
import ContentLoader from 'react-content-loader';

const ProjectCardSkeleton = (props) => (
  <ContentLoader
    speed={2}
    width={680}
    height={376}
    viewBox="0 0 680 376"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="0" y="0" rx="40" ry="40" width="680" height="376" />
  </ContentLoader>
);

export default ProjectCardSkeleton;
