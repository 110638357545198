import React, { useRef, useState, useEffect } from 'react';

import hark from 'hark';

import { logError } from '@/sentry';

const withRTC = (Component) => (props) => {
  const { track, unattending, muted, ...rest } = props;

  const audioElem = useRef(null);
  const harkInstance = useRef(null);
  const [volume, setVolume] = useState(0);

  useEffect(() => {
    if (track) {
      const stream = new MediaStream();

      stream.addTrack(track);
      audioElem.current.srcObject = stream;

      audioElem.current.play().catch(logError);

      harkInstance.current = hark(stream, { play: false });

      harkInstance.current.on('volume_change', (dBs) => {
        let audioVolume = Math.round(Math.pow(10, dBs / 85) * 10);

        if (audioVolume === 1) audioVolume = 0;

        if (audioVolume !== volume) setVolume(audioVolume);
      });
    } else {
      audioElem.current.srcObject = null;
      if (harkInstance.current) harkInstance.current.stop();
    }
  }, [track]);

  useEffect(() => {
    if (track) {
      if (unattending) {
        track.enabled = false;
      } else {
        track.enabled = true;
      }
    }
  }, [unattending]);

  useEffect(() => {
    return () => {
      if (harkInstance.current) harkInstance.current.stop();
    };
  }, []);

  return (
    <>
      <audio ref={audioElem} autoPlay playsInline controls={false} />
      <Component
        volume={!muted && !unattending ? volume : 0}
        muted={muted}
        unattending={unattending}
        {...rest}
      />
    </>
  );
};

export default withRTC;
