import React from 'react';

import styled from 'styled-components';

import { rgba } from 'polished';

import Ripple, { useRipple } from 'Components/Elements/Ripple';

import { colors } from 'Abstracts/variables';

import { StyledButton, ButtonIcon } from './Button.styles';

const Button = (props) => {
  const {
    children,
    ripple = { color: rgba(colors.dark, 0.25), duration: 500 },
    startIcon = null,
    onMouseDown = () => {},
    ...rest
  } = props;

  const [ripples, addRipple, cleanRipples] = useRipple();

  return (
    <StyledButton
      onMouseDown={(event) => {
        onMouseDown(event);
        addRipple(event);
      }}
      {...rest}
    >
      {!!startIcon && <ButtonIcon>{startIcon}</ButtonIcon>}
      {children}
      <Ripple
        color={ripple.color}
        duration={ripple.duration}
        ripples={ripples}
        cleanRipples={cleanRipples}
      />
    </StyledButton>
  );
};

export default styled(Button)``;
