import styled, { css } from 'styled-components';

import { colors } from 'Abstracts/variables';

import { lighten } from 'polished';
import { device } from 'Helpers/styling';

import Card from 'Components/Elements/Card';

import { width } from 'styled-system';

export const DataWrapper = styled.div`
  margin: 0 auto;
`;

export const UtilContainer = styled.div`
  flex-direction: row;
  align-content: center;
  justify-content: flex-end;
  display: inline-flex;
  align-self: flex-end;
  max-width: 100%;
  background: ${colors.light};
  ${width}
`;

export const OverflowContainer = styled.div`
  position: absolute;
  z-index: -1;
`;

export const SearchWrapper = styled.label`
  ${device.lg`
    margin: 0 5px;
  `};

  width: 100%;

  ${device.lg`
    width: auto;
  `}

  display: inline-flex;
  border-radius: 20px;
  border: solid 1px #666666;
  z-index: 10;
  min-width: 32px;
  height: 32px;
  margin: ${({ activated }) =>
    !!activated &&
    css`
      width: 100%;
      border-color: ${colors.secondary};
      svg {
        g {
          stroke: ${colors.secondary};
        }
      }
    `};
`;

export const RemoveWrapper = styled.label`
  border-radius: 20px;
  border: solid 1px #666666;
  z-index: 10;

  margin: 0 5px;
  ${({ activated }) =>
    !!activated &&
    css`
      border-color: ${colors.secondary};
      svg {
        g {
          fill: ${colors.secondary};
        }
      }
    `}

  margin-left: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  padding-left: 10px;
  padding-right: 10px;

  ${device.lg`
    display: inline-flex;
    width: 32px;
    height: 32px;
    padding-left: 0;
    padding-right: 0;
  `}
`;

export const FilterWrapper = styled.label`
  display: inline-flex;
  margin-left: 10px;
  overflow: hidden;
  position: relative;
  ${device.lg`
    margin-left: 40px;
    overflow: visible;
  `};

  select {
    position: absolute;
    opacity: 0;
    top: 0;
    right: 0;
  }

  svg {
    cursor: pointer;
    width: 32px;
    height: 32px;
    ${device.md`
      width: 36px;
      height: 36px;
    `};
  }
`;

export const Filter = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  [class*='-container'] {
    min-width: 160px;
  }

  [class*='-control'] {
    height: 32px;
    min-height: 32px;
    border-radius: 20px;
    border: solid 1px #707070;
    background-color: #ffffff;
    padding-left: 16px;

    &:focus {
      border-radius: 20px;
      border: solid 1px #622de3;
      background-color: #ffffff;
      padding-left: 16px;
    }
    > div:first-child {
      padding: 0;
    }
  }

  [class*='-indicatorContainer'] {
    margin: 0 7.5px 0 0;
    svg {
      width: 18px;
      height: 9px;
    }
  }

  ${device.lg`
    max-width: 300px;
  `}
`;

export const CTA = styled(Card)`
  width: 100%;
  background-color: ${colors.secondary};
  border-radius: 15px;
  height: 192px;
  justify-content: center;
  position: relative;
  cursor: 'pointer';
  transition: background-color 0.2s ease-in-out;
  &:hover {
    background-color: ${lighten(0.05, colors.secondary)};
  }
`;

export const MobileCTA = styled.div`
  background-color: ${colors.secondary};
  width: 100vw;
  left: 0;
  box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.16);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 0;
  svg {
    display: inline-block;
  }
`;

export const SkeletonWrapper = styled(Card)`
  svg {
    width: 100%;
    height: auto;
  }
`;

export const SessionListTop = styled(Card)`
  transition: box-shadow 0.2s ease-in-out;
  border-radius: 30px 30px 0 0;
  flex-shrink: 0;
`;

export const NoFoundContainter = styled.div`
  width: 100%;
  height: calc(100vh - 80px - 80px - 110px);
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledMessageBox = styled.div`
  padding: 22px 28px;
  border-radius: 10px;
  border: solid 1px #7945fa;
`;
