import { useState, useEffect, useCallback, useMemo, useRef } from 'react';

const getCurrentScrollPostion = () =>
  window.scrollY || window.pageYOffset || document.documentElement.scrollTop;

export default function useScroll() {
  const [scrollInfo, setScrollInfo] = useState({
    direction: 0,
    position: getCurrentScrollPostion(),
  });

  const prevScrollDirection = useRef(0);
  const prevScrollPostion = useRef(getCurrentScrollPostion());

  useEffect(() => {
    prevScrollPostion.current = scrollInfo.position;
  }, [scrollInfo.position]);

  useEffect(() => {
    prevScrollDirection.current = scrollInfo.position;
  }, [scrollInfo.direction]);

  const scrollHandler = useCallback(() => {
    const currentScrollPostion = getCurrentScrollPostion();
    let currentScrollDirection = prevScrollDirection.current;

    if (currentScrollPostion !== prevScrollPostion.current) {
      if (currentScrollPostion > prevScrollPostion.current) {
        currentScrollDirection = 1;
      } else if (currentScrollPostion < prevScrollPostion.current) {
        currentScrollDirection = -1;
      }
    }

    setScrollInfo({
      direction: currentScrollDirection,
      position: currentScrollPostion,
    });
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', scrollHandler);
    return () => {
      window.removeEventListener('scroll', scrollHandler);
    };
  }, []);

  const output = useMemo(
    () => ({
      scrollPositon: scrollInfo.position,
      scrollDirection: scrollInfo.direction,
    }),
    [scrollInfo]
  );

  return output;
}
