import { useDispatch } from 'react-redux';

import i18n from '@/i18n';

import { ReactComponent as PickColorIcon } from 'Assets/images/modules/pick-color.svg';

import { postPickColor } from 'Stores/actions/module';

import { objectToForm } from 'Helpers/data-transform';
import { basicFields } from './_common';

const parseData = ({ data }) =>
  data.modulePickColor.data.reduce(
    (accumulator, currentUser) => [...accumulator, currentUser.color],
    []
  );

const getHandler = ({ brainstormId, data }) => {
  const dispatch = useDispatch();

  const handler = (value) => {
    const payload = objectToForm({
      color: value,
    });

    dispatch(postPickColor(brainstormId, data.id, payload));
  };

  return handler;
};

const getAnswer = (datum) => datum;

const type = {
  name: 'pickColor',
  obj: i18n.t('Collect color suggestions'),
  info: i18n.t(
    'This module is designed to help you find a color(s) for your project.'
  ),
  icon: PickColorIcon,
  label: i18n.t('Color Storm'),
  unit: i18n.t('color'),
  endpoint: 'pick-color',
  color: '#3D1D8B',
  getHandler,
  parseData,
  getAnswer,
  fields: [...basicFields],
  methodologies: [
    {
      label: 'None',
      value: 'none',
      description: i18n.t(`When you think of____, what color comes to mind?`),
    },
  ],
  compliment: [
    i18n.t('Every color tells a story.'),
    i18n.t('Explore our palette.'),
    i18n.t('Swatch do you think of these colors?'),
    i18n.t('That paints a pretty picture.'),
    i18n.t('Technicolor terrific!'),
  ],
  listed: true,
};

export default type;
