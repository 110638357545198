import React from 'react';

import filesize from 'filesize';

// Elements
import { Row, Col } from 'Components/Elements/Grid';
import Card from 'Components/Elements/Card';
import Text from 'Components/Elements/TypeSet/Text';

// Icons
import { ReactComponent as DownloadIcon } from 'Assets/images/icons/download.svg';
import { ReactComponent as DocumentIcon } from 'Assets/images/document.svg';

export default function ChatAsset(props) {
  const { url, fileSize, reverse } = props;

  const getShorterText = (string) => {
    if (string.length < 38) return string;

    return `${string.substring(0, 18)} .... ${string.substring(
      string.length - 6,
      string.length
    )}`;
  };

  return (
    <Row
      mx="0"
      mb="34px"
      width="220px"
      flexDirection={reverse ? 'row-reverse' : 'row'}
    >
      <Col span={{ _: 2 }} alignItems="center" paddingTop="6px">
        <a href={url} download target="_blank">
          <DownloadIcon style={{ cursor: 'pointer' }} />
        </a>
      </Col>
      <Col span={{ _: 10 }} p="0">
        <Card fullWidth bg="#e1e1e1" borderRadius="10px" p="0">
          <Row mx="0">
            <Col span={{ sm: 4, _: 12 }} alignItems="center">
              <DocumentIcon />
              <Text variant="text" color="#666666" mt="6px">
                File
              </Text>
            </Col>
            <Col span={{ sm: 8, _: 12 }}>
              <Text
                style={{ overflowWrap: 'anywhere' }}
                variant="paragraph"
                color="#666666"
                fontWeight="500"
              >
                {getShorterText(url.substring(93))}
              </Text>
            </Col>
          </Row>
          <Row mx="0">
            <Col span={{ _: 12 }}>
              <Text variant="text" color="#666666">
                {filesize(fileSize)}
              </Text>
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
}
