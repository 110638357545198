import { isFilled } from 'Helpers/validations';

const isFilledEither = (eitherValue) => (value) => {
  if (isFilled(eitherValue) !== false) {
    return true;
  }
  return isFilled(value);
};

export default isFilledEither;
