import styled, { css } from 'styled-components';

import Overlay from 'Components/Elements/Overlay';

const OverlayWithTransition = styled(Overlay)`
  ${({ transitionStatus }) =>
    !!transitionStatus &&
    transitionStatus === 'exited' &&
    css`
      pointer-events: none;
      height: 0;
      overflow: hidden;
    `};

  &:before {
    transition: opacity ${({ transitionDuration }) => transitionDuration}ms
      ease-in-out;
    opacity: 0;

    ${({ transitionStatus }) =>
      !!transitionStatus &&
      (transitionStatus === 'entered' || transitionStatus === 'exiting') &&
      css`
        opacity: 1;
      `};
  }
`;

export default OverlayWithTransition;
