import dayjs from 'dayjs';

import customParseFormat from 'dayjs/plugin/customParseFormat';

import plans from 'Abstracts/plans';

dayjs.extend(customParseFormat);

const TRIAL_DAYS = 30;

const getUserPemission = (user) => {
  const notAccessible = !user;

  const errorAccount = !notAccessible && user.plan === '';

  const inactivated = !notAccessible && !user.isPlanActive;

  const freeTrialEned =
    !notAccessible &&
    user.plan === plans.SPARK.name &&
    dayjs(user.freePlanStartDate.slice(0, 19), 'YYYY-MM-DDTHH:mm:ss')
      .add(TRIAL_DAYS, 'day')
      .isBefore(dayjs(), 'day');

  if (notAccessible || errorAccount) {
    return {
      plan: 'none',
      valid: false,
    };
  }

  return {
    plan: user.plan,
    valid: !(inactivated || freeTrialEned),
  };
};

export default getUserPemission;
