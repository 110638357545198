import React from 'react';
import PropTypes from 'prop-types';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { langPath } from '@/i18n';
import Locale from '@/Locale';

import WebFont from 'webfontloader';
import 'proxy-polyfill/proxy.min.js';

import ToastContainer from 'Components/Elements/Toast';
import ZendeskChat from 'Components/ZendeskChat';

import { checkToken } from 'Helpers/api/auth';
import Frames from 'Components/Frames';

import { setToken, setAuthenticate } from 'Stores/actions/auth';
import { setParticipantToken } from 'Stores/actions/session';
import { getProfilePicture, setUser } from 'Stores/actions/user';
import { getCust, getCard } from 'Stores/actions/payment';

import { hot } from 'react-hot-loader/root';

import GlobalStyle from './GlobalStyle';

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setToken,
      setParticipantToken,
      setUser,
      getCust,
      getCard,
      setAuthenticate,
      getProfilePicture,
    },
    dispatch
  );

const isDev = process.env.TARGET_ENV === 'development';

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ready: false,
    };

    this.loadWebFont = this.loadWebFont.bind(this);
  }

  componentDidMount() {
    this.validateUser();

    this.loadWebFont();

    this.setState({
      ready: true,
    });
  }

  validateUser() {
    const modToken = checkToken('moderator', 'cookies');

    if (modToken) {
      Promise.all([
        this.props.setToken(modToken),
        this.props.setAuthenticate(true),
      ]).then(() => {
        this.props
          .setUser()
          .then((response) => {
            this.props.getCust();
            this.props.getCard();
            this.props.getProfilePicture(response.id);
          })
          .catch(() => {
            this.props.setToken(null), this.props.setAuthenticate(false);
          });
      });
    }

    const userToken = checkToken();

    if (userToken && !modToken) {
      this.props.setParticipantToken(userToken);
    }
  }

  loadWebFont() {
    return WebFont.load({
      google: {
        families: ['Montserrat', 'Roboto:400,500,700', 'Abril Fatface'],
      },
    });
  }

  render() {
    const { ready } = this.state;
    const { history } = this.props;

    return (
      ready && (
        <Router basename={process.env.WEB_BASE} history={history}>
          <Switch>
            <Route
              path={langPath}
              render={({
                match: {
                  params: { locale },
                },
              }) => (
                <Locale lang={locale}>
                  <Helmet>
                    <title>{process.env.SITE_TITLE}</title>
                    <meta
                      name="description"
                      content={`${process.env.SITE_TITLE} application`}
                    />
                  </Helmet>

                  <GlobalStyle />
                  <ToastContainer />
                  <Frames />
                  <ZendeskChat />
                </Locale>
              )}
            />
          </Switch>
        </Router>
      )
    );
  }
}

App.propTypes = {
  setToken: PropTypes.func.isRequired,
  setUser: PropTypes.func.isRequired,
};

export default connect(null, mapDispatchToProps)(isDev ? hot(App) : App);
