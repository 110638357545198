import styled, { css } from 'styled-components';

import { device } from 'Helpers/styling';
import { colors } from 'Abstracts/variables';

import ScrollView from 'Components/Elements/ScrollView';
import Image from 'Components/Elements/Image';
import Icon from 'Components/Elements/Icon';
import Input from 'Components/Elements/Form/Input';

import { ReactComponent as SendIcon } from 'Assets/images/icons/send.svg';

export const ChatWrapper = styled.div`
  height: 100%;
  width: 100%;
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  /* padding-top: 20px; */
  ${device.md`
    padding-top: 40px;
  `}
  ${({ transparent }) => css`
    ${transparent &&
    css`
      background: none;
    `}
  `};
`;

export const ChatMessage = styled(ScrollView)`
  padding: 0 10px 0 10px;

  ${device.sm`
    padding: 0 20px;
  `}

  > div {
    &:last-of-type {
      padding-top: 40px;
    }
  }
`;

export const ChatInputWrapper = styled.div`
  position: relative;
  /* margin: 0 -20px 0 0; */
  ${device.sm`
    margin: 30px 0 0;
    padding: 0 0 0 30px;
  `}
  ${({ transparent }) => css`
    ${transparent &&
    css`
      ${device.sm`
    margin: 15px 0 0;
  `}
      ${Icon} svg {
        circle {
          fill: #fff;
        }
        g {
          fill: none;
        }
        path {
          stroke: #fff;
        }
        & > path {
          fill: #fff;
        }
      }
    `}
  `};
`;

export const ChatInput = styled(Input)``;

export const ChatBubbleIn = styled.div`
  background-color: #fff;
  border-radius: 0 10px 10px 10px;
  margin-bottom: 34px;
  margin-top: 5px;
  padding: 10px;
  text-align: left;
  align-self: flex-start;
  max-width: 300px;
  white-space: pre-wrap;
  overflow-wrap: break-word;
`;

export const ChatBubbleOut = styled.div`
  background-color: #bee8ff;
  border-radius: 10px 10px 0 10px;
  margin-bottom: 34px;
  padding: 10px;
  text-align: right;
  align-self: flex-end;
  max-width: 300px;
  white-space: pre-wrap;
  overflow-wrap: break-word;
`;

export const Separator = styled.div`
  ${device.sm`
    padding: 0 15px;
    `}
  padding: 5px 10px;

  & hr {
    background-color: #622de3;
    height: 2px;
    ${({ transparent }) => css`
      ${transparent &&
      css`
        background-color: #fff;
      `}
    `};
  }
`;

export const InsideWrapper = styled.div`
  /* align-self: flex-end; */
  /* flex-direction: column; */
  /* display: flex; */
  /* margin-left: 10px; */
  /* align-items: flex-start; */
`;

export const SentMessage = styled.div`
  /* display: flex;
  align-self: flex-end;
  flex-direction: column; */
  text-align: right;
`;

export const ReceivedMessage = styled.div`
  /* display: flex;
  align-self: flex-start; */
  text-align: left;
`;

export const ImageWrapper = styled(Image)`
  max-width: 100%;
  /* max-height: 100%; */
  object-fit: contain;
  /* border-radius: 10px; */
  align-self: center;
`;

export const ThumbnailWrapper = styled(Image)`
  border-radius: 10px;
  cursor: pointer;
  margin-bottom: 34px;
  max-width: 80%;
  align-self: flex-start;
  ${({ rightAligned }) => css`
    ${rightAligned &&
    css`
      align-self: flex-end;
    `}
  `};
`;

export const PopupWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  transform: translateY(-100%);
  width: 100%;
  padding: 10px 2px 2px;
  z-index: 100;
`;

export const SendIconWrapper = styled(Icon)`
  background-color: ${colors.light};
  border: 1px solid ${colors.grey5};
  svg {
    path {
      stroke: ${colors.secondary} !important;
    }
  }
`;
