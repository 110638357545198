import styled, { css } from 'styled-components';

import { compose, space, layout, border, color } from 'styled-system';

export const IconWrapper = styled.div`
  display: inline-flex;
  border-radius: ${(props) => (props.rounded ? '100%' : 0)};
  overflow: hidden;
  mask-image: -webkit-radial-gradient(white, black);
  box-sizing: border-box;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  cursor: ${(props) => (props.onClick ? 'pointer' : 'normal')};
  ${(props) =>
    props.onClick &&
    css`
      &:before {
        content: ' ';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: ${({ hoverColor }) => hoverColor};
        opacity: 0;
        transition: opacity 0.2s ease-in-out;
      }
      &:hover {
        &:before {
          opacity: 1;
        }
      }
    `};
  ${compose(space, color, layout, border)};
`;
