import decode from 'lean-he/decode';

const objectHtmlDecode = (obj) => {
  if (typeof obj !== 'object') {
    return obj;
  }

  let output = { ...obj };

  Object.keys(output).forEach((k) => {
    if (
      typeof output[k] === 'object' &&
      output[k] !== null &&
      !Array.isArray(output[k])
    ) {
      output[k] = objectHtmlDecode(output[k]);
    } else if (Array.isArray(output[k])) {
      output[k] = output[k].map((item) => objectHtmlDecode(item));
    } else if (typeof output[k] === 'string') {
      output[k] = decode(output[k]);
    }
  });

  return output;
};

export default objectHtmlDecode;
