import React from 'react';
import styled, { css } from 'styled-components';
import shouldForwardProp from '@styled-system/should-forward-prop';

import {
  space,
  layout,
  flexbox,
  system,
  border,
  color,
  position,
} from 'styled-system';

const Component = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-right: -15px;
  margin-left: -15px;
  max-height: 100%;
  flex-shrink: 0;
  ${color}
  ${space}
  ${border}
  ${layout}
  ${flexbox}
  ${position}
  ${system({
    opacity: true,
    transition: true,
    userSelect: true,
  })}  

  ${({ purpleBottomBorder }) =>
    purpleBottomBorder &&
    css`
      border-bottom: 2px solid #622de3;
    `}
`;

const ComponentWithProps = styled(Component).withConfig({
  shouldForwardProp,
})(layout);

const Row = (props) => {
  const { children, ...rest } = props;

  return <ComponentWithProps {...rest}>{children}</ComponentWithProps>;
};

export default Row;
