/* eslint-disable no-multi-assign */

import {
  breakpoints as respponsiveBreakpoints,
  colors,
  fonts,
} from 'Abstracts/variables';

let breakpoints = [];

breakpoints.xxs = breakpoints[0] = respponsiveBreakpoints.xxs;
breakpoints.xs = breakpoints[1] = respponsiveBreakpoints.xs;
breakpoints.sm = breakpoints[2] = respponsiveBreakpoints.sm;
breakpoints.md = breakpoints[3] = respponsiveBreakpoints.md;
breakpoints.lg = breakpoints[4] = respponsiveBreakpoints.lg;
breakpoints.xl = breakpoints[5] = respponsiveBreakpoints.xl;

export default {
  breakpoints,
  colors,
  fonts,
};
