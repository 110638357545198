import React, { useEffect } from 'react';

import TagManager from 'react-gtm-module';
import track from 'react-tracking';

const trackingData = { app: process.env.GTM_APP_NAME };

const trackingOption = {
  dispatch: (data) => {
    (window.dataLayer = window.dataLayer || []).push(data);
  },
};

const withTracker = (Component) =>
  track(
    trackingData,
    trackingOption
  )((props) => {
    useEffect(() => {
      TagManager.initialize({
        gtmId: process.env.GTM_ID,
        dataLayerName: 'dataLayer',
      });
    }, []);

    return <Component {...props} />;
  });

export default withTracker;
