import { createGlobalStyle } from 'styled-components';
import { device } from 'Helpers/styling';
import { error, regularGrey, smallGreyItalic } from 'Abstracts/fonts';
import { scrollBar } from 'Mixins/styles';

const GlobalStyle = createGlobalStyle`
    html, body{
        width: 100%;
    }

    [data-tippy-root] {
        z-index: 1004 !important;
    }

    .custom-react-select__menu {
        z-index: 999999;
    } 

    html {
        box-sizing: border-box;
        *, *:before, *:after {
        box-sizing: inherit;
        }
    }

    body {
        padding: 0;
        margin: 0;
        font-family: 'Roboto', sans-serif;
        line-height: 1.5;
    }

    html, body, div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, code, del, dfn, em, img, q, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td {
        margin: 0;
        padding: 0;
        border: 0;
        font-weight: inherit;
        font-style: inherit;
        font-size: 100%;
        vertical-align: baseline;
    }

    p {
        margin-top: 0;
        margin-bottom: 1.2rem;
    }

    ul {
        list-style:none
    }

    h1 {
        font-family: 'Abril Fatface', cursive;
        color: white;
    }

    h2 {
        color: black;
    }

    h3 {
    }

    a {
        text-decoration: none;
    }

    input, button, select, optgroup, textarea {
        margin: 0;
        font-family: inherit;
        font-size: inherit;
        line-height: inherit;
    }

    button, [type="button"], [type="reset"], [type="submit"] {
        -webkit-appearance: button;
    }
    
    .fade-enter {
        opacity: 0;
        &.fade-enter-active {
          opacity: 1;
          transition: opacity .3s ease-in-out;
        }
    }
      
    .fade-exit {
        opacity: 1;
        &.fade-exit-active {
            opacity: 0;
            transition: opacity .3s ease-in-out;
        }
    }
    
    .container {
        position: relative;
    }
    
    .page {
        position: absolute;
        left: 15px;
        right: 15px;
    }
    
    .page-enter {
    opacity: 0;
    transform: scale(1.1);
        &.page-enter-active {
            opacity: 1;
            transform: scale(1);
            transition: opacity 300ms, transform 300ms;
        }
    }
    
    .page-exit {
    opacity: 1;
    transform: scale(1);
        &.page-exit-active {
            opacity: 0;
            transform: scale(0.9);
            transition: opacity 300ms, transform 300ms;
        }
    }

    #logo a img {
        width: 100%
    }

    #header {
        ${device.sm`
            position: sticky;
        `}
        top: 0;
        z-index: 2000;
    }

    #header #main-nav {
        text-align: right;
        align-self: center;
    }

    #header #logo {
        width: 200px;
        z-index: 2;
    }

    .brainstorm-app{ 
        width: 100%;
        height: 100%;
        position: relative;
        .slick-dots {
            bottom: 0;
            position: relative;
        }

        .slick-dots li {
            border: 2px solid #B7B7B7;
            border-radius: 50%;
            width: 12px;
            height: 12px;
            background: #B7B7B7;
            margin: 0 13.5px;
        }

        .slick-track {
            width: 7000px !important;
        }

        .slick-dots li button::before {
            content: '';
        }

        .slick-dots .slick-active {
            background: ${(props) => props.theme.colors.primary};
        }
    }







    .l-icon-button {
        display: inline-block;
        border: 2px solid #ddd;
        border-radius: 100%;
        padding: 10px;
        line-height: 100%;
        background-color: transparent;
        position: relative;
        overflow: hidden;
    }

    .svg-inline--fa.fa-w-11 {
        width: 1em;
        position: absolute;
        top: 5px;
        left: 5px;
    }

    .svg-inline--fa {
        display: inline-block;
        font-size: inherit;
        position: absolute;
        left: 7px;
        top: 6px;
        height: 1em;
        overflow: visible;
    }

    .mobile{
        @media (min-width: 480px){
          display: none;
        }
    }

    dl {
        display: flex;
        flex-direction: row;
        margin-top: 5px;
        margin-bottom: 10px;
    }

    dl > img {
        height: auto;
        width: 100%;
    }

    dt {
        width: 50%;
    }

    .fjc {
        display: flex;
        justify-content: center;
    }
    
    .fjse {
        display: flex;
        justify-content: space-evenly;
    }
    
    .fjfe {
        display: flex;
        justify-content: flex-end;
    }

    .fjsb {
        display: flex;
        justify-content: space-between;
    }

    .mb0 {
        margin-bottom: 0px;
    }

    .profile {
        width: 50px;
        top: -1px;
        left: -1px;
        position: absolute;
    }

    .icon {
        width: 30px;
        height: 30px;
    }

    .overlay {
        height: 100%;
        content: '';
        width: 100%;
        position: fixed;
        z-index: 100;
        left: 0;
        top: 0;
        backdrop-filter: blur(4px);
    }

    hr {
        height: 1px;
        border: none;
        background-color: #fcce44;
    }

    .field > span {
        display: block;
        padding-left: 0px;
        padding-top: 5px;
        font-size: 13px;
    }

    form > p {
        padding-left: 0px;
        font-size: 13px;
    }

    #login-form > button > .svg-inline--fa {
        left: 3px;
        top: 3px;
    }

    #signup-form >button > .svg-inline--fa {
        left: 2px;
        top: 2px;
    }

    #signup-form p, #login-form p {
        padding-top: 12px;
        font-size: 13px;
    }

    .remark {
        font-size: 10px;
        font-style: italic;
        margin-bottom: 0;
        color: #999999 !important;
    }

    .profile.nav {
        top: 50px;
        left: 25px;
    }

    .input-icon {
        position: absolute;
        right: 0;
        width: 20px;
    }

    .input-wrapper {
        display: flex;
        position: relative;
        width: 100%;
        ${device.sm`
            width: calc( 100% - 800px );
        `}
    }

    .add-icon {
        max-width: 60px;
        height: 30px;
    }

    .medium-icon {
        width: 70px;
    }

    .fdc {
        flex-direction: column;
    }

    .error {
        ${error}
    }

    .checkBoxPadding {
        padding: 1px 0 5px;
    }

    .grey-circle {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        color: #707070;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #E1E1E1;
    } 

    .outside-box {
        position: relative;
        left: 50vw;
        color: white;
        top: 20px;
        font-size: 12px !important;
    }

    input[type="checkbox"] {
        width: inherit;
        margin-right: 7px;
    }

    .login-bg {
        z-index: -1;
    }

    .alc {
        align-items: center;
    }
    
    .asc {
        align-self: center;
    }

    &.session-bg:before {
        position: fixed;
        transform: translate(-25%, 10%);
        background: ${(props) => props.theme.colors.secondary};
        width: 200%;
        height: 100%;
        z-index: -1;
        left: 0;
        border-radius: 50%;
        content: '';
    }

    .regularGrey {
        ${regularGrey}
        padding-bottom: 10px;
        display: block;
    }

    .smallGreyItalic {
        ${smallGreyItalic}
    }
    
    .rel-left-15 {
        position: relative;
        left: 10%;
    }

    .white-svg > g {
        > * {
                stroke: white;
            }
        > path {
            fill: white;
        }
    }
    
    .chat-panel {
        padding-right: 20px;
        display: flex;
        flex-direction: column;    
        ${scrollBar}
    }
`;

export default GlobalStyle;
