import React, { useState, useEffect, useRef } from 'react';

import styled, { css } from 'styled-components';
import { space, layout, border } from 'styled-system';

import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';

const ImageContent = styled.img`
  max-width: 100%;
  ${space}
  ${layout}
  ${border}
  transition: .2s opacity ease-in-out;
  ${(props) =>
    !props.show &&
    css`
      opacity: 0;
    `}
  ${(props) =>
    props.framed &&
    css`
      border-radius: 10px;
    `}
`;

const ZoomWrapper = styled.div`
  cursor: move;
  cursor: grab;
  .react-transform-component {
    overflow: visible;
  }
`;

const Image = React.forwardRef((props, ref) => {
  const innerRef = useRef(ref);

  const {
    onLoad = () => {},
    onError = () => {},
    placeholder = null,
    zoom = false,
    ...rest
  } = props;

  const [loaded, setLoaded] = useState(false);

  const handleLoad = (event) => {
    onLoad(event);

    setLoaded(true);
  };

  const handleError = (event) => {
    onError(event);
  };

  if (placeholder) {
    return (
      <>
        {!loaded && placeholder}
        <ImageContent
          ref={innerRef}
          onLoad={handleLoad}
          onError={handleError}
          show={loaded}
          {...rest}
        />
      </>
    );
  }

  const imageConent = (
    <ImageContent
      ref={innerRef}
      show
      onLoad={handleLoad}
      onError={handleError}
      {...rest}
    />
  );

  return !zoom ? (
    imageConent
  ) : (
    <ZoomWrapper>
      <TransformWrapper>
        <TransformComponent>{imageConent}</TransformComponent>
      </TransformWrapper>
    </ZoomWrapper>
  );
});

export default styled(Image)``;
