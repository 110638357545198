const POST_KEYWORDS = 'POST_KEYWORDS';

const POST_UPLOAD_IMAGE = 'POST_UPLOAD_IMAGE';
const DELETE_UPLOAD_IMAGE = 'DELETE_UPLOAD_IMAGE';
const PUT_UPLOAD_IMAGES = 'PUT_UPLOAD_IMAGES';

const POST_UPLOAD_AUDIO = 'POST_UPLOAD_AUDIO';
const PUT_UPLOAD_AUDIOS = 'PUT_UPLOAD_AUDIOS';
const DELETE_UPLOAD_AUDIO = 'DELETE_UPLOAD_AUDIO';

const POST_SOUNDCLOUD = 'POST_SOUNDCLOUD';

const POST_UPLOAD_VIDEO = 'POST_UPLOAD_VIDEO';
const PUT_UPLOAD_VIDEOS = 'PUT_UPLOAD_VIDEOS';
const DELETE_UPLOAD_VIDEO = 'DELETE_UPLOAD_VIDEO';

const POST_TYPE_SOMETHING = 'POST_TYPE_SOMETHING';
const POST_PICK_COLOR = 'POST_PICK_COLOR';

const POST_VOTE_MODULE = 'POST_VOTE_MODULE';

const POST_REQUEST_VOTE = 'POST_REQUEST_VOTE';
const POST_VOTE_SELECT = 'POST_VOTE_SELECT';

export {
  POST_KEYWORDS,
  POST_UPLOAD_IMAGE,
  DELETE_UPLOAD_IMAGE,
  PUT_UPLOAD_IMAGES,
  POST_UPLOAD_AUDIO,
  POST_SOUNDCLOUD,
  PUT_UPLOAD_AUDIOS,
  DELETE_UPLOAD_AUDIO,
  POST_UPLOAD_VIDEO,
  PUT_UPLOAD_VIDEOS,
  DELETE_UPLOAD_VIDEO,
  POST_TYPE_SOMETHING,
  POST_PICK_COLOR,
  POST_VOTE_MODULE,
  POST_REQUEST_VOTE,
  POST_VOTE_SELECT,
};
