import i18n from '@/i18n';

const plans = {
  SPARK: {
    name: 'spark',
    label: i18n.t('Spark'),
    description: i18n.t('Sweet treat from us'),
    slogan: i18n.t(
      'Free Trial for testing out our platform*.\n\n*After 30 day trial you will automatically be upgraded to a paid plan starting at $12.50/mo'
    ),
    price: {
      promo: '0.00',
      monthly: '0.00',
      yearly: 0,
    },
    features: [
      i18n.t('1 moderator'),
      i18n.t('Up to 5 participants'),
      i18n.t('Text chat'),
      i18n.t('1 month free trial'),
    ],
    moduleLimit: Number.POSITIVE_INFINITY,
    participantLimit: 5,
  },
  STRIKE: {
    name: 'strike',
    label: i18n.t('Strike'),
    description: i18n.t('Standard Plan for medium brainstorms'),
    slogan: `${i18n.t('Standard Plan for medium brainstorms')}\n${i18n.t(
      'Billing cycle'
    )}`,
    price: {
      promo: `12.50`,
      monthly: 24.99,
      yearly: 254.9,
    },
    features: [
      i18n.t('1 moderator'),
      i18n.t('Up to 10 participants'),
      i18n.t('Data confidentiality'),
      i18n.t('Text chat'),
      i18n.t('Audio chat'),
      i18n.t('20GB data storage limit'),
    ],
    moduleLimit: Number.POSITIVE_INFINITY,
    participantLimit: 10,
  },
  IGNITE: {
    name: 'ignite',
    label: i18n.t('Ignite'),
    description: i18n.t('Premium Plan for large brainstorms'),
    slogan: `${i18n.t('Premium Plan for large brainstorms')}\n${i18n.t(
      'Billing cycle'
    )}`,
    price: {
      promo: 0,
      monthly: 44.9,
      yearly: 1019.9,
    },
    features: [
      i18n.t('1 moderator'),
      i18n.t('Up to 20 participants'),
      i18n.t('Data confidentiality'),
      i18n.t('Text chat'),
      i18n.t('Audio chat'),
      i18n.t('50GB data storage limit'),
      i18n.t('Early access to new features'),
    ],
    moduleLimit: Number.POSITIVE_INFINITY,
    participantLimit: 20,
  },
};

export default plans;

export const getPlanBy = (node, value) =>
  Object.values(plans).find((item) => item[node] === value) || plans.SPARK;
