export const breakpoints = {
  xxs: '375px',
  xs: '480px',
  sm: '768px',
  md: '992px',
  lg: '1200px',
  xl: '1400px',
  xxl: '1680px',
};

export const colors = {
  primary: '#FCCE44',
  secondary: '#622DE3',
  light: '#fff',
  dark: '#333333',
  grey1: '#666666',
  grey2: '#999999',
  grey3: '#B7B7B7',
  grey4: '#707070',
  grey5: '#EBEBEB',
  grey6: '#E1E1E1',
  blue: '#0083C7',
  red: '#C64F4B',
  rose: '#F58D84',
  pink: '#F5B4C4',
};

export const fonts = {
  'abril-fatface': 'Abril Fatface, Arial, sans-serif',
  roboto: 'Roboto, Arial, sans-serif',
};

export const locales = ['en', 'cn'];
