import convert from 'color-convert'; // https://www.npmjs.com/package/color-convert

const colorConvert = (input, inputType = 'rgb', outputType = 'hex') => {
  let inputValue;
  let output;

  switch (inputType) {
    case 'rgb':
      inputValue = [parseInt(input.r), parseInt(input.g), parseInt(input.b)];

      output = convert[inputType][outputType](...inputValue);
      break;
    default:
      inputValue = input;
      output = convert[inputType][outputType](inputValue);
  }

  switch (outputType) {
    case 'rgb':
      return {
        r: output[0],
        g: output[1],
        b: output[2],
      };
    case 'hex':
      return `#${output}`;
    default:
      return output;
  }
};

export default colorConvert;
