import React from 'react';

import styled, { css } from 'styled-components';
import shouldForwardProp from '@styled-system/should-forward-prop';

import {
  compose,
  variant,
  padding,
  margin,
  border,
  color,
  flex,
  layout,
  shadow,
  typography,
  flexbox,
  background,
} from 'styled-system';

import { colors } from 'Abstracts/variables';
import { layoutStyles } from 'Mixins/styles';

const Component = styled.div`
  box-sizing: border-box;
  padding: 10px;
  margin: 0 auto;
  background: white;
  overflow: hidden;
  flex-direction: column;
  display: inline-flex;
  border-radius: ${(props) => (props.rounded ? '30px' : 0)};

  ${variant({
    variants: {
      'style-signup': {
        borderRadius: '30px',
        boxShadow: '3px 3px 6px 0 rgba(0, 0, 0, 0.16)',
        border: 'solid 1px #622de3',
      },
      'style-4': {
        border: `2px solid ${colors.secondary}`,
        boxShadow: '0 3px 5px rgba(0, 0, 0, .3)',
        borderRadius: '20px',
      },
      'style-6': {
        border: `2px solid ${colors.secondary}`,
        borderRadius: '20px',
      },
      image: {
        border: `2px solid ${colors.secondary}`,
        maxWidth: '100%',
        borderRadius: '15px',
        padding: 0,
        background: 'none',
      },
    },
  })};

  ${variant({
    prop: 'h-align',
    variants: layoutStyles['h-align'],
  })};

  ${variant({
    prop: 'v-align',
    variants: layoutStyles['v-align'],
  })};

  ${({ transparent, fullWidth, disabled }) => css`
    ${
      transparent &&
      css`
        background: none;
      `
    }
    ${
      fullWidth &&
      css`
        width: 100%;
        max-width: 100%;
      `
    }
    ${
      disabled &&
      css`
        background-color: rgba(0, 0, 0, 0.16);
      `
    }
  `};

  ${compose(
    padding,
    margin,
    background,
    color,
    flex,
    layout,
    border,
    shadow,
    flexbox,
    typography
  )};
`;

const ComponentWithProps = styled(Component).withConfig({
  shouldForwardProp,
})(layout);

const Card = React.forwardRef((props, ref) => {
  const { rounded = true, children, ...rest } = props;

  return (
    <ComponentWithProps ref={ref} rounded={rounded} {...rest}>
      {children}
    </ComponentWithProps>
  );
});
Card.displayName = 'Card';

export default Card;
