import styled, { css } from 'styled-components';

import { transparentize } from 'polished';
import { space } from 'styled-system';

import { scrollBar } from 'Mixins/styles';

const FADE_HEIGHT = '50px';

export const ViewWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-height: ${(props) => (props.maxHeight ? props.maxHeight : '100%')};
`;

export const ViewContainer = styled.div`
  width: 100%;

  overflow-y: auto;
  box-sizing: border-box;
  ${({ disabled }) =>
    !disabled &&
    css`
      height: 100%;
    `}
  ${({ reverse }) =>
    !!reverse &&
    css`
      display: flex;
      flex-direction: column-reverse;
      ${'' /* justify-content: flex-end; */}
    `}
  ${space};
  ${scrollBar};
`;

export const FadeOverlay = styled.div`
  position: sticky;
  width: calc(100% - 4px);
  height: 0;
  z-index: 1;
  left: 0;
  pointer-events: none;
  ${(props) =>
    props.location === 'top' &&
    css`
      top: 0;
    `}
  ${(props) =>
    props.location === 'bottom' &&
    css`
      bottom: 0;
    `};
  &:before {
    content: '';
    display: inline-block;
    position: absolute;
    height: ${FADE_HEIGHT};
    width: 100%;
    ${(props) =>
      props.location === 'top' &&
      css`
        top: 0;
        ${'' /* margin-bottom: -${FADE_HEIGHT}; */}
        background: linear-gradient(
          to bottom,
          ${transparentize(0, props.color)} 0%,
          ${transparentize(0.5, props.color)} 40%,
          ${transparentize(1, props.color)} 100%
        );
      `}
    ${(props) =>
      props.location === 'bottom' &&
      css`
        bottom: -1px;
        ${'' /* margin-top: -${FADE_HEIGHT}; */}
        background: linear-gradient(
          to bottom,
          ${transparentize(1, props.color)} 0%,
          ${transparentize(0.5, props.color)} 40%,
          ${transparentize(0, props.color)} 100%
        );
      `}
  }
  opacity: 1;
  transition: opacity 0.1s ease-in-out;
  ${(props) =>
    !props.show &&
    css`
      opacity: 0;
    `}
  &:after {
    content: '';
    width: 100%;
    height: 100%;
    display: inline-block;
  }
`;
