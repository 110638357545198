import { useEffect, useRef, useCallback } from 'react';

import { usePrevious } from 'Helpers/hooks';
import { isObjectEqual } from 'Helpers/utils';

const useAsyncState = ([syncState, syncSetState]) => {
  const asyncSetState = useCallback(
    (newValue) => {
      return new Promise((resolve) => {
        let output;

        syncSetState((prev) => {
          output = typeof newValue === 'function' ? newValue(prev) : newValue;

          setTimeout(() => resolve(output));

          return output;
        });
      });
    },
    [syncSetState]
  );

  return asyncSetState;
};

export default useAsyncState;
