import protooClient from 'protoo-client';

import * as mediasoup from 'mediasoup-client';

const PC_PROPRIETARY_CONSTRAINTS = {
  optional: [{ googDscp: true }],
};

class WebRTC {
  constructor(room, user, ticket, initOption = {}) {
    this.room = room;
    this.user = user;
    this.option = initOption;
    this.peers = [];
    this.consumers = [];

    this.server = `${process.env.WEBRTC_URI}/?roomId=${room}&peerId=${user.id}&ticket=${ticket}`;
    this.transportOption = {
      retries: 0,
      factor: 2,
      minTimeout: 1 * 10000, // https://github.com/versatica/mediasoup-client/issues/48#issuecomment-434016320
      maxTimeout: 2 * 10000,
      ...(initOption && initOption.transport ? initOption.transport : {}),
    };

    this.device = null;
    this.audioTrack = null;
    this.protoo = null;
    this.transport = null;
    this.handler = null;

    this.producer = null;
    this.consumer = null;

    this.mic = null;
    this.mediaStream = null;

    this.cb = null;
    this.errorCb = null;
    this.closeCb = null;
    this.failCb = null;
  }

  onChange(cb) {
    this.cb = cb;
  }

  onError(cb) {
    this.errorCb = cb;
  }

  onClose(cb) {
    this.closeCb = cb;
  }

  onFail(cb) {
    this.failCb = cb;
  }

  setValue(prop, val) {
    if (val.constructor === Function) {
      this[prop] = val(this);
    } else {
      this[prop] = val;
    }

    if (this.cb) this.cb(this);
  }

  connect(callback, errback) {
    this.transport = new protooClient.WebSocketTransport(this.server, {
      retry: this.transportOption,
    });

    this.transport.on('failed', () => {
      if (this.failCb) this.failCb(new Error('Failed to connect to WebRTC.'));
    });

    this.transport.on('close', () => {
      if (this.closeCb) this.closeCb(new Error('WebRTC connection closed.'));
    });

    this.protoo = new protooClient.Peer(this.transport);

    this.protoo.on('open', async () => {
      try {
        const routerRtpCapabilities = await this.protoo.request(
          'getRouterRtpCapabilities'
        );

        await this.loadDevice(routerRtpCapabilities);

        callback();
      } catch (error) {
        errback(error);
      }
    });

    this.protoo.on('disconnected', () => {
      if (this.closeCb) this.closeCb(error);

      this.protoo = null;
    });

    this.protoo.on('failed', (n) => {
      if (this.errorCb)
        this.errorCb(
          new Error(`An error occurred during connection, attempt: ${n}`)
        );
    });

    this.protoo.on('request', async (request, callback, errback) => {
      try {
        if (request.method === 'newConsumer') {
          const {
            peerId,
            producerId,
            id,
            kind,
            rtpParameters,
            type,
            appData,
            producerPaused,
          } = request.data;

          const consumer = await this.consumer.consume({
            id,
            producerId,
            kind,
            rtpParameters,
            appData: { ...appData, peerId },
          });

          const {
            spatialLayers,
            temporalLayers,
          } = mediasoup.parseScalabilityMode(
            consumer.rtpParameters.encodings[0].scalabilityMode
          );

          const newConsumer = {
            consumer: {
              id: consumer.id,
              type,
              locallyPaused: false,
              remotelyPaused: producerPaused,
              rtpParameters: consumer.rtpParameters,
              spatialLayers,
              temporalLayers,
              preferredSpatialLayer: spatialLayers - 1,
              preferredTemporalLayer: temporalLayers - 1,
              priority: 1,
              codec: consumer.rtpParameters.codecs[0].mimeType.split('/')[1],
              track: consumer.track,
            },
            peerId,
            disabled: this.option.disabled ? this.option.disabled : false,
            muted: producerPaused,
          };

          this.setValue('consumers', (prev) =>
            prev.consumers.find((consumer) => consumer.peerId === peerId)
              ? prev.consumers.map((consumer) =>
                  consumer.peerId === peerId ? newConsumer : consumer
                )
              : [...prev.consumers, newConsumer]
          );

          consumer.on('transportclose', () => {
            this.setValue('consumers', (prev) =>
              prev.consumers.filter((consumer) => consumer.peerId !== peerId)
            );
          });

          callback();
        }
      } catch (error) {
        errback(error);
      }
    });

    this.protoo.on('notification', (notification) => {
      switch (notification.method) {
        case 'newPeer': {
          const newPeer = notification.data;
          const peerId = newPeer.id;

          this.setValue('peers', (prev) =>
            prev.peers.find((peer) => peer.id === peerId)
              ? prev.peers.map((peer) => (peer.id === peerId ? newPeer : peer))
              : [...prev.peers, newPeer]
          );

          break;
        }
        case 'peerClosed': {
          const { peerId } = notification.data;

          this.setValue('peers', (prev) =>
            prev.peers.filter((peer) => peer.id !== peerId)
          );

          break;
        }
        case 'activeSpeaker': {
          const { peerId, volume } = notification.data;

          this.setValue('peers', (prev) => {
            return prev.peers.map((peer) =>
              peer.id !== peerId ? peer : { ...peer, volume }
            );
          });

          break;
        }

        case 'consumerPaused': {
          const { consumerId } = notification.data;
          this.setValue('consumers', (prev) => {
            return prev.consumers.map((item) =>
              item.consumer.id !== consumerId ? item : { ...item, muted: true }
            );
          });

          break;
        }

        case 'consumerResumed': {
          const { consumerId } = notification.data;

          this.setValue('consumers', (prev) => {
            return prev.consumers.map((item) =>
              item.consumer.id !== consumerId ? item : { ...item, muted: false }
            );
          });

          break;
        }

        case 'consumerClosed': {
          const { consumerId } = notification.data;

          this.setValue('consumers', (prev) =>
            prev.consumers.filter((item) => item.consumer.id !== consumerId)
          );

          break;
        }
      }
    });
  }

  disconnect() {
    this.cb = () => {};

    if (this.audioTrack) this.audioTrack.stop();

    if (this.transport) this.transport = null;

    if (this.protoo && !!this.protoo._connected && !this.protoo._closed) {
      this.protoo.close();
    }
  }

  loadDevice(routerRtpCapabilities) {
    return new Promise(async (resolve, reject) => {
      try {
        try {
          this.device = new mediasoup.Device();
        } catch (error) {
          throw error;
        }

        await this.device.load({ routerRtpCapabilities });

        await this.connectUserAudio();

        await this.publish();

        await this.subscribe();

        await this.join();

        resolve(true);
      } catch (error) {
        reject(error);
      }
    });
  }

  connectUserAudio() {
    return new Promise(async (resolve, reject) => {
      try {
        // https://webrtcdemo.audiocodes.com/sdk/webrtc-api-base/examples/tutorial.html

        this.mediaStream = await navigator.mediaDevices.getUserMedia({
          audio: {
            autoGainControl: true,
            echoCancellation: true,
            // noiseSuppression: true,
            googEchoCancellation: { exact: true },
            googAutoGainControl: { exact: true },
            // googNoiseSuppression: { exact: true },
            mozAutoGainControl: { exact: true },
            // mozNoiseSuppression: { exact: true },
          },
          video: false,
        });

        this.audioTrack = this.mediaStream.getAudioTracks()[0];

        resolve(true);
      } catch (error) {
        reject(error);
      }
    });
  }

  getMediaStream() {
    return this.mediaStream;
  }

  publish() {
    return new Promise(async (resolve, reject) => {
      try {
        const transportInfo = await this.protoo.request(
          'createWebRtcTransport',
          {
            forceTcp: false,
            sctpCapabilities: this.device.sctpCapabilities,
            producing: true,
            consuming: false,
          }
        );

        const {
          id,
          iceParameters,
          iceCandidates,
          dtlsParameters,
          sctpParameters,
        } = transportInfo;

        this.producer = this.device.createSendTransport({
          id,
          iceParameters,
          iceCandidates,
          dtlsParameters,
          sctpParameters,
          iceServers: [],
          proprietaryConstraints: PC_PROPRIETARY_CONSTRAINTS,
        });

        this.producer.on('connect', ({ dtlsParameters }, callback, errback) => {
          this.protoo
            .request('connectWebRtcTransport', {
              transportId: this.producer.id,
              dtlsParameters,
            })
            .then(callback)
            .catch(errback);
        });

        this.producer.on(
          'produce',
          async ({ kind, rtpParameters, appData }, callback, errback) => {
            try {
              const { id } = await this.protoo.request('produce', {
                transportId: this.producer.id,
                kind,
                rtpParameters,
                appData,
              });

              callback({ id });
            } catch (error) {
              errback(error);
            }
          }
        );

        this.producer.on(
          'producedata',
          async (
            { sctpStreamParameters, label, protocol, appData },
            callback,
            errback
          ) => {
            try {
              const { id } = await this.protoo.request('produceData', {
                transportId: this.producer.id,
                sctpStreamParameters,
                label,
                protocol,
                appData,
              });

              callback({ id });
            } catch (error) {
              errback(error);
            }
          }
        );

        if (!this.option.muted) {
          this.enableMic();
        }

        resolve(true);
      } catch (error) {
        reject(error);
      }
    });
  }

  subscribe() {
    return new Promise(async (resolve, reject) => {
      try {
        const transportInfo = await this.protoo.request(
          'createWebRtcTransport',
          {
            forceTcp: false,
            sctpCapabilities: this.device.sctpCapabilities,
            producing: false,
            consuming: true,
          }
        );

        const {
          id,
          iceParameters,
          iceCandidates,
          dtlsParameters,
          sctpParameters,
        } = transportInfo;

        this.consumer = this.device.createRecvTransport({
          id,
          iceParameters,
          iceCandidates,
          dtlsParameters,
          sctpParameters,
          iceServers: [],
        });

        this.consumer.on('connect', ({ dtlsParameters }, callback, errback) => {
          this.protoo
            .request('connectWebRtcTransport', {
              transportId: this.consumer.id,
              dtlsParameters,
            })
            .then(callback)
            .catch(errback);
        });

        resolve(true);
      } catch (error) {
        reject(error);
      }
    });
  }

  join() {
    return new Promise(async (resolve, reject) => {
      try {
        const { peers } = await this.protoo.request('join', {
          displayName: this.user.name,
          device: this.device,
          rtpCapabilities: this.device.rtpCapabilities,
          sctpCapabilities: this.device.sctpCapabilities,
        });

        this.setValue('peers', peers);

        resolve(true);
      } catch (error) {
        reject(error);
      }
    });
  }

  enableMic() {
    return new Promise(async (resolve, reject) => {
      try {
        this.option.muted = false;

        if (this.mic) {
          await this.protoo.request('resumeProducer', {
            producerId: this.mic.id,
          });
        } else {
          this.mic = await this.producer.produce({
            track: this.audioTrack,
            codecOptions: {
              opusStereo: 1,
              opusDtx: 1,
            },
          });
        }

        resolve(true);
      } catch (error) {
        reject(error);
      }
    });
  }

  disableMic() {
    return new Promise(async (resolve, reject) => {
      try {
        this.option.muted = true;

        if (this.mic) {
          await this.protoo.request('pauseProducer', {
            producerId: this.mic.id,
          });
        }

        resolve(true);
      } catch (error) {
        reject(error);
      }
    });
  }

  enableSound() {
    this.option.disabled = false;

    this.setValue('consumers', (prev) =>
      prev.consumers.map((item) => ({ ...item, disabled: false }))
    );
  }

  disableSound() {
    this.option.disabled = true;

    this.setValue('consumers', (prev) =>
      prev.consumers.map((item) => ({ ...item, disabled: true }))
    );
  }
}

export default WebRTC;
