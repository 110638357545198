import { lazy } from 'react';

import withThemeSetup from 'Containers/withThemeSetup';

import bg2 from 'Assets/images/backgrounds/2.svg';
import signupImage from 'Assets/images/signupBg.svg';
import errorImage from 'Assets/images/error-404.svg';

const loadComponent = (fn) =>
  lazy(
    () =>
      new Promise((resolve, reject) => {
        (async () => {
          try {
            const res = await fn();
            resolve(res);
          } catch (error) {
            if (
              error.name === 'ChunkLoadError' &&
              window.location.hash !== '#retry'
            ) {
              window.location.hash = '#retry';
              window.location.reload();
            } else {
              reject(error);
            }
          }
        })();
      })
  );

const routes = [
  {
    path: '/',
    component: withThemeSetup(
      loadComponent(() => import(/* webpackPrefetch: true */ 'Pages/Home')),
      {
        frame: 'default',
        background: {
          color: '#622DE3',
          image: `url(${bg2})`,
          position: {
            _: 'calc(50% + 30px) 20px',
            md: 'center',
          },
          size: { _: 'auto 400px', md: '100% auto' },
        },
      }
    ),
    exact: true,
    isPrivate: true,
  },
  {
    path: '/login',
    component: withThemeSetup(
      loadComponent(() =>
        import(/* webpackPrefetch: true */ 'Pages/Auth/SignIn')
      ),
      {
        logo: 'colored',
        background: {
          color: '#F7F7F7',
        },
        frame: 'signin',
      }
    ),
  },
  {
    path: '/signup/:plan/:token?',
    component: withThemeSetup(
      loadComponent(() => import('Pages/Auth/SignIn')),
      {
        logo: 'colored',
        background: {
          color: '#F7F7F7',
        },
      }
    ),
  },
  {
    path: '/signup/',
    component: withThemeSetup(
      loadComponent(() => import('Pages/Auth/SignIn')),
      {
        logo: 'colored',
        background: {
          color: '#F7F7F7',
          image: { _: 'none', md: `url(${signupImage})` },
          position: {
            md: 'calc(50% - 450px) 420px',
          },
          size: { md: '772px 560px' },
        },
      }
    ),
  },
  {
    path: '(change|upgrade)',
    component: withThemeSetup(
      loadComponent(() => import('Pages/Auth/Upgrade')),
      {
        logo: 'colored',
        background: {
          color: '#F7F7F7',
          image: { _: 'none', md: `url(${signupImage})` },
          position: {
            md: 'calc(50% - 450px) 420px',
          },
          size: { md: '772px 560px' },
        },
      }
    ),
    isPrivate: true,
  },
  {
    path: '/logout',
    component: loadComponent(() =>
      import(/* webpackPrefetch: true */ 'Pages/Auth/LogOut')
    ),
    isPrivate: true,
  },
  {
    path: '/register/confirm',
    component: loadComponent(() => import('Pages/Auth/Register/Confirm')),
  },
  {
    path: '/manage/create',
    component: withThemeSetup(
      loadComponent(() => import('Pages/Manage/Create')),
      {
        background: {
          color: '#622DE3',
        },
        frame: 'manage-brainstorm',
      }
    ),
    isPrivate: true,
  },
  {
    path: '/manage/update/:id',
    component: withThemeSetup(
      loadComponent(() => import('Pages/Manage/Update')),
      {
        background: {
          color: '#622DE3',
        },
        frame: 'manage-brainstorm',
      }
    ),
    isPrivate: true,
  },
  {
    path: '/register/:id',
    component: withThemeSetup(
      loadComponent(() => import('Pages/Auth/Register/Verified')),
      {
        background: {
          color: '#F7F7F7',
        },
      }
    ),
    isPrivate: false,
  },
  {
    path: '/forget/:id',
    component: withThemeSetup(
      loadComponent(() => import('Pages/Auth/Reset')),
      {
        background: {
          color: '#F7F7F7',
        },
      }
    ),
  },
  {
    path: '/session/:id',
    component: withThemeSetup(
      loadComponent(() => import(/* webpackPrefetch: true */ 'Pages/Session')),
      {
        frame: 'session-alt',
        background: {
          color: '#622DE3',
        },
      }
    ),
  },
  {
    path: '(user|account|profile)',
    component: withThemeSetup(
      loadComponent(() => import('Pages/User')),
      {
        background: {
          color: '#622DE3',
          image: `url(${bg2})`,
          position: {
            _: 'calc(50% + 30px) 20px',
            md: 'center',
          },
          size: { _: 'auto 400px', md: '100% auto' },
        },
      }
    ),
    isPrivate: true,
  },
  {
    path: '/unsubscribe',
    component: withThemeSetup(
      loadComponent(() => import('Pages/User/Unsubscribe')),
      {
        background: {
          color: '#f5f5f5',
        },
      }
    ),
    isPrivate: true,
  },
  {
    path: '/404',
    component: withThemeSetup(
      loadComponent(() => import(/* webpackPrefetch: true */ 'Pages/NotFound')),
      {
        background: {
          color: '#2C83C6',
          image: `url(${errorImage})`,
          repeat: 'no-repeat',
          position: 'bottom -1px center',
          size: { _: '400px 248px', sm: '90% auto', md: '684px 372px' },
        },
        frame: 'error',
      }
    ),
    isPrivate: false,
  },
];

export default routes;
