import React, { useState, useContext } from 'react';

import { useSelector } from 'react-redux';
// Elements
import { Container, Row, Col } from 'Components/Elements/Grid';

import Avatar from 'Components/Compounds/Avatar';
import AccountDrawer from 'Components/Compounds/AccountDrawer';

// Icons
import { ReactComponent as Logo } from 'Assets/images/logo-white.svg';
import { ReactComponent as LogoColour } from 'Assets/images/colour-logo.svg';

import { ThemeContext } from 'Contexts';

import {
  HeaderNav,
  HeaderPlaceholder,
} from 'Components/Frames/_common/Frames.styles';
import ExpiryBanner from 'Components/Frames/_common/ExpiryBanner';
import Tips from './Tips';

import { StyledLogoLink, HeaderWrapper } from '../DefaultFrame.styles';

const Header = (props) => {
  const {
    tips,
    hideOnMobile,
    hideExpiry = false,
    expired = false,
    expireDays,
  } = props;

  const { theme } = useContext(ThemeContext);

  const [showNav, setShowNav] = useState('none');

  const reduxUser = useSelector((reduxState) => reduxState.user, []) || [];

  return (
    <>
      <HeaderPlaceholder hideOnMobile={hideOnMobile} />
      <HeaderWrapper id="main-header" hideOnMobile={hideOnMobile}>
        {!!reduxUser.data && (expired || expireDays < 7) && !hideExpiry && (
          <Row
            padding={{ _: '10px 20px', md: '10px 20px 10px 60px' }}
            mx="0"
            minHeight={{ _: '30px' }}
            bg="#C64F4B"
          >
            <ExpiryBanner days={expireDays} plan={reduxUser.plan} />
          </Row>
        )}
        <Container bg="#ffbf00" fluid>
          <Row
            padding={{ _: '0 5px', md: '10px 20px 10px 40px' }}
            mx="0"
            minHeight={{ _: '68px', md: '80px' }}
          >
            <Col span="auto" v-align="center" p="0" h-align="left">
              <StyledLogoLink to="/">
                {theme.logo === 'colored' ? <LogoColour /> : <Logo />}
              </StyledLogoLink>
            </Col>
            <Col span="auto" h-align="right" v-align="center" p="0">
              <HeaderNav>
                <HeaderNav.List>
                  {!!tips && (
                    <HeaderNav.ListItem>
                      <Tips
                        data={tips}
                        show={showNav === 'tips'}
                        onOpen={() => setShowNav('tips')}
                        onClose={() => setShowNav('none')}
                      />
                    </HeaderNav.ListItem>
                  )}
                  <HeaderNav.ListItem>
                    {!!reduxUser.data && (
                      <Avatar
                        name={`${reduxUser.data.name} ${reduxUser.data.surname}`}
                        picture={reduxUser.data.image || null}
                        onClick={() => setShowNav('account')}
                        tooltip={null}
                        size="light"
                        color="#622DE3"
                      />
                    )}

                    <AccountDrawer
                      show={showNav === 'account'}
                      onClose={() => setShowNav('none')}
                      onClick={() => setShowNav('none')}
                    />
                  </HeaderNav.ListItem>
                </HeaderNav.List>
              </HeaderNav>
            </Col>
          </Row>
        </Container>
      </HeaderWrapper>
    </>
  );
};

export default Header;
