const layoutStyles = {
  'h-align': {
    left: {
      textAlign: 'left',
      alignItems: 'flex-start',
      alignContent: 'flex-start',
    },
    center: {
      textAlign: 'center',
      alignItems: 'center',
      alignContent: 'center',
    },
    right: {
      textAlign: 'right',
      alignItems: 'flex-end',
      alignContent: 'flex-end',
    },
  },
  'v-align': {
    top: {
      verticalAlign: 'top',
      justifyItems: 'flex-start',
      justifyContent: 'flex-start',
    },
    center: {
      verticalAlign: 'center',
      justifyItems: 'center',
      justifyContent: 'center',
    },
    bottom: {
      verticalAlign: 'bottom',
      justifyItems: 'flex-end',
      justifyContent: 'flex-end',
    },
  },
};

export default layoutStyles;
