import * as types from 'Stores/types';

export function brainstorm(state = {}, action) {
  switch (action.type) {
    case types.GET_CATEGORIES_START:
    case types.GET_CATEGORIES_SUCCESS:
    case types.GET_CATEGORIES_FAILURE:
      return {
        ...state,
        categories: {
          ...state.categories,
          ...action.payload,
        },
      };
    case types.GET_BRAINSTORM_START:
    case types.GET_BRAINSTORM_FAILURE:
      return {
        ...state,
        form: {
          ...state.form,
        },
      };
    case types.GET_BRAINSTORM_SUCCESS:
      const {
        modules,
        image,
        documents,
        participants,
        ...rest
      } = action.payload.data;

      return {
        ...state,
        form: {
          status: action.payload.status,
          data: { ...rest },
        },
        modules: {
          ...state.modules,
          data: modules || [],
        },
        image: {
          ...state.image,
          data: image,
        },
        documents: {
          ...state.documents,
          data: documents || [],
        },
        participants: {
          ...state.participants,
          data: participants || [],
        },
      };
    case types.GET_BRAINSTORMS_START:
    case types.GET_BRAINSTORMS_SUCCESS:
    case types.GET_BRAINSTORMS_FAILURE:
      return {
        ...state,
        brainstorms: {
          ...state.brainstorms,
          ...action.payload,
        },
      };
    case types.GET_BRAINSTORMS_NEXT_START:
    case types.GET_BRAINSTORMS_NEXT_FAILURE:
      return {
        ...state,
        brainstorms: {
          ...state.brainstorms,
          ...action.payload,
          data: state.brainstorms.data,
        },
      };
    case types.GET_BRAINSTORMS_NEXT_SUCCESS:
      return {
        ...state,
        brainstorms: {
          ...state.brainstorms,
          ...action.payload,
          data: [...state.brainstorms.data, ...action.payload.data],
        },
      };
    case types.RESET_BRAINSTORMS:
      return {
        ...state,
        brainstorms: {
          ...action.payload,
        },
      };
    case types.DELETE_BRAINSTORM_SUCCESS:
      return {
        ...state,
        brainstorms: {
          ...state.brainstorms,
          data: [
            ...state.brainstorms.data.filter(
              (brainstorm) => brainstorm.ID !== action.payload.data
            ),
          ],
        },
      };
    case types.GET_BRAINSTORM_IMAGE_PRESET_START:
    case types.GET_BRAINSTORM_IMAGE_PRESET_SUCCESS:
    case types.GET_BRAINSTORM_IMAGE_PRESET_FAILURE:
      return {
        ...state,
        imagePresets: {
          ...state.imagePresets,
          ...action.payload,
        },
      };
    case types.POST_BRAINSTORM_START:
    case types.POST_BRAINSTORM_SUCCESS:
    case types.POST_BRAINSTORM_FAILURE:
    case types.PUT_BRAINSTORM_START:
    case types.PUT_BRAINSTORM_SUCCESS:
    case types.PUT_BRAINSTORM_FAILURE:
      return {
        ...state,
        form: {
          ...state.form,
          ...action.payload,
        },
      };
    case types.VOID_BRAINSTORM:
      return {
        ...state,
        form: {
          ...action.payload,
          error: null,
          data: null,
        },
        modules: {
          ...action.payload,
          error: null,
          data: [],
        },
        image: {
          ...action.payload,
          error: null,
          data: [],
        },
        imagePresets: {
          ...action.payload,
          error: null,
          data: [],
        },
        documents: {
          ...action.payload,
          error: null,
          data: [],
        },
        participants: {
          ...action.payload,
          error: null,
          data: [],
        },
        invitation: {
          ...action.payload,
          error: null,
          data: [],
        },
      };
    case types.POST_BRAINSTORM_MODULES_START:
    case types.POST_BRAINSTORM_MODULES_SUCCESS:
    case types.POST_BRAINSTORM_MODULES_FAILURE:
    case types.PUT_BRAINSTORM_MODULES_START:
    case types.PUT_BRAINSTORM_MODULES_SUCCESS:
    case types.PUT_BRAINSTORM_MODULES_FAILURE:
    case types.DELETE_BRAINSTORM_MODULES_START:
    case types.DELETE_BRAINSTORM_MODULES_SUCCESS:
    case types.DELETE_BRAINSTORM_MODULES_FAILURE:
      return {
        ...state,
        modules: {
          ...state.modules,
          ...action.payload,
        },
      };
    case types.POST_BRAINSTORM_IMAGE_START:
    case types.POST_BRAINSTORM_IMAGE_SUCCESS:
    case types.POST_BRAINSTORM_IMAGE_FAILURE:
    case types.PUT_BRAINSTORM_IMAGE_START:
    case types.PUT_BRAINSTORM_IMAGE_SUCCESS:
    case types.PUT_BRAINSTORM_IMAGE_FAILURE:
    case types.POST_BRAINSTORM_IMAGE_PRESET_START:
    case types.POST_BRAINSTORM_IMAGE_PRESET_SUCCESS:
    case types.POST_BRAINSTORM_IMAGE_PRESET_FAILURE:
    case types.PUT_BRAINSTORM_IMAGE_PRESET_START:
    case types.PUT_BRAINSTORM_IMAGE_PRESET_SUCCESS:
    case types.PUT_BRAINSTORM_IMAGE_PRESET_FAILURE:
      return {
        ...state,
        image: action.payload,
      };
    case types.POST_BRAINSTORM_DOCUMENTS_START:
    case types.POST_BRAINSTORM_DOCUMENTS_FAILURE:
    case types.DELETE_BRAINSTORM_DOCUMENTS_START:
    case types.POST_BRAINSTORM_DOCUMENTS_SUCCESS:
    case types.DELETE_BRAINSTORM_DOCUMENTS_SUCCESS:
    case types.DELETE_BRAINSTORM_DOCUMENTS_FAILURE:
      return {
        ...state,
        documents: {
          ...action.payload,
        },
      };
    case types.POST_BRAINSTORM_INVITATION_START:
    case types.POST_BRAINSTORM_INVITATION_FAILURE:
      return {
        ...state,
        invitation: {
          status: action.payload.status,
          error: action.payload.error,
          data: action.payload.data,
        },
      };
    case types.POST_BRAINSTORM_INVITATION_SUCCESS:
      return {
        ...state,
        invitation: {
          status: action.payload.status,
          error: null,
          data: action.payload.data,
        },
        participants: {
          ...state.participants,
          data: action.payload.data,
          error: null,
        },
      };
    case types.POST_BRAINSTORM_MODULE_SORT:
    case types.GET_BRAINSTORM_BY_PARICIPANT_START:
    case types.GET_BRAINSTORM_BY_PARICIPANT_SUCCESS:
    case types.GET_BRAINSTORM_BY_PARICIPANT_FAILURE:
    case types.DELETE_BRAINSTORM_START:

    case types.DELETE_BRAINSTORM_FAILURE:
    default:
      return state;
  }
}
