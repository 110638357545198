class UserError extends Error {
  constructor(message) {
    super(message);

    this.name = 'UserError';

    const { prototype } = new.target;

    if (Object.setPrototypeOf) {
      Object.setPrototypeOf(this, prototype);
    } else {
      this.__proto__ = prototype;
    }
  }
}

export default UserError;
