import { useDispatch } from 'react-redux';

import i18n from '@/i18n';
import { logError } from '@/sentry';

import { ReactComponent as UploadImageIcon } from 'Assets/images/modules/upload-image.svg';

import {
  postUploadImage,
  deleteUploadImage,
  putUploadImages,
} from 'Stores/actions/module';

import { objectToForm } from 'Helpers/data-transform';

import { basicFields } from './_common';

const parseData = ({ participants, data }) => {
  const findUser = (datum) => {
    return participants.find((participant) => participant.id === datum.user);
  };

  return data.moduleUploadImage.data
    .filter((item) => !!item.submitted)
    .reduce(
      (accumulator, current) => [
        ...(accumulator || []),
        { id: current.id, image: current.image, user: findUser(current) },
      ],
      []
    );
};

const getHandler = ({ brainstormId, user, participants, data }) => {
  const dispatch = useDispatch();

  const handler = ({ type, ...payload }) => {
    let formData;

    switch (type) {
      case 'upload':
        let node = 'image';

        if (payload.dataType === 'url') {
          node = 'url';
        }

        formData = objectToForm({
          [node]: payload.file,
        });
        dispatch(
          postUploadImage(
            brainstormId,
            data.id,
            formData,
            payload.onProgress,
            payload.cancelToken
          )
        )
          .then((response) => {
            payload.onDone(response.data.payload.id);
            payload.onProgress(100);
          })
          .catch((error) => {
            logError(error);
            payload.onError(error);
          });
        break;
      case 'remove':
        dispatch(deleteUploadImage(brainstormId, data.id, payload.id)).catch(
          logError
        );
        break;
      case 'submit':
        dispatch(
          putUploadImages(
            brainstormId,
            data.id,
            Object.keys(payload.files)
              .filter((key) => !!payload.files[key].id)
              .reduce(
                (acc, key) => ({
                  ...acc,
                  [key]: payload.files[key],
                }),
                {}
              )
          )
        ).then((res) => {
          res.forEach((item) => {
            if (item instanceof Error) {
              logError(item);
            }
          });
        });
        break;
    }
  };

  return handler;
};

const getAnswer = (datum) => datum.image;

const uploadImage = {
  name: 'uploadImage',
  obj: i18n.t('Collect image inspirations'),
  info: i18n.t('Participants share photos inspired by your question.'),
  icon: UploadImageIcon,
  label: i18n.t('Image Collection'),
  unit: i18n.t('image'),
  endpoint: 'upload-image',
  color: '#F5B4C4',
  getHandler,
  parseData,
  getAnswer,
  fields: [...basicFields],
  methodologies: [
    {
      label: i18n.t('Personification'),
      value: 'personification',
      description: i18n.t(
        `If _____ was a [Disney character/celebrity/historical figure/etc.], who would it be?`
      ),
    },
    {
      label: i18n.t('Idea Generator'),
      value: 'ideaGenerator',
      description: i18n.t(`When you think of _____ what images come to mind?`),
    },
    {
      label: i18n.t('Comparison'),
      value: 'comparison',
      description: i18n.t(`What would be a good image to compare _____ to?`),
    },
    {
      label: i18n.t('Oxymoron'),
      value: 'oxymoron',
      description: i18n.t(`What would the exact opposite of _____ be?`),
    },
    {
      label: i18n.t('Hyperbole'),
      value: 'hyperbole',
      description: i18n.t(
        `What would be a good reference to amplify / exaggerate _____?`
      ),
    },
    {
      label: i18n.t('SWOT'),
      value: 'swot',
      description: i18n.t(
        `What would be a good visual representation of a [Strength/Weakness/Opportunity/Threat] for _____?`
      ),
    },
    {
      label: i18n.t('Methaphor'),
      value: 'methaphor',
      description: i18n.t(
        `Share an image of what you think _____ would look if it were _____.`
      ),
    },
    {
      label: i18n.t('N/A'),
      value: 'none',
      description: i18n.t(``),
    },
  ],
  compliment: [
    i18n.t('Well look at that!'),
    i18n.t('Dope pics!'),
    i18n.t('Cool, check this out!'),
    i18n.t('Explore this collection.'),
    i18n.t('Did you see this?'),
  ],
  listed: true,
};

export default uploadImage;
