const SET_PARTICIPANT_TOKEN = 'SET_PARTICIPANT_TOKEN';

const POST_PARTICIPANT_ACCEPT_START = 'POST_PARTICIPANT_ACCEPT_START';
const POST_PARTICIPANT_ACCEPT_SUCCESS = 'POST_PARTICIPANT_ACCEPT_SUCCESS';
const POST_PARTICIPANT_ACCEPT_FAILURE = 'POST_PARTICIPANT_ACCEPT_FAILURE';

const POST_PARTICIPANT_VALIDATE = 'POST_PARTICIPANT_VALIDATE';
const POST_PARTICIPANT_PROFILE_START = 'POST_PARTICIPANT_PROFILE_START';
const POST_PARTICIPANT_PROFILE_SUCCESS = 'POST_PARTICIPANT_PROFILE_SUCCESS';
const POST_PARTICIPANT_PROFILE_FAILURE = 'POST_PARTICIPANT_PROFILE_FAILURE';

const POST_PARTICIPANT_TICKET_REQUEST_START =
  'POST_PARTICIPANT_TICKET_REQUEST_START';
const POST_PARTICIPANT_TICKET_REQUEST_SUCCESS =
  'POST_PARTICIPANT_TICKET_REQUEST_SUCCESS';
const POST_PARTICIPANT_TICKET_REQUEST_FAILURE =
  'POST_PARTICIPANT_TICKET_REQUEST_FAILURE';

const POST_PARTICIPANT_AUDIO_TICKET_REQUEST_START =
  'POST_PARTICIPANT_AUDIO_TICKET_REQUEST_START';
const POST_PARTICIPANT_AUDIO_TICKET_REQUEST_SUCCESS =
  'POST_PARTICIPANT_AUDIO_TICKET_REQUEST_SUCCESS';
const POST_PARTICIPANT_AUDIO_TICKET_REQUEST_FAILURE =
  'POST_PARTICIPANT_AUDIO_TICKET_REQUEST_FAILURE';

const VOID_SESSION = 'VOID_SESSION';

const POST_SESSION_PLAY = 'POST_SESSION_PLAY';

const POST_SESSION_NEXT_START = 'POST_SESSION_NEXT_START';
const POST_SESSION_NEXT_SUCCESS = 'POST_SESSION_NEXT_SUCCESS';
const POST_SESSION_NEXT_FAILURE = 'POST_SESSION_NEXT_FAILURE';

const PUT_SESSION_HOLD = 'PUT_SESSION_HOLD';

const POST_SESSION_PAUSE_START = 'POST_SESSION_PAUSE_START';
const POST_SESSION_PAUSE_SUCCESS = 'POST_SESSION_PAUSE_SUCCESS';
const POST_SESSION_PAUSE_FAILURE = 'POST_SESSION_PAUSE_FAILURE';

const POST_SESSION_ADD_TIME = 'POST_SESSION_ADD_TIME';

const POST_CHAT_MESSAGE_START = 'POST_CHAT_MESSAGE_START';
const POST_CHAT_MESSAGE_SUCCESS = 'POST_CHAT_MESSAGE_SUCCESS';
const POST_CHAT_MESSAGE_FAILURE = 'POST_CHAT_MESSAGE_FAILURE';

const FETCH_CHAT_MESSAGES_START = 'FETCH_CHAT_MESSAGES_START';
const FETCH_CHAT_MESSAGES_SUCCESS = 'FETCH_CHAT_MESSAGES_SUCCESS';
const FETCH_CHAT_MESSAGES_FAILURE = 'FETCH_CHAT_MESSAGES_FAILURE';

const UPDATE_CHAT_MESSAGES = 'UPDATE_CHAT_MESSAGES';

const SET_AUDIO = 'SET_AUDIO';

const POST_MUTE_PARTICIPANT = 'POST_MUTE_PARTICIPANT';
const POST_MUTE_ALL_PARTICIPANTS = 'POST_MUTE_ALL_PARTICIPANTS';
const POST_MUTE_SELF = 'POST_MUTE_SELF';

export {
  SET_PARTICIPANT_TOKEN,
  POST_PARTICIPANT_ACCEPT_START,
  POST_PARTICIPANT_ACCEPT_SUCCESS,
  POST_PARTICIPANT_ACCEPT_FAILURE,
  POST_PARTICIPANT_TICKET_REQUEST_START,
  POST_PARTICIPANT_TICKET_REQUEST_SUCCESS,
  POST_PARTICIPANT_TICKET_REQUEST_FAILURE,
  POST_PARTICIPANT_AUDIO_TICKET_REQUEST_START,
  POST_PARTICIPANT_AUDIO_TICKET_REQUEST_SUCCESS,
  POST_PARTICIPANT_AUDIO_TICKET_REQUEST_FAILURE,
  VOID_SESSION,
  POST_SESSION_PLAY,
  POST_SESSION_NEXT_START,
  POST_SESSION_NEXT_SUCCESS,
  POST_SESSION_NEXT_FAILURE,
  PUT_SESSION_HOLD,
  POST_SESSION_PAUSE_START,
  POST_SESSION_PAUSE_SUCCESS,
  POST_SESSION_PAUSE_FAILURE,
  POST_SESSION_ADD_TIME,
  POST_CHAT_MESSAGE_START,
  POST_CHAT_MESSAGE_SUCCESS,
  POST_CHAT_MESSAGE_FAILURE,
  FETCH_CHAT_MESSAGES_START,
  FETCH_CHAT_MESSAGES_SUCCESS,
  FETCH_CHAT_MESSAGES_FAILURE,
  UPDATE_CHAT_MESSAGES,
  POST_PARTICIPANT_VALIDATE,
  POST_PARTICIPANT_PROFILE_START,
  POST_PARTICIPANT_PROFILE_SUCCESS,
  POST_PARTICIPANT_PROFILE_FAILURE,
  SET_AUDIO,
  POST_MUTE_PARTICIPANT,
  POST_MUTE_ALL_PARTICIPANTS,
  POST_MUTE_SELF,
};
