import styled, { css } from 'styled-components';

import { device } from 'Helpers/styling';

import Link from 'Components/Elements/Link';

export const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

export const PageContent = styled.div`
  flex: 1 0 auto;
  z-index: 2;
`;

export const HeaderPlaceholder = styled.div`
  height: 68px;
  ${device.md`
    height: 80px;
  `}
  width: 100%;
  display: block;

  ${({ hideOnMobile }) =>
    !!hideOnMobile &&
    css`
      display: none;
      ${device.md`
        display: block;
      `}
    `}
`;

const HeaderNav = styled.nav`
  line-height: 1em;

  ${device.md`
    margin-bottom: -4px; 
  `}
`;

HeaderNav.List = styled.ul`
  list-style: none;
  line-height: inherit;
  margin: 0 -6px 0;
`;

HeaderNav.ListItem = styled.li`
  list-style: none;
  float: left;
  line-height: inherit;
  margin: 0 6px;
`;

export { HeaderNav };

export const LogoLink = styled(Link)`
  line-height: 1em;
  margin-bottom: -5px;
`;
