import React from 'react';
import PropTypes from 'prop-types';

import { Route, Redirect } from 'react-router-dom';

import { connect } from 'react-redux';

const mapStateToProps = (state) => {
  const { auth } = state;

  return auth;
};

const PrivateRoute = ({ component: Component, authenticated, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        authenticated ? <Component {...props} /> : <Redirect to="/login" />
      }
    />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.elementType,
  authenticated: PropTypes.bool,
};

export default connect(mapStateToProps)(PrivateRoute);
