import styled, { css } from 'styled-components';

import { device } from 'Helpers/styling';

import { Container } from 'Components/Elements/Grid';
import {
  createShouldForwardProp,
  props as styledSystemProps,
} from '@styled-system/should-forward-prop';

import { ReactComponent as CloseIcon } from 'Assets/images/close.svg';

const shouldForwardProp = createShouldForwardProp([
  ...styledSystemProps,
  'fullPage',
]);

export const ModalContainer = styled(Container)`
  padding: 15px 0;
  box-sizing: border-box;
  ${device.md`
    padding: 50px 0;
  `};
  ${({ fluid }) =>
    !fluid &&
    css`
      padding: 15px 20px;
      ${device.md`
    padding: 50px 20px;
  `};
    `};
  margin: 0 auto;
  position: relative;
  ${({ fullPage }) =>
    fullPage &&
    css`
      padding: 0;
      width: 100%;
      margin: 0;
      height: 100%;
      max-width: 100%;
      overflow: hidden;
    `}
`;

export const ModalContent = styled.div.withConfig({
  shouldForwardProp,
})`
  width: 100%;
  position: relative;
  margin: 0 auto;
  padding-bottom: 75px;
  ${device.md`
  padding-bottom: 20px;
  `}
  left: 0;
  top: ${({ closable }) => (closable ? '10px' : 0)};
  display: inline-flex;
  flex-direction: column;
  ${({ closable, contentHeight }) =>
    !!contentHeight &&
    css`
      height: ${!closable ? contentHeight : `calc(${contentHeight} - 10px)`};
    `};

  ${({ fullPage }) =>
    fullPage &&
    css`
      top: 0;
      width: 100%;
      height: inherit;
      padding: 0;
      justify-content: center;
      align-items: center;
    `}
`;

export const ModalCloseButton = styled(CloseIcon).withConfig({
  shouldForwardProp,
})`
  width: 30px;
  height: 30px;

  path {
    fill: ${(props) => props.theme.colors.light};
  }
  circle {
    stroke: ${(props) => props.theme.colors.light};
  }
  transition: opacity 0.1s ease-in-out;
  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
  &:focus {
    outline: none;
  }

  ${({ fullPage }) =>
    !fullPage
      ? css`
          top: 5px;
          right: -10px;
          position: relative;
          float: right;
          ${device.lg`
          right: -25px;
          `};
        `
      : css`
          position: absolute;
          top: 15px;
          right: 15px;
          z-index: 100;
          ${device.lg`
          top: 25px;
          right: 25px;
          `};
        `}
`;
