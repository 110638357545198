const blobToDataURI = (blob) => {
  return new Promise((resolve, reject) => {
    try {
      let reader = new FileReader();

      reader.onload = (event) => resolve(event.target.result);

      reader.readAsDataURL(blob);
    } catch (error) {
      reject(error);
    }
  });
};

export default blobToDataURI;
