const session = {
  token: {
    status: 'initial',
    error: null,
    data: null,
  },
  ticket: {
    status: 'initial',
    error: null,
    data: null,
  },
  user: {
    data: null,
  },
  messages: {
    status: 'initial',
    error: null,
    data: [],
  },
  audio: true,
  transition: {
    status: 'initial',
    error: null,
    data: null,
  },
};

export default session;
