import React from 'react';

import Button from 'Components/Elements/Button';
import { Row, Col } from 'Components/Elements/Grid';

const ToastConfirm = ({
  copy = {
    confirm: 'Yes',
    reject: 'No',
  },
  handlers = {
    confirm: () => {},
    reject: () => {},
  },
}) => {
  return (
    <Row mx="0" minWidth={{ _: undefined, md: '400px' }}>
      <Col p="0" span="12">
        {copy.body}
      </Col>
      <Col p="0" span="12" h-align="right">
        <Row mx="0" mt="10px" maxWidth="240px">
          <Col p="0 5px" span="auto">
            <Button
              variant="secondary"
              onClick={() => {
                handlers.reject();
              }}
            >
              {copy.reject}
            </Button>
          </Col>
          <Col p="0 5px" span="auto">
            <Button variant="primary" onClick={() => handlers.confirm()}>
              {copy.confirm}
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default ToastConfirm;
