import { combineReducers } from 'redux';

import { auth } from './auth';
import { user } from './user';
import { brainstorm } from './brainstorm';
import { session } from './session';
import { module } from './module';
import { payment } from './payment';
import { tour } from './tour';

export default combineReducers({
  auth,
  user,
  brainstorm,
  session,
  module,
  payment,
  tour,
});
