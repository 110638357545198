import { isFilled } from 'Helpers/validations';

const useRequired = (asyncChecker) => {
  const toArray = (data) => {
    const dataType = data.constructor.name;

    switch (dataType) {
      case 'Object':
        return Object.entries(data);
      case 'Array':
        return data;
      default:
        return null;
    }
  };

  return (data) => {
    return new Promise(async (resolve) => {
      let formatedData = toArray(data);

      if (!formatedData) return;

      let errors;

      errors = {};

      formatedData.forEach(async ([key, value]) => {
        if (key !== 'data') {
          const foundEmpty = !isFilled(value);
          if (foundEmpty) {
            errors[key] = isFilled;
          }
        } else {
          errors.data = [];
          value.forEach((datum, index) => {
            const nodeError = {};

            let nodeData = toArray(datum);

            nodeData.forEach(async ([key, value]) => {
              const foundEmpty = !isFilled(value);

              if (foundEmpty) {
                nodeError[key] = isFilled;
              }
            });

            errors.data[index] = nodeError;
          });
        }
      });

      const newState = await asyncChecker(errors);

      return resolve(newState);
    });
  };
};

export default useRequired;
