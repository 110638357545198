import KEYWORD_GENERATION from 'Abstracts/modules/keywords';
import UPLOAD_IMAGE from 'Abstracts/modules/uploadImage';
import UPLOAD_VIDEO from 'Abstracts/modules/uploadVideo';
import UPLOAD_AUDIO from 'Abstracts/modules/uploadAudio';
import TYPE_SOMETHING from 'Abstracts/modules/typeSomething';
import PICK_COLOR from 'Abstracts/modules/pickColor';

const modules = {
  KEYWORD_GENERATION,
  UPLOAD_IMAGE,
  UPLOAD_VIDEO,
  UPLOAD_AUDIO,
  TYPE_SOMETHING,
  PICK_COLOR,
};

export default modules;

export const getModuleBy = (node, value) =>
  Object.values(modules).find((item) => item[node] === value);
