import styled, { css } from 'styled-components';

import { rgba, lighten } from 'polished';

import { colors } from 'Abstracts/variables';

import Icon from 'Components/Elements/Icon';
import { Text } from 'Components/Elements/TypeSet';
import Card from 'Components/Elements/Card';
import { ReactComponent as EditIcon } from 'Assets/images/icons/edit-white.svg';
import { ReactComponent as ViewIcon } from 'Assets/images/eyes.svg';

export const StyledView = styled(ViewIcon)`
  width: 24px;
`;

export const StyledEdit = styled(EditIcon)`
  width: 24px;
`;

export const StyledCard = styled(Card)`
  background-position: center;
  background-size: cover;
  position: relative;
  border-radius: 20px;
  padding-top: 53%;
  position: relative;
  overflow: visible;
  &:before,
  &:after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: transparent;
    transition: background-color 0.1s ease-in-out;
  }
  &:after {
    pointer-events: none;
  }
  ${({ clickable, removing }) =>
    !removing &&
    clickable &&
    css`
      cursor: pointer;
      &:hover {
        &:before {
          background-color: ${rgba(colors.dark, 0.3)};
        }
      }
    `}
  ${({ removing }) =>
    removing
      ? css`
          &:after {
            background-color: ${rgba(colors.light, 0.3)};
          }
        `
      : css`
          mask-image: -webkit-radial-gradient(white, black);
        `}
`;

export const StyledCardInner = styled.div`
  display: inline-flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 15px 15px 15px 20px;
  box-sizing: border-box;
  ${({ removing }) =>
    removing &&
    css`
      pointer-events: none;
    `}
`;

export const StyledIcon = styled(Icon)`
  margin: -10px;
`;

export const CardTitle = styled(Text)`
  text-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
`;

export const RemoveButton = styled.div`
  background-color: ${colors.red};
  border-radius: 100%;
  position: absolute;
  top: -10px;
  right: -10px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 2;
  &:hover {
    background-color: ${lighten(0.1, colors.red)};
  }
`;
