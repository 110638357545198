const SET_TOKEN = 'SET_TOKEN';
const SET_AUTHENTICATE = 'SET_AUTHENTICATE';

const POST_SIGNUP_START = 'POST_SIGNUP_START';
const POST_SIGNUP_SUCCESS = 'POST_SIGNUP_SUCCESS';
const POST_SIGNUP_FAILURE = 'POST_SIGNUP_FAILURE';

export {
  SET_TOKEN,
  SET_AUTHENTICATE,
  POST_SIGNUP_START,
  POST_SIGNUP_SUCCESS,
  POST_SIGNUP_FAILURE,
};
