import React, { createContext, useState } from 'react';

import { Redirect, Link, useLocation, useHistory } from 'react-router-dom';

export const Context = createContext({});

export const { Consumer } = Context;

export const Provider = (props) => {
  const { initState, children } = props;

  const [state, setState] = useState(initState);

  const history = useHistory();
  const location = useLocation();

  const pushHistory = ({ pathname, state }) => {
    return history.push(pathname, state);
  };

  const handleChange = (field, { target }) => {
    setState((prevState) => ({
      ...prevState,
      [field]: target.value,
    }));
  };

  const authContext = {
    state,
    setState,
    location,
    pushHistory,
    handleChange,
  };

  return <Context.Provider value={authContext}>{children}</Context.Provider>;
};
