import { css } from 'styled-components';

// DEPRECATED, DON'T USE

export const smallGreyItalic = css`
  font-size: 14px;
  font-style: italic;
  color: #999999;
`;

export const smallerDarkItalic = css`
  font-size: 14px;
  font-style: italic;
  color: #666666;
`;

export const placeholder = css`
  &::-webkit-input-placeholder {
    ${smallGreyItalic}
  }
`;

export const labelSpan = css`
  font-size: 16px;
`;

export const small = css`
  font-size: 12px;
`;

export const error = css`
  color: red !important;
  font-size: 12px;
`;

export const smallGrey = css`
  color: #666666;
  font-size: 14px;
`;

export const smallBlue = css`
  color: #0083c7;
  font-size: 14px;
`;

export const smallWhite = css`
  color: #fff;
  font-size: 12px;
`;

export const smallWhiteItalic = css`
  color: #fff;
  font-size: 14px;
  font-style: italic;
`;

export const smallerWhite = css`
  color: #fff;
  font-size: 10px;
`;

export const italic = css`
  font-style: italic;
`;

export const regularGrey = css`
  color: #666666;
  font-size: 16px;
`;

export const regularBlue = css`
  color: #0083c7;
  font-size: 16px;
`;

export const purpleRegular = css`
  color: #622de3;
`;

export const label = css`
  font-size: 15px;
  position: relative;
  padding-right: 10px;
  top: 5px;
`;
