import React from 'react';
import i18n from '@/i18n';

import styled from 'styled-components';

import { device } from 'Helpers/styling';

const FooterWrapper = styled.div`
  font-size: 10px;
  line-height: 16px;
  flex-shrink: 0;
  width: 100%;
  padding: 40px 20px;
  text-align: left;
  color: #fff;
  ${device.md`
    font-size: 12px;
    padding: 55px 20px 12.5px;
  `}
`;

const Footer = () => {
  return (
    <FooterWrapper>
      {i18n.t('©2001-2020 All Rights Reserved. BRAINSTORM Online®')}
    </FooterWrapper>
  );
};

export default Footer;
