import { useContext, useEffect, useRef } from 'react';
import { ThemeContext } from 'Contexts';

const useConfirm = () => {
  const { changeThemeSetup } = useContext(ThemeContext);

  const rejectRef = useRef();

  const closeDialog = () =>
    changeThemeSetup({
      dialog: {
        show: false,
      },
    });

  const handleReply = (resolve) => (ans) => {
    resolve(ans);
    closeDialog();
  };

  useEffect(() => {
    return () => {
      if (rejectRef.current) {
        rejectRef.current();
      }
      closeDialog();
    };
  }, []);

  return (prompt, options) =>
    new Promise((resolve, reject) => {
      rejectRef.current = () => reject({ canceled: true });

      changeThemeSetup({
        dialog: {
          show: true,
          prompt,
          options,
          reply: handleReply(resolve),
        },
      });
    });
};

export default useConfirm;
