import axiosInstance from 'Helpers/api/_instance';

export const fetchCard = (token) =>
  axiosInstance({
    method: 'get',
    url: `payment/card`,
    headers: { Authorization: token },
  });

export const fetchCust = (token) =>
  axiosInstance({
    method: 'get',
    url: `payment/card/default`,
    headers: { Authorization: token },
  });

export const fetchInvoice = (token) =>
  axiosInstance({
    method: 'get',
    url: `payment/invoice`,
    headers: { Authorization: token },
  });

export const postDefaultCard = (token, id) =>
  axiosInstance({
    method: 'post',
    url: `payment/card/default/${id}`,
    headers: { Authorization: token },
  });

export const postCreateCard = async (data, token) =>
  axiosInstance({
    method: 'post',
    url: `payment/card`,
    headers: { 'Content-Type': 'application/json', Authorization: token },
    data,
  });
