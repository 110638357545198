import React, { useMemo, useState, useContext, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { DataContext } from 'Contexts';

import { Badge } from 'Components/Elements/Badge';
import Drawer from 'Components/Elements/Drawer';
import Chat from 'Components/Compounds/Chat';

// Actions
import { postChatMessage } from 'Stores/actions/session';

import { ReactComponent as ChatIcon } from 'Assets/images/icons/chat.svg';

import { BadgeIconWrapper } from '../SessionFrame.styles';

const ChatSection = ({ brainstormId, showNav, setShowNav }) => {
  const dispatch = useDispatch();

  const { scopedData, setScopedData } = useContext(DataContext);

  const chatMessages =
    useSelector((state) => state.session.messages.data, {}) || false;

  let criteria = {
    limit: 10,
  };

  if (chatMessages && chatMessages.length > 0)
    criteria.last_id = chatMessages[chatMessages.length - 1].id;

  const handleOpenChat = () => {
    setShowNav((prevState) => (prevState !== 'chat' ? 'chat' : 'none'));
  };

  const handleSubmitChat = (type, message) => {
    dispatch(postChatMessage(brainstormId, type, message));
  };

  const handleLastMessage = (messageId) => {
    setScopedData(() => ({
      lastMessageId: messageId,
    }));
  };

  const chatData = useMemo(
    () => chatMessages.map((msg) => scopedData.session.parsedMessage(msg)),
    [chatMessages]
  );

  const unreadCount = !scopedData.lastMessageId
    ? chatData.length
    : chatData.findIndex((message) => message.id === scopedData.lastMessageId);

  const handleFetchChat = () => {
    return scopedData.session.fetchMessages(criteria);
  };

  useEffect(() => {
    if (scopedData.session) scopedData.session.fetchMessages(criteria, false);
  }, []);

  return (
    <>
      <BadgeIconWrapper>
        <ChatIcon
          height="45px"
          onClick={handleOpenChat}
          style={{ cursor: 'pointer' }}
        />
        {unreadCount > 0 && <Badge>{unreadCount}</Badge>}
      </BadgeIconWrapper>
      <Drawer
        show={showNav === 'chat'}
        closable
        hideButton
        pt="0"
        pb={{ _: '19px', md: '40px' }}
        px={{ _: '22.5px', md: '35px' }}
        maxWidth="630px"
        onClose={handleOpenChat}
        outside
        mountOnEnter
        unmountOnExit
      >
        <Chat
          data={chatData}
          dataHandler={handleSubmitChat}
          lastHandler={handleLastMessage}
          fetchHandler={handleFetchChat}
          lastSeen={scopedData.lastMessageId}
          fadeColor="#f5f5f5"
        />
      </Drawer>
    </>
  );
};

export default ChatSection;
