import React from 'react';

import styled, { css } from 'styled-components';
import shouldForwardProp from '@styled-system/should-forward-prop';

import {
  compose,
  variant,
  padding,
  layout,
  flexbox,
  border,
  space,
  color,
  system,
  typography,
} from 'styled-system';

import { device } from 'Helpers/styling';
import layoutStyles from 'Mixins/styles/layout';

// Render
const Component = styled.div`
  box-sizing: border-box;
  display: flex;

  ${system({
    opacity: true,
  })};

  flex-direction: column;
  padding: 12px 15px;
  width: 100%;

  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
  max-height: 100%;

  ${variant({
    prop: 'h-align',
    variants: layoutStyles['h-align'],
  })};

  ${variant({
    prop: 'v-align',
    variants: layoutStyles['v-align'],
  })};

  ${({ span, width }) => {
    if (!span) return;

    const getStyle = (span) => {
      if (typeof span === 'string' && span === 'auto') {
        return `
            flex: 0 0 auto;
            ${
              !width
                ? `
            max-width: none;
            width: auto;
            flex-grow: 1;
            `
                : ''
            }
          `;
      }

      let spanWidth = `${(span / 12) * 100}%`;

      return `
            flex: 0 0 ${spanWidth};
            max-width: ${spanWidth};
          `;
    };

    if (typeof span === 'number' || typeof span === 'string') {
      return css`
        ${getStyle(span)}
      `;
    }

    if (typeof span === 'object') {
      return Object.entries(span).map(([key, value]) => {
        if (key === '_' || key === 'default') {
          return css`
            ${getStyle(value)}
          `;
        }

        return device[key]`${getStyle(value)};`;
      });
    }
  }};

  ${compose(padding, flexbox, layout, space, color, border)};
`;

const Col = styled(Component).withConfig({
  shouldForwardProp,
})(layout);

export default Col;
