import { useState, useEffect, useCallback, useRef } from 'react';

export default function useEdge(elRef, position, dep = []) {
  const [windowDimensions, setWindowDimensions] = useState(null);
  const [elDimensions, setElDimensions] = useState(null);
  const mountedRef = useRef(false);

  const updateDimensions = useCallback(() => {
    if (!elRef || !mountedRef.current) return;

    setWindowDimensions({
      width: window.innerWidth,
      height: window.innerHeight,
    });

    if (elRef.current) {
      const elRect = elRef.current.getBoundingClientRect();

      setElDimensions({
        x: elRect.left + window.scrollX,
        y: elRect.top + window.scrollY,
        width: elRect.width,
        height: elRect.height,
      });
    }
  }, []);

  useEffect(() => {
    mountedRef.current = true;

    updateDimensions();

    window.addEventListener('resize', updateDimensions);
    return () => {
      mountedRef.current = false;
      window.removeEventListener('resize', updateDimensions);
    };
  }, dep);

  let res;

  if (!elDimensions || !windowDimensions) {
    res = null;
  } else {
    switch (position) {
      case 'top':
        res = elDimensions.height + elDimensions.y;
        break;
      case 'bottom':
        res = windowDimensions.height - elDimensions.y;
        break;
      case 'left':
        res = elDimensions.width + elDimensions.x;
        break;
      case 'right':
        res = windowDimensions.width - elDimensions.x;
        break;
      default:
    }
  }

  return res;
}
