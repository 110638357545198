import React, { useState, useEffect, useMemo, useCallback } from 'react';

import { useSelector } from 'react-redux';

import { colors } from 'Abstracts/variables';

// Elements
import { Container, Row, Col } from 'Components/Elements/Grid';
import Avatar from 'Components/Compounds/Avatar';

import Mount from 'Components/Elements/Spring/Mount';
import slideDown from 'Mixins/spring/slideDown';

import AccountDrawer from 'Components/Compounds/AccountDrawer';

import plans from 'Abstracts/plans';

// Icons
import { ReactComponent as BrainstormIcon } from 'Assets/images/brainstorm-logo.svg';

import {
  HeaderPlaceholder,
  HeaderNav,
  LogoLink,
} from 'Components/Frames/_common/Frames.styles';
import { useResponsive } from 'Helpers/hooks';
import { HeaderWrapper } from '../SessionFrame.styles';

import ChatSection from './ChatSection';

import UserSection from './UserSection';

const Header = (props) => {
  const {
    isFacilitator,
    participants,
    brainstormId,
    permission,
    status,
    chiefStream,
  } = props;

  const [showNav, setShowNav] = useState('none');

  const screen = useResponsive();
  const isDesktop = screen.size('md');

  const { user } = useSelector((state) => state.session) || '';
  const userAuth = useSelector((state) => state.user) || '';

  const handleOpenNav = () => {
    setShowNav((prevState) => (prevState !== 'account' ? 'account' : 'none'));
  };

  return (
    <>
      <HeaderPlaceholder />
      <Mount
        id="main-header"
        animationOption={{
          native: true,
          disable: status !== 'holding' || !isDesktop,
          ...slideDown,
        }}
        component={HeaderWrapper}
      >
        <Container fluid>
          <Row
            span={{ _: 12 }}
            mx="0"
            minHeight={{ _: '68px', md: '80px' }}
            style={{ zIndex: 100 }}
            padding={{ _: '0 5px', md: '10px 20px 10px 40px' }}
          >
            <Col span="auto" p="0" v-align="center" h-align="left">
              <LogoLink to="/">
                <BrainstormIcon />
              </LogoLink>
            </Col>

            <Col span="auto" p="0" v-align="center" h-align="right">
              <HeaderNav>
                <HeaderNav.List>
                  <HeaderNav.ListItem>
                    <ChatSection
                      brainstormId={brainstormId}
                      setShowNav={setShowNav}
                      showNav={showNav}
                    />
                  </HeaderNav.ListItem>
                  <HeaderNav.ListItem>
                    {isFacilitator ? (
                      <>
                        <Avatar
                          name={`${userAuth.data.name} ${userAuth.data.surname}`}
                          picture={userAuth.data.image || null}
                          onClick={handleOpenNav}
                          style={{ cursor: 'pointer' }}
                          color={colors.secondary}
                          size="light"
                          tooltip={null}
                        />
                        <AccountDrawer
                          // outside
                          show={showNav === 'account'}
                          onClose={() => setShowNav(false)}
                          onClick={() => setShowNav(false)}
                        />
                      </>
                    ) : (
                      <>
                        {/* {user.data && (
                          <Avatar
                            name={user.data.username}
                            color={colors.secondary}
                            size="light"
                          />
                        )} */}
                      </>
                    )}
                  </HeaderNav.ListItem>
                  <HeaderNav.ListItem>
                    <UserSection
                      user={isFacilitator ? userAuth.data : user.data}
                      participants={participants}
                      isFacilitator={isFacilitator}
                      brainstormId={brainstormId}
                      type={
                        permission.plan !== plans.SPARK.name &&
                        !!permission.valid
                          ? 'audio'
                          : 'simple'
                      }
                      chiefStream={chiefStream}
                      show={showNav === 'user'}
                      onClose={() =>
                        setShowNav((prevState) =>
                          prevState !== 'user' ? 'user' : 'none'
                        )
                      }
                    />
                  </HeaderNav.ListItem>
                </HeaderNav.List>
              </HeaderNav>
            </Col>
          </Row>
        </Container>
      </Mount>
    </>
  );
};

export default Header;
