import { useSelector } from 'react-redux';

import getUserPermission from 'Helpers/utils/getUserPermission';

const usePermission = () => {
  const userStatus =
    useSelector((reduxState) => reduxState.user.status, []) || null;
  const userData =
    useSelector((reduxState) => reduxState.user.data, []) || null;

  return getUserPermission(userStatus === 'loaded' && userData);
};

export default usePermission;
