import React, { useState } from 'react';

import Drawer from 'Components/Elements/Drawer';

import { Text, Paragraph } from 'Components/Elements/TypeSet';

import { ReactComponent as QuestionIcon } from 'Assets/images/icons/question-mark.svg';

import i18n from '@/i18n';

import { TipNav, TipIcon, TipList, TipListItem } from './Tips.styles';

const Tips = (props) => {
  const { data, onClose, onOpen, show } = props;

  const [currentSection, setCurrentSection] = useState('none');
  const [currentTip, setCurrentTip] = useState('none');

  return (
    <>
      <TipIcon onClick={onOpen}>
        <QuestionIcon />
      </TipIcon>

      <Drawer
        show={show}
        onClose={onClose}
        maxWidth="428px"
        closable
        transparent
        noPadding
        headerStyle={{
          padding: { _: '21px 70px 21px 50px', md: '21px 70px 21px 50px' },
        }}
        title={i18n.t('How to Tips')}
        description={
          <TipNav>
            <ul>
              <li>
                <a onClick={() => setCurrentSection('none')}>Home</a>
              </li>
              {currentSection !== 'none' && (
                <>
                  <li>
                    {
                      data.find((section) => section.name === currentSection)
                        .label
                    }
                  </li>
                </>
              )}
            </ul>
          </TipNav>
        }
      >
        {currentSection === 'none' ? (
          <TipList>
            {data.map((section, index) => (
              <TipListItem
                key={index}
                onClick={() => setCurrentSection(section.name)}
              >
                <Text variant="font-13">{section.label}</Text>
              </TipListItem>
            ))}
          </TipList>
        ) : (
          <TipList>
            {data
              .find((section) => section.name === currentSection)
              .tips.map((tip, index) => (
                <TipListItem
                  key={index}
                  onClick={() =>
                    currentTip !== tip.name
                      ? setCurrentTip(tip.name)
                      : setCurrentTip('none')
                  }
                  activated={currentTip === tip.name}
                >
                  <Text variant="font-13">{tip.label}</Text>

                  {currentTip === tip.name && (
                    <Paragraph as="div" pt="20px">
                      {tip.content}
                    </Paragraph>
                  )}
                </TipListItem>
              ))}
          </TipList>
        )}
      </Drawer>
    </>
  );
};

export default Tips;
