import React from 'react';
import { Text } from 'Components/Elements/TypeSet';
import { useHistory } from 'react-router-dom';

const ExpiryBanner = ({ days, plan }) => {
  const history = useHistory();
  const status =
    days < 7 && days > 0
      ? `Your ${
          plan === 'spark' ? 'free trial' : 'subscription'
        } is about to expire, please `
      : 'BRAINSTORM is locked because your subscription has been paused or expired. To create a brainstorm, please ';

  return (
    <>
      <Text color="white">
        {`${status}`}
        <Text
          textDecoration="underline"
          fontWeight="bold"
          cursor="pointer"
          onClick={() => history.push('/user')}
        >
          choose your plan
        </Text>
        .
      </Text>
    </>
  );
};

export default ExpiryBanner;
