import React, { useMemo } from 'react';

import { ReactLottie } from 'Helpers/libs';

const Lottie = (props) => {
  const { data, options = {}, ...rest } = props;

  const animation = useMemo(() => JSON.parse(JSON.stringify(data)), []); // Clone to prevent memory leak

  const defaultOptions = {
    loop: true,
    autoplay: true,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  const lottieOptions = {
    ...defaultOptions,
    ...options,
    animationData: animation,
  };

  return <ReactLottie options={lottieOptions} {...rest} />;
};

export default Lottie;
