import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import { matchPath } from 'react-router-dom';

import {
  init as sentryInit,
  reactRouterV5Instrumentation,
  createReduxEnhancer as createSentryReduxEnhancer,
} from '@sentry/react';

import { Integrations as SentryIntegrations } from '@sentry/tracing';

import routes from 'Abstracts/routes';

import { Provider } from 'react-redux';
// import { actions } from 'Stores';

import '@/i18n';
import history from '@/history';

import App from '@/App';
import withContext, {
  ThemeContextProvider,
  DataContextProvider,
} from 'Contexts';

import { ThemeProvider as StyledProvider } from 'styled-components';
import styledTheme from '@/styled-system';

import withDND from 'Containers/withDND';
import withTracker from 'Containers/withTracker';
import withServiceWorker from 'Containers/withServiceWorker';

import ErrorBoundary from 'Components/ErrorBoundary';

import { configureStore } from '@reduxjs/toolkit';

// const { initializeStore } = actions;
import reducer from 'Stores/reducers';
import initialState from 'Stores/states';

const sentryReduxEnhancer = createSentryReduxEnhancer({});

const store = configureStore({
  reducer,
  preloadedState: initialState,
  devTools: process.env.NODE_ENV !== 'production',
  enhancers: [sentryReduxEnhancer],
});

// const store = initializeStore();

const isDev = process.env.TARGET_ENV === 'development';

if (!isDev) {
  sentryInit({
    dsn: process.env.SENTRY_DSN,
    normalizeDepth: 5,
    environment: process.env.TARGET_ENV,
    integrations: [
      new SentryIntegrations.BrowserTracing({
        routingInstrumentation: reactRouterV5Instrumentation(
          history,
          routes,
          matchPath
        ),
      }),
    ],
    maxBreadcrumbs: 20,
    tracesSampleRate: 1.0,
  });
}

const AppWithContexts = withServiceWorker(
  withTracker(
    withDND(
      withContext(withContext(App, ThemeContextProvider), DataContextProvider)
    )
  )
);

AppWithContexts.displayName = 'AppWithContexts';

ReactDOM.render(
  <ErrorBoundary>
    <Provider store={store}>
      <StyledProvider theme={styledTheme}>
        <AppWithContexts history={history} />
      </StyledProvider>
    </Provider>
  </ErrorBoundary>,
  document.querySelector('#root')
);
