import axiosInstance from 'Helpers/api/_instance';

export const submitKeywords = (brainstormId, moduleId, data, token) =>
  axiosInstance({
    method: 'post',
    url: `participant/brainstorm/${brainstormId}/module/${moduleId}/keywords`,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: token,
    },
    data,
  });

export const uploadImage = (
  brainstormId,
  moduleId,
  data,
  progresHandler,
  cancelToken,
  token
) =>
  axiosInstance({
    method: 'post',
    url: `participant/brainstorm/${brainstormId}/module/${moduleId}/upload-image`,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: token,
    },
    data,
    cancelToken,
    onUploadProgress: (event) => {
      const progress = Math.round((event.loaded / event.total) * 100);
      progresHandler(progress >= 100 ? 99 : progress);
    },
  });

export const removeImage = (brainstormId, moduleId, fileId, token) =>
  axiosInstance({
    method: 'delete',
    url: `participant/brainstorm/${brainstormId}/module/${moduleId}/upload-image/${fileId}`,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: token,
    },
  });

export const updateImage = (brainstormId, moduleId, fileId, token) =>
  axiosInstance({
    method: 'put',
    url: `participant/brainstorm/${brainstormId}/module/${moduleId}/upload-image/${fileId}`,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: token,
    },
  });

export const uploadAudio = (
  brainstormId,
  moduleId,
  data,
  progresHandler,
  cancelToken,
  token
) =>
  axiosInstance({
    method: 'post',
    url: `participant/brainstorm/${brainstormId}/module/${moduleId}/upload-audio`,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: token,
    },
    data,
    cancelToken,
    onUploadProgress: (event) => {
      const progress = Math.round((event.loaded / event.total) * 100);
      progresHandler(progress >= 100 ? 99 : progress);
    },
  });

export const getSoundcloud = (brainstormId, data, token) =>
  axiosInstance({
    method: 'post',
    url: `participant/brainstorm/${brainstormId}/soundcloud`,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: token,
    },
    data,
  });

export const removeAudio = (brainstormId, moduleId, fileId, token) =>
  axiosInstance({
    method: 'delete',
    url: `participant/brainstorm/${brainstormId}/module/${moduleId}/upload-audio/${fileId}`,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: token,
    },
  });

export const updateAudio = (brainstormId, moduleId, fileId, token) =>
  axiosInstance({
    method: 'put',
    url: `participant/brainstorm/${brainstormId}/module/${moduleId}/upload-audio/${fileId}`,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: token,
    },
  });

export const uploadAudioUrl = (brainstormId, moduleId, data, token) =>
  axiosInstance({
    method: 'post',
    url: `participant/brainstorm/${brainstormId}/module/${moduleId}/upload-audio`,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: token,
    },
    data,
  });

export const uploadVideo = (
  brainstormId,
  moduleId,
  data,
  progresHandler,
  token
) =>
  axiosInstance({
    method: 'post',
    url: `participant/brainstorm/${brainstormId}/module/${moduleId}/upload-video`,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: token,
    },
    data,
    onUploadProgress: (event) => {
      const progress = Math.round((event.loaded / event.total) * 100);
      progresHandler(progress >= 100 ? 99 : progress);
    },
  });
export const removeVideo = (brainstormId, moduleId, fileId, token) =>
  axiosInstance({
    method: 'delete',
    url: `participant/brainstorm/${brainstormId}/module/${moduleId}/upload-video/${fileId}`,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: token,
    },
  });

export const updateVideo = (brainstormId, moduleId, fileId, token) =>
  axiosInstance({
    method: 'put',
    url: `participant/brainstorm/${brainstormId}/module/${moduleId}/upload-video/${fileId}`,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: token,
    },
  });

export const submitTypeSomething = (brainstormId, moduleId, data, token) =>
  axiosInstance({
    method: 'post',
    url: `participant/brainstorm/${brainstormId}/module/${moduleId}/type-something`,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: token,
    },
    data,
  });

export const submitPickColor = (brainstormId, moduleId, data, token) =>
  axiosInstance({
    method: 'post',
    url: `participant/brainstorm/${brainstormId}/module/${moduleId}/pick-color`,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: token,
    },
    data,
  });

export const voteModule = (brainstormId, moduleId, data, token) =>
  axiosInstance({
    method: 'post',
    url: `participant/brainstorm/${brainstormId}/module/${moduleId}/vote`,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: token,
    },
    data,
  });

export const requestVote = (brainstormId, moduleId, token) =>
  axiosInstance({
    method: 'post',
    url: `participant/brainstorm/${brainstormId}/module/${moduleId}/request-vote`,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: token,
    },
  });

export const voteSelectOptions = (brainstormId, moduleId, data, token) =>
  axiosInstance({
    method: 'post',
    url: `brainstorm/${brainstormId}/module/${moduleId}/vote-select-options`,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: token,
    },
    data,
  });
