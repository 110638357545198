import axios from 'axios';

const TIMEOUT_MS = 3000;

const isRemoteAvailable = (url) => {
  const timeout = new Promise((_, reject) =>
    setTimeout(reject, TIMEOUT_MS, 'Request timed out')
  );

  const request = axios.head(url);

  return Promise.race([timeout, request])
    .then(() => true)
    .catch(() => false);
};

export default isRemoteAvailable;
