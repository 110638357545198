import React, { forwardRef } from 'react';

import styled, { css } from 'styled-components';
import { variant, layout, space } from 'styled-system';

import { device } from 'Helpers/styling';
import layoutStyles from 'Mixins/styles/layout';

import {
  createShouldForwardProp,
  props as styledSystemProps,
} from '@styled-system/should-forward-prop';

const shouldForwardProp = createShouldForwardProp([
  ...styledSystemProps,
  'fullPage',
]);

const Component = styled.div.withConfig({
  shouldForwardProp,
})`
  margin: 0 auto;
  padding-right: 15px;
  padding-left: 15px;
  width: 100%;

  ${variant({
    prop: 'h-align',
    variants: layoutStyles['h-align'],
  })};

  ${variant({
    prop: 'v-align',
    variants: layoutStyles['v-align'],
  })};

  ${({ fluid }) =>
    !fluid
      ? css`
      ${device.sm`
        max-width: 900px;
      `}
      ${device.md`
        max-width: 1000px;
      `}
      ${device.lg`
        max-width: 1140px;
      `}  
    `
      : css`
          width: 100%;
          padding-right: 15px;
          padding-left: 15px;
          margin-right: auto;
          margin-left: auto;
          ${layout}
        `};

  ${({ fullHeight }) => css`
    ${fullHeight &&
    css`
      height: 100%;
      min-height: 100vh;
    `}
  `};

  ${layout}
  ${space}
`;

const Container = forwardRef((props, ref) => {
  const { children, fluid = false, ...rest } = props;

  return (
    <Component ref={ref} fluid={fluid} {...rest}>
      {children}
    </Component>
  );
});

export default Container;
