import styled from 'styled-components';

import { breakpoints } from 'Abstracts/variables';

const ArtBoard = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-self: stretch;
  position: relative;
  left: 50%;
  transform: translateX(-50%);

  @media only screen and (min-width: ${({ device }) =>
      breakpoints[device || 'md']}) and (min-height: ${({ threshold }) =>
      threshold || '900px'}) {
    min-height: 100%;
    max-width: 100%;
    max-height: 100%;
    align-content: center;
    align-items: center;
    justify-content: center;
    justify-items: center;
    position: absolute;
    left: 0;
    top: 0;
    transform: none;
  }
`;

export default ArtBoard;
