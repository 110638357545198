import React, { forwardRef, useMemo } from 'react';

import i18n, { getLangPrefix } from '@/i18n';

import { Link as RouterLink } from 'react-router-dom';

const Link = forwardRef((props, ref) => {
  const { to, ...rest } = props;
  const langPrefix = useMemo(() => getLangPrefix(i18n), [i18n.language]);

  const mergeURL = (prefix, url) =>
    url.indexOf(prefix) !== -1 ? url : prefix + url;

  const newTo =
    typeof to === 'string'
      ? mergeURL(langPrefix, to)
      : { ...to, pathname: mergeURL(langPrefix, to.pathname) };

  return <RouterLink ref={ref} to={newTo} {...rest} />;
});

Link.displayName = 'Link';

export default Link;
