import styled, { css } from 'styled-components';

import { colors } from 'Abstracts/variables';
import fontStyles from 'Mixins/styles/typography';

import DownIcon from 'Assets/images/icons/down-large.svg';
import SmallArrow from 'Assets/images/icons/small-arrow.svg';

export const TipIcon = styled.div`
  height: 100%;
  display: inline-flex;
  align-items: center;
  height: 47px;
  padding: 0 10px;
  &:hover {
    cursor: pointer;
    opacity: 0.85;
  }
`;

export const TipNav = styled.nav`
  ul {
    list-style: none;
    text-align: left;
    li {
      display: inline-block;
      position: relative;
      padding-left: 25px;
      ${fontStyles['font-12']};
      &:before {
        content: '';
        display: block;
        height: 10px;
        width: 5px;
        background: url(${SmallArrow}) no-repeat;
        position: absolute;
        left: 10px;
        top: 50%;
        transform: translateY(-50%);
      }
      a {
        cursor: pointer;
      }
      &:first-child {
        padding-left: 0;
        &:before {
          display: none;
        }
      }
    }
  }
`;

export const TipList = styled.ul`
  list-style: none;
  width: 100%;
  text-align: left;
`;

export const TipListItem = styled.li`
  padding: 36.5px 50px;
  border-bottom: 2px solid ${colors.grey6};
  position: relative;
  ${fontStyles['font-12']};
  &:hover {
    cursor: pointer;
  }
  &:before {
    content: '';
    position: absolute;
    width: 26px;
    height: 15px;
    top: 45px;
    left: calc(100% - 40px);
    background: url(${DownIcon}) no-repeat;
    transform: rotate(270deg) translate3d(25%, 0, 0);
  }

  ${({ activated }) => {
    if (activated === false) {
      return css`
        &:before {
          left: calc(100% - 45px);
          transform: rotate(0deg) translate3d(0, 0, 0);
        }
      `;
    }
    if (activated === true) {
      return css`
        &:before {
          left: calc(100% - 45px);
          transform: rotate(180deg) translate3d(0, 0, 0);
        }
      `;
    }
  }};
`;
