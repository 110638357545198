const hasEmail = (string) => {
  if (!string) return false;

  let value = string.trim();

  const emailsPattern = /([a-zA-Z0-9+._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi;
  if (!emailsPattern.test(value)) return false;

  return true;
};

export default hasEmail;
