import React, { useState, useCallback, useEffect } from 'react';
import i18n from '@/i18n';
import { useDropzone } from 'react-dropzone';

import validator from 'Helpers/validations';

import Image from 'Components/Elements/Image';

import { isObjectEqual } from 'Helpers/utils';

import { ReactComponent as UploadCloudIcon } from 'Assets/images/upload-cloud.svg';
import { ReactComponent as ErrorIcon } from 'Assets/images/error.svg';

import {
  PlaceholderText,
  FileText,
  StyledInput,
  StyledCard,
  FileOverlay,
  FilePreviewImage,
} from './File.styles';

const File = (props) => {
  const {
    onUpload,
    placeholder,
    type,
    padding = '2px 0',
    rules,
    error,
    minSize,
    maxSize,
    accept,
    onError = () => false,
    value,
    multiple = false,
    validateOnMount = false,
    list,
    previewImage,
    showList = true,
    ...rest
  } = props;

  const parseErrorMessage = (rejectedFile) => {
    // Error codes
    // FILE_INVALID_TYPE = 'file-invalid-type'
    // FILE_TOO_LARGE = 'file-too-large'
    // FILE_TOO_SMALL = 'file-too-small'
    // TOO_MANY_FILES = 'too-many-files'
    switch (rejectedFile.code) {
      case 'file-too-large':
        return `${i18n.t('File size must be less than')} ${
          Math.floor((maxSize / (1024 * 1024)) * 100) / 100
        }mb`;
      case 'file-invalid-type':
        return i18n.t('File format is not supported');
      case 'file-too-small':
        return `${i18n.t('File size must be greater than')} ${
          Math.ceil((minSize / (1024 * 1024)) * 100) / 100
        }mb`;
      default:
        return rejectedFile.message;
    }
  };

  const fileOptions = Object.fromEntries(
    Object.entries({
      multiple,
      maxSize,
      accept,
      minSize,
    }).filter(([_, value]) => !!value)
  );

  const [fileList, setFileList] = useState(value || []);
  const [uploadError, setUploadError] = useState('');
  const [isMounted, setMounted] = useState(false);

  const onDrop = useCallback(
    (acceptedFiles) => {
      if (acceptedFiles.length === 0) return;

      setUploadError('');

      if (!multiple && acceptedFiles.length > 1) {
        setUploadError('Please only upload one file');
      } else {
        if (!multiple) {
          setFileList([...acceptedFiles]);
        } else {
          setFileList((prev) => [...prev, ...acceptedFiles]);
        }

        onUpload(acceptedFiles, list);
      }
    },
    [setFileList, onUpload, setUploadError]
  );

  const onDropRejected = useCallback(
    (rejectedFiles) => {
      if (rejectedFiles.length === 0) return;
      let errorMessage = parseErrorMessage(rejectedFiles[0].errors[0]);

      setUploadError(errorMessage);
    },
    [setUploadError]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    onDropRejected,
    ...fileOptions,
  });

  const handleError = useCallback(
    (params) => {
      if (typeof params === 'object') {
        if (params === null) {
          // No error
          onError(false);
        } else {
          // Error with message
          onError(params);
        }
      } else {
        // error without message
        onError(!!params);
      }
    },
    [onError]
  );

  useEffect(() => {
    if (isMounted) {
      if (fileList.length > 0) {
        if (rules) validator(rules, fileList, handleError);
      }
    }
  }, [fileList]);

  useEffect(() => {
    if (value) {
      setUploadError('');
      if (!isObjectEqual(value, fileList)) {
        setFileList(value);
      }
    }
  }, [value]);

  useEffect(() => {
    setMounted(true);
    if (!!validateOnMount && rules) validator(rules, fileList, handleError);
  }, []);

  return (
    <StyledCard
      padding={padding}
      h-align="center"
      isError={!!(uploadError || error)}
      {...getRootProps({})}
      {...rest}
    >
      <StyledInput {...getInputProps()} />
      {previewImage && <FilePreviewImage src={previewImage} />}
      {!(uploadError || error) ? (
        <>
          <Image as={UploadCloudIcon} margin="10px 0" />

          {placeholder ? (
            <PlaceholderText color="#1383c7" underline bold>
              {placeholder}
            </PlaceholderText>
          ) : (
            <PlaceholderText color="#1383c7" underline bold>
              {i18n.t('Upload from computer')}
            </PlaceholderText>
          )}

          <PlaceholderText color="#666">{i18n.t('or')}</PlaceholderText>
          <PlaceholderText color="#666">
            {i18n.t('drag it here')}
          </PlaceholderText>
        </>
      ) : (
        <>
          <ErrorIcon />
          <PlaceholderText error>{uploadError || error}</PlaceholderText>
        </>
      )}

      {showList && fileList.length > 0 && !(uploadError || error) && (
        <FileOverlay v-align="center">
          {fileList.map((file, index) => (
            <FileText key={index}>{file.name}</FileText>
          ))}
        </FileOverlay>
      )}
    </StyledCard>
  );
};

export default File;
