const promiseChain = (promiseFuncs) => {
  return promiseFuncs.reduce((previousPromise, currentFunc) => {
    const handler = (result) =>
      currentFunc()
        .then(Array.prototype.concat.bind(result))
        .catch(Array.prototype.concat.bind(result));

    return previousPromise.then(handler).catch(handler);
  }, Promise.resolve([]));
};

export default promiseChain;
