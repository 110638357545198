import withContext from 'Containers/withContext';

export {
  Context as ThemeContext,
  Provider as ThemeContextProvider,
  Consumer as ThemeContextConsumer,
  defaultState as themeDefaultState,
} from 'Contexts/theme';

export {
  Context as DataContext,
  Provider as DataContextProvider,
  Consumer as DataContextConsumer,
  defaultState as dataDefaultState,
} from 'Contexts/data';

export {
  Context as BrainstormContext,
  Provider as BrainstormContextProvider,
  Consumer as BrainstormContextConsumer,
} from 'Contexts/brainstorm';

export {
  Context as ManageContext,
  Provider as ManageContextProvider,
  Consumer as ManageContextConsumer,
} from 'Contexts/manage';

export {
  Context as SessionContext,
  Provider as SessionContextProvider,
  Consumer as SessionContextConsumer,
} from 'Contexts/session';

export {
  Context as AuthContext,
  Provider as AuthContextProvider,
  Consumer as AuthContextConsumer,
} from 'Contexts/auth';

export default withContext;
