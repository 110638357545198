import styled from 'styled-components';

export const Badge = styled.div`
  padding: 2px;
  position: absolute;
  right: -8px;
  top: -2px;
  background-color: #c64f4b;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Roboto;
  font-size: 12px;
  font-weight: bold;
  line-height: 1.42;
  letter-spacing: 0.48px;
  text-align: center;
  color: #ffffff;
`;
