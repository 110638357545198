import styled from 'styled-components';
import { space, width, variant } from 'styled-system';
import Bullet from 'Components/Elements/Form/Bullet';

export const SmallBullet = styled(Bullet)`
  background: #c64f4b;
  padding: 0;
  text-align: center;
  color: white;
  text-transform: uppercase;
  left: 20px;
  position: absolute;
  top: 15px;
  ${space} ${width}
`;

export const StatusBullet = styled.div`
  border-radius: 20px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #ffffff;
  padding: 3px 6px;
  width: 96px;
  text-align: center;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 500;
  font-style: italic;
  line-height: 1.5;
  letter-spacing: 0.48px;
  color: #fff;
  backgroundcolor: '#666666';
  display: flex;
  align-items: center;
  justify-content: center;
  ${variant({
    variants: {
      ended: {
        backgroundColor: '#0083c6',
      },
      pending: {
        backgroundColor: '#ffbf00',
        color: '#666666',
      },
      holding: {
        backgroundColor: '#2EE080',
        color: '#666666',
      },
      playing: {
        backgroundColor: '#2EE080',
        color: '#666666',
      },
      draft: {
        backgroundColor: '#c64f4b',
      },
    },
  })};
`;
