import React from 'react';
import { Row } from 'Components/Elements/Grid';
import { useResponsive } from 'Helpers/hooks';

import { ReactComponent as LogoColour } from 'Assets/images/colour-logo.svg';
import { ReactComponent as MobileLogoColour } from 'Assets/images/mobile-colour-logo.svg';

export default function SignInHeader() {
  const screen = useResponsive();
  const isDesktop = screen.size('md');

  return (
    <Row
      span={{ _: 12 }}
      mx="0"
      minHeight="80px"
      style={{ zIndex: 100 }}
      padding={{ _: '20px', md: '10px 20px 10px 50px' }}
    >
      <a href={`${process.env.PROMOTE_URI}`}>
        {isDesktop ? <LogoColour /> : <MobileLogoColour />}
      </a>
    </Row>
  );
}
