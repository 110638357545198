import React, { useMemo } from 'react';

import Drawer from 'Components/Elements/Drawer';
import ScrollView from 'Components/Elements/ScrollView';

import { Row, Col } from 'Components/Elements/Grid';
import { Text } from 'Components/Elements/TypeSet';
import Avatar from 'Components/Compounds/Avatar';

import { useResponsive } from 'Helpers/hooks';
import { colors } from 'Abstracts/variables';

import { ReactComponent as AudioIcon } from 'Assets/images/icons/audio-icon.svg';

import {
  UserContainer,
  UserHeader,
  UserContent,
  UserUserContainer,
} from './UserSection.styles';

const UserSectionWithSimple = ({ participants, user, show, onClose }) => {
  const screen = useResponsive();
  const isDesktop = screen.size('md');

  const handleOpenChat = onClose;

  const userName = useMemo(
    () =>
      user
        ? user.name
          ? `${user.name} ${user.surname}`
          : user.username
          ? user.username
          : user.email
        : '',
    [user]
  );

  return (
    <>
      <AudioIcon
        height="45px"
        onClick={handleOpenChat}
        style={{ cursor: 'pointer' }}
      />

      <Drawer
        show={show}
        closable
        hideButton
        outside
        noPadding
        maxWidth="450px"
        onClose={handleOpenChat}
      >
        <UserContainer>
          <UserHeader>
            <Row mx="0">
              <Col
                p="0"
                span={{ _: 4, md: 'auto' }}
                width={{ _: '115px', md: '80px' }}
                h-align="left"
                v-align="center"
              >
                {' '}
                <Avatar
                  name={userName}
                  picture={user ? user.image : null}
                  size={isDesktop ? 'normal' : 'extraLarge'}
                  color={colors.secondary}
                />
              </Col>
              <Col p="0" span={{ _: 8, md: 'auto' }} v-align="center">
                <Row mx="0">
                  <Col
                    p={{ _: '0 0 14px', md: 0 }}
                    span="12"
                    h-align="left"
                    v-align="center"
                  >
                    <Text variant="audioHeader" color="#666">
                      {userName.replace(/(.{20})..+/, '$1...')}
                    </Text>
                  </Col>
                </Row>
              </Col>
            </Row>
          </UserHeader>
          <UserContent>
            <ScrollView height="100%">
              {!!participants &&
                participants.length > 0 &&
                participants
                  .filter((participant) => participant.id !== user.id)
                  .map((participant, index) => {
                    return (
                      <UserUserContainer key={index}>
                        <Row mx="0">
                          <Col
                            p="0 18px"
                            span="auto"
                            width="80px"
                            h-align="left"
                            v-align="center"
                          >
                            <Avatar
                              key={index}
                              name={participant.username || participant.email}
                              disabled={!participant.connected}
                            />
                          </Col>
                          <Col p="0 18px" h-align="left" v-align="center">
                            <Text variant="audioUser" color="#666">
                              {(
                                participant.username || participant.email
                              ).replace(/(.{30})..+/, '$1...')}
                            </Text>
                          </Col>
                        </Row>
                      </UserUserContainer>
                    );
                  })}
            </ScrollView>
          </UserContent>
        </UserContainer>
      </Drawer>
    </>
  );
};

export default UserSectionWithSimple;
