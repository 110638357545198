import styled, { css } from 'styled-components';
import { device } from 'Helpers/styling';

import { LogoLink } from 'Components/Frames/_common/Frames.styles';

export const StyledLogoLink = styled(LogoLink)`
  display: inline-block;
  svg {
    width: auto;
    height: 47px;
  }
`;

export const ImageWrapper = styled.img`
  border-radius: 50%;
  width: 45px;
  height: 45px;
  border: 2px solid #622de3;
`;

export const HeaderWrapper = styled.header`
  position: fixed;
  top: 0;
  z-index: 100;
  width: 100%;
  background: #ffbf00;

  ${({ hideOnMobile }) =>
    !!hideOnMobile &&
    css`
      display: none;
      ${device.md`
        display: block;
      `}
    `}
`;
