import React, { forwardRef, useRef, useEffect } from 'react';

import { Transition } from 'react-transition-group';

// Elements
import { Text } from 'Components/Elements/TypeSet';
import { Row } from 'Components/Elements/Grid';

import Overlay from 'Components/Elements/Overlay/WithTransition';

import {
  DrawerContainer,
  DrawerHeader,
  TitleIconWrapper,
  DrawerContent,
  DrawerCloseButton,
} from './Drawer.styles';

const DURATION = 200;
const DRAWER_DURATION = 250;

const drawerDefaultStyle = {
  transition: `transform ${DRAWER_DURATION}ms ease-in-out  ${DURATION}ms`,
  transform: `translateX(calc(100% + 20px))`,
};

const drawerTransitionStyles = {
  entering: { transform: `translateX(calc(100% + 20px))` },
  entered: { transform: `none` },
  exiting: { transform: `translateX(calc(100% + 20px))` },
  exited: { transform: `translateX(calc(100% + 20px))` },
};

const Drawer = forwardRef((props, ref) => {
  const {
    children,
    title = '',
    description = '',
    show = false,
    icon = '',
    outside = false,
    onClose = () => false,
    closable = true,
    transparent = false,
    fluid = false,
    maxWidth = null,
    blurred = false,
    fullHeight = false,
    rounded = false,
    noPadding = false,
    mountOnEnter = false,
    unmountOnExit = false,
    hideButton = false,
    headerStyle = {},
    ...rest
  } = props;

  const node = useRef();

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  const handleClick = (event) => {
    if (!node.current || !closable) return;

    const isOverlay = node.current === event.target;

    if (isOverlay) {
      onClose();
    }
  };

  const Icon = icon;

  return (
    <Transition
      in={show}
      timeout={DURATION + DRAWER_DURATION}
      mountOnEnter={mountOnEnter}
      unmountOnExit={unmountOnExit}
    >
      {(state) => (
        <Overlay
          ref={node}
          transparent={transparent}
          fullHeight={fullHeight}
          blurred={blurred}
          transitionStatus={state}
          transitionDuration={DURATION}
          outside={outside}
        >
          <DrawerContainer
            rounded={rounded}
            maxWidth={maxWidth}
            fluid={fluid}
            style={{
              ...drawerDefaultStyle,
              ...drawerTransitionStyles[state],
            }}
          >
            {(!!title || !!description) && (
              <DrawerHeader {...headerStyle}>
                <Row marginLeft="0">
                  <TitleIconWrapper>
                    <Text variant="h1" color="#333333">
                      {title}
                    </Text>
                  </TitleIconWrapper>
                </Row>
                <Row mt="15px" alignItems="center" ml="5px">
                  {icon ? (
                    <>
                      <Icon />
                      <Text
                        variant="cardTitle-1"
                        color="#666666"
                        ml="14px"
                        mr="20px"
                      >
                        {description.toUpperCase()}
                      </Text>
                    </>
                  ) : (
                    <Text variant="cardTitle-1" color="#666666">
                      {description}
                    </Text>
                  )}
                </Row>
                {!!closable && <DrawerCloseButton onClick={onClose} />}
              </DrawerHeader>
            )}
            <DrawerContent
              closable={closable}
              noPadding={noPadding}
              ref={ref}
              {...rest}
              moduleEditor
            >
              {!!closable && !hideButton && (
                <DrawerCloseButton onClick={onClose} />
              )}
              {children}
            </DrawerContent>
          </DrawerContainer>
        </Overlay>
      )}
    </Transition>
  );
});

export default Drawer;
