import React, { useState } from 'react';

import i18n from '@/i18n';

import { useSelector } from 'react-redux';

import { useResponsive } from 'Helpers/hooks';

// Components
import { Container, Row, Col } from 'Components/Elements/Grid';
import Button from 'Components/Elements/Button';
import Avatar from 'Components/Compounds/Avatar';

import AccountDrawer from 'Components/Compounds/AccountDrawer';

import { colors } from 'Abstracts/variables';

// Icons
import { ReactComponent as Logo } from 'Assets/images/logo-white.svg';
import { ReactComponent as SimpleLogo } from 'Assets/images/brainstorm-logo.svg';

import {
  HeaderPlaceholder,
  HeaderNav,
} from 'Components/Frames/_common/Frames.styles';
import { HeaderWrapper, StyledLogoLink } from '../SessionFrame.styles';

const HeaderAlt = (props) => {
  const { isFacilitator } = props;

  const screen = useResponsive();
  const isDesktop = screen.size('md');

  const [showNav, setShowNav] = useState(false);

  const { user: userAuth } = useSelector((state) => state) || '';

  const handleOpenNav = () => {
    setShowNav((prevState) => !prevState);
  };

  const handleAbout = () => {
    window.open(process.env.PROMOTE_URI, '_blank');
  };

  return (
    <>
      <HeaderPlaceholder />
      <HeaderWrapper id="main-header" color="transparent">
        <Container fluid>
          <Row
            span={{ _: 12 }}
            mx="0"
            minHeight={{ _: '68px', md: '80px' }}
            padding={{ _: '0 5px', md: '10px 20px 10px 40px' }}
            style={{ zIndex: 100 }}
          >
            <Col span="auto" p="0" v-align="center" h-align="left">
              <StyledLogoLink to="/">
                {isDesktop ? <Logo /> : <SimpleLogo />}
              </StyledLogoLink>
            </Col>
            <Col span="auto" p="0" h-align="right" v-align="center">
              <HeaderNav>
                <HeaderNav.List>
                  <HeaderNav.ListItem>
                    {isFacilitator ? (
                      <>
                        <Avatar
                          name={`${userAuth.data.name} ${userAuth.data.surname}`}
                          picture={userAuth.data.image || null}
                          onClick={handleOpenNav}
                          style={{ cursor: 'pointer' }}
                          size="light"
                          color={colors.secondary}
                          tooltip={null}
                        />
                        <AccountDrawer
                          outside
                          show={showNav}
                          onClose={() => setShowNav(false)}
                          onClick={() => setShowNav(false)}
                        />
                      </>
                    ) : (
                      <Button variant="outline-primary" onClick={handleAbout}>
                        {i18n.t('What is BRAINSTORM Online?')}
                      </Button>
                    )}
                  </HeaderNav.ListItem>
                </HeaderNav.List>
              </HeaderNav>
            </Col>
          </Row>
        </Container>
      </HeaderWrapper>
    </>
  );
};

export default HeaderAlt;
