import { fonts } from 'Abstracts/variables';

const fontStyles = {
  // Elements
  text: {
    fontFamily: fonts.roboto,
  },
  button: {
    fontFamily: fonts.roboto,
    fontSize: '16px',
    lineHeight: '19px',
    fontWeight: 500,
  },
  // Customs
  'font-1': {
    fontFamily: fonts.roboto,
    fontSize: '22px',
    fontWeight: '400',
    lettSpacing: 0,
  },
  'font-2': {
    fontFamily: fonts['abril-fatface'],
    fontSize: '32px',
    lineHeight: '36px',
  },
  'font-3': {
    fontFamily: fonts.roboto,
    fontSize: '14px',
    fontStyle: 'Italic',
    lineHeight: '22px',
    WebkitFontSmoothing: 'antialiased',
    MozOsxFontSmoothing: 'grayscale',
  },
  'font-4': {
    fontFamily: fonts.roboto,
    fontSize: '14px',
  },
  'font-5': {
    fontFamily: fonts['abril-fatface'],
    fontSize: '24px',
    lineHeight: '32px',
  },
  'font-6': {
    fontFamily: fonts.roboto,
    fontSize: '12px',
    lineHeight: '18px',
    fontStyle: 'Italic',
    textTransform: 'uppercase',
  },
  'font-7': {
    fontFamily: fonts['abril-fatface'],
    fontSize: '26px',
    lineHeight: '36px',
  },
  'font-8': {
    fontFamily: fonts.roboto,
    fontSize: '11px',
    lineHeight: '16px',
  },
  'font-9': {
    fontFamily: fonts.roboto,
    fontSize: '20px',
    lineHeight: '32px',
    fontWeight: '400',
    letterSpacing: '0',
    WebkitFontSmoothing: 'antialiased',
    MozOsxFontSmoothing: 'grayscale',
  },
  'font-10': {
    fontFamily: fonts['abril-fatface'],
    fontSize: '22px',
    fontWeight: 'normal',
    letterSpacing: '0.96px',
  },
  'font-11': {
    fontFamily: fonts.roboto,
    fontSize: '14px',
    lineHeight: '22px',
    fontWeight: 'normal',
    letterSpacing: '0',
    WebkitFontSmoothing: 'antialiased',
    MozOsxFontSmoothing: 'grayscale',
  },
  'font-12': {
    fontFamily: fonts.roboto,
    fontSize: '16px',
    lineHeight: '26px',
    fontWeight: 'normal',
    WebkitFontSmoothing: 'antialiased',
    MozOsxFontSmoothing: 'grayscale',
  },
  'font-13': {
    fontFamily: fonts.roboto,
    fontSize: '20px',
    lineHeight: '24px',
    fontWeight: '400',
    letterSpacing: '0',
  },
  'font-14': {
    fontFamily: fonts.roboto,
    fontSize: '16px',
    fontWeight: 'normal',
    letterSpacing: 'normal',
    fontStyle: 'italic',
    WebkitFontSmoothing: 'antialiased',
    MozOsxFontSmoothing: 'grayscale',
  },
  'font-15': {
    fontFamily: fonts.roboto,
    fontSize: '16px',
    lineHeight: '1.5em',
    fontWeight: 'normal',
    letterSpacing: 'normal',
  },
  'font-16': {
    fontFamily: fonts.roboto,
    fontSize: '18px',
    lineHeight: '22px',
    fontWeight: 700,
  },
  'font-17': {
    fontFamily: fonts.roboto,
    fontSize: '18px',
    lineHeight: '22px',
    fontWeight: 400,
    WebkitFontSmoothing: 'antialiased',
    MozOsxFontSmoothing: 'grayscale',
  },
  'font-18': {
    fontFamily: fonts.roboto,
    fontSize: '44px',
    lineHeight: '1em',
    fontWeight: 500,
  },
  'font-19': {
    fontFamily: fonts.roboto,
    fontSize: '18px',
    lineHeight: '22px',
    fontWeight: 500,
  },
  'font-20': {
    fontFamily: fonts.roboto,
    fontSize: '16px',
    lineHeight: '26px',
    fontWeight: 400,
    WebkitFontSmoothing: 'antialiased',
    MozOsxFontSmoothing: 'grayscale',
    fontStyle: 'italic',
  },
  'font-21': {
    fontFamily: fonts.roboto,
    fontSize: '20px',
    lineHeight: '32px',
    fontWeight: 500,
  },
  'font-22': {
    fontFamily: fonts.roboto,
    fontSize: '16px',
    lineHeight: '32px',
    fontWeight: 500,
    WebkitFontSmoothing: 'antialiased',
    MozOsxFontSmoothing: 'grayscale',
  },
  'font-23': {
    fontFamily: fonts.roboto,
    fontSize: '18px',
    lineHeight: '22px',
    fontWeight: '800',
    textTransform: 'uppercase',
  },
  purpleRegular: {
    fontFamily: fonts.roboto,
    fontSize: '14px',
    color: '#622de3',
  },
  regular: {
    fontFamily: fonts.roboto,
    fontSize: '14px',
    color: '#fff',
  },
  purpleItalic: {
    fontFamily: fonts.roboto,
    fontSize: '12px',
    fontStyle: 'italic',
    color: '#622de3',
  },
  purpleBold: {
    fontFamily: fonts.roboto,
    fontSize: '20px',
    fontWeight: '800',
    color: '#622de3',
  },
  // updated typography guidelines on 2020-06-22
  h1: {
    fontFamily: fonts['abril-fatface'],
    fontSize: '32px',
    fontWeight: 'normal',
    letterSpacing: '1.28px',
    lineHeight: '1.4em',
  },
  h2: {
    fontFamily: fonts['abril-fatface'],
    fontSize: '24px',
    fontWeight: 'normal',
    letterSpacing: '0.96px',
  },
  h3: {
    fontFamily: fonts.roboto,
    fontSize: '24px',
    fontWeight: '500',
    letterSpacing: '0.24px',
  },
  h4: {
    fontFamily: fonts.roboto,
    fontSize: '20px',
    fontWeight: '500',
    letterSpacing: '0.2px',
  },
  paragraph: {
    fontFamily: fonts.roboto,
    fontSize: '16px',
    fontWeight: 'normal',
    letterSpacing: 'normal',
  },
  question: {
    fontFamily: fonts.roboto,
    fontSize: '16px',
    fontWeight: 'bold',
    letterSpacing: '0.64px',
  },
  textfield: {
    fontFamily: fonts.roboto,
    fontSize: '16px',
    fontWeight: 'normal',
    letterSpacing: 'normal',
    WebkitFontSmoothing: 'antialiased',
    MozOsxFontSmoothing: 'grayscale',
  },
  textfieldPlaceholder: {
    fontFamily: fonts.roboto,
    fontSize: '16px',
    fontWeight: 'normal',
    letterSpacing: 'normal',
    fontStyle: 'italic',
    WebkitFontSmoothing: 'antialiased',
    MozOsxFontSmoothing: 'grayscale',
  },
  moduleAddText: {
    fontFamily: fonts.roboto,
    fontSize: '18px',
    fontWeight: '500',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.22em',
    letterSpacing: '0.18px',
    textDecoration: 'underline',
  },
  cardLink: {
    fontFamily: fonts.roboto,
    fontSize: '16px',
    fontWeight: '500',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.22em',
    letterSpacing: '0.18px',
    textDecoration: 'underline',
  },
  externalLink: {
    fontFamily: fonts.roboto,
    fontSize: '14px',
    fontWeight: 'normal',
    color: '#999999',
    letterSpacing: 'normal',
    fontStyle: 'italic',
  },
  tipsNotes: {
    fontFamily: fonts.roboto,
    fontSize: '14px',
    fontWeight: '400',
    letterSpacing: '0.14px',
    fontStyle: 'italic',
    WebkitFontSmoothing: 'antialiased',
    MozOsxFontSmoothing: 'grayscale',
  },
  error: {
    fontFamily: fonts.roboto,
    fontSize: '14px',
    fontWeight: '500',
    letterSpacing: '0.56px',
    fontStyle: 'italic',
    color: '#c64f4b',
  },
  success: {
    fontFamily: fonts.roboto,
    fontSize: '14px',
    fontWeight: '500',
    letterSpacing: '0.56px',
    fontStyle: 'italic',
    color: '2ee080',
  },

  // Not in Typography Guidelines
  'cardTitle-1': {
    fontFamily: fonts.roboto,
    fontSize: '18px',
    fontWeight: '500',
    letterSpacing: '0.18px',
    lineHeight: '1.2em',
  },
  'cardTitle-2': {
    fontFamily: fonts.roboto,
    fontSize: '14px',
    fontWeight: 'bold',
    lineHeight: '1.1em',
    letterSpacing: '0.14px',
  },
  'cardTitle-3': {
    fontFamily: fonts.roboto,
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '22px',
    letterSpacing: '0',
  },
  'cardTitle-4': {
    fontFamily: fonts.roboto,
    fontSize: '15px',
    fontWeight: 500,
    fontStyle: 'italic',
    lineHeight: '22px',
    letterSpacing: '0',
  },
  'cardTitle-5': {
    fontFamily: fonts['abril-fatface'],
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '22px',
    letterSpacing: '0',
  },
  'cardTitle-6': {
    fontSize: '18px',
    fontWeight: '400',
    letterSpacing: '0',
    lineHeight: '30px',
    WebkitFontSmoothing: 'antialiased',
    MozOsxFontSmoothing: 'grayscale',
  },
  timer: {
    fontFamily: fonts.roboto,
    fontSize: '20px',
    fontWeight: 'bold',
    letterSpacing: '0.18px',
    lineHeight: '1.2em',
  },

  timestamp: {
    fontFamily: fonts.roboto,
    fontSize: '12px',
    fontStyle: 'italic',
    lineHeight: 1.17,
  },
  'avatar-normal': {
    fontFamily: fonts.roboto,
    fontSize: '18px',
    fontWeight: 'bold',
    lineHeight: '1em',
    letterSpacing: '0.72px',
  },
  'avatar-tiny': {
    fontFamily: fonts.roboto,
    fontSize: '12px',
    transform: 'scale(.83)',
    'transform-origin': '80% 40%',
    fontWeight: 'bold',
    lineHeight: '1.5em',
    letterSpacing: '0.4px',
  },
  'avatar-light': {
    fontFamily: fonts.roboto,
    fontSize: '20px',
    fontWeight: 'bold',
    lineHeight: '40px',
    letterSpacing: '0',
  },
  'avatar-lightMedium': {
    fontFamily: fonts.roboto,
    fontSize: '20px',
    fontWeight: 'bold',
    lineHeight: '40px',
    letterSpacing: '0',
  },
  'avatar-medium': {
    fontFamily: fonts.roboto,
    fontSize: '20px',
    fontWeight: 'bold',
    lineHeight: '40px',
    letterSpacing: '0',
  },
  'avatar-large': {
    fontFamily: fonts.roboto,
    fontSize: '30px',
    fontWeight: 'bold',
    lineHeight: '1em',
    letterSpacing: '1.2px',
  },
  'avatar-extraLarge': {
    fontFamily: fonts.roboto,
    fontSize: '30px',
    fontWeight: 'bold',
    lineHeight: '43px',
    letterSpacing: '0',
  },
  'avatar-extraExtraLarge': {
    fontFamily: fonts.roboto,
    fontSize: '55px',
    fontWeight: 'bold',
    lineHeight: '1em',
    letterSpacing: '2.2px',
  },
  externalLinkBlue: {
    fontFamily: fonts.roboto,
    fontSize: '14px',
    fontWeight: 'normal',
    color: '#0083c6',
    letterSpacing: 'normal',
    fontStyle: 'italic',
    textDecoration: 'underline',
    textDecorationColor: '#0083c6',
    cursor: 'pointer',
  },
  'nav-indicator': {
    fontFamily: fonts.roboto,
    fontSize: '22px',
    fontWeight: 'bold',
    letterSpacing: '0.88px',
  },
  'nav-message': {
    fontFamily: fonts.roboto,
    fontSize: '14px',
    lineHeight: '1em',
    fontWeight: 'normal',
    fontStyle: 'italic',
    WebkitFontSmoothing: 'antialiased',
    MozOsxFontSmoothing: 'grayscale',
  },
  tooltip: {
    fontFamily: fonts.roboto,
    fontSize: '14px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'italic',
    lineHeight: 1.57,
    letterSpacing: 'normal',
    WebkitFontSmoothing: 'antialiased',
    MozOsxFontSmoothing: 'grayscale',
  },
  audioHeader: {
    fontFamily: fonts.roboto,
    fontSize: '18px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    lineHeight: 1.44,
    letterSpacing: 'normal',
  },
  audioUser: {
    fontFamily: fonts.roboto,
    fontSize: '16px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.63,
    letterSpacing: 'normal',
  },
};

export default fontStyles;
