import styled, { css } from 'styled-components';

import { rgba } from 'polished';
import { colors } from 'Abstracts/variables';
import { device } from 'Helpers/styling';

import Link from 'Components/Elements/Link';

import { LogoLink } from 'Components/Frames/_common/Frames.styles';

export const StyledLogoLink = styled(LogoLink)`
  display: inline-block;
  svg {
    width: auto;
    height: 47px;
  }
`;

export const HeaderWrapper = styled.header`
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 100;
  background: ${({ color }) =>
    color === 'transparent' ? rgba('#501CD0', 0.5) : '#ffbf00'};
  ${({ color }) =>
    color === 'transparent' &&
    css`
      border-bottom: 1px solid ${colors.light};
      backdrop-filter: blur(2.5px);
    `}
`;

export const InitialContainer = styled.div`
  margin-right: 16px;
`;

export const ParticipantWrapper = styled.div`
  display: flex;
  overflow: hidden;

  & .slick-slide {
    & div {
      &:focus {
        outline: none;
      }
    }
  }
`;

export const BadgeIconWrapper = styled.div`
  position: relative;
  line-height: 1em;
  overflow: visible;
  svg {
    max-height: 44px;
    width: auto;
    float: left;
  }
`;
