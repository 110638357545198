import styled, { keyframes, css } from 'styled-components';

import { compose, color, border, variant, space, layout } from 'styled-system';

import { rgba } from 'polished';

import { colors } from 'Abstracts/variables';

import fontStyles from 'Mixins/styles/typography';

import { ReactComponent as NextIcon } from 'Assets/images/next.svg';
import buttonVariants from './Button.variants';

export const StyledButton = styled.button`
  ${fontStyles.button};
  justify-content: center;
  display: inline-flex;
  align-items: center;
  background-color: transparent;
  color: #000;
  border: 1px solid black;
  padding: 9px 24px;
  border-radius: 60px;
  border: 1px solid transparent;
  white-space: nowrap;
  position: relative;
  overflow: hidden;
  transition: background-color 0.1s ease-in, border-color 0.1s ease-in,
    color 0.1s ease-in;

  ${({ pulsing }) =>
    !!pulsing &&
    css`
      animation: 1.2s ${pulseAnimation} cubic-bezier(0, 0, 0.58, 1) infinite;
    `}

  & #next-icon {
    width: 12px;
    height: 12px;
    margin-left: 5px;
    margin-bottom: -1px;
    path {
      stroke: #000;
    }
  }

  & #prev-icon {
    width: 12px;
    height: 12px;
    margin-right: 5px;
    margin-bottom: -1px;
    path {
      stroke: #000;
    }
  }

  &.prev {
    background: #e1e1e1;
    border: 1px solid black;
  }

  &:hover {
    background-color: ${rgba(colors.dark, 0.1)};
    transition: background-color 0.2s ease-out, border-color 0.2s ease-out,
      color 0.1s ease-out;

    cursor: pointer;
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    background: #e1e1e1;
    color: #333333;
    border-color: #e1e1e1;
    cursor: not-allowed;
    &:hover {
      background: #e1e1e1;
      color: #333333;
      border-color: #e1e1e1;
    }
  }

  ${variant({
    variants: buttonVariants,
  })};

  ${compose(color, border, space, layout)};
`;

export const ButtonIcon = styled.span`
  margin-right: 10px;
  line-height: 1em;
  > svg {
    min-width: 20px;
    min-height: 20px;
    float: left;
  }
`;

const pulseAnimation = keyframes`
    0% {
      box-shadow: 0 0 0 0 ${rgba(colors.dark, 0.3)};
    }

    50% {
      box-shadow: 0 0 0 10px ${rgba(colors.dark, 0)};
    }

    100% {
      box-shadow: 0 0 0 0 ${rgba(colors.dark, 0)};
    } 
`;
