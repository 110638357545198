import React, { useEffect, useContext } from 'react';
import { useSelector } from 'react-redux';

import { ThemeContext } from 'Contexts';
import Zendesk, { ZendeskAPI } from 'Helpers/libs/Zendesk';

const user = (state) => state.user;

const setting = {
  color: {
    theme: '#ffbf00',
    launcherText: '#fff',
  },
  launcher: {
    chatLabel: {
      'en-US': 'Need Help',
    },
  },
  contactForm: {
    fields: [
      {
        id: 'description',
        prefill: { '*': 'My pre-filled description' },
      },
    ],
  },
};

const ZendeskChat = () => {
  const reduxUser = useSelector(user);
  const { theme } = useContext(ThemeContext);

  useEffect(() => {
    if (reduxUser.data) {
      ZendeskAPI('webWidget', 'identify', {
        name: `${reduxUser.data.name} ${reduxUser.data.surname}`,
        email: reduxUser.data.email,
      });
    }
  }, [reduxUser.data]);

  useEffect(() => {
    if (theme.frame === 'session-alt' || theme.frame === 'session') {
      ZendeskAPI('webWidget', 'hide');
    } else {
      ZendeskAPI('webWidget', 'show');
    }
  }, [theme.frame]);

  useEffect(() => {
    ZendeskAPI('webWidget', 'setLocale', 'en');
  }, []);

  return (
    !!reduxUser && <Zendesk zendeskKey={process.env.ZENDESK_KEY} {...setting} />
  );
};

export default ZendeskChat;
