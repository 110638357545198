import * as types from 'Stores/types';

export function user(state = {}, action) {
  switch (action.type) {
    case types.SET_USER_START:
    case types.SET_USER_SUCCESS:
    case types.SET_USER_FAILURE:
    case types.PUT_USER_START:
    case types.PUT_USER_SUCCESS:
    case types.RESET_USER:
      return {
        ...state,
        ...action.payload,
      };
    case types.GET_PROFILE_PICTURE_START:
    case types.GET_PROFILE_PICTURE_SUCCESS:
    case types.GET_PROFILE_PICTURE_FAILURE:
    case types.UPGRADE_USER_START:
    case types.UPGRADE_USER_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload,
        },
      };
    case types.SET_PROFILE_PICTURE_START:
    case types.SET_PROFILE_PICTURE_SUCCESS:
    case types.SET_PROFILE_PICTURE_FAILURE:
      return {
        ...state,
        ...action.payload,
      };
    case types.DELETE_PROFILE_PICTURE_START:
    case types.DELETE_PROFILE_PICTURE_SUCCESS:
    case types.DELETE_PROFILE_PICTURE_FAILURE:
      return {
        ...state,
        ...action.payload,
      };
    case types.GET_PROFILE_PICTURE_BY_PARTICIPANT_START:
    case types.GET_PROFILE_PICTURE_BY_PARTICIPANT_SUCCESS:
    case types.GET_PROFILE_PICTURE_BY_PARTICIPANT_FAILURE:
    default:
      return state;
  }
}
