import { useDispatch } from 'react-redux';

import i18n from '@/i18n';

import { ReactComponent as KeywordGenerationIcon } from 'Assets/images/modules/keyword-generation.svg';

import { postKeywords } from 'Stores/actions/module';

import { basicFields } from './_common';

const parseData = ({ data }) =>
  data.moduleKeywords.data
    .reduce(
      (accumulator, currentUser) => [...accumulator, ...currentUser.keywords],
      []
    )
    .reduce((accumulator, current) => {
      const found = accumulator.findIndex((item) => item.text === current);

      const existed = found !== -1;

      return existed
        ? accumulator.map((item, index) =>
            index === found
              ? {
                  text: current,
                  value: accumulator[found].value + 1,
                }
              : item
          )
        : [...accumulator, { text: current, value: 1 }];
    }, []);

const getHandler = ({ brainstormId, user, participants, data }) => {
  const dispatch = useDispatch();

  const processedData = data.moduleKeywords.data.find(
    (datum) => datum.user === user.id
  ) || { keywords: [] };

  const handler = (value) => {
    const payload = [...processedData.keywords, ...value];

    dispatch(postKeywords(brainstormId, data.id, payload));
  };

  return handler;
};

const getAnswer = (datum) => datum.text;

const keywords = {
  name: 'keywords',
  obj: i18n.t('Generate word associations'),
  info: i18n.t('Participants share words inspired by your question.'),
  icon: KeywordGenerationIcon,
  label: i18n.t('Word Storm'),
  unit: i18n.t('keyword'),
  endpoint: 'keywords',
  color: '#FCCE44',
  getHandler,
  parseData,
  getAnswer,
  fields: [...basicFields],
  methodologies: [
    {
      label: i18n.t('Personification'),
      value: 'personification',
      description: i18n.t(
        `List as many words as you can think of to describe _____ if it were a person.`
      ),
    },
    {
      label: i18n.t('Idea Generator'),
      value: 'ideaGenerator',
      description: i18n.t(
        `Quickly enter all the words that come to mind when you think of _____. Go!`
      ),
    },
    {
      label: i18n.t('Comparison'),
      value: 'comparison',
      description: i18n.t(
        `Using single words, share how _____ compares to _____.`
      ),
    },
    {
      label: i18n.t('Oxymoron'),
      value: 'oxymoron',
      description: i18n.t(`List words that you feel are opposite to _____.`),
    },
    {
      label: i18n.t('Hyperbole'),
      value: 'hyperbole',
      description: i18n.t(
        `LIst words that amplify/exaggerate a characteristic of _____.`
      ),
    },
    {
      label: i18n.t('SWOT'),
      value: 'swot',
      description: i18n.t(
        `In a word, share a [Strength/Weakness/Opportunity/Threat] for _____?`
      ),
    },
    {
      label: i18n.t('Methaphor'),
      value: 'methaphor',
      description: i18n.t(`Share words that visually represent _____.`),
    },
    {
      label: i18n.t('N/A'),
      value: 'none',
      description: i18n.t(``),
    },
  ],
  listed: true,
};

export default keywords;
