import React, { useMemo } from 'react';

import UserSectionWithAudio from './WithAduio';

import UserSectionWithSimple from './WithSimple';

const UserSection = ({ type, ...rest }) => {
  const isAudio = useMemo(() => type === 'audio', [type]);

  return isAudio ? (
    <UserSectionWithAudio {...rest} />
  ) : (
    <UserSectionWithSimple {...rest} />
  );
};

export default UserSection;
