import React, { useContext, useEffect } from 'react';
import { ThemeContext, themeDefaultState } from 'Contexts';

const withThemeSetup = (Component, themeSetup = {}) => {
  const WrappedComponent = (props) => {
    const { changeThemeSetup } = useContext(ThemeContext);

    useEffect(() => {
      changeThemeSetup(themeSetup);

      return () => changeThemeSetup(themeDefaultState);
    }, []);

    return <Component {...props} />;
  };

  WrappedComponent.displayName = `ComponentWithThemeSetup`;

  return WrappedComponent;
};

export default withThemeSetup;
