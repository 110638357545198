import React from 'react';

import { DndProvider } from 'react-dnd-multi-backend';
import HTML5toTouch from 'react-dnd-multi-backend/dist/esm/HTML5toTouch';

const withDND = (Component) => {
  const WrappedComponent = (props) => {
    return (
      <DndProvider options={HTML5toTouch}>
        <Component {...props} />
      </DndProvider>
    );
  };

  WrappedComponent.displayName = `ComponentWithDND`;

  return WrappedComponent;
};

export default withDND;
