import React from 'react';

import Modal from 'Components/Elements/Modal';
// styles
import { ImageWrapper, ThumbnailWrapper } from './Chat.styles';

export default function ChatImage(props) {
  const {
    image,
    thumb,
    index,
    show,
    onClose,
    onClick,
    rightAligned = false,
    onLoad,
    ...rest
  } = props;
  return (
    <>
      <ThumbnailWrapper
        rightAligned={rightAligned}
        src={thumb}
        alt={`chat_thumb${index}`}
        onClick={onClick}
        onLoad={onLoad}
      />
      <Modal show={show} position="center" closable onClose={onClose} fullPage>
        <ImageWrapper src={image} alt={`chat_image${index}`} zoom />
      </Modal>
    </>
  );
}
