import axios from 'axios';

// const pattern = /^https?[^ \!@\$\^\(\)\+]+(\.png|\.jpeg|\.jpg)/i;

const isImageUrl = async (string) => {
  let value = string.trim();

  try {
    const contentType = await axios
      .head(value)
      .then((response) => response.headers['Content-Type']);

    switch (contentType) {
      case 'image/jpeg':
      case 'image/png':
        return true;
      default:
        return false;
    }
  } catch (err) {
    return false;
  }

  // return pattern.test(value);
};

export default isImageUrl;
